import React, { useEffect, useRef } from 'react';
import IMask from 'imask';
import Input from '../html/Input';

const DateOfBirthInput = ({ value, disabled, required, onChange }) => {
  const inputRef = useRef();
  const maskRef = useRef();
  const valueRef = useRef(value);
  const internalChangeRef = useRef(false);

  useEffect(() => {
    const input = inputRef.current;

    if (!input) {
      return;
    }

    const today = new Date();

    const mask = IMask(input, {
      mask: Date,
      pattern: 'm/`d/`Y',
      format: date => {
        let day = date.getDate();
        let month = date.getMonth() + 1;
        const year = date.getFullYear();

        if (day < 10) {
          day = '0' + day;
        }

        if (month < 10) {
          month = '0' + month;
        }

        return [month, day, year].join('/');
      },
      parse: str => {
        const [month, day, year] = str.split('/');
        return new Date(year, month - 1, day);
      },
      lazy: false,
    });

    syncValue(mask, valueRef.current);

    maskRef.current = mask;

    const regex = /(\d{2})\/(\d{2})\/(\d{4})/;

    const handleAccept = () => {
      internalChangeRef.current = true;

      onChange();

      requestIdleCallback(() => {
        internalChangeRef.current = false;
      });
    };

    const handleComplete = () => {
      const [, month, day, year] = mask.value.match(regex);

      onChange(`${year}-${month}-${day}`);
    };

    const handleBlur = () => {
      if (!mask.value.match(regex)) {
        mask.unmaskedValue = '';
      }
    };

    mask.on('accept', handleAccept);
    mask.on('complete', handleComplete);

    input.addEventListener('blur', handleBlur);

    return () => {
      input.removeEventListener('blur', handleBlur);
      mask.off('complete', handleComplete);
      mask.off('accept', handleAccept);
      mask.destroy();
    };
  }, []);

  useEffect(() => {
    const mask = maskRef.current;

    if (!mask) {
      return;
    }

    if (internalChangeRef.current) {
      return;
    }

    syncValue(mask, value);
  }, [value]);

  const complete = value?.match(/(\d{4})-(\d{2})-(\d{2})/);

  useEffect(() => {
    const input = inputRef.current;

    if (!input) {
      return;
    }

    input.setCustomValidity(required && !complete ? 'Date of birth is required' : '');
  }, [required, complete]);

  return (
    <Input
      ref={inputRef}
      autoComplete="on"
      autoCapitalize="off"
      autoCorrect="off"
      disabled={disabled}
      required={required}
    />
  );
};

export default DateOfBirthInput;

function syncValue(mask, value) {
  if (value) {
    const match = value.match(/(\d{4})-(\d{2})-(\d{2})/);

    if (match) {
      const [, year, month, day] = match;

      const unmaskedValue = `${month}${day}${year}`;

      if (unmaskedValue !== mask.unmaskedValue) {
        mask.unmaskedValue = unmaskedValue;
      }
    } else {
      mask.unmaskedValue = '';
    }
  } else {
    mask.unmaskedValue = '';
  }
}
