import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

interface DailyCapacityViewProps {
  dailyCapacity: {
    monday?: number;
    tuesday?: number;
    wednesday?: number;
    thursday?: number;
    friday?: number;
    saturday?: number;
    sunday?: number;
  };
}

const DailyCapacityView = ({ dailyCapacity }: DailyCapacityViewProps) => {
  const classes = useStyles();

  return (
    <dl className={classes.dl}>
      <dt className={classes.dt}>Mon</dt>
      <dd className={classes.dd}>{dailyCapacity.monday}</dd>
      <dt className={classes.dt}>Tue</dt>
      <dd className={classes.dd}>{dailyCapacity.tuesday}</dd>
      <dt className={classes.dt}>Wed</dt>
      <dd className={classes.dd}>{dailyCapacity.wednesday}</dd>
      <dt className={classes.dt}>Thu</dt>
      <dd className={classes.dd}>{dailyCapacity.thursday}</dd>
      <dt className={classes.dt}>Fri</dt>
      <dd className={classes.dd}>{dailyCapacity.friday}</dd>
      <dt className={classes.dt}>Sat</dt>
      <dd className={classes.dd}>{dailyCapacity.saturday}</dd>
      <dt className={classes.dt}>Sun</dt>
      <dd className={classes.dd}>{dailyCapacity.sunday}</dd>
    </dl>
  );
};

export default DailyCapacityView;

const useStyles = makeStyles(theme => ({
  dl: {
    display: 'grid',
    gridTemplateRows: 'auto auto',
    gridAutoColumns: '1fr',
    gridAutoFlow: 'column',
    columnGap: '0.5em',
    justifyItems: 'center',
    justifyContent: 'start',
    marginInlineStart: 'unset',
  },
  dt: {
    lineHeight: 1,
    textTransform: 'uppercase',
    fontSize: '0.5em',
  },
  dd: {
    lineHeight: 1,
  },
}));
