import React, { Fragment } from 'react';
import { compose, withProps } from 'recompose';
import { withLabel } from '../../../../se/components/Label';
import NumberInput from '../../../../se/components/inputs/NumberInput';
import SelectInput from '../../../../se/components/inputs/SelectInput';

const DelayInput = withLabel('Delay (in minutes)')(NumberInput);

const hoursOptions = {
  0: 'Midnight',
  1: '1 AM',
  2: '2 AM',
  3: '3 AM',
  4: '4 AM',
  5: '5 AM',
  6: '6 AM',
  7: '7 AM',
  8: '8 AM',
  9: '9 AM',
  10: '10 AM',
  11: '11 AM',
  12: 'Noon',
  13: '1 PM',
  14: '2 PM',
  15: '3 PM',
  16: '4 PM',
  17: '5 PM',
  18: '6 PM',
  19: '7 PM',
  20: '8 PM',
  21: '9 PM',
  22: '10 PM',
  23: '11 PM',
};

const hourDefaultValue = 9;

const HourInput = compose(
  withLabel('Send at'),
  withProps({
    options: hoursOptions,
    required: true,
  })
)(SelectInput);

const repeatOptions = {
  0: 'Never',
  1: 'Every 1 days',
  2: 'Every 2 days',
  3: 'Every 3 days',
  4: 'Every 4 days',
  5: 'Every 5 days',
  6: 'Every 6 days',
  7: 'Every 7 days',
  8: 'Every 8 days',
  9: 'Every 9 days',
  10: 'Every 10 days',
  14: 'Every 14 days',
};

const repeatDefaultValue = 0;

export const RepeatInput = compose(
  withLabel('Repeat'),
  withProps({
    options: repeatOptions,
    required: false,
  })
)(SelectInput);

const OffsetDaysInputOptions = {
  1: '1 day',
  2: '2 days',
  3: '3 days',
  4: '4 days',
  5: '5 days',
  6: '6 days',
  7: '7 days',
  8: '8 days',
  9: '9 days',
  10: '10 days',
};

const OffsetDaysInputDefaultValue = 2;

export const OffsetDaysInput = compose(
  withLabel('Prior to'),
  withProps({
    options: OffsetDaysInputOptions,
    required: true,
  })
)(SelectInput);

const limitOptions = {
  1: 'Maximum 1 times',
  2: 'Maximum 2 times',
  3: 'Maximum 3 times',
  4: 'Maximum 4 times',
  5: 'Maximum 5 times',
  6: 'Maximum 6 times',
};

const limitDefaultValue = 1;

export const LimitInput = compose(
  withLabel('Limit'),
  withProps({
    options: limitOptions,
    required: false,
  })
)(SelectInput);

const getOffsetInputOptions = () =>
  [...Array(25).keys()]
    .slice(1)
    .reduce((res, e) => ({ ...res, [e]: e === 1 ? `${e} hour before` : `${e} hours before` }), {});

const offsetDefaultValue = 6;

export const OffsetInput = compose(
  withLabel('Send X hours before scheduled procedure'),
  withProps({
    options: getOffsetInputOptions(),
    required: true,
  })
)(SelectInput);

const validateNumberInput = (value, defaultValue) => (isNaN(parseInt(value, 10)) ? defaultValue : value);

export default ({ value, onChange }) => {
  const matchDelay = value.match(/^([^+]+)\+(\d+)$/);
  const matchNegativeOffset = value.match(/^([^-]+)-(\d+)$/);
  const matchHour = value.match(/^([^@]+)@(\d+)$/);
  const matchHourAndOffsetDay = value.match(/^([^@-]+)@(\d+)-(\d+)$/);
  const matchRepeatAt = value.match(/^([^@]+)@(\d+)\^\((\d+),(\d+)\)$/);
  const match = matchDelay || matchNegativeOffset || matchHour || matchRepeatAt;

  const trigger = matchHourAndOffsetDay ? matchHourAndOffsetDay[1] : match ? match[1] : null;
  const firstArg = matchHourAndOffsetDay
    ? parseInt(matchHourAndOffsetDay[2], 10)
    : match
      ? parseInt(match[2], 10)
      : null;
  const secondArg = matchRepeatAt
    ? parseInt(matchRepeatAt[3], 10)
    : matchHourAndOffsetDay
      ? parseInt(matchHourAndOffsetDay[3], 10)
      : null;
  const thirdArg = matchRepeatAt ? parseInt(matchRepeatAt[4], 10) : null;
  return (
    <Fragment>
      {matchDelay && <DelayInput value={firstArg} onChange={d => onChange(`${trigger}+${d}`)} />}
      {matchNegativeOffset && !matchHourAndOffsetDay && (
        <OffsetInput
          value={firstArg}
          onChange={d => onChange(`${trigger}-${validateNumberInput(d, offsetDefaultValue)}`)}
        />
      )}
      {matchHour && (
        <HourInput
          value={firstArg}
          onChange={d => onChange(`${trigger}@${validateNumberInput(d, hourDefaultValue)}`)}
        />
      )}
      {matchHourAndOffsetDay && (
        <Fragment>
          <HourInput
            value={firstArg}
            onChange={d => {
              onChange(`${trigger}@${validateNumberInput(d, hourDefaultValue)}-${secondArg}`);
            }}
          />
          <OffsetDaysInput
            value={secondArg}
            onChange={d => onChange(`${trigger}@${firstArg}-${validateNumberInput(d, OffsetDaysInputDefaultValue)}`)}
          />
        </Fragment>
      )}
      {matchRepeatAt && (
        <Fragment>
          <HourInput
            value={firstArg}
            onChange={d =>
              onChange(`${trigger}@${validateNumberInput(d, hourDefaultValue)}^(${secondArg},${thirdArg})`)
            }
          />
          <RepeatInput
            value={secondArg}
            onChange={d =>
              onChange(`${trigger}@${firstArg}^(${validateNumberInput(d, repeatDefaultValue)},${thirdArg})`)
            }
          />
          <LimitInput
            value={thirdArg}
            onChange={d =>
              onChange(`${trigger}@${firstArg}^(${secondArg},${validateNumberInput(d, limitDefaultValue)})`)
            }
          />
        </Fragment>
      )}
    </Fragment>
  );
};
