import iconCleaning from '../../../assets/images/status/cleaning.svg';
import iconIdle from '../../../assets/images/status/idle.svg';
import iconTimeOut from '../../../assets/images/status/time_out.svg';
import iconOngoing from '../../../assets/images/status/ongoing.svg';
import iconClosing from '../../../assets/images/status/closing.svg';
import iconVacant from '../../../assets/images/status/vacant.svg';

import { STATUSES as ROOM_STATUSES } from '../room/enums';

export const ICONS = [
  'bug_report',
  'explore',
  'extension',
  'home',
  'language',
  'settings',
  'theaters',
  'work',
  'album',
  'mic',
  'radio',
  'call',
  'create',
  'send',
  'attach_file',
  'format_paint',
  'toys',
  'watch',
  'videogame_asset',
  'brush',
  'color_lens',
  'local_florist',
  'wb_sunny',
  'golf_course',
  'casino',
  'cake',
  'beach_access',
  'ac_unit',
  'local_shipping',
  'directions_boat',
];

export const COLORS = [
  '#7ED321',
  '#50E3C2',
  '#00A7F7',
  '#B057FF',
  '#FF5ABB',
  '#F55323',
  '#B29377',
  '#FFAC00',
  '#F8E71C',
  '#9B9B9B',
];

export const NO_STATUS = '';

export const STATUSES = {
  ADMITTED: 'Admitted',
  IN_OR: 'InOr',
  READY: 'Ready',
  ANESTHETIC_START: 'AnestheticStart',
  READY_FOR_SURGERY: 'ReadyForSurgery',
  PHYSICIAN_IN: 'PhysicianIn',
  TIME_OUT: 'TimeOut',
  ONGOING: 'Ongoing',
  TIME_OUT_2: 'TimeOut2',
  OPERATION_START: 'ProcedureStart',
  CALL_NEXT_PATIENT: 'CallNextPatient',
  CLOSING: 'Closing',
  SURGEON_LEFT_OR: 'SurgeonLeftOR',
  DRESSING_ON: 'DressingOn',
  OPERATION_END: 'ProcedureEnd',
  ANESTHETIC_END: 'AnestheticEnd',
  DONE: 'Done',
  DISCHARGED: 'Discharged',
  CANCELED: 'Canceled',
};

export const STATUS_LABELS = {
  [STATUSES.ADMITTED]: 'Admitted',
  [STATUSES.IN_OR]: 'In OR',
  [STATUSES.READY]: 'In OR',
  [STATUSES.ANESTHETIC_START]: 'Anesthetic Start',
  [STATUSES.READY_FOR_SURGERY]: 'Ready For Surgery',
  [STATUSES.PHYSICIAN_IN]: 'Physician In',
  [STATUSES.TIME_OUT]: 'Time Out',
  [STATUSES.ONGOING]: 'Ongoing',
  [STATUSES.TIME_OUT_2]: 'Ongoing',
  [STATUSES.OPERATION_START]: 'Operation Started',
  [STATUSES.CALL_NEXT_PATIENT]: 'Called',
  [STATUSES.CLOSING]: 'Closing',
  [STATUSES.SURGEON_LEFT_OR]: 'Surgeon Leaves',
  [STATUSES.DRESSING_ON]: 'Dressing On',
  [STATUSES.OPERATION_END]: 'Procedure End',
  [STATUSES.ANESTHETIC_END]: 'Anesthetic End',
  [STATUSES.DONE]: 'Done',
  [STATUSES.DISCHARGED]: 'Discharged',
  [STATUSES.CANCELED]: 'Canceled',
};

export const MONITOR_STATUS_ICONS = {
  [ROOM_STATUSES.VACANT]: iconVacant,
  [STATUSES.IN_OR]: iconIdle,
  [STATUSES.READY]: iconIdle,
  [STATUSES.TIME_OUT]: iconTimeOut,
  [STATUSES.ANESTHETIC_START]: iconTimeOut,
  [STATUSES.READY_FOR_SURGERY]: iconTimeOut,
  [STATUSES.PHYSICIAN_IN]: iconTimeOut,
  [STATUSES.TIME_OUT_2]: iconOngoing,
  [STATUSES.CALL_NEXT_PATIENT]: iconOngoing,
  [STATUSES.ONGOING]: iconOngoing,
  [STATUSES.ANESTHETIC_END]: iconClosing,
  [STATUSES.OPERATION_END]: iconClosing,
  [STATUSES.CLOSING]: iconClosing,
  [STATUSES.SURGEON_LEFT_OR]: iconClosing,
  [STATUSES.DRESSING_ON]: iconClosing,
  [ROOM_STATUSES.CLEANING]: iconCleaning,
};
export const MONITOR_STATUSES = [
  STATUSES.IN_OR,
  STATUSES.READY,
  STATUSES.ANESTHETIC_START,
  STATUSES.READY_FOR_SURGERY,
  STATUSES.PHYSICIAN_IN,
  STATUSES.TIME_OUT,
  STATUSES.TIME_OUT_2,
  STATUSES.ONGOING,
  STATUSES.CALL_NEXT_PATIENT,
  STATUSES.CLOSING,
  STATUSES.SURGEON_LEFT_OR,
  STATUSES.DRESSING_ON,
  STATUSES.OPERATION_END,
  STATUSES.ANESTHETIC_END,
];

export const TABLET_IGNORE_STATUSES = [STATUSES.ADMITTED, STATUSES.DISCHARGED, STATUSES.DONE, ROOM_STATUSES.VACANT];

export const MONITOR_IGNORE_STATUSES = [
  STATUSES.ADMITTED,
  STATUSES.DISCHARGED,
  STATUSES.CANCELED,
  STATUSES.DONE,
  ROOM_STATUSES.VACANT,
  ROOM_STATUSES.CLEANING,
];

export const PATIENT_EVENT_TYPES = {
  CREATED: 'Created',
  ENTERED: 'Entered',
  ADMITTED: 'Admitted',
  EXITED: 'Exited',
  PROCEDURE_STATUS_SET: 'ProcedureStatusSet',
  DISCHARGED: 'Discharged',
  ARCHIVED: 'Archived',
  BECAME_READY: 'BecameReady',
  BECAME_FAMILY_READY_IN_PREP: 'ReadyToSeeFamilyPreOp',
  BECAME_READY_IN_PREP: 'BecameReadyInPREP',
  BECAME_READY_IN_PACU: 'BecameReadyInPACU',
  BECAME_READY_IN_POSTOP: 'BecameReadyInPOSTOP',
  READY_FOR_ANESTHESIA: 'ReadyForAnesthesia',
  READY_FOR_SURGEON: 'ReadyForSurgeon',
  NOT_FAMILY_READY_IN_PREP: 'NotReadyToSeeFamilyPreOp',
  NOT_READY_IN_PACU: 'NotReadyInPACU',
  NOT_READY_IN_POSTOP: 'NotReadyInPOSTOP',
  NOT_READY_IN_PREP: 'NotReadyInPREP',
  NOT_READY_FOR_ANESTHESIA: 'NotReadyForAnesthesia',
  NOT_READY_FOR_SURGEON: 'NotReadyForSurgeon',
  READY_FOR_SURGEON_NOTIFY: 'ReadyForSurgeonNotify',
  READY_FOR_ANESTHESIOLOGIST_NOTIFY: 'ReadyForAnesthesiologistNotify',
  FAMILY_READY_PACU: 'FamilyReadyPACU',
  HOLD_PROCEDURE: 'HoldProcedure',
  UNDO_HOLD_PROCEDURE: 'UndoHoldProcedure',
  BLOCK_NERVE: 'BlockNerve',
  UNDO_BLOCK_NERVE: 'UndoBlockNerve',
  HELP: 'Help',
  UNDO_HELP: 'UndoHelp',
};

export const PROCEDURE_STATUSES = {
  IN_OR: 'InOr',
  READY: 'Ready',
  ANESTHETIC_START: 'AnestheticStart',
  READY_FOR_SURGERY: 'ReadyForSurgery',
  PHYSICIAN_IN: 'PhysicianIn',
  TIME_OUT: 'TimeOut',
  TIME_OUT_2: 'TimeOut2',
  ONGOING: 'Ongoing',
  CALL_NEXT_PATIENT: 'CallNextPatient',
  CLOSING: 'Closing',
  SURGEON_LEFT_OR: 'SurgeonLeftOR',
  DRESSING_ON: 'DressingOn',
  OPERATION_END: 'ProcedureEnd',
  ANESTHETIC_END: 'AnestheticEnd',
  COMPLETED: 'Completed',
};

export const OR_FLOW = [
  PROCEDURE_STATUSES.IN_OR,
  PROCEDURE_STATUSES.READY,
  PROCEDURE_STATUSES.ANESTHETIC_START,
  PROCEDURE_STATUSES.TIME_OUT,
  PROCEDURE_STATUSES.READY_FOR_SURGERY,
  PROCEDURE_STATUSES.PHYSICIAN_IN,
  PROCEDURE_STATUSES.ONGOING,
  PROCEDURE_STATUSES.CALL_NEXT_PATIENT,
  PROCEDURE_STATUSES.TIME_OUT_2,
  PROCEDURE_STATUSES.CLOSING,
  PROCEDURE_STATUSES.SURGEON_LEFT_OR,
  PROCEDURE_STATUSES.DRESSING_ON,
  PROCEDURE_STATUSES.OPERATION_END,
  PROCEDURE_STATUSES.ANESTHETIC_END,
  PROCEDURE_STATUSES.COMPLETED,
];
