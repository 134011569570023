import { useMutation } from '@apollo/client';
import { Box, Button, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { useState } from 'react';
import { compose, withProps } from 'recompose';
import { setPatientEventTimestamp } from '../../../../graph/procedureSteps';
import ObjectInput from '../../../../se/components/inputs/ObjectInput';
import TextInput from '../../../../se/components/inputs/TextInput';
import { withLabel } from '../../../../se/components/Label';
import { RouterlessModal } from '../../../../se/components/Modal';
import { CancelableForm, DateInput, formatTime } from '../../../inputs/timeEdit/TimeEditForm';
import { makeStyles } from '@material-ui/core/styles';
import PatientEventInput from './PatientEventInput';
import { ZonedDateTime } from '@js-joda/core';

export const convertToDateTime = (timestamp, time) => {
  const dateTime = new Date(timestamp);
  const [hours, minutes] = time.split(':').map(e => parseInt(e, 10));
  dateTime.setHours(hours);
  dateTime.setMinutes(minutes);
  return dateTime;
};

const getEventType = event => {
  switch (event?.type) {
    case 'Created':
    case 'Discharged':
    case 'BlockNerve':
    case 'CleaningCompleted':
      return { __type: `${event?.type}$` };
    case 'Entered':
      return { __type: 'Entered', roomId: event?.room?.id, manualEntryBy: null, manualTimestamp: null };
    case 'Exited':
      return { __type: 'Exited', roomId: event?.room?.id, manualEntryBy: null };
    case 'ProcedureStatusSet':
      return {
        __type: 'ProcedureStatusSet',
        status: { [event?.status]: {} },
        manualEntryBy: null,
        manualTimestamp: null,
      };
    default:
      return null;
  }
};

export const FormInput = withProps({
  schema: {
    eventType: props => <PatientEventInput {...props} />,
    time: compose(
      withLabel('Time'),
      withProps({
        type: 'datetime-local',
        // style: { fontSize: '2rem', lineHeight: '1.8rem', minHeight: '1.5em' },
      })
    )(TextInput),
  },
})(ObjectInput);

const PatientEventCreateButton = ({ patientId, date }) => {
  const [create, setCreate] = useState(false);
  const [save] = useMutation(setPatientEventTimestamp);
  const classes = useStyles();

  const toggleCreate = () => {
    setCreate(prev => !prev);
  };

  const saveTimestamp = async (eventType, timestamp) => {
    await save({
      variables: {
        patientId,
        eventType,
        timestamp: new Date(timestamp).toISOString(),
      },
    });
    toggleCreate();
  };

  const onSubmit = async val => {
    const eventType = val?.eventType;
    const timestamp = val?.time;

    if (!eventType || !timestamp) {
      return;
    }

    await saveTimestamp(eventType, timestamp);
  };

  return (
    <>
      <Box>
        <IconButton color="primary" onClick={toggleCreate} className={classes.mobileButton}>
          <AddIcon />
        </IconButton>
      </Box>
      <Button
        variant="outlined"
        size="small"
        onClick={toggleCreate}
        startIcon={<AddIcon />}
        className={classes.desktopButton}
      >
        Create Event
      </Button>
      {create && (
        <RouterlessModal title="Create Event" onClose={toggleCreate}>
          <CancelableForm
            autoFocus
            label="Create Event"
            onClose={toggleCreate}
            input={FormInput}
            onSubmit={onSubmit}
            busy={false}
            initialValue={{
              time: date + 'T' + formatTime(new Date().toISOString()) ?? '00:00',
            }}
          />
        </RouterlessModal>
      )}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  mobileButton: {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  desktopButton: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

export default PatientEventCreateButton;
