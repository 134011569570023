import React, { FC, ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';
import { light as lightTheme } from './theme';
import { ThemeProvider as MUIThemeProvider } from '@material-ui/styles';
import { muiThemeLight } from './muiTheme';
import { CssBaseline } from '@material-ui/core';

const WhiteThemeProviderWrapper: FC<{ children: ReactNode }> = ({ children }) => (
  <ThemeProvider theme={lightTheme}>
    <MUIThemeProvider theme={muiThemeLight}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  </ThemeProvider>
);

export default WhiteThemeProviderWrapper;
