import React, { Fragment } from 'react';
import { format, parse } from 'date-fns';
import styled, { css } from 'styled-components';
import responsive from '../../../se/utilities/responsive';
import get from 'lodash/get';
import { translateToUTC } from '../../../util/dateTime';
import { isSameMonth } from 'date-fns';
import { withTheme } from '../../../se/theme';
import Slot from './Slot';
import { HeaderCell } from './Cell';

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(1, max-content) repeat(5, 1fr);
  grid-template-rows: repeat(${props => props.numberOfRows}, min-content);
  grid-column-gap: 0.1666666667em;
  grid-auto-flow: columns;
  margin-bottom: 0.33333em;
`;

const Slots = styled.div`
  display: grid;
  grid-column: 2 / span 6;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 0.1666666667em;
  margin-bottom: 0.08333333333em;
`;

const Row = styled(Slots)`
  grid-template-rows: auto;
  margin-bottom: 0;
`;

const OrCell = styled(HeaderCell)`
  ${responsive.md.andSmaller`
    writing-mode: vertical-rl;
    text-orientation: mixed;
  `}
`;

const Day = styled.div`
  font-size: 1.25em;
  color: ${withTheme(theme => theme.textColor.string())};
`;

const Timeline = styled.div`
  background: rgba(255, 255, 255, 0.08);
  min-height: 4em;
  position: relative;
  display: flex;
  justify-content: space-evenly;
  ${props =>
    props.makeRoom &&
    css`
      padding-top: 1.5em;
      min-height: 5.5em;
    `}
`;

const DateHeader = ({ dates = [] }) => (
  <Row>
    {dates.map((day, i) => (
      <Fragment key={`${day}-${i}`}>
        <HeaderCell>
          <Day>{format(day, 'D')}</Day>
        </HeaderCell>
      </Fragment>
    ))}
  </Row>
);

const VerticalTick = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > label {
    position: absolute;
    top: 0;
    left: auto;
    opacity: 0.6;
  }

  > span {
    display: block;
    height: 100%;
    width: 0.08rem;
    background-color: rgba(255, 255, 255, 0.12);
  }
`;

const Week = ({
  dates = [],
  bookings = {},
  dateSelection,
  physicianSelection,
  rooms,
  onCellClick,
  showDayNames,
  firstWeek,
  colorMode,
}) => {
  const dayNames = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
  const hours = [8, 10, 12, 14, 16];
  return (
    <Container numberOfRows={showDayNames ? 3 : 2}>
      {showDayNames && (
        <Fragment>
          <HeaderCell style={{ marginBottom: '0.1666666667em' }} />
          <Row>
            {dayNames.map((day, i) => (
              <Fragment key={`${day}-${i}`}>
                <HeaderCell style={{ marginBottom: '0.1666666667em' }}>{day}</HeaderCell>
              </Fragment>
            ))}
          </Row>
        </Fragment>
      )}
      <HeaderCell />

      <DateHeader dates={dates} />
      {rooms.map((room, i) => (
        <Fragment key={`${room.id}-${i}`}>
          <OrCell style={{ gridColumn: 1 }}>{room.name}</OrCell>
          <Slots>
            {dates.map((date, k) => (
              <Timeline key={`${date}-${k}`} makeRoom={firstWeek && i === 0}>
                {hours.map(h => (
                  <VerticalTick key={`hour-${h}`}>
                    {firstWeek && i === 0 && <label>{h}</label>}
                    <span />
                  </VerticalTick>
                ))}
                <Slot
                  colorMode={colorMode}
                  grayed={!isSameMonth(date, dateSelection)}
                  physicianSelection={physicianSelection}
                  key={date}
                  date={date}
                  bookings={get(bookings, `${room.id}_${format(date, 'YYYY-MM-DD')}`, []).map(booking => ({
                    ...booking,
                    start: translateToUTC(parse(booking.start)),
                    end: translateToUTC(parse(booking.end)),
                  }))}
                  onClick={value => {
                    const booker =
                      value.booking && value.booking.physician
                        ? `physician-${value.booking.physician.id}`
                        : value.booking && value.booking.organization
                          ? `partner-${value.booking.organization.id}`
                          : 'open';
                    onCellClick({ room, date, booker, ...value });
                  }}
                />
              </Timeline>
            ))}
          </Slots>
        </Fragment>
      ))}
    </Container>
  );
};

export default Week;
