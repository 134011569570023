import React, { useState } from 'react';
import ScheduleUser from '../../../../types/ScheduleUser';
import { Button, TableCell, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Destination } from '../../../../types/NotificationTemplate';
import { VendorScheduleAccessEditor } from '../../../entities/vendor/Vendors';
import { item as vendor, listAssignedVendors } from '../../../../graph/vendors';
import format from 'date-fns/format';
import { item as staffMember, listAssignedStaffMembers } from '../../../../graph/staff';
import { StaffMemberScheduleAccessEditor } from '../../../entities/staff/StaffMembers';
import { item as anesthesiologist, listAssignedAnesthesiologists } from '../../../../graph/anesthesiologists';
import { AnesthesiologistScheduleAccessEditor } from '../../../entities/anesthesiologist/Anesthesiologists';

interface ScheduleAccessProps {
  date: Date;
  scheduleUser: ScheduleUser;
  destination: Destination;
}

const getScheduleAccessLabel = (hasScheduleAccess: boolean, scheduleAccessAll: boolean) => {
  if (hasScheduleAccess && scheduleAccessAll) return 'Full schedule access';
  else if (hasScheduleAccess) return 'Restricted schedule access';
  else return 'No schedule access';
};

const ScheduleAccess = ({ date, scheduleUser, destination }: ScheduleAccessProps) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const onClose = () => setOpen(false);

  return (
    <>
      <TableCell>
        <Typography
          color={
            scheduleUser.hasScheduleAccess && scheduleUser.scheduleAccessAll
              ? 'inherit'
              : scheduleUser.hasScheduleAccess
                ? 'inherit'
                : 'error'
          }
          className={classes.tableCellText}
        >
          {getScheduleAccessLabel(scheduleUser.hasScheduleAccess, scheduleUser.scheduleAccessAll)}
        </Typography>
      </TableCell>
      <TableCell>
        <Button
          variant="outlined"
          color="primary"
          onClick={e => {
            e.stopPropagation();
            setOpen(true);
          }}
        >
          Change
        </Button>
      </TableCell>

      {open && destination === Destination.Vendor && (
        <VendorScheduleAccessEditor
          vendor={scheduleUser}
          refetchQueries={[
            { query: vendor, variables: { id: scheduleUser?.id } },
            { query: listAssignedVendors, variables: { date: format(date, 'YYYY-MM-DD') } },
          ]}
          onClose={onClose}
        />
      )}
      {open && destination === Destination.StaffMember && (
        <StaffMemberScheduleAccessEditor
          staffMember={scheduleUser}
          refetchQueries={[
            { query: staffMember, variables: { id: (scheduleUser as any)?.staffId } },
            { query: listAssignedStaffMembers, variables: { date: format(date, 'YYYY-MM-DD') } },
          ]}
          onClose={onClose}
        />
      )}
      {open && destination === Destination.Anesthesiologist && (
        <AnesthesiologistScheduleAccessEditor
          anesthesiologist={scheduleUser}
          refetchQueries={[
            { query: anesthesiologist, variables: { id: scheduleUser?.id } },
            { query: listAssignedAnesthesiologists, variables: { date: format(date, 'YYYY-MM-DD') } },
          ]}
          onClose={onClose}
        />
      )}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  tableCellText: {
    whiteSpace: 'nowrap',
  },
}));

export default ScheduleAccess;
