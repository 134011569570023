const customStyles = ({ theme, menuWidthFollowContent, hasError }) => ({
  option: (base, state) => ({
    ...base,
    padding: theme.input.padding,
    color: theme.textColor.string(),
    backgroundColor: state.isFocused
      ? theme.textColor.darken(0.2).fade(0.85).string()
      : state.isSelected
        ? theme.textColor.darken(0.2).fade(0.75).string()
        : 'transparent',

    '&:hover': {
      backgroundColor: theme.textColor.darken(0.2).fade(0.75).string(),
    },
    fontSize: theme.textSize.m,
    lineHeight: 1.25,
    whiteSpace: menuWidthFollowContent ? 'nowrap' : 'normal',
  }),
  control: (base, state) => ({
    ...base,
    borderWidth: theme.border.width.default,
    borderRadius: theme.border.radius.default,
    borderStyle: 'solid',
    borderColor: state.isFocused
      ? theme.border.color.focus.string()
      : hasError
        ? theme.border.color.warning.string()
        : theme.border.color.default.string(),
    boxShadow: 'none',
    '&:hover, &:focus': {
      borderColor: theme.border.color.focus.string(),
      outline: 'none',
    },
    backgroundColor: theme.backgroundColor.string(),
    fontSize: theme.textSize.m,
    padding: theme.input.padding,
    lineHeight: theme.lineHeight.xs,
    opacity: state.isDisabled ? theme.input.disabled.opacity : '1',
  }),
  menuList: base => ({
    ...base,
    zIndex: 20,
    width: menuWidthFollowContent ? 'max-content' : '100%',
  }),
  menu: base => ({
    ...base,
    width: menuWidthFollowContent ? 'auto' : '100%',
    minWidth: menuWidthFollowContent ? 'unset' : '25em',
    zIndex: 18,
    backgroundColor: theme.backgroundColor.string(),
    border: `${theme.border.width.default} solid ${theme.border.color.default.string()}`,
  }),
  valueContainer: base => ({
    ...base,
    padding: 0,
  }),
  singleValue: (base, state) => ({
    ...base,
    padding: `${theme.input.padding} 0`,
    color: state.isDisabled ? theme.textColor.fade(0.5).string() : theme.textColor.string(),
  }),
  input: base => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
    margin: 0,
    color: theme.textColor.string(),
  }),
  indicatorSeparator: base => ({
    ...base,
    marginTop: 0,
    marginBottom: 0,
    backgroundColor: theme.border.color.default.string(),
  }),
  placeholder: base => ({
    ...base,
    color: theme.textColor.fade(0.5).string(),
  }),
  noOptionsMessage: base => ({ ...base, color: theme.textColor.string() }),
  loadingMessage: base => ({ ...base, color: theme.textColor.string() }),
  loadingIndicator: base => ({
    ...base,
    padding: `0 ${theme.input.padding}`,
    color: theme.textColor.fade(0.5).string(),
    '& > span': {
      backgroundColor: theme.textColor.fade(0.25).string(),
    },
  }),
  dropdownIndicator: base => ({
    ...base,
    padding: 0,
    paddingLeft: `${theme.input.padding}`,
    color: theme.textColor.fade(0.5).string(),
    '&:hover': {
      color: theme.textColor.fade(0.25).string(),
    },
    '& > svg': {
      width: theme.textSize.m,
      height: theme.textSize.m,
    },
  }),
  clearIndicator: base => ({
    ...base,
    padding: `0 ${theme.input.padding}`,
    color: theme.textColor.fade(0.5).string(),
    '&:hover': {
      color: theme.textColor.fade(0.25).string(),
    },
    '& > svg': {
      width: theme.textSize.m,
      height: theme.textSize.m,
    },
  }),
  group: base => ({ ...base, color: theme.textColor.string() }),
  multiValue: base => ({
    ...base,
    backgroundColor: theme.backgroundColor.string(),
    color: theme.textColor.string(),
    border: `solid 1px ${theme.border.color.default.string()}`,
  }),
  multiValueLabel: base => ({
    ...base,
    color: theme.textColor.string(),
    padding: '6px',
  }),
  multiValueRemove: base => ({
    ...base,
    '&:hover': {
      backgroundColor: theme.button.danger.color.string(),
    },
  }),
});

export default customStyles;
