import { Mutation } from '@apollo/client/react/components';
import React from 'react';
import { setPatientNotReadyInPACU } from '../../../../graph/patients';

export const withSetPatientNotReadyInPACU = Component => props => (
  <Mutation mutation={setPatientNotReadyInPACU}>
    {setPatientNotReadyInPACU => <Component {...props} setPatientNotReadyInPACU={setPatientNotReadyInPACU} />}
  </Mutation>
);

export default withSetPatientNotReadyInPACU;
