import { graphql } from '@apollo/client/react/hoc';
import React from 'react';
import get from 'lodash/get';
import Logo from '../../../Logo';
import { scope } from '@/graph/scope';
import { Box } from '@material-ui/core';
import HospitalName from '@/components/HospitalName';

const Header = ({ data, match, compressed }) => (
  <Box px={compressed ? 1 : 3} pb={3} pt={match && match.path === '/su/:organizationId' ? 3 : 2}>
    <Box pb={3}>
      <Logo />
    </Box>
    <HospitalName compressed={compressed}>{get(data, 'scope.hospital.name')}</HospitalName>
  </Box>
);

export default graphql(scope)(Header);
