import { isAfter, isBefore } from 'date-fns';
import parse from 'date-fns/parse';
import { groupBy } from 'lodash';
import { optionalFormatWithTimezone, takeFirst } from '../../../pages/kiosk/tablet/utils';
import get from 'lodash/get';
import { sortDate } from '../../../../util/sort';
import { TruncatedText } from '../../../../se/components/typography';
import React from 'react';
import { ProcedureStepType } from '../../../../types/ProcedureStep';
import { PATIENT_EVENT_TYPES } from '../enums';

export const baseEventColumns = [
  {
    title: 'Admitted',
    lens: data => optionalFormatWithTimezone(get(data, 'events.admittedAt'), 'MM/DD/YYYY HH:mm'),
    sortFunction: (l, r) => sortDate(get(l, 'events.admittedAt'), get(r, 'events.admittedAt')),
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
  {
    title: 'PreOp Entry',
    lens: data => optionalFormatWithTimezone(get(data, 'events.preOpAt'), 'HH:mm'),
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
  {
    title: 'OR Entry',
    lens: data => optionalFormatWithTimezone(get(data, 'events.orAt'), 'HH:mm'),
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
  {
    title: 'Recovery Entry',
    lens: data => optionalFormatWithTimezone(get(data, 'events.recoveryAt'), 'HH:mm'),
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
  {
    title: 'Post Op Entry',
    lens: data => optionalFormatWithTimezone(get(data, 'events.postOpAt'), 'HH:mm'),
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
  {
    title: 'Discharged At',
    lens: data => optionalFormatWithTimezone(get(data, 'events.dischargedAt'), 'HH:mm'),
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
];

const eventColumnsObj = () => ({
  [ProcedureStepType.Admitted]: {
    title: 'Admitted',
    lens: data => optionalFormatWithTimezone(get(data, 'events.admittedAt'), 'HH:mm'),
    sortFunction: (l, r) => sortDate(get(l, 'events.admittedAt'), get(r, 'events.admittedAt')),
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
  [ProcedureStepType.WrIn]: {
    title: 'WR Entry',
    lens: data => optionalFormatWithTimezone(get(data, 'events.wrAt'), 'HH:mm'),
    sortFunction: (l, r) => sortDate(get(l, 'events.wrAt'), get(r, 'events.wrAt')),
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
  [ProcedureStepType.WrOut]: {
    title: 'WR Exit',
    lens: data => optionalFormatWithTimezone(get(data, 'events.wrOutAt'), 'HH:mm'),
    sortFunction: (l, r) => sortDate(get(l, 'events.wrOutAt'), get(r, 'events.wrOutAt')),
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
  [ProcedureStepType.PreOpIn]: {
    title: 'PreOp Entry',
    lens: data => optionalFormatWithTimezone(get(data, 'events.preOpAt'), 'HH:mm'),
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
  [ProcedureStepType.BlockNerve]: {
    title: 'Block Nerve',
    lens: data => optionalFormatWithTimezone(get(data, 'events.blockNerveAt'), 'HH:mm'),
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
  [ProcedureStepType.ReadyForAnesthesia]: {
    title: 'Ready For Anesthesia',
    lens: data => optionalFormatWithTimezone(get(data, 'events.readyForAnesthesiaAt'), 'HH:mm'),
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
  [ProcedureStepType.ReadyForOR]: {
    title: 'Ready For OR',
    lens: data => optionalFormatWithTimezone(get(data, 'events.readyForOrAt'), 'HH:mm'),
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
  [ProcedureStepType.PreOpOut]: {
    title: 'PreOp Exit',
    lens: data => optionalFormatWithTimezone(get(data, 'events.preOpOutAt'), 'HH:mm'),
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
  [ProcedureStepType.OrIn]: {
    title: 'OR Entry',
    lens: data => optionalFormatWithTimezone(get(data, 'events.orAt'), 'HH:mm'),
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
  [ProcedureStepType.AnestheticStart]: {
    title: 'Anesthetic Start',
    lens: data => optionalFormatWithTimezone(get(data, 'events.anestheticStartAt'), 'HH:mm'),
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
  [ProcedureStepType.PhysicianIn]: {
    title: 'Physician In',
    lens: data => optionalFormatWithTimezone(get(data, 'events.physicianInAt'), 'HH:mm'),
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
  [ProcedureStepType.TimeOut]: {
    title: 'Time Out',
    lens: data => optionalFormatWithTimezone(get(data, 'events.timeOutAt'), 'HH:mm'),
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
  [ProcedureStepType.ReadyForSurgery]: {
    title: 'Ready for Surgery',
    lens: data => optionalFormatWithTimezone(get(data, 'events.readyForSurgeryAt'), 'HH:mm'),
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
  [ProcedureStepType.OperationStart]: {
    title: 'Procedure Start',
    lens: data => optionalFormatWithTimezone(get(data, 'events.procedureStartAt'), 'HH:mm'),
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
  [ProcedureStepType.CallNextPatient]: {
    title: 'Called',
    lens: data => optionalFormatWithTimezone(get(data, 'events.calledNextPatientAt'), 'HH:mm'),
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
  [ProcedureStepType.Closing]: {
    title: 'Closing Start',
    lens: data => optionalFormatWithTimezone(get(data, 'events.closingStartAt'), 'HH:mm'),
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
  [ProcedureStepType.SurgeonLeftOR]: {
    title: 'Surgeon Leave',
    lens: data => optionalFormatWithTimezone(get(data, 'events.surgeonLeftAt'), 'HH:mm'),
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
  [ProcedureStepType.DressingOn]: {
    title: 'Dressing On',
    lens: data => optionalFormatWithTimezone(get(data, 'events.dressingOnAt'), 'HH:mm'),
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
  [ProcedureStepType.OperationEnd]: {
    title: 'Procedure End',
    lens: data => optionalFormatWithTimezone(get(data, 'events.procedureEndAt'), 'HH:mm'),
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
  [ProcedureStepType.AnestheticEnd]: {
    title: 'Anesthetic End',
    lens: data => optionalFormatWithTimezone(get(data, 'events.anestheticEndAt'), 'HH:mm'),
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
  [ProcedureStepType.OrOut]: {
    title: 'OR Exit',
    lens: data => optionalFormatWithTimezone(get(data, 'events.orOutAt'), 'HH:mm'),
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
  [ProcedureStepType.CleaningCompleted]: {
    title: 'OR Room Ready',
    lens: data => optionalFormatWithTimezone(get(data, 'events.cleaningCompletedAt'), 'HH:mm'),
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
  [ProcedureStepType.PacuIn]: {
    title: 'Recovery Entry',
    lens: data => optionalFormatWithTimezone(get(data, 'events.recoveryAt'), 'HH:mm'),
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
  [ProcedureStepType.PacuReadyToLeave]: {
    title: 'Recovery Ready to Leave',
    lens: data => optionalFormatWithTimezone(get(data, 'events.ableToVisitPACUAt'), 'HH:mm'),
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
  [ProcedureStepType.PacuOut]: {
    title: 'Pacu Exit',
    lens: data =>
      optionalFormatWithTimezone(
        data?.events?.recoveryOutAt
          ? data?.events?.recoveryOutAt
          : data?.events?.recoveryAt && data?.events?.dischargedAt,
        'HH:mm'
      ),
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
  [ProcedureStepType.PostOpIn]: {
    title: 'PostOp Entry',
    lens: data => optionalFormatWithTimezone(data?.events?.postOpAt, 'HH:mm'),
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
  [ProcedureStepType.PostOpOut]: {
    title: 'PostOp Exit',
    lens: data =>
      optionalFormatWithTimezone(
        data?.events?.postOpAt && (data?.events?.postOpOutAt || data?.events?.dischargedAt),
        'HH:mm'
      ),
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
  [ProcedureStepType.Discharged]: {
    title: 'Discharged At',
    lens: data => optionalFormatWithTimezone(data?.events?.dischargedAt, 'HH:mm'),
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
});

export const getEventsColumns = procedureSteps =>
  procedureSteps?.map(e => ({ ...eventColumnsObj()?.[e?.type], title: e?.name })).filter(e => !!e);

const groupByType = events =>
  Object.values(groupBy(events, e => `${e?.type}-${e?.status || '*'}-${e?.room?.type || '*'}`));
const sortDesc = events => events.sort((l, r) => (isAfter(parse(l.createdAt), parse(r.createdAt)) ? -1 : 1));
const sortAsc = events => events.sort((l, r) => (isBefore(parse(l.timestamp), parse(r.timestamp)) ? -1 : 1));

const flatEvents = events =>
  events.flatMap(entry =>
    entry?.entries?.map((e, i) => ({
      ...e,
      id: `${entry.id}-${i}`,
      timestamp: entry.timestamp,
      createdBy: entry.createdBy,
      createdAt: entry.createdAt,
    }))
  );

export const transform = events => {
  const flatten = flatEvents(events);

  const lastEvents = groupByType(flatten).map(events => {
    const sorted = sortDesc(events);
    return {
      ...sorted?.[0],
      history: sorted?.slice(1),
    };
  });

  return sortAsc(lastEvents);
};

export const isProcedureEventEditable = event => {
  switch (event.type) {
    case 'Created':
    case 'Entered':
    case 'Exited':
    case 'Discharged':
    case 'BlockNerve':
    case 'CleaningCompleted':
      return true;
    case 'ProcedureStatusSet':
      switch (event?.status) {
        case 'Ready':
          return false;
        default:
          return true;
      }
    default:
      return false;
  }
};

export const patientProcedureStatusTime = (patient, status) => {
  const flatten = flatEvents(patient?.log || []);
  const entry = sortDesc(
    flatten.filter(event => event.type === PATIENT_EVENT_TYPES.PROCEDURE_STATUS_SET && event?.status === status)
  );

  const selected = takeFirst(entry) || {};
  return selected?.timestamp ? parse(selected?.timestamp) : null;
};

export const patientEnteredRoomTypeTime = (patient, roomType) => {
  const flatten = flatEvents(patient?.log || []);
  const entry = sortDesc(
    flatten.filter(event => event.type === PATIENT_EVENT_TYPES.ENTERED && event?.room?.type === roomType)
  );

  const selected = takeFirst(entry) || {};
  return selected?.timestamp ? parse(selected?.timestamp) : null;
};

export const patientDischarged = patient => {
  const flatten = flatEvents(patient?.log || []);
  const entry = sortDesc(flatten.filter(event => event.type === PATIENT_EVENT_TYPES.DISCHARGED));

  const selected = takeFirst(entry) || {};
  return selected?.timestamp ? parse(selected?.timestamp) : null;
};
