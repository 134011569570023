import { Mutation } from '@apollo/client/react/components';
import React from 'react';
import { setPatientReadyForSurgeon } from '../../../../graph/patients';

const withSetPatientReadyForSurgeon = Component => props => (
  <Mutation mutation={setPatientReadyForSurgeon}>
    {setPatientReadyForSurgeon => <Component {...props} setPatientReadyForSurgeon={setPatientReadyForSurgeon} />}
  </Mutation>
);

export default withSetPatientReadyForSurgeon;
