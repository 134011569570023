import { get } from 'lodash';
import { selectRaw, selectList } from '../../selectors.js';
import { useQuestionnaireValueViaSelector } from '../../../components/entities/surgeonProcedures/predefinedForms/hooks.jsx';

export interface Surgery {
  procedure: string;
  year: string;
  anesthesia: string;
}

interface AllergiesQuestionary {
  surgeries: Surgery[];
  answered: boolean;
  emptyName: string;
}

export const useSurgeries = (): AllergiesQuestionary => {
  const emptyName = 'NO KNOWN SURGERIES';

  const list = useQuestionnaireValueViaSelector(selectList('priorSurgeries.q1.yes.surgery_list'));

  const q1 = useQuestionnaireValueViaSelector(selectRaw('priorSurgeries.q1'));
  const answered = hasAnswered(q1);
  const surgeries = extractSurgeries(list);

  return { surgeries, answered, emptyName };
};

function extractSurgeries(list: any[]): Surgery[] {
  return list.map(m => ({
    procedure: get(m, 'schema.procedure.text', ''),
    year: get(m, 'schema.year.text', ''),
    anesthesia: get(m, 'schema.anesthesia.text', ''),
  }));
}

function hasAnswered(item: any): boolean {
  const value = get(item, 'yes');
  return value != null;
}
