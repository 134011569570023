import { Link, Tooltip } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import MessageIcon from '@material-ui/icons/Message';
import { Link as RouterLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { withTheme } from '../../../se/theme';
import ChatButton from '../../chat/ChatButton';
import { CaretakerChatDialog } from '../../chat/ChatDialog';
import { PatientTypeIconSwitch } from '../../inputs/PatientTypeButtonGroup';
import { isEmpty } from 'lodash/fp';
import React, { useMemo } from 'react';
import { createStyles, makeStyles, IconButton } from '@material-ui/core';

const useStyles = makeStyles(theme =>
  createStyles({
    chatButton: {
      paddingLeft: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);

export const Container = styled.h4`
  display: flex;
  flex-wrap: ${props => (props.allowWrap ? 'wrap' : 'none')};
  justify-content: space-between;
  margin: 0;
  flex: 1;
  line-height: 1.5;

  ${props =>
    props.miniView &&
    css`
      flex-direction: column;
    `}
`;

export const Info = styled.div`
  flex-shrink: 1;
  flex-grow: 1;
  max-width: 100%;
  font-size: ${props => (props.size === 'large' ? '2.25em' : '1.5em')};
  line-height: 1.4;
  color: ${withTheme(theme => theme.textColor.string())};
  display: flex;

  @media (max-height: 900px) {
    font-size: ${props => (props.size === 'large' ? '2.25em' : '1rem')};
  }

  @media (max-height: 600px) {
    font-size: ${props => (props.size === 'large' ? '2.25em' : '2vh')};
    line-height: ${props => (props.size === 'large' ? '1' : '1.4')};
  }

  ${props =>
    props.miniView &&
    css`
      font-size: 1rem;
      line-height: 1.4;
    `}
`;

const InfoRight = styled(Info)`
  flex-grow: ${props => (props.grow ? 1 : 0)};
  flex-shrink: 0;
  display: flex;
  align-items: center;

  ${props =>
    props.miniView &&
    css`
      margin-left: 0;
      color: ${withTheme(theme => theme.secondaryTextColor.string())};
      opacity: 0.8;
    `}
`;

const Physician = styled.div`
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
`;

const Tag = styled.div`
  display: flex;
  align-items: center;
  color: #003c0a;
  background: #43be57;
  border-radius: 0.1875rem;
  font-size: 0.5em;
  padding: 0 0.5em;
  margin-left: 0.6em;
  height: 80%;
`;

const DelayTag = styled.div`
  display: flex;
  align-items: center;
  color: #003c0a;
  background: #ffbd00;
  border-radius: 0.1875rem;
  font-size: 0.5em;
  padding: 0 0.5em;
  margin-left: 0.6em;
  height: 80%;
`;

const AlertTag = styled.div`
  display: flex;
  align-items: center;
  color: #003c0a;
  background: #b13030;
  border-radius: 0.1875rem;
  font-size: 0.5em;
  padding: 0 0.5em;
  margin-left: 0.6em;
  height: 80%;
`;

const containsWord = (str, word) => str?.match(new RegExp('\\b' + word + '\\b')) != null;

const getPhysicianPrefix = physician => (containsWord(physician?.toLowerCase(), 'dr') ? '' : 'Dr. ');

const PatientSlotInfo = ({
  physician,
  patient,
  patientId,
  patientType,
  procedure,
  size,
  readyForOr,
  readyForSurgeon,
  readyInOr,
  familyReady,
  isHoldProcedure,
  isBlockNerve,
  called,
  allowWrap,
  miniView,
  isDPU,
  isPreOp,
  isSuperAdmin,
  hospitalId,
  messagingView,
  patientData,
  grow,
}) => {
  const classes = useStyles();

  const hasCaretakerConsent = patientData?.caretakerConsent;
  const hasCaretakerNotificationNumber = patientData ? Boolean(patientData?.notificationNumbers) : undefined;

  return (
    <Container allowWrap={allowWrap} miniView={miniView}>
      <Info style={{ minWidth: 0, marginRight: '1rem' }} size={size} miniView={miniView}>
        <Physician>
          {!isEmpty(physician) && getPhysicianPrefix(physician.replaceAll('.', ''))}
          {physician}
        </Physician>
      </Info>
      <InfoRight size={size} miniView={miniView} grow={grow}>
        {patientId ? (
          <Link
            component={RouterLink}
            to={`${isSuperAdmin ? `/su/${hospitalId}` : ''}/patients/${patientId}`}
            onClick={e => e.stopPropagation()}
            style={{ color: 'inherit', flex: 1 }}
          >
            {patient}
          </Link>
        ) : (
          patient
        )}
        {messagingView && patient && (
          <Box className={classes.chatButton}>
            {hasCaretakerConsent && hasCaretakerNotificationNumber ? (
              <ChatButton
                hasUnreadMessages={patientData?.caretakerThread?.hasUnreadMessages}
                chatComponent={CaretakerChatDialog}
                fontSize="inherit"
                chatProps={{
                  patient: patientData,
                }}
              />
            ) : (
              <Tooltip
                title={
                  !hasCaretakerConsent
                    ? 'No caretaker consent.'
                    : !hasCaretakerNotificationNumber
                      ? 'No caretaker phone number.'
                      : 'Unexpected error occurred.'
                }
              >
                <div>
                  <IconButton color="primary" size="small" edge="end" disabled>
                    <MessageIcon fontSize="inherit" />
                  </IconButton>
                </div>
              </Tooltip>
            )}
          </Box>
        )}
        <PatientTypeIconSwitch patientType={patientType} />
        {readyForSurgeon && !miniView && !isDPU && !readyForOr && !messagingView && <Tag>Ready For Surgeon</Tag>}
        {readyForOr && !miniView && !isDPU && !messagingView && <Tag>Ready For OR</Tag>}
        {readyInOr && !miniView && !messagingView && <Tag>Ready For Surgery</Tag>}
        {familyReady && !miniView && !messagingView && <Tag>Family Ready</Tag>}
        {isHoldProcedure && !miniView && !messagingView && <DelayTag>On Hold</DelayTag>}
        {isBlockNerve && !miniView && !messagingView && <DelayTag>Nerve Block</DelayTag>}
        {called && !miniView && isPreOp && !messagingView && <AlertTag>Called</AlertTag>}
      </InfoRight>
    </Container>
  );
};

export default PatientSlotInfo;
