import { useApolloClient, useSubscription } from '@apollo/client';
import { Typography, useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ArrowBack } from '@material-ui/icons';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import clsx from 'clsx';
import { addDays, addMinutes, format, isAfter, parse, startOfDay } from 'date-fns';
import { sortBy, isEqual } from 'lodash';
import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Route } from 'react-router-dom';
import styled from 'styled-components';

import {
  listWithStaffShiftSubscription as listProceduresSubscription,
  proceduresDayOverSubscription,
} from '../../../graph/procedures';
import { roomsListWithStaffShiftSubscription } from '../../../graph/rooms';
import { hasAssigned } from '../../../graph/staff';

import LinkButton from '../../../se/components/LinkButton';
import RouterLink from '../../../se/components/RouterLink';
import { getNestedValue } from '../../../se/utilities/data/object';
import responsive, { breakpoint } from '../../../se/utilities/responsive';
import { ScheduleUserType } from '../../../types/ScheduleUser';

import ClientUpdater, { ClientUpdaterPresetTvSchedule } from '../../ClientUpdater';
import ToastBar from '../../core/ToastBar';
import HospitalInfo from '../../HospitalInfo';
import HospitalInfoDatePicker from '../../HospitalInfoDatePicker';
import CheckboxInput from '../../inputs/CheckboxInput';
import { Formats } from '../../inputs/upload/formats';
import { ScheduleUploadButton } from '../../inputs/upload/UploadButton';

import EmptySchedule from './EmptySchedule';
import ProcedureEditor from './schedule/edit/ProcedureEditor';
import Filters from './schedule/Filters';
import MonthlySchedule from './schedule/MonthlyView/MonthlySchedule';
import MobileORNavigation from './schedule/MobileORNavigation';
import Overview from './schedule/overview/Overview';
import PDFScheduleButton from './schedule/pdf/PDFScheduleButton';
import { getRoomWithStaff, scheduleStaff } from './schedule/pdfGeneratorUtils';
import { ScheduleProcedureForm } from './schedule/procedure/ScheduleProcedureForm';
import ScheduleFooter from './schedule/ScheduleFooter';
import ScheduleNotificationDialog from './schedule/ScheduleNotificationDialog';
import SendNotificationDropdown from './schedule/SendNotificationDropdown';
import MultipleSelectionBox from './schedule/staff/MultipleSelectionBox';
import { MultipleStaffShiftSelectionContextProvider } from './schedule/staff/MultipleStaffShiftSelection';
import StaffingCostButton from './schedule/staff/StaffingCostButton';
import StaffShiftCopyButton from './schedule/staff/StaffShiftCopyButton';
import Timeline from './schedule/timeline/Timeline';
import ToggleOverlay from './schedule/ToggleOverlay';
import { getOperationRooms, getProcedures, transformScheduleOperationRooms } from './schedule/transform';
import WeeklySchedule, { padWeek } from './schedule/WeekView/WeekSchedule';

const Header = styled.div`
  flex: 0 0 3em;

  ${responsive.md.andSmaller`
    flex: 0 0 auto;
  `};
`;

export const EditingHeader = styled(Header)`
  padding: 0.875em 1em;
  display: flex;
  justify-content: stretch;
  align-items: center;

  ${responsive.md.andSmaller`
    overflow: hidden;
    flex-wrap: wrap;
    flex-flow: column;
  `};

  > div {
    overflow: hidden;
    flex: 1;
    display: flex;
    align-items: center;
  }
`;

const FilterBox = styled.div`
  > div {
    overflow: hidden;
    flex: 1;
    display: flex;
    align-items: center;
  }
`;

const ActionBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  ${responsive.md.andSmaller`
    overflow: hidden;
    flex-wrap: wrap;
    flex-flow: column;
  `};
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  > div:first-of-type {
    margin-top: 0.75em;
    margin-bottom: 0;

    > label {
      align-items: center;
    }
  }
`;

export const HospitalName = styled.h3`
  opacity: 0.35;
  font-size: 1.125em;
  font-weight: 500;
  padding-bottom: 0.5em;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  text-align: center;
  width: 100%;
`;

const MonthContainer = styled.div`
  text-align: center;
  margin-bottom: 1em;
  font-size: 1.125em;
  font-weight: 500;
  background-color: ${props => props.theme.backgroundColor.string()};
`;

export const formats = [Formats.CSV, Formats.XLS, Formats.XLSX, Formats.TXT, Formats.PDF];

const ScrollableContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const shouldFilterOrWithoutId = hospitalId =>
  (window.location.hostname === 'view2.ospitek.com' && hospitalId === 24) ||
  (window.location.hostname === 'testing.ospitek.com' && hospitalId === 77);

const Schedule = props => {
  const {
    scope,
    isSuperAdmin,
    isGroupAdmin,
    isMonitoringEngineer,
    isPreOPNurse,
    isNursing,
    isAdmin,
    isFrontDesk,
    isOperationsManager,
    isAnesthesiologist,
    isScheduleUser,
    location,
    history,
    match,
    isKiosk,
    config,
    hasScheduleAccessAllRight,
    myProceduresChecked,
    setMyProceduresChecked,
    scheduleUserId,
    scheduleUserType,

    /** Unified from HospitalSchedule */
    parsedParams,
    physician,
    date,
    dayIsOver,
  } = props;

  const formattedDate = useMemo(() => format(date, 'YYYY-MM-DD'), [date]);
  const roomsSub = useSubscription(roomsListWithStaffShiftSubscription, {
    variables: { date: formattedDate },
    shouldResubscribe: true,
  });
  const proceduresSub = useSubscription(listProceduresSubscription, {
    variables: {
      date: formattedDate,
      physician: physician ? parseInt(physician, 10) : undefined,
      isCanceled: false,
    },
    shouldResubscribe: true,
  });

  const proceduresData = proceduresSub.data?.procedures || [];
  const proceduresLoading = proceduresSub.loading;
  const proceduresError = proceduresSub.error;

  const roomsData = roomsSub.data;
  const roomsLoading = roomsSub.loading;
  const roomsError = roomsSub.error;

  /** Extract parameters from query */
  const isTimelineParam = parsedParams.timeline === 'true';
  const hospitalId = scope?.hospital?.id;
  const currentOR = decodeURIComponent(location.hash).replace('#', '');

  // We can still check or override the timeline if config says so
  const [isTimeline, setIsTimeline] = useState(false);

  const theme = useTheme();
  const [showOverlay, setShowOverlay] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < breakpoint.md);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < breakpoint.md);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [setIsMobile]);

  useEffect(() => {
    setIsTimeline(isKiosk ? (config?.isTimeline ?? false) : isTimelineParam);
  }, [isKiosk, config?.isTimeline, isTimelineParam]);

  const toggleView = () => {
    setIsTimeline(prev => !prev);
    const old = queryString.parse(location.search) || {};
    history.replace({
      pathname: location.pathname,
      search: queryString.stringify({ ...old, timeline: !isTimeline }),
      hash: location.hash,
    });
  };

  const operationRooms = useMemo(() => {
    if (!roomsData?.rooms) return [];
    const rooms = getOperationRooms({ data: roomsData });
    const procedures = getProcedures({ data: { procedures: proceduresData } });

    const transformed = transformScheduleOperationRooms({
      rooms,
      procedures,
    });

    const showOrRooms = getNestedValue('showOrRooms', config);
    const configured = showOrRooms ? transformed.filter(room => showOrRooms.includes(room.id)) : transformed;

    const filtered = sortBy(
      (shouldFilterOrWithoutId(hospitalId) ? configured.filter(room => !!room.id) : configured).filter(
        e => !e?.isHideScheduleScreen
      ),
      ['name']
    );

    const roomsWithOrder = filtered.filter(room => room?.order !== null).sort((a, b) => a.order - b.order);
    const roomsWithoutOrder = filtered.filter(room => room?.order === null);

    const ordered = [
      ...roomsWithOrder,
      ...roomsWithoutOrder.filter(room => room?.name?.toUpperCase()?.startsWith('OR')),
      ...roomsWithoutOrder.filter(room => !room?.name?.toUpperCase()?.startsWith('OR')),
    ];

    return ordered;
  }, [roomsData, proceduresData, config, hospitalId]);

  /**
   * Decide the core schedule UI
   */
  const anesthesiologistView = isKiosk ? getNestedValue('anesthesiologistSchedule', config) : isAnesthesiologist;

  const ScheduleView = anesthesiologistView ? Timeline : isTimeline ? Timeline : Overview;
  const filteredOperationRooms = anesthesiologistView
    ? operationRooms.filter(room => room.name.toLowerCase().startsWith('or'))
    : [];

  const client = useApolloClient();
  useEffect(() => {
    if (formattedDate) {
      client.refetchQueries({
        include: [{ query: hasAssigned, variables: { date: formattedDate } }],
      });
    }
  }, [formattedDate, client]);

  const isAdminUser = isSuperAdmin || isGroupAdmin || isAdmin || isAnesthesiologist;
  const isPowerUser = isAdminUser || isOperationsManager;
  const canUseScheduleViewProcedure = isPowerUser || isPreOPNurse || isFrontDesk;

  const unassignedProcedures = useMemo(() => {
    return proceduresData.filter(procedure => !procedure.orId && !procedure.or);
  }, [proceduresData]);

  const noProcedures = useMemo(() => {
    if (!proceduresData.length) return true;
    return unassignedProcedures.length === 0 && operationRooms.every(room => room.procedures?.length === 0);
  }, [proceduresData.length, unassignedProcedures.length, operationRooms]);

  const noShifts = useMemo(() => {
    if (!roomsData?.rooms) return true;
    return roomsData.rooms.every(r => r.staffShifts.length === 0);
  }, [roomsData]);

  /**
   * For the toggle between day/week/month
   */
  const [view, setView] = useState('day');
  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setView(newView);
    }
  };

  const hasMoreThan6ORs = useMemo(() => {
    if (isKiosk) return false;
    return operationRooms.length + (unassignedProcedures.length > 0 ? 1 : 0) > 6;
  }, [operationRooms.length, unassignedProcedures.length, isKiosk]);

  const [hasMoreThan6ORsView, setHasMoreThan6ORsView] = useState(true);
  const toggle6ORViewMode = () => {
    setHasMoreThan6ORsView(prevState => !prevState);
  };

  const formattedMonth = useMemo(() => format(date, 'MMMM YYYY'), [date]);
  const pad = useMemo(() => padWeek(date, getNestedValue('showWeekends', config)), [date, config]);
  const formattedWeekStart = useMemo(() => format(pad?.[0] ?? date, 'MMMM DD'), [pad, date]);
  const formattedWeekEnd = useMemo(() => format(pad?.[pad.length - 1] ?? date, 'MMMM DD'), [pad, date]);

  const classes = hospitalScheduleStyles();
  const isMaxWidth1920 = useMediaQuery('(max-width: 1920px)');
  const isMaxWidth1600 = useMediaQuery('(max-width: 1600px)');
  const isMaxWidth1400 = useMediaQuery('(max-width: 1400px)');

  const [editProcedure, setEditProcedure] = useState(null);
  const [editProcedureMonthly, setEditProcedureMonthly] = useState(null);

  const openProcedureForm = (procedure, operationRooms, operationRoom, dateArg, timeRange) => {
    history.push({
      pathname: procedure?.id ? `${match.url}/procedure/${procedure?.id}` : `${match.url}/procedure`,
      search: location.search,
      state: { procedure, operationRooms, operationRoom, date: dateArg, timeRange },
    });
  };

  const onFilterChange = value => {
    const old = queryString.parse(location.search) || {};
    history.replace({
      pathname: location.pathname,
      search: queryString.stringify({ ...old, ...value }),
      hash: location.hash,
    });
  };

  const showStaffList = getNestedValue('showStaffListOnSchedule', config);
  const showBedNumber = isKiosk ? getNestedValue('showBedNumber', config) : true;
  const showDatePicker = getNestedValue('showDatePickerOnSchedule', config);

  const scheduleFooter = useMemo(() => {
    return (
      <ScheduleFooter
        isFrontDesk={isFrontDesk}
        isPowerUser={isPowerUser}
        isScheduleUser={isScheduleUser}
        hospitalId={hospitalId}
        date={formattedDate}
        rooms={roomsData?.rooms?.filter(e => !e?.isHideScheduleScreen) || []}
        staffId={scheduleUserType === ScheduleUserType.StaffMember ? scheduleUserId : null}
        myProceduresChecked={myProceduresChecked}
        editableStaff={isPowerUser}
        isKiosk={isKiosk}
        isFooter={!showStaffList}
        anesthesiologistView={anesthesiologistView}
        anesthesiologistDate={date}
      />
    );
  }, [
    anesthesiologistView,
    date,
    roomsData,
    scheduleUserType,
    scheduleUserId,
    myProceduresChecked,
    showStaffList,
    config,
    isKiosk,
    isFrontDesk,
    isPowerUser,
    isScheduleUser,
    scope,
    formattedDate,
  ]);

  const staffPerRoom = useMemo(() => {
    if (!roomsData?.rooms?.length || !proceduresData.length) return [];
    const roomsWithValidStaff = roomsData.rooms.map(room => ({
      ...room,
      staffShifts: room.staffShifts.filter(shift => !!shift.staff?.name),
    }));
    return scheduleStaff(roomsWithValidStaff, getRoomWithStaff(proceduresData));
  }, [roomsData, proceduresData]);

  const createProcedureSchedule = scope?.hospital?.modules?.createProcedureSchedule;
  const showStaffCost = scope?.hospital?.modules?.showStaffCost;
  const showCSVUpload = scope?.hospital?.modules?.showCSVUpload;
  const showWeekends = scope?.hospital?.modules?.showWeekends;
  const secondaryRoomAnesthesiologist = scope?.hospital?.modules?.secondaryRoomAnesthesiologist;

  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
  });

  const handleChange = event => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <>
      {isKiosk && <ClientUpdater {...ClientUpdaterPresetTvSchedule} />}

      {/**
       * If dayIsOver logic is used, HospitalSchedule might pass a "next day" date.
       * Show a note if date is different from the system's current date
       */}
      {dayIsOver && (
        <ToastBar id="forcedDate" variant="warning">
          Note that this screen displays schedule for {format(date, 'dddd')}.
        </ToastBar>
      )}

      {isKiosk && (
        <Helmet>
          <meta name="viewport" content="width=1920, initial-scale=1" />
        </Helmet>
      )}

      <Box
        className={clsx(classes.page, {
          [classes.kiosk]: isKiosk,
          [classes.hdtv]: isMaxWidth1920,
          [classes.kioskMaxWidth1600]: isKiosk && isMaxWidth1600,
          [classes.kioskMaxWidth1400]: isKiosk && isMaxWidth1400,
        })}
      >
        {/**
         * 1) If user is power user or relevant staff => editing header
         * 2) If normal schedule user => simpler
         * 3) If read only => show bare
         */}
        {isPowerUser || isPreOPNurse || isNursing || isFrontDesk || isMonitoringEngineer ? (
          <>
            <Box className={classes.editingHeader}>
              <Box className={classes.mainActions}>
                <Button
                  component={RouterLink}
                  color="primary"
                  to={isAdmin ? '/' : `/su/${hospitalId}`}
                  startIcon={<ArrowBack />}
                >
                  {` Back${isOperationsManager ? '' : isPowerUser ? ' to admin' : ''}`}
                </Button>

                <Box className={classes.divider}>
                  <Divider orientation="vertical" />
                </Box>

                {/** Show CSV upload if relevant */}
                {!createProcedureSchedule && !isMonitoringEngineer && !isAnesthesiologist && showCSVUpload && (
                  <ScheduleUploadButton history={history} location={location} formats={formats} className="vanishing" />
                )}

                {!isAnesthesiologist && (
                  <Button onClick={toggleView} color="primary">
                    {isTimeline ? 'List View' : 'Timeline View'}
                  </Button>
                )}

                {hasMoreThan6ORs && (isTimeline || isAnesthesiologist) && (
                  <Button onClick={toggle6ORViewMode} style={{ marginLeft: '1em' }}>
                    {hasMoreThan6ORsView ? 'Scroll View' : 'Full View'}
                  </Button>
                )}
                {/* <Box ml={4}>
                  <FormControlLabel
                    control={
                      <Switch checked={state.checkedB} onChange={handleChange} name="checkedB" color="primary" />
                    }
                    label="Show Room Graphs"
                  />
                </Box> */}
              </Box>

              <Typography component="h1" variant="h4" align="center">
                {scope?.hospital?.name}
              </Typography>

              <ActionBox>
                {createProcedureSchedule && isTimeline && (
                  <ToggleOverlay showOverlay={showOverlay} setShowOverlay={setShowOverlay} />
                )}

                {!isAnesthesiologist && (
                  <>
                    <ToggleButtonGroup
                      value={view}
                      exclusive
                      onChange={handleViewChange}
                      className={classes.toggleCalendar}
                    >
                      <ToggleButton value="day">Day</ToggleButton>
                      <ToggleButton value="week">Week</ToggleButton>
                      <ToggleButton value="month">Month</ToggleButton>
                    </ToggleButtonGroup>

                    <Box display="flex">
                      {roomsData?.rooms && <StaffShiftCopyButton date={date} rooms={roomsData.rooms} />}

                      {showStaffCost && isAdminUser && <StaffingCostButton match={match} date={formattedDate} />}

                      {isPowerUser && (
                        <PDFScheduleButton date={date} staffPerRoom={staffPerRoom} operationRooms={operationRooms} />
                      )}

                      {!isFrontDesk && !isPreOPNurse && !isMonitoringEngineer && (
                        <SendNotificationDropdown date={date} />
                      )}
                      <ScheduleNotificationDialog />
                    </Box>
                  </>
                )}
                <FilterBox>
                  <Filters
                    color={theme.palette.background.default}
                    textColor={theme.palette.text.primary}
                    date={date}
                    physician={physician}
                    onChange={onFilterChange}
                    withoutPhysician={isAnesthesiologist}
                  />
                </FilterBox>
              </ActionBox>
            </Box>

            {!isFrontDesk && <MultipleSelectionBox />}

            {editProcedure && (
              <ProcedureEditor
                date={date}
                operationRooms={operationRooms.map(r => r.name)}
                procedure={editProcedure}
                onDone={() => setEditProcedure(null)}
                onClose={() => setEditProcedure(null)}
                fullWindow={isMobile}
                isAnesthesiologist={isAnesthesiologist}
              />
            )}
            {editProcedureMonthly && (
              <ProcedureEditor
                date={date}
                operationRooms={operationRooms.map(r => r.name)}
                procedure={editProcedureMonthly}
                onDone={() => setEditProcedureMonthly(null)}
                onClose={() => setEditProcedureMonthly(null)}
                fullWindow={isMobile}
                isAnesthesiologist={isAnesthesiologist}
              />
            )}
          </>
        ) : isScheduleUser ? (
          <Box className={classes.editingHeader}>
            <LinkButton onClick={toggleView} style={{ marginLeft: '1em' }}>
              {isTimeline ? 'List View' : 'Timeline View'}
            </LinkButton>
            <HospitalName>{scope?.hospital?.name}</HospitalName>
            <Row>
              {hasScheduleAccessAllRight && (
                <CheckboxInput
                  label="My procedures"
                  name="myProcedure"
                  value={myProceduresChecked}
                  onChange={setMyProceduresChecked}
                  layoutProps={{ style: { marginRight: theme.spacing(1) } }}
                />
              )}
              <Filters date={date} withoutPhysician onChange={onFilterChange} />
            </Row>
          </Box>
        ) : showDatePicker ? (
          <Header>
            <HospitalInfoDatePicker date={date} onChange={onFilterChange} />
          </Header>
        ) : (
          <Header>
            <HospitalInfo />
          </Header>
        )}

        {/**
         * Loading or Error states
         */}
        {roomsLoading || proceduresLoading ? (
          <Refresh inSeconds={60} />
        ) : roomsError || proceduresError ? (
          <Refresh inSeconds={15}>Error</Refresh>
        ) : (
          <>
            {noProcedures && noShifts && view === 'day' ? (
              <EmptySchedule
                date={date}
                timelineView={isTimeline}
                physician={physician}
                isKiosk={isKiosk}
                isScheduleUser={isScheduleUser}
                myProceduresOnly={myProceduresChecked}
                hospitalId={hospitalId}
                currentOR={currentOR}
                isFrontDesk={isFrontDesk}
                isPowerUser={isPowerUser}
                editableStaff={isPowerUser}
                operationRooms={operationRooms}
                setEditProcedure={setEditProcedure}
                unassignedProcedures={unassignedProcedures}
                showBedNumber={showBedNumber}
                scheduleFooter={showStaffList ? scheduleFooter : null}
                history={history}
                match={match}
                canUseScheduleViewProcedure={canUseScheduleViewProcedure}
                openProcedureForm={openProcedureForm}
                showOverlay={showOverlay}
              />
            ) : (
              <>
                {isMobile && <MobileORNavigation rooms={operationRooms} />}
                {view === 'day' ? (
                  <ScheduleView
                    date={date}
                    hospitalId={hospitalId}
                    isKiosk={isKiosk}
                    currentOR={currentOR}
                    canUseScheduleViewProcedure={canUseScheduleViewProcedure}
                    isFrontDesk={isFrontDesk}
                    isPowerUser={isPowerUser}
                    editableStaff={isPowerUser}
                    operationRooms={anesthesiologistView ? filteredOperationRooms : operationRooms}
                    setEditProcedure={setEditProcedure}
                    unassignedProcedures={unassignedProcedures}
                    showBedNumber={showBedNumber}
                    scheduleStaffList={showStaffList ? scheduleFooter : null}
                    history={history}
                    match={match}
                    openProcedureForm={openProcedureForm}
                    showOverlay={showOverlay}
                    isPreOPNurse={isPreOPNurse}
                    isNursing={isNursing}
                    hasMoreThan6ORsView={hasMoreThan6ORsView}
                    showGraphs={state.checkedB}
                    isAnesthesiologistSchedule={anesthesiologistView}
                    secondaryRoomAnesthesiologist={secondaryRoomAnesthesiologist}
                  />
                ) : view === 'month' ? (
                  <ScrollableContainer>
                    <MonthContainer>
                      <h2>{formattedMonth}</h2>
                    </MonthContainer>
                    <MonthlySchedule
                      date={date}
                      physician={physician}
                      setEditProcedure={setEditProcedure}
                      setEditProcedureMonthly={setEditProcedureMonthly}
                      openProcedureForm={openProcedureForm}
                      canUseScheduleViewProcedure={canUseScheduleViewProcedure}
                      showWeekends={showWeekends}
                    />
                  </ScrollableContainer>
                ) : (
                  <ScrollableContainer>
                    <MonthContainer>
                      <h2>
                        {formattedWeekStart} - {formattedWeekEnd}
                      </h2>
                    </MonthContainer>
                    <WeeklySchedule
                      date={date}
                      physician={physician}
                      setEditProcedureMonthly={setEditProcedureMonthly}
                      openProcedureForm={openProcedureForm}
                      canUseScheduleViewProcedure={canUseScheduleViewProcedure}
                      showWeekends={showWeekends}
                    />
                  </ScrollableContainer>
                )}
                {!showStaffList && view === 'day' && scheduleFooter}
              </>
            )}
          </>
        )}
      </Box>
      {/** ROUTES for schedule procedure forms */}
      <Route
        path={`${match.url}/procedure`}
        render={routeProps => {
          const isCorrectPath = routeProps?.location?.pathname === `${match.url}/procedure`;
          return (
            isCorrectPath && (
              <ScheduleProcedureForm
                operationRooms={routeProps?.location?.state?.operationRooms}
                date={routeProps?.location?.state?.date}
                operationRoom={routeProps?.location?.state?.operationRoom}
                timeRange={routeProps?.location?.state?.timeRange}
                handleClose={() => routeProps.history.goBack()}
                showGraphs={state.checkedB}
                theme={anesthesiologistView ? 'gray' : 'blue'}
              />
            )
          );
        }}
      />
      <Route
        path={`${match.url}/procedure/:procedureId`}
        render={routeProps => {
          const procedureId = parseInt(routeProps?.match?.params?.procedureId, 10);
          const isCorrectPath = routeProps?.location?.pathname === `${match.url}/procedure/${procedureId}`;
          return (
            isCorrectPath && (
              <ScheduleProcedureForm
                procedureId={procedureId}
                procedure={routeProps?.location?.state?.procedure}
                operationRooms={routeProps?.location?.state?.operationRooms}
                date={routeProps?.location?.state?.date}
                operationRoom={routeProps?.location?.state?.operationRoom}
                timeRange={routeProps?.location?.state?.timeRange}
                handleClose={() => routeProps.history.goBack()}
                showGraphs={state.checkedB}
                theme={anesthesiologistView ? 'gray' : 'blue'}
              />
            )
          );
        }}
      />
    </>
  );
};

function useDayIsOver({
  formattedDate,
  physician,
  currentDate,
  isPowerUser,
  isScheduleUser,
  useDateOverride,
  isPreOPNurse,
}) {
  /** Subscription: Procedures => fetch ALL */
  const { data } = useSubscription(proceduresDayOverSubscription, {
    variables: { date: formattedDate, physician },
  });

  const proceduresData = data?.procedures || [];

  const computedDayIsOver = useMemo(() => {
    if (!proceduresData.length) return false;

    const proceduresAreOver = proceduresData.every(procedure => {
      if (procedure.isCanceled) return true;
      const fullyRecovered =
        procedure.patient?.events && (procedure.patient.events.recoveryAt || procedure.patient.events.dischargedAt);
      if (fullyRecovered) return true;
      const ended = !procedure.patient && isAfter(currentDate, addMinutes(procedure.startTime, procedure.duration));
      return ended;
    });

    return (
      !isPowerUser &&
      !isScheduleUser &&
      !useDateOverride &&
      !isPreOPNurse &&
      proceduresData.length > 0 &&
      proceduresAreOver
    );
  }, [proceduresData, currentDate, isPowerUser, isScheduleUser, useDateOverride, isPreOPNurse]);

  // Update only when computedDayIsOver changes
  const [dayIsOver, setDayIsOver] = useState(computedDayIsOver);
  useEffect(() => {
    setDayIsOver(prev => (prev === computedDayIsOver ? prev : computedDayIsOver));
  }, [computedDayIsOver]);

  return dayIsOver;
}

const HospitalSchedule = props => {
  const {
    location,
    isSuperAdmin,
    isGroupAdmin,
    isMonitoringEngineer,
    isAdmin,
    isFrontDesk,
    isOperationsManager,
    isScheduleUser,
    isPreOPNurse,
    date: forcedDate, // Possibly forced externally
  } = props;

  /** Single state for current date/time system clock */
  const [currentDate, setCurrentDate] = useState(new Date());

  /** Param logic: parse once here */
  const parsedParams = useMemo(() => queryString.parse(location.search), [location.search]);
  const physician = parsedParams.physician ? parseInt(parsedParams.physician, 10) : null;

  // If there's a date in query string, parse it
  const dateOverride = parse((parsedParams.date || '') + 'T00:00:00');
  const useDateOverride = !isNaN(dateOverride.getTime());

  /**
   * Decide the final date
   * - If forcedDate is valid, use that
   * - Else if dateOverride is valid, use that
   * - Else fallback to currentDate
   */
  const date = useMemo(() => {
    if (forcedDate && !isNaN(forcedDate.getTime())) return forcedDate;
    if (useDateOverride) return dateOverride;
    return currentDate;
  }, [forcedDate, useDateOverride, dateOverride, currentDate]);

  const formattedDate = useMemo(() => format(date, 'YYYY-MM-DD'), [date]);

  const isPowerUser =
    isSuperAdmin || isGroupAdmin || isMonitoringEngineer || isAdmin || isFrontDesk || isOperationsManager;

  const dayIsOver = useDayIsOver({
    formattedDate,
    physician,
    currentDate,
    isPowerUser,
    isScheduleUser,
    useDateOverride,
    isPreOPNurse,
  });

  /** If day is over => show next day, else show the date we derived above */
  const finalDate = dayIsOver ? startOfDay(addDays(date, 1)) : date;

  /** Keep updating the system clock every minute */
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000 * 60);
    return () => clearInterval(interval);
  }, []);

  return (
    <MultipleStaffShiftSelectionContextProvider date={formattedDate}>
      <Schedule {...props} parsedParams={parsedParams} physician={physician} date={finalDate} dayIsOver={dayIsOver} />
    </MultipleStaffShiftSelectionContextProvider>
  );
};

const Refresh = ({ inSeconds, children }) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      window.location.reload();
    }, inSeconds * 1000);
    return () => clearTimeout(timeout);
  }, [inSeconds]);

  return children || null;
};

export const hospitalScheduleStyles = makeStyles(theme => ({
  page: {
    flex: 1,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
    fontSize: '1.075rem',
    height: '100vh',
    width: '100vw',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.875rem',
      WebkitOverflowScrolling: 'touch',
    },
  },
  hdtv: {
    fontSize: '1rem',
  },
  kiosk: {
    [theme.breakpoints.down('lg')]: {
      fontSize: '1rem',
    },
  },
  kioskMaxWidth1600: {
    fontSize: '0.8125rem',
  },
  kioskMaxWidth1400: {
    fontSize: '0.6875rem',
  },
  editingHeader: {
    display: 'flex',
    padding: '0.875em 1em',
    justifyContent: 'stretch',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      // overflow: 'hidden',
      flexFlow: 'column',
      alignItems: 'stretch',
    },
    '& > div': {
      overflow: 'hidden',
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        flex: 'initial',
      },
    },
  },
  mainActions: {
    display: 'flex',
    gap: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      '& .vanishing': {
        display: 'none',
      },
    },
  },
  divider: {
    alignSelf: 'stretch',
  },
  toggleCalendar: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

export default HospitalSchedule;
