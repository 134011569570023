import React, { Fragment } from 'react';
import MultiSelectInput from './../../se/components/inputs/MultiSelectInput';

type Option = { value: string; label: 'Analytics Pdf v1' };

export const analyticsPdfsOptions = [
  {
    label: 'Analytics Pdf v1',
    value: 'Analytics Pdf v1',
  },
  {
    label: 'Analytics Pdf v2',
    value: 'Analytics Pdf v2',
  },
];

const AnalyticsPdfsMultipleSelectInput = ({
  value,
  onChange,
}: {
  value: Option[];
  onChange: (value: Option[]) => void;
}) => {
  return (
    <Fragment>
      <MultiSelectInput
        name={`AnalyticsPDFs`}
        options={analyticsPdfsOptions}
        onChange={onChange}
        value={analyticsPdfsOptions.filter(e => !!(value || [])?.find(el => e?.value === el?.value))}
        loading={false}
        disabled={false}
      />
    </Fragment>
  );
};

export default AnalyticsPdfsMultipleSelectInput;
