import React, { Fragment } from 'react';
import { Box, Button, SvgIconProps, Typography } from '@material-ui/core';
import { CheckCircleTwoTone, WarningTwoTone } from '@material-ui/icons';
import queryString from 'query-string';
import compactQueryParams from '../../util/compactQueryParams';
import config from '../../config';
import { Category, CovidScreeningStatus } from '../../types/CovidScreening';
import { Mutation } from '@apollo/client/react/components';
import LinkButton from '../../se/components/LinkButton';
import Tooltip from '../../components/Tooltip';
import { Count, DeliveryStatus } from '../../components/entities/patient/columns';
import { sendCovidScreeningInvitation } from '../../graph/covidScreening';
import { differenceInMinutes, isBefore, parse } from 'date-fns';
import { smsStatusType } from '../../components/entities/procedures/ProcedureEventLog';
import useNow from '../../hooks/useNow';
import { useQuery } from '@apollo/client';
import { getNotificationTemplate } from '../../graph/notificationTemplates';
import { withScope } from '../../contexts/ScopeContext';
import { SendingSetting } from '../../components/entities/notificationTemplate/NotificationTemplates';

interface FormStatusProps {
  scope: any;
  data?: {
    status: CovidScreeningStatus;
    procedureId?: string;
    submissionId?: string;
    startTime?: string;
    patientName?: string;
    patientPhoneNumber?: string;
    covidScreeningInviteSentAt?: string;
    covidScreeningInviteSMSCount?: number;
    lastCovidScreeningInviteSMS?: {
      id: any;
      type: any;
      timestamp: any;
      patientPin: any;
      channel: any;
      text: any;
      status: any;
      content: any;
      exchangeId: any;
    };
    category: Category;
  };
}

interface IndicatorProps {
  color: string;
  icon: SvgIconProps;
  text: string;
}

const Indicator = ({ color, icon, text }: IndicatorProps) => (
  <Box color={color} display="flex" alignItems="center">
    <Box mr={1} fontSize={0}>
      {icon}
    </Box>
    <Typography color="inherit">{text}</Typography>
  </Box>
);

const FormStatus = ({ scope, data }: FormStatusProps) => {
  const {
    status,
    procedureId,
    submissionId,
    startTime,
    patientName,
    patientPhoneNumber,
    covidScreeningInviteSentAt,
    covidScreeningInviteSMSCount,
    lastCovidScreeningInviteSMS,
    category,
  } = data || {};

  const { data: smsPatientReadyNotification } = useQuery(getNotificationTemplate, {
    variables: { trigger: 'CovidScreeningInvite', channel: 'SMS' },
  });
  const smsNotificationData = smsPatientReadyNotification?.getNotificationTemplate[0] || null;

  const smsStatus = lastCovidScreeningInviteSMS?.status;

  const now = useNow();
  const minutesSinceLastAction = covidScreeningInviteSentAt
    ? differenceInMinutes(now, parse(covidScreeningInviteSentAt))
    : Number.POSITIVE_INFINITY;
  const canResend =
    !smsStatus ||
    [smsStatusType.delivered, smsStatusType.failed, smsStatusType.undelivered].includes(smsStatus) ||
    minutesSinceLastAction >= 3;

  const openSubmissionWindow = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const organizationId = scope?.hospital?.covidScreening?.id;

    if (!organizationId) {
      return null;
    }

    const search = queryString.stringify(compactQueryParams({ category, name: patientName }));
    const url = `${config.covidScreeningURL}/${organizationId}/${submissionId}?${search}`;
    window.open(url, 'submission', `width=1024,height=750`);
  };

  const handleResend = (mutate: any) => async (e: any) => {
    e.stopPropagation();

    if (!procedureId) {
      return;
    }

    const procedureIdNumber = parseInt(procedureId);

    if (isNaN(procedureIdNumber)) {
      return;
    }

    await mutate({ variables: { procedureId: procedureIdNumber } });
  };

  const disabled =
    !patientPhoneNumber ||
    (startTime ? isBefore(new Date(startTime), now) : false) ||
    !(smsNotificationData?.sendingSetting !== SendingSetting.Off);
  const disabledTooltip = !patientPhoneNumber
    ? 'No Patient Cell Phone'
    : startTime && isBefore(new Date(startTime), now)
      ? 'Procedure is completed'
      : 'Patient covid messaging has been turned off. Go to message configuration to enable it.';
  const tooltip = `This will ${
    (covidScreeningInviteSMSCount ?? 0) > 0 ? 'resend' : 'send'
  } the SMS containing a link to the patient covid screening form.`;
  const error = [lastCovidScreeningInviteSMS?.content, lastCovidScreeningInviteSMS?.text].filter(_ => !!_).join(' - ');

  return (
    <div>
      {status === CovidScreeningStatus.NotFilled && (
        <Box display="flex" alignItems="baseline" style={{ gap: '1em' }}>
          <Typography color="textSecondary">
            Not completed.{' '}
            <Button variant="text" onClick={openSubmissionWindow}>
              Fill out
            </Button>
          </Typography>
          {procedureId && !isNaN(parseInt(procedureId)) && (
            <Tooltip content={error ? error : disabled && disabledTooltip ? disabledTooltip : tooltip} delay={200}>
              <Mutation mutation={sendCovidScreeningInvitation}>
                {(mutate: any, { loading }: any) => (
                  <Fragment>
                    <DeliveryStatus status={smsStatus} working={loading} />
                    <LinkButton
                      onClick={handleResend(mutate)}
                      disabled={
                        loading ||
                        disabled ||
                        !canResend ||
                        !patientPhoneNumber ||
                        !(smsNotificationData?.sendingSetting !== SendingSetting.Off)
                      }
                    >
                      Send SMS Invite
                      <Count>{`[ ${covidScreeningInviteSMSCount || 0} ]`}</Count>
                    </LinkButton>
                  </Fragment>
                )}
              </Mutation>
            </Tooltip>
          )}
        </Box>
      )}
      {status !== CovidScreeningStatus.NotFilled && (
        <Box display="flex" alignItems="center" style={{ gap: '1em' }}>
          {status === CovidScreeningStatus.Pass ? (
            <Indicator color="success.main" text="Pass" icon={<CheckCircleTwoTone fontSize="small" />} />
          ) : (
            <Indicator color="warning.main" text="Covid Risk" icon={<WarningTwoTone fontSize="small" />} />
          )}
          <Button variant="text" onClick={openSubmissionWindow}>
            Edit
          </Button>
        </Box>
      )}
    </div>
  );
};

export default withScope(FormStatus);
