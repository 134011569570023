import clsx from 'clsx';
import React, { ReactNode } from 'react';

export interface CellProps {
  rowspan?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  colspan?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  children?: ReactNode;
  vertical?: boolean;
  justifyContent?: 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly';
  highlight?: boolean;
}

const Cell = ({ rowspan, colspan, children, vertical, justifyContent, highlight }: CellProps) => (
  <div
    className={clsx(`f-span-${colspan ?? 12}`, { 'f-bg-light': highlight, 'f-flex-col': vertical })}
    style={{
      gridRow: rowspan ? `auto / span ${rowspan}` : undefined,
      justifyContent: justifyContent || 'initial',
      wordBreak: 'break-word',
    }}
  >
    {children}
  </div>
);

export interface LabeledCellProps extends CellProps {
  label?: string;
}
export const LabeledCell = ({ label, colspan, rowspan, children }: LabeledCellProps) => (
  <Cell colspan={colspan} rowspan={rowspan}>
    <span className="f-text-bold" style={{ marginRight: '1ch' }}>
      {!!label ? `${label}:` : ''}
    </span>
    {children}
  </Cell>
);

export default Cell;
