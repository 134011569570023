import React, { FC, useContext } from 'react';
import GraphLayout from '../analytics/GraphLayout';
import HospitalRating from '../analytics/HospitalRating';
import PWAInstallBanner from '../../../se/components/PWAInstallBanner';
import {
  dashboardQuery,
  dashboardQueryPrev,
  ORUtilizationAnalyticsQuery,
  ORUtilizationAnalyticsTotalsQuery,
} from '@/graph/dashboard';
import { useQuery } from '@apollo/client';
import pick from 'lodash/fp/pick';
import { AnalyticsLayout } from '../Analytics';
import ProcedureCancellationChart from './v2/ProcedureCancellationChart';
import PatientJourneyChart from './v2/PatientJourneyChart';
import ORPerformanceChart from './v2/ORPerformanceChart';
import AnalyticsPanel from './components/AnalyticsPanel';
import BarUtilizationByOr from './v2/charts/BarUtilizationByOr';
import { toPieChartData } from './ORUtilizationAnalytics';
import { AggregationContext } from './Aggregation';
import Configuration from '@/components/pages/analytics/components/Configuration';

export const pickFilter = pick(['physician', 'speciality', 'procedureType', 'dateRange']);

const GeneralAnalytics: FC<{ filter: any; setFilter: any; isBusinessManager: boolean; thresholds: any }> = ({
  filter,
  setFilter,
  isBusinessManager,
  thresholds,
}) => {
  const { dateRange, operationRooms, physicians, ...rest } = filter || {};
  const toDate = new Date(
    dateRange?.date?._year,
    dateRange?.date?._month - 1, // Subtract 1 from month
    dateRange?.date?._day
  );

  const customDateRange = {
    from: (() => {
      const fromDate = new Date(toDate);
      fromDate.setFullYear(fromDate.getFullYear() - (dateRange?.period?._years || 0));
      fromDate.setMonth(fromDate.getMonth() - (dateRange?.period?._months || 0));
      fromDate.setDate(fromDate.getDate() - (dateRange?.period?._days || 0));

      return '' + fromDate.getFullYear() + '-' + (fromDate.getMonth() + 1) + '-' + fromDate.getDate();
    })(),
    to: '' + dateRange?.date?._year + '-' + dateRange?.date?._month + '-' + dateRange?.date?._day,
  };

  const sanitizedOperationRoomsFilter =
    Array.isArray(operationRooms) && operationRooms.length > 0
      ? { operationRooms: operationRooms.map(or => parseInt(or, 10)) }
      : isFinite(parseInt(operationRooms, 10))
        ? { operationRooms: [parseInt(operationRooms, 10)] }
        : {};

  const sanitizedPhysiciansFilter =
    Array.isArray(physicians) && physicians.length > 0
      ? { physicians: physicians.map(py => parseInt(py, 10)) }
      : isFinite(parseInt(physicians, 10))
        ? { physicians: [parseInt(physicians, 10)] }
        : {};

  const { data: prevData } = useQuery(dashboardQueryPrev, {
    variables: {
      filter: {
        dateRange: customDateRange,
        ...sanitizedOperationRoomsFilter,
        ...sanitizedPhysiciansFilter,
        ...pickFilter(rest),
      },
      wrMinThreshold: thresholds.wrMinThreshold,
      wrMaxThreshold: thresholds.wrMaxThreshold,
      preOpMinThreshold: thresholds.preOpMinThreshold,
      preOpMaxThreshold: thresholds.preOpMaxThreshold,
      inOrMinThreshold: thresholds.inOrMinThreshold,
      inOrMaxThreshold: thresholds.inOrMaxThreshold,
      cleaningMinThreshold: thresholds.cleaningMinThreshold,
      cleaningMaxThreshold: thresholds.cleaningMaxThreshold,
      popiMinThreshold: thresholds.popiMinThreshold,
      popiMaxThreshold: thresholds.popiMaxThreshold,
      pacuMinThreshold: thresholds.pacuMinThreshold,
      pacuMaxThreshold: thresholds.pacuMaxThreshold,
      postOpMinThreshold: thresholds.postOpMinThreshold,
      postOpMaxThreshold: thresholds.postOpMaxThreshold,
      samePhysicianORTurnover: thresholds.samePhysicianORTurnover,
      closingMinThreshold: thresholds.closingMinThreshold,
      closingMaxThreshold: thresholds.closingMaxThreshold,
    },
    fetchPolicy: 'network-only',
  });
  const prevStatistics = prevData?.dashboardPrev || [];

  const { data, loading } = useQuery(dashboardQuery, {
    variables: {
      filter: {
        dateRange: dateRange.toJSON(),
        ...sanitizedOperationRoomsFilter,
        ...sanitizedPhysiciansFilter,
        ...pickFilter(rest),
      },
      wrMinThreshold: thresholds.wrMinThreshold,
      wrMaxThreshold: thresholds.wrMaxThreshold,
      preOpMinThreshold: thresholds.preOpMinThreshold,
      preOpMaxThreshold: thresholds.preOpMaxThreshold,
      inOrMinThreshold: thresholds.inOrMinThreshold,
      inOrMaxThreshold: thresholds.inOrMaxThreshold,
      cleaningMinThreshold: thresholds.cleaningMinThreshold,
      cleaningMaxThreshold: thresholds.cleaningMaxThreshold,
      popiMinThreshold: thresholds.popiMinThreshold,
      popiMaxThreshold: thresholds.popiMaxThreshold,
      pacuMinThreshold: thresholds.pacuMinThreshold,
      pacuMaxThreshold: thresholds.pacuMaxThreshold,
      postOpMinThreshold: thresholds.postOpMinThreshold,
      postOpMaxThreshold: thresholds.postOpMaxThreshold,
      samePhysicianORTurnover: thresholds.samePhysicianORTurnover,
      closingMinThreshold: thresholds.closingMinThreshold,
      closingMaxThreshold: thresholds.closingMaxThreshold,
    },
    fetchPolicy: 'network-only',
  });

  const aggregation = useContext(AggregationContext);

  const { data: orUtilizationData, loading: orUtilizationLoading } = useQuery(ORUtilizationAnalyticsQuery, {
    variables: {
      filter: {
        dateRange: dateRange.toJSON(),
        ...sanitizedOperationRoomsFilter,
        ...sanitizedPhysiciansFilter,
        ...pickFilter(rest),
      },
      pipoMinThreshold: thresholds.pipoMinThreshold,
      pipoMaxThreshold: thresholds.pipoMaxThreshold,
      popiMinThreshold: thresholds.popiMinThreshold,
      popiMaxThreshold: thresholds.popiMaxThreshold,
      wrMinThreshold: thresholds.wrMinThreshold,
      wrMaxThreshold: thresholds.wrMaxThreshold,
      preOpMinThreshold: thresholds.preOpMinThreshold,
      preOpMaxThreshold: thresholds.preOpMaxThreshold,
      cleaningMinThreshold: thresholds.cleaningMinThreshold,
      cleaningMaxThreshold: thresholds.cleaningMaxThreshold,
      pacuMinThreshold: thresholds.pacuMinThreshold,
      pacuMaxThreshold: thresholds.pacuMaxThreshold,
      postOpMinThreshold: thresholds.postOpMinThreshold,
      postOpMaxThreshold: thresholds.postOpMaxThreshold,
      preOpToDischargedMinThreshold: thresholds.preOpToDischargedMinThreshold,
      preOpToDischargedMaxThreshold: thresholds.preOpToDischargedMaxThreshold,
      inOrMinThreshold: thresholds.inOrMinThreshold,
      inOrMaxThreshold: thresholds.inOrMaxThreshold,
      inSurgeryMinThreshold: thresholds.inSurgeryMinThreshold,
      inSurgeryMaxThreshold: thresholds.inSurgeryMaxThreshold,
      closingMinThreshold: thresholds.closingMinThreshold,
      closingMaxThreshold: thresholds.closingMaxThreshold,
      procedureCompleteMinThreshold: thresholds.procedureCompleteMinThreshold,
      procedureCompleteMaxThreshold: thresholds.procedureCompleteMaxThreshold,
      orReadyMinThreshold: thresholds.orReadyMinThreshold,
      orReadyMaxThreshold: thresholds.orReadyMaxThreshold,
      samePhysicianORTurnover: thresholds.samePhysicianORTurnover,
    },
    fetchPolicy: 'cache-and-network',
  });

  const { data: orUtilizationTotalsData, loading: orUtilizationTotalsLoading } = useQuery(
    ORUtilizationAnalyticsTotalsQuery,
    {
      variables: {
        filter: {
          dateRange: dateRange.toJSON(),
          ...sanitizedOperationRoomsFilter,
          ...sanitizedPhysiciansFilter,
          ...pickFilter(rest),
        },
        pipoMinThreshold: thresholds.pipoMinThreshold,
        pipoMaxThreshold: thresholds.pipoMaxThreshold,
        popiMinThreshold: thresholds.popiMinThreshold,
        popiMaxThreshold: thresholds.popiMaxThreshold,
        wrMinThreshold: thresholds.wrMinThreshold,
        wrMaxThreshold: thresholds.wrMaxThreshold,
        preOpMinThreshold: thresholds.preOpMinThreshold,
        preOpMaxThreshold: thresholds.preOpMaxThreshold,
        cleaningMinThreshold: thresholds.cleaningMinThreshold,
        cleaningMaxThreshold: thresholds.cleaningMaxThreshold,
        pacuMinThreshold: thresholds.pacuMinThreshold,
        pacuMaxThreshold: thresholds.pacuMaxThreshold,
        postOpMinThreshold: thresholds.postOpMinThreshold,
        postOpMaxThreshold: thresholds.postOpMaxThreshold,
        preOpToDischargedMinThreshold: thresholds.preOpToDischargedMinThreshold,
        preOpToDischargedMaxThreshold: thresholds.preOpToDischargedMaxThreshold,
        inOrMinThreshold: thresholds.inOrMinThreshold,
        inOrMaxThreshold: thresholds.inOrMaxThreshold,
        inSurgeryMinThreshold: thresholds.inSurgeryMinThreshold,
        inSurgeryMaxThreshold: thresholds.inSurgeryMaxThreshold,
        closingMinThreshold: thresholds.closingMinThreshold,
        closingMaxThreshold: thresholds.closingMaxThreshold,
        procedureCompleteMinThreshold: thresholds.procedureCompleteMinThreshold,
        procedureCompleteMaxThreshold: thresholds.procedureCompleteMaxThreshold,
        orReadyMinThreshold: thresholds.orReadyMinThreshold,
        orReadyMaxThreshold: thresholds.orReadyMaxThreshold,
        samePhysicianORTurnover: thresholds.samePhysicianORTurnover,
      },
      fetchPolicy: 'cache-and-network',
    }
  );
  const statistics = data?.dashboard || [];

  const configArray = [
    thresholds.totalPatientsThreshold,
    thresholds.waitingRoomTimeThreshold,
    thresholds.preopTimeThreshold,
    thresholds.readyForAnesthesiaThreshold,
    thresholds.readyForORThreshold,
    // analyticsConfig?.data?.analyticsConfiguration?.orUtilThreshold,
    thresholds.orTimeThreshold,
    thresholds.cleaningTimeThreshold,
    thresholds.closingTimeThreshold,
    thresholds.popiTimeThreshold,
    thresholds.pacuTimeThreshold,
    thresholds.postOpTimeThreshold,
  ];

  return (
    <>
      <PWAInstallBanner />

      {!isBusinessManager && <HospitalRating filter={filter} />}
      <AnalyticsLayout filter={filter} setFilter={setFilter} loading={loading}>
        <AnalyticsPanel
          title={statistics[0]?.title}
          action={
            <Configuration
              title="Total Patients Received Configuration"
              configType="totalPatientsReceived"
              size="small"
            />
          }
        >
          <GraphLayout
            key={`${statistics[0]?.id}-${0}`}
            {...statistics[0]}
            greenLineY={configArray[0]}
            data={statistics[0]}
            prevStat={prevStatistics[0]}
          />
        </AnalyticsPanel>
        <AnalyticsPanel title="Average Patient Journey">
          <PatientJourneyChart
            filter={{
              dateRange: dateRange.toJSON(),
              ...sanitizedOperationRoomsFilter,
              ...sanitizedPhysiciansFilter,
              ...pickFilter(rest),
            }}
          />
        </AnalyticsPanel>
        <AnalyticsPanel title="OR Performance">
          <ORPerformanceChart
            filter={{
              dateRange: dateRange.toJSON(),
              ...sanitizedOperationRoomsFilter,
              ...sanitizedPhysiciansFilter,
              ...pickFilter(rest),
            }}
            thresholds={thresholds}
          />
        </AnalyticsPanel>
        <AnalyticsPanel title="Procedure Cancellation Reasons">
          <ProcedureCancellationChart
            filter={{
              dateRange: dateRange.toJSON(),
              ...sanitizedOperationRoomsFilter,
              ...sanitizedPhysiciansFilter,
              ...pickFilter(rest),
            }}
          />
        </AnalyticsPanel>
        {statistics.slice(1, 5).map((stat, i) => (
          <AnalyticsPanel
            key={`${stat.id}-${i}`}
            title={stat.title}
            action={<Configuration title={`${stat.title} Configuration`} configType={stat.id} size="small" />}
          >
            <GraphLayout
              {...stat}
              greenLineY={configArray.slice(1, 5)[i]}
              data={stat}
              prevStat={prevStatistics.slice(1, 5)[i]}
            />
          </AnalyticsPanel>
        ))}
        <BarUtilizationByOr
          data={toPieChartData(
            orUtilizationData?.ORUtilizationAnalytics || [],
            orUtilizationTotalsData?.ORUtilizationAnalyticsTotals || [],
            aggregation
          )}
          generalAnalytics
        />
        {statistics.slice(5).map((stat, i) => (
          <AnalyticsPanel
            key={`${stat.id}-${i}`}
            title={stat.title}
            action={<Configuration title={`${stat.title} Configuration`} configType={stat.id} size="small" />}
          >
            <GraphLayout
              {...stat}
              greenLineY={configArray.slice(5)[i]}
              data={stat}
              prevStat={prevStatistics.slice(5)[i]}
            />
          </AnalyticsPanel>
        ))}
      </AnalyticsLayout>
    </>
  );
};

export default GeneralAnalytics;
