import { graphql } from '@apollo/client/react/hoc';
import React, { useState } from 'react';
import { ValueAction } from '../../entities/patient/views/components';
import { DataTitle } from '../../../se/components/entity/EntityView';
import LinkButton from '../../../se/components/LinkButton';
import { compose, withProps } from 'recompose';
import ModalForm from '../../ModalForm';
import TextInput from '../../../se/components/inputs/TextInput';
import styled from 'styled-components';
import SimpleMutationForm from '../../SimpleMutationForm';
import { getNestedValue } from '../../../se/utilities/data/object';
import { signalProcessing as scheme } from '../../../graph/signal';
import Tooltip from '@material-ui/core/Tooltip';
import withStyles from '@material-ui/core/styles/withStyles';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

const SWPeriodInput = withProps({ placeholder: 'e.g., 16000 (milliseconds)', required: true })(styled(TextInput)`
  width: 100%;
  margin-bottom: 2em;
`);

const SamplePeriodInput = withProps({ placeholder: '4 (samples)', required: true })(styled(TextInput)`
  width: 100%;
  margin-bottom: 2em;
`);

const LightTooltip = withStyles(theme => ({
  tooltip: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.body1.fontWeight,
    backgroundColor: theme.palette.background.default,
  },
}))(Tooltip);

const SWPInput = (value, onChange) => props => <SWPeriodInput {...props} value={value} onChange={onChange} />;
const SampleInput = (value, onChange) => props => <SamplePeriodInput {...props} value={value} onChange={onChange} />;

const ChangeConfigForm =
  (Input, mutation) =>
  ({ value, onChange, onSubmit }) => (
    <SimpleMutationForm
      scheme={{ ...scheme, update: mutation }}
      Input={Input}
      value={value}
      onChange={v => onChange(parseInt(v))}
      onSubmit={onSubmit}
    />
  );

const ChangeSWPForm = ChangeConfigForm(SWPInput, scheme.setSlidingWindowPeriod);
const ChangeSampleForm = ChangeConfigForm(SampleInput, scheme.setMinimumSamples);

class ChangeConfig extends React.Component {
  render() {
    return (
      <ModalForm
        title="Update Sliding Window Width"
        variableName={this.props.fieldName}
        entityId={0}
        initialValue={this.props.initialValue}
        onClose={() => this.props.setEditModalOpen(false)}
        FormComponent={this.props.Component}
      />
    );
  }
}

const WattAveragingSetter = graphql(scheme.setUseWattAveraging)(({ useWattAveraging, mutate, ...rest }) => (
  <LinkButton onClick={() => mutate({ variables: { useWattAveraging } })}>
    Change to {useWattAveraging ? 'mW' : 'dBm'}
  </LinkButton>
));

const AdditionalFilterSetter = graphql(scheme.setUseAveragingStage)(({ useAveragingStage, mutate, ...rest }) => (
  <LinkButton onClick={() => mutate({ variables: { useAveragingStage } })}>
    {!useAveragingStage ? 'Use v1' : 'Use v2'}
  </LinkButton>
));

const CenteredValueAction = styled(ValueAction)`
  align-items: center;
`;

const Panel = ({ title, tooltipText, value, action }) => (
  <Box component={Paper} p={2}>
    <Box
      component="span"
      display="flex"
      flexDirection="row"
      alignItems="flex-start"
      justifyContent="space-between"
      mb={2}
    >
      <DataTitle>{title}</DataTitle>
      <LightTooltip title={tooltipText}>
        <InfoOutlined color="primary" />
      </LightTooltip>
    </Box>
    <CenteredValueAction>
      <Typography>{value}</Typography>
      {action}
    </CenteredValueAction>
  </Box>
);

const ConfigurationEdit = ({ withActionButton, data, style }) => {
  const useAveragingStage = getNestedValue('signalProcessingConfig.useAveragingStage', data);
  const minimumSamples = getNestedValue('signalProcessingConfig.minimumSamples', data);
  const period = getNestedValue('signalProcessingConfig.swPeriod', data);
  const useWattAveraging = getNestedValue('signalProcessingConfig.useWattAveraging', data);

  const [swpEditModalOpen, setSwpEditModalOpen] = useState(false);
  const [sampleEditModalOpen, setSampleEditModalOpen] = useState(false);

  if (data.signalProcessingConfig) {
    return (
      <Grid container spacing={2}>
        <Grid item md xs={12}>
          <Panel
            title="Signal Averaging Unit"
            tooltipText="Calculate signal rolling average from data points in mW or dBm. mW is linear scale and should be
              preferred."
            value={useWattAveraging ? 'mW' : 'dBm'}
            action={withActionButton && <WattAveragingSetter useWattAveraging={!useWattAveraging} />}
          />
        </Grid>

        <Grid item md xs={12}>
          <Panel
            title="Sliding Window Period"
            tooltipText="Sliding window width in ms for rolling signal average calculation."
            value={period ? `${period} ms` : '-'}
            action={withActionButton && <LinkButton onClick={() => setSwpEditModalOpen(true)}>Change</LinkButton>}
          />
        </Grid>

        <Grid item md xs={12}>
          <Panel
            title="Minimum Averaging Samples"
            tooltipText="Minimal number of samples needed to evaluate bracelet signal, bracelet with number of sample
                          bellow the minimum setting will be considered inactive."
            value={minimumSamples ? `${minimumSamples} sample` : '-'}
            action={withActionButton && <LinkButton onClick={() => setSampleEditModalOpen(true)}>Change</LinkButton>}
          />
        </Grid>

        <Grid item md xs={12}>
          <Panel
            title="Filtering Logic"
            tooltipText={
              <Box>
                <Typography gutterBottom>Different version of calculating the winning gateway.</Typography>
                <Typography style={{ fontWeight: 'bold' }}>V1. Only By Thresholds</Typography>
                <Typography gutterBottom>
                  Declare winning gateway by comparing bracelet average signal values across all gateways equally.
                </Typography>
                <Typography style={{ fontWeight: 'bold' }}>V2. Thresholds and Average</Typography>
                <Typography>
                  After applying threshold logic and if new and old winning rooms both have more than 1 gateway,
                  calculate bracelet averages across all room gateways and inform definite decision.
                </Typography>
              </Box>
            }
            value={useAveragingStage ? 'Thresholds And Average' : 'Only By Thresholds'}
            action={withActionButton && <AdditionalFilterSetter useAveragingStage={!useAveragingStage} />}
          />
        </Grid>

        {swpEditModalOpen && (
          <ChangeConfig
            initialValue={period}
            setEditModalOpen={setSwpEditModalOpen}
            Component={ChangeSWPForm}
            fieldName={'swPeriod'}
          />
        )}
        {sampleEditModalOpen && (
          <ChangeConfig
            initialValue={minimumSamples}
            setEditModalOpen={setSampleEditModalOpen}
            Component={ChangeSampleForm}
            fieldName={'minimumSamples'}
          />
        )}
      </Grid>
    );
  } else {
    return null;
  }
};

export default compose(graphql(scheme.item))(ConfigurationEdit);
