import React from 'react';
import styled, { css } from 'styled-components';

import { Text } from './typography';
import { isDefinedAndNotNull } from '../utilities/check';
import { withTheme } from '../theme';

const VanishingText = styled(Text)`
  ${props =>
    !props.hasValue &&
    css`
      display: none;
    `};
`;

const InputContainer = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 1.5rem;
`;

const FieldLabel = styled.label`
  margin-bottom: 0.75em;
  font-size: 0.937em;
`;

const AlternativeFieldLabel = styled(FieldLabel)`
  color: ${withTheme(theme => theme.textColor.string())};
  font-weight: 500;
  opacity: 0.45;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const HelperText = styled.p`
  opacity: 0.6;
  font-size: 0.875em;
  margin-bottom: 0.75rem;
`;

const Label = ({ text, children, section, ...rest }) => {
  const Component = section ? AlternativeFieldLabel : FieldLabel;

  return (
    <InputContainer style={{ marginBottom: section ? '0' : undefined, ...rest.style }}>
      <Component {...rest}>
        <VanishingText hasValue={isDefinedAndNotNull(text)}>{text}</VanishingText>
      </Component>
      {children}
    </InputContainer>
  );
};

export default Label;

export const withLabel =
  (label, labelProps = {}, helperText) =>
  Component =>
  ({ name, ...rest }) => (
    <Label {...labelProps} text={label} htmlFor={name}>
      {helperText && <HelperText>{helperText}</HelperText>}
      <Component {...rest} name={name} />
    </Label>
  );
