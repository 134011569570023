import React, { ReactNode, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { useValue } from '../components/entities/surgeonProcedures/predefinedForms/ValueContext';
import { useQuestionnaireValueViaSelector } from '../components/entities/surgeonProcedures/predefinedForms/hooks';

interface CheckboxProps {
  label?: ReactNode;
  rowspan?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  colspan?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  name: string;
  questionnaire?: (questionnaire: unknown, questionnaireValue: unknown) => boolean | undefined;
  negative?: boolean;
  vertical?: boolean;
  onChanged?: (checked: boolean) => void;
  className?: string;
}

const Checkbox = ({
  label,
  rowspan,
  colspan,
  name,
  questionnaire,
  negative,
  vertical,
  onChanged,
  className,
}: CheckboxProps) => {
  const { defaultValue, value, setValue } = useValue(name);

  const questionnaireValue = useQuestionnaireValueViaSelector(questionnaire);

  const checked = value === undefined ? (questionnaireValue === undefined ? defaultValue : questionnaireValue) : value;

  const onChangedRef = useRef(onChanged);

  useEffect(() => {
    onChangedRef.current = onChanged;
  }, [onChanged]);

  useEffect(() => {
    onChangedRef.current?.(checked);
  }, []);

  return (
    <label
      className={clsx(`f-span-${colspan ?? 12}`, 'checkmark', { 'f-flex-col': vertical }, className)}
      style={{ gridRow: rowspan ? `auto / span ${rowspan}` : undefined }}
    >
      <input
        type="checkbox"
        name={name}
        checked={negative ? checked === false : checked === true} // if checked is nullish, show one as not checked
        onChange={e => {
          const newValue = negative ? !e.target.checked : e.target.checked;
          setValue(newValue);
          onChanged?.(newValue);
        }}
      />
      <span style={vertical ? { marginBottom: 6 } : undefined}></span>
      {label && <span className="f-uppercase">{label}</span>}
    </label>
  );
};

export default Checkbox;
