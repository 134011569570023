import CSVExportService from 'json2csvexporter';

export default (list, columns, data, scope) => {
  const exporter = CSVExportService.create({
    columns: columns.map(_ => _.title),
    headers: columns.map(_ => ({ [_.title]: _.title })),
    includeHeaders: true,
  });
  const json = [];
  list.forEach(_ => {
    if (_ && !_.separator) {
      let item = {};
      columns.forEach(column => {
        let value = column.lens(_, { data }, scope);
        if (value && typeof value === 'object') {
          const key = column.key;
          value = key in value ? value[key] : value.id;
        }
        item[column.title] = value ? value : '';
      });
      json.push(item);
    }
  });
  exporter.downloadCSV(json);
};
