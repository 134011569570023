import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from '../styles';
import MedicalPassportField from '../components/MedicalPassportField';
import FallRiskCheckbox from '../components/FallRiskCheckbox';
import Barcode from '../../Barcode';

function initializeValues(rootRef, setChecked) {
  const root = rootRef.current;

  if (!root) {
    return;
  }

  const checkboxes = root.querySelectorAll('input[type="checkbox"]');

  setChecked(
    [...checkboxes].reduce((acc, checkbox) => {
      const value = checkbox.checked ? parseInt(checkbox.value) : 0;
      return { ...acc, [checkbox.name]: isFinite(value) ? value : 1 };
    }, {})
  );
}

const OCOMfallRiskAssessment = ({ formName, pageNumber, showQRCode, value, defaultValue }) => {
  const [values1, setValues1] = useState({});

  const total1 = useMemo(() => Object.values(values1).reduce((acc, value) => acc + value, 0), [values1]);

  const root1Ref = useRef(undefined);

  useEffect(() => {
    initializeValues(root1Ref, setValues1);
  }, []);

  const handleCheckboxChange = setValues => (value, name) => {
    setValues(prev => ({ ...prev, [name]: value }));
  };

  return (
    <div id="page7" className="page">
      <div style={styles.center}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div />
          <h2 style={styles.underlineHeading}>Fall Risk Assessment</h2>
          <div>
            {showQRCode && (
              <Barcode
                formName={formName}
                pageNumber={pageNumber}
                providerId={defaultValue?.procedure?.patient?.providerId}
                serviceTime={defaultValue?.procedure?.serviceTime}
              />
            )}
          </div>
        </div>
        <div
          style={{
            marginBottom: '0.4rem',
            width: '100%',
            display: 'grid',
            gridTemplateRows: '1fr 1fr',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <MedicalPassportField name="procedure.patient.name" label="Patient" fontWeight={'bold'} fontSize={16} />
            <MedicalPassportField name="procedure.patient.dob" label="DOB" />
            <MedicalPassportField name="procedure.patient.providerId" label="MRN" />
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <MedicalPassportField name="procedure.physician.name" label="Surgeon" />
            <MedicalPassportField name="procedure.patient.sex" label="Sex" />
            <MedicalPassportField name="procedure.patient.age" label="Age" />
            <MedicalPassportField name="procedure.serviceTime" label="DOS" />
          </div>
        </div>
      </div>
      <br />
      <br />
      <div>
        <div ref={root1Ref}>
          <div style={styles.oneColumn}>
            <b>Morse Fall Risk</b>
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '3fr 2fr 1fr',
              border: '1px solid black',
              borderBottom: 0,
            }}
          >
            <div style={{ textAlign: 'center', padding: '0.5rem', borderRight: '1px solid black' }}>Risk Factor(s)</div>
            <div style={{ textAlign: 'center', padding: '0.5rem', borderRight: '1px solid black' }}>Scale</div>
            <div style={{ textAlign: 'center', padding: '0.5rem' }}>Score</div>
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '3fr 2fr 1fr',
              border: '1px solid black',
              borderBottom: 0,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRight: '1px solid black',
                gridRow: '1 / 3',
              }}
            >
              <div> History of Falling Immediate or Within Last 3 Months</div>
            </div>
            <div
              style={{
                padding: '0.25rem',
                borderRight: '1px solid black',
                borderBottom: '1px solid black',
              }}
            >
              <FallRiskCheckbox
                name="fallingHx.yes"
                label="Yes"
                value={25}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
            <div
              style={{
                textAlign: 'center',
                borderBottom: '1px solid black',
                padding: '0.25rem',
              }}
            >
              25
            </div>
            <div style={{ borderRight: '1px solid black', padding: '0.25rem' }}>
              <FallRiskCheckbox
                name="fallingHx.no"
                label="No"
                value={0}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
            <div style={{ textAlign: 'center', padding: '0.25rem' }}>0</div>
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '3fr 2fr 1fr',
              border: '1px solid black',
              borderBottom: 0,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRight: '1px solid black',
                gridRow: '1 / 3',
                padding: '0.5rem',
              }}
            >
              <div>
                Presence of Secondary Diagnosis
                <br />
                <span style={{ fontSize: '.8rem' }}>
                  If the patient is on any of the following classes of medications, check "YES" for secondary diagnosis:
                  Anti-seizure Medications, Laxatives, Sedatives/Hypnotics, Benzodiazepines, Narcotics, Blood Thinners.
                  Diuretics, Psychotropics, Skeletal Muscle Relaxants, and Sedating Antihistamines.
                </span>
              </div>
            </div>
            <div
              style={{
                padding: '0.25rem',
                borderRight: '1px solid black',
                borderBottom: '1px solid black',
              }}
            >
              <FallRiskCheckbox
                name="presence.yes"
                label="Yes"
                value={15}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
            <div
              style={{
                textAlign: 'center',
                borderBottom: '1px solid black',
                padding: '0.25rem',
                color: value?.historyOfFallsYes ? 'red' : 'black',
              }}
            >
              15
            </div>
            <div style={{ borderRight: '1px solid black', padding: '0.25rem' }}>
              <FallRiskCheckbox
                name="presence.no"
                label="No"
                value={0}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
            <div style={{ textAlign: 'center', padding: '0.25rem' }}>0</div>
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '3fr 2fr 1fr',
              border: '1px solid black',
              borderBottom: 0,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRight: '1px solid black',
                gridRow: '1 / 4',
              }}
            >
              Use of Ambulatory Aid
            </div>
            <div
              style={{
                padding: '0.25rem',
                borderRight: '1px solid black',
                borderBottom: '1px solid black',
              }}
            >
              <FallRiskCheckbox
                name="ambulatory.furniture"
                label="Furniture"
                value={30}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
            <div
              style={{
                textAlign: 'center',
                borderBottom: '1px solid black',
                padding: '0.25rem',
              }}
            >
              30
            </div>
            <div
              style={{
                padding: '0.25rem',
                borderRight: '1px solid black',
                borderBottom: '1px solid black',
              }}
            >
              <FallRiskCheckbox
                name="ambulatory.crutches"
                label="Crutches, cane, walker"
                value={15}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
            <div
              style={{
                textAlign: 'center',
                borderBottom: '1px solid black',
                padding: '0.25rem',
              }}
            >
              15
            </div>
            <div style={{ borderRight: '1px solid black', padding: '0.25rem' }}>
              <FallRiskCheckbox
                name="ambulatory.none"
                label="None, bedrest, wheelchair, nurse"
                value={0}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
            <div style={{ textAlign: 'center', padding: '0.25rem' }}>0</div>
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '3fr 2fr 1fr',
              border: '1px solid black',
              borderBottom: 0,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRight: '1px solid black',
                gridRow: '1 / 3',
              }}
            >
              IV / Heparin Lock
            </div>
            <div
              style={{
                padding: '0.25rem',
                borderRight: '1px solid black',
                borderBottom: '1px solid black',
              }}
            >
              <FallRiskCheckbox
                name="IV.yes"
                label="Yes"
                value={20}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
            <div
              style={{
                textAlign: 'center',
                borderBottom: '1px solid black',
                padding: '0.25rem',
              }}
            >
              20
            </div>
            <div style={{ borderRight: '1px solid black', padding: '0.25rem' }}>
              <FallRiskCheckbox name="IV.no" label="No" value={0} onCheckboxChange={handleCheckboxChange(setValues1)} />
            </div>
            <div
              style={{
                textAlign: 'center',
                padding: '0.25rem',
              }}
            >
              0
            </div>
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '3fr 2fr 1fr',
              border: '1px solid black',
              borderBottom: 0,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRight: '1px solid black',
                gridRow: '1 / 4',
              }}
            >
              Gait / Transferring
            </div>
            <div
              style={{
                padding: '0.25rem',
                borderRight: '1px solid black',
                borderBottom: '1px solid black',
              }}
            >
              <FallRiskCheckbox
                name="gait.impaired"
                label="Impaired"
                value={20}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
            <div
              style={{
                textAlign: 'center',
                borderBottom: '1px solid black',
                padding: '0.25rem',
              }}
            >
              20
            </div>
            <div
              style={{
                padding: '0.25rem',
                borderRight: '1px solid black',
                borderBottom: '1px solid black',
              }}
            >
              <FallRiskCheckbox
                name="gait.weak"
                label="Weak"
                value={10}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
            <div
              style={{
                textAlign: 'center',
                borderBottom: '1px solid black',
                padding: '0.25rem',
              }}
            >
              10
            </div>
            <div style={{ borderRight: '1px solid black', padding: '0.25rem' }}>
              <FallRiskCheckbox
                name="gait.normal"
                label="Normal, bedrest, immobile"
                value={0}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
            <div style={{ textAlign: 'center', padding: '0.25rem' }}>0</div>
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '3fr 2fr 1fr',
              border: '1px solid black',
              borderBottom: 0,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRight: '1px solid black',
                gridRow: '1 / 3',
              }}
            >
              Mental Status
            </div>
            <div
              style={{
                padding: '0.25rem',
                borderRight: '1px solid black',
                borderBottom: '1px solid black',
              }}
            >
              <FallRiskCheckbox
                name="mentalStatus.forgets"
                label="Forgets limitations"
                value={15}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
            <div
              style={{
                textAlign: 'center',
                borderBottom: '1px solid black',
                padding: '0.25rem',
              }}
            >
              15
            </div>
            <div style={{ borderRight: '1px solid black', padding: '0.25rem' }}>
              <FallRiskCheckbox
                name="mentalStatus.oriented"
                label="Oriented to own ability"
                value={0}
                onCheckboxChange={handleCheckboxChange(setValues1)}
              />
            </div>
            <div
              style={{
                textAlign: 'center',
                padding: '0.25rem',
              }}
            >
              0
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '3fr 2fr 1fr',
            border: '1px solid black',
            borderBottom: '1px solid black',
          }}
        >
          <div></div>
          <div style={{ textAlign: 'right', padding: '0.5rem', borderRight: '1px solid black' }}>Total Score:</div>
          <div style={{ textAlign: 'center', padding: '0.5rem' }}>
            <MedicalPassportField name="fallRiskAssessment.totalScore" value={total1} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OCOMfallRiskAssessment;
