import React, { useEffect, useRef } from 'react';
import { useValue } from '../ValueContext';
import { useQuestionnaireValue } from '../hooks';
import toString from '../../../../../questionnaire/toString';

const styles = {
  inputField: {
    border: 0,
    outline: 0,
    width: '100%',
    resize: 'none',
    boxSizing: 'border-box',
    fontSize: 'inherit',
  },
};

const emptyOrNoneValue = (allEmpty, name) => (name === 'allergies1' && !allEmpty ? 'NKA' : '');

const NamedInput = ({ name, path, paths = [], reducer, warning, value, fontSize, nobackground, placeholder }) => {
  const { defaultValue, setValue } = useValue(name);

  const questionnaireValue = useQuestionnaireValue(reducer || toString, ...[path, ...paths].filter(path => path));
  const textareaRef = useRef(null);

  useEffect(() => {
    textareaRef.current.style.height = '0px';
    const scrollHeight = textareaRef.current.scrollHeight;
    textareaRef.current.style.height = scrollHeight + 'px';
  }, [value, questionnaireValue]);

  return (
    <textarea
      ref={textareaRef}
      name={name}
      rows={1}
      style={{
        ...styles.inputField,
        color: warning ? 'red' : undefined,
        fontSize: fontSize,
        backgroundColor: nobackground ? 'none' : 'lightgray',
        paddingBottom: 0,
        marginBottom: 0,
      }}
      value={
        value !== undefined
          ? value
          : questionnaireValue ||
            defaultValue ||
            emptyOrNoneValue(
              value === undefined && questionnaireValue === undefined && defaultValue === undefined,
              name
            )
      }
      onChange={e => setValue(e.target.value || ' ')}
      placeholder={placeholder}
    />
  );
};

const MedicalPassportTextArea2 = ({
  label,
  name,
  path,
  paths,
  reducer,
  style,
  labelStyle,
  warning,
  value,
  fontSize,
  nobackground,
  placeholder,
}) => (
  <div style={style}>
    {label && <div style={{ paddingBottom: '0', margin: '.25em .5em', ...labelStyle }}>{label}:</div>}
    <NamedInput
      name={name}
      path={path}
      paths={paths}
      reducer={reducer}
      warning={warning}
      value={value}
      fontSize={fontSize}
      nobackground={nobackground}
      placeholder={placeholder}
    />
  </div>
);

export default MedicalPassportTextArea2;
