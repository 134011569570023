import React, { Ref, useRef, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import Box from '@material-ui/core/Box';
import {
  CircularProgress,
  ClickAwayListener,
  Grow,
  IconButton,
  Link,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MenuItem from '@material-ui/core/MenuItem';
import { useScope } from '@/hooks/useScope';
import { Link as RouterLink } from 'react-router-dom';
import { useToaster } from '../../core/Toaster';
import {
  list as usersQuery,
  item as userQuery,
  setPhysicianRepresentationMutation,
  removePhysicianRepresentationMutation,
} from '@/graph/users.jsx';
import { TruncatedText } from '../../../se/components/typography';
import Modal from '../../../se/components/Modal';
import OspitekButton from '../../../se/components/Button';
import get from 'lodash/get';
import PhysicianSelectInput from '@/components/inputs/physician/PhysicianSelectInput.jsx';
import { item as physicianQuery } from '@/graph/physicians';

const Text = TruncatedText as any;

interface RepresentationProps {
  userName: string;
  userId: number;
  physicianId?: number;
}

const Representation = ({ userName, userId, physicianId }: RepresentationProps) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>();
  const [editing, setEditing] = useState(false);

  const handleToggle = (e: MouseEvent) => {
    e.stopPropagation();
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const [setPhysicianRepresentation, physicianRepresentationSetting] = useMutation(setPhysicianRepresentationMutation, {
    variables: {
      userId,
      physicianId: 0,
    },
    refetchQueries: [{ query: usersQuery }, { query: userQuery, variables: { id: userId } }],
  });

  const [removePhysicianRepresentation, physicianRepresentationRemoval] = useMutation(
    removePhysicianRepresentationMutation,
    {
      variables: {
        userId,
      },
      refetchQueries: [{ query: usersQuery }, { query: userQuery, variables: { id: userId } }],
    }
  );

  const handleChange = async (physicianId: number) => {
    await setPhysicianRepresentation({
      variables: {
        userId,
        physicianId,
      },
    });

    setEditing(false);
  };

  const scope = useScope();
  const toaster = useToaster();

  const { loading, error, data } = useQuery(physicianQuery, { variables: { id: physicianId }, skip: !physicianId });

  return (
    <Box>
      {physicianId ? (
        <Box>
          {editing && (
            <Box gridRow={1} gridColumn={1}>
              <RepresentationEdit
                name={userName}
                initialValue={physicianId}
                onSave={handleChange}
                onCancel={() => setEditing(false)}
              />
            </Box>
          )}
          <div ref={anchorRef as Ref<HTMLDivElement>} style={{ display: 'flex', alignItems: 'center' }}>
            <Link
              component={RouterLink}
              to={`/su/${get(scope, 'hospital.id')}/physicians/${physicianId}`}
              onClick={e => e.stopPropagation()}
            >
              <TruncatedText>
                {loading ? 'Loading…' : error ? `Physician #${physicianId}` : data.physician.name}
              </TruncatedText>
            </Link>
            <IconButton
              color="primary"
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
              style={{ marginTop: '-1.125em', marginBottom: '-1em' }}
            >
              <ArrowDropDownIcon />
            </IconButton>
          </div>
          <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper onClick={e => e.stopPropagation()}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      <MenuItem
                        onClick={() => {
                          setOpen(false);
                          setEditing(true);
                        }}
                      >
                        Change Physician
                      </MenuItem>
                      <MenuItem
                        onClick={async () => {
                          await removePhysicianRepresentation();
                          const name = userName;
                          toaster.success(
                            [...(name ? [name + '’s'] : []), 'associated physician removed'].join(' ') + '.'
                          );
                          setOpen(false);
                        }}
                      >
                        Remove Physician{' '}
                        {physicianRepresentationRemoval.loading && (
                          <CircularProgress size={16} style={{ marginLeft: '8px' }} />
                        )}
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Box>
      ) : (
        <Box>
          {editing && (
            <Box gridRow={1} gridColumn={1}>
              <RepresentationEdit create name={userName} onSave={handleChange} onCancel={() => setEditing(false)} />
            </Box>
          )}
          <div ref={anchorRef as Ref<HTMLDivElement>} style={{ display: 'flex', alignItems: 'center' }}>
            <Text>-</Text>
            <IconButton
              color="primary"
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
              style={{ marginTop: '-1.125em', marginBottom: '-1em' }}
            >
              <ArrowDropDownIcon />
            </IconButton>
          </div>
          <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper onClick={e => e.stopPropagation()}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      <MenuItem
                        onClick={async () => {
                          setOpen(false);
                          setEditing(true);
                        }}
                      >
                        Assign Physician{' '}
                        {physicianRepresentationSetting.loading && (
                          <CircularProgress size={16} style={{ marginLeft: '8px' }} />
                        )}
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Box>
      )}
    </Box>
  );
};

export default Representation;

interface RepresentationEditProps {
  create?: boolean;
  name?: string;
  initialValue?: number;
  onSave: (physicianId: number) => Promise<unknown>;
  onCancel: () => void;
}

const RepresentationEdit = ({ create, name, initialValue, onSave, onCancel }: RepresentationEditProps) => {
  const saveRef = useRef<HTMLSpanElement>();

  const [value, setValue] = useState(initialValue);
  const [busy, setBusy] = useState(false);

  const toaster = useToaster();

  const cancel = () => onCancel();
  const save = async () => {
    try {
      setBusy(true);
      await onSave(value!);
      toaster.success(
        [...(name ? [name + '’s'] : []), 'associated physician', create ? 'assigned' : 'changed'].join(' ') + '.'
      );
    } catch (e) {
      toaster.error('Unable to ' + title + '. Please try again.');
    } finally {
      setBusy(false);
    }
  };

  const title = [create ? 'Assign' : 'Change', ...(name ? [name + '’s'] : []), 'Associated Physician'].join(' ');

  return (
    <Modal title={title} withRouter={false} onClose={cancel}>
      <PhysicianSelectInput
        value={value}
        onChange={setValue}
        onComplete={() => {
          const save = saveRef.current;

          if (!save) {
            return;
          }

          const button = save.parentElement;

          if (!button) {
            return;
          }

          button.focus();
        }}
        disabled={false}
      />
      <Box display="flex" style={{ marginTop: '2rem', gap: '1rem' }}>
        <OspitekButton primary busy={busy} disabled={!value} onClick={save}>
          <span ref={saveRef as Ref<HTMLSpanElement>}>{create ? 'Create' : 'Save'}</span>
        </OspitekButton>
        <OspitekButton busy={false} disabled={false} onClick={cancel}>
          Cancel
        </OspitekButton>
      </Box>
    </Modal>
  );
};
