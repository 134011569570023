import { Mutation } from '@apollo/client/react/components';
import React from 'react';
import { assignPriority } from '../../../../graph/patients';

const withAssignPriorityMutation = Component => props => (
  <Mutation mutation={assignPriority}>
    {assignPriorityMutation => <Component {...props} assignPriorityMutation={assignPriorityMutation} />}
  </Mutation>
);

export default withAssignPriorityMutation;
