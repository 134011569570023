import React from 'react';
import MedicalPassportField from '../../components/MedicalPassportField';
import toAge from '../../../../../../questionnaire/toAge';
import toSex from '../../../../../../questionnaire/toSex';
import getAgeAndBmi from './pageUtils';
import styles from '../../styles';
import Barcode from '../../../Barcode';

const Page13 = ({
  formName,
  pageNumber,
  showQRCode,
  hospitalName,
  value,
  defaultValue,
  lastPreOpCompletedEvent,
  ...props
}) => {
  const providerId = defaultValue?.procedure?.patient?.providerId;

  const { age } = getAgeAndBmi(defaultValue?.demographicInformation?.dob, defaultValue?.demographicInformation?.bmi);

  return (
    <div id="page13" className="page" style={{ height: '100%', fontFamily: 'Arial', paddingTop: '180px' }}>
      <div
        style={{
          height: '90%',
          width: '103%',
          zoom: '80%',
          paddingBottom: '0',
          transform: 'rotate(-90deg)', // 시계 반대방향 90도 회전
          transformOrigin: 'center',

          marginRight: '0',
        }}
      >
        <div>
          <div>
            <b>
              <div>{(hospitalName || '').toUpperCase()}</div>
            </b>
            <b>
              <div
                style={{
                  fontSize: '16px',
                }}
              >
                SCA Health
              </div>
            </b>
            <b>
              <div
                style={{
                  fontSize: '16px',
                }}
              >
                Fall Risk Assessment Tool
              </div>
            </b>
          </div>
          <div style={{ backgroundColor: '#585858', color: 'white', padding: '2px', fontSize: '11.5px' }}>
            <b>
              REMINDER: ALL PACU PATIENTS ARE CONSIDERED HIGH RISK FOR FALLS. ADDITIONAL PROTOCOLS FOR ANY PATIENTS
              EXPERIENCING MOBILITY RISKS SHOULD BE FOLLOWED.
            </b>
          </div>
          <div
            style={{
              paddingTop: '5px',
              display: 'grid',
              gridTemplateColumns: '1fr 1.5fr 2fr 2fr 2.5fr',
              gridGap: '5px',
              alignContent: 'start',
              width: '115%',
            }}
          >
            <div style={styles.tableHeader}>Test</div>
            <div style={styles.tableHeader}>Task</div>
            <div style={styles.tableHeader}>Positive Response Pass</div>
            <div style={styles.tableHeader}>Negative Response Fail</div>
            <div style={styles.tableHeader}>Assistive Device for Use if Negative Response</div>
            <div style={styles.tableData}>
              <b>Level 1 Assessment:</b>
              <br />
              Strength and stability
            </div>
            <div style={styles.tableData}>
              <b>Stretch and Point:</b>
              <br />
              Sit on edge of bed. Stretch and bend legs, flex ankles/point toes.
            </div>
            <div style={styles.tableData}>
              Patient demonstrates stability, strength, and ability to maintain balance. Move to level 2 assessment
              task.
              <br />
              <br />
              <input type="checkbox" name="ResponsePass1" />
              Pass Time:______ Initials _______
            </div>
            <div style={styles.tableData}>
              Patient demonstrates weakness and inability to steady self on side of bed.
              <br />
              <br />
              <input type="checkbox" name="ResponseFail1" />
              Fail Time:_______ Initials ________
              <br />
              <input type="checkbox" name="ResponseFail2" />
              Fail Time:_______ Initials ________
              <br />
              <input type="checkbox" name="ResponseFail3" />
              Fail Time:_______ Initials ________
            </div>
            <div style={styles.tableData}>
              Wait 5 minutes and test again. Do not transfer a patient if negative response, offer a urinal or bedpan.
            </div>
            <div style={styles.tableData}>
              <b>
                Level 2<br />
                Assessment:
              </b>
              <br />
              Strength for standing
            </div>
            <div style={styles.tableData}>
              <b>
                Stand:
                <br />
              </b>
              Stand and bear weight on extremities, use assistive device if needed.
            </div>
            <div style={styles.tableData}>
              Patient demonstrates lower extremity stability and strength bilaterally.
              <br />
              <br />
              <input type="checkbox" name="ResponsePass2" />
              Pass Time:______ Initials _______
            </div>
            <div style={styles.tableData}>
              Patient demonstrates weakness, inability to bear weight on lower extremities.
              <br />
              <br />
              <input type="checkbox" name="ResponseFail4" />
              Fail Time:_______ Initials ________
              <br />
              <input type="checkbox" name="ResponseFail5" />
              Fail Time:_______ Initials ________
              <br />
              <input type="checkbox" name="ResponseFail6" />
              Fail Time:_______ Initials ________
            </div>
            <div style={styles.tableData}>
              <b>Gait Belt</b>
              should be utilized at all times. If weight-bearing on both lower extremities,
              <b>walker</b>
              should be utilized, and 2 person assist during ambulation. If weight-bearing on one lower extremity,
              <b>axillary crutches</b>
              or
              <b>cane</b>
              should be used during ambulation.
            </div>
            <div style={styles.tableData}>
              <b>
                Level 3<br />
                Assessment:
              </b>
              <br />
              Standing and gait
            </div>
            <div style={styles.tableData}>
              <b>
                Walk:
                <br />
              </b>
              Walk in place at bedside. Then, take a few steps in patient bay.
            </div>
            <div style={styles.tableData}>
              Patient has a steady gait, good balance, and safety awareness.
              <br />
              <br />
              <input type="checkbox" name="ResponsePass3" />
              Pass Time:______ Initials _______
            </div>
            <div style={styles.tableData}>
              Patient demonstrates poor balance, coordination, and weakness.
              <br />
              <br />
              <input type="checkbox" name="ResponseFail7" />
              Fail Time:_______ Initials ________
              <br />
              <input type="checkbox" name="ResponseFail8" />
              Fail Time:_______ Initials ________
              <br />
              <input type="checkbox" name="ResponseFail9" />
              Fail Time:_______ Initials ________
            </div>
            <div style={styles.tableData}>
              <b>Gait Belt</b>
              should be utilized at all times. If weight- bearing on both lower extremities,
              <b>walker</b>
              should be utilized during ambulation and 2 person assist. If weight-bearing on one lower extremity,
              <b>axillary crutches</b>
              or
              <b>cane</b>
              should be used during ambulation.
            </div>
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 4fr',
            }}
          >
            <div
              style={{
                width: '900px',
                height: '350px',
              }}
            >
              <br />
              <b>Comments:</b>
              <div
                style={{
                  width: '100%',
                  borderBottom: '1px solid black',
                }}
              >
                <br />
                <br />
              </div>
              <div
                style={{
                  width: '100%',
                  borderBottom: '1px solid black',
                }}
              >
                <br />
                <br />
              </div>
              <div
                style={{
                  width: '100%',
                  borderBottom: '1px solid black',
                }}
              >
                <br />
                <br />
              </div>
              <div
                style={{
                  width: '100%',
                  borderBottom: '1px solid black',
                }}
              >
                <br />
                <br />
              </div>
              <br />
              <br />
              PACU Nurse Signature:___________________________________________________________
              Date:_________________Time:_________________
            </div>
            <div
              style={{
                width: '280px',
                height: '110px',
                msTransform: 'rotate(90deg)',
                WebkitTransform: 'rotate(90deg)',
                transform: 'rotate(90deg)',
                transformOrigin: '18% 65%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                }}
              >
                {showQRCode && (
                  <Barcode
                    formName={formName}
                    pageNumber={pageNumber}
                    providerId={defaultValue?.procedure?.patient?.providerId}
                    serviceTime={defaultValue?.procedure?.serviceTime}
                  />
                )}
              </div>
              <div
                style={{
                  border: '1px solid black',
                  padding: '1px',
                  lineHeight: '15px',
                }}
              >
                <MedicalPassportField
                  name="procedure.patient.name"
                  label="Patient"
                  fontWeight={'bold'}
                  fontSize={14}
                  nobackground
                />
                <MedicalPassportField name="procedure.patient.providerId" label="MRN#" fontSize={12} nobackground />
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '40% 60%',
                    alignContent: 'start',
                  }}
                >
                  <MedicalPassportField
                    name="dob"
                    label="DOB"
                    path={['demographicInformation', 'dob']}
                    fontSize={12}
                    nobackground
                  />
                  <MedicalPassportField
                    name="age"
                    label="Age"
                    path={['demographicInformation', 'dob']}
                    reducer={toAge}
                    warning={age > 70}
                    nobackground
                    fontSize={12}
                  />
                  <MedicalPassportField name="procedure.serviceTime" label="DOS" fontSize={12} nobackground />
                  <MedicalPassportField
                    name="sex"
                    label="Sex"
                    path={['demographicInformation', 'sex']}
                    reducer={toSex}
                    nobackground
                    fontSize={12}
                  />
                </div>
                <MedicalPassportField name="procedure.physician.name" label="Surgeon" fontSize={12} nobackground />
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer
        style={{
          display: 'grid',
          gridTemplateColumns: '13.25% 14.5% 11.5% 17% 0.05% 14.5% 29%',
          fontSize: '11px',
          height: '20px',
          paddingTop: '10px',
        }}
      >
        <div style={{ paddingTop: '3px' }}>Provided by Patient{'('}</div>
        <MedicalPassportField name="questionnaire.firstSubmittedAt" fontSize={11} nobackground />
        <div style={{ paddingTop: '3px' }}>{')'} Last updated by</div>
        <MedicalPassportField name="procedure.patient.name" fontSize={11} nobackground />
        <div style={{ paddingTop: '3px' }}>{'('}</div>
        <MedicalPassportField name="questionnaire.submittedAt" fontSize={11} nobackground />
        <div>
          {')'}{' '}
          <span style={{ fontSize: '14px', marginLeft: '130px' }}>
            <b>Ospitek, Inc</b>
          </span>
        </div>
      </footer>
    </div>
  );
};

export default Page13;
