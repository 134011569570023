import { deepOrange, orange, red, yellow } from '@material-ui/core/colors';
import { Chip, SvgIcon } from '@material-ui/core';
import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

export type FallRiskWarningProps = 'low' | 'medium' | 'high';

function FallIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path
          fill={props.style.color || '#000'}
          d="m13.5 22.5l-2-.4l.8-4.3l-3.6-2.7l-1.3-5.7l-2.2 1.9l.8 3.8l-2 .4l-1-4.9l4.45-3.975q.575-.5 1.363-.412t1.512.387q.8.35 1.663.5t1.737.025Q14.6 7 15.338 6.55t1.412-1L18 7.1q-.75.575-1.55 1.075t-1.725.775q-.825.225-1.662.238T11.4 9l.7 3.1l3.7-.7l5.2 3.7l-1.2 1.6l-4.3-3l-3.6.7l2.7 2l-1.1 6.1ZM8 5.5q-.825 0-1.413-.588T6 3.5q0-.825.588-1.413T8 1.5q.825 0 1.413.588T10 3.5q0 .825-.588 1.413T8 5.5Z"
        />
      </svg>
    </SvgIcon>
  );
}

const FallRiskWarning = ({ fallRiskAssessment }: { fallRiskAssessment: FallRiskWarningProps }) => {
  const classes = useStyles();

  return (
    <Chip
      icon={
        <FallIcon
          style={{
            color:
              fallRiskAssessment === 'low' ? yellow[900] : fallRiskAssessment === 'medium' ? deepOrange[800] : red[600],
          }}
        />
      }
      classes={{
        root:
          fallRiskAssessment === 'low'
            ? classes.lowRisk
            : fallRiskAssessment === 'medium'
              ? classes.mediumRisk
              : classes.highRisk,
      }}
      size="small"
      label={fallRiskAssessment}
    />
  );
};

const useStyles = makeStyles(theme => ({
  lowRisk: {
    backgroundColor: yellow[100],
    color: yellow[900],
    textTransform: 'capitalize',
  },
  mediumRisk: {
    backgroundColor: orange[100],
    color: deepOrange[900],
    textTransform: 'capitalize',
  },
  highRisk: {
    backgroundColor: red[100],
    color: red[900],
    textTransform: 'capitalize',
  },
}));

export default FallRiskWarning;
