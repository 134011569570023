import createReducer from './reducer';
import { format } from 'date-fns';
import { capitalize } from 'lodash';

const toString = createReducer<string | null>({
  Sections: sections => {
    const visibleSections = sections.filter(section => section.child);

    return visibleSections.length === 0 ? null : visibleSections.map(section => section.child).join('; ');
  },
  Object: fields => {
    const visibleFields = fields.filter(field => field.child);

    return visibleFields.length === 0
      ? null
      : visibleFields.length === 1
        ? visibleFields[0].child
        : visibleFields.map(field => field.child).join(', ');
  },
  Text: (label, text) => text || null,
  LongText: (label, text) => text || null,
  Checkboxes: (label, checkboxes) => {
    const visibleCheckboxes = checkboxes.filter(checkbox => checkbox.checked);

    return visibleCheckboxes.length === 0
      ? null
      : visibleCheckboxes.length === 1
        ? `${visibleCheckboxes[0].label}${visibleCheckboxes[0].child ? ` (${visibleCheckboxes[0].child})` : ''}`
        : visibleCheckboxes
            .map(checkbox => `${checkbox.label.replace(/\?/g, '')}${checkbox.child ? ` (${checkbox.child})` : ''}`)
            .join(', ');
  },
  List: (label, children) => {
    const visibleChildren = children.filter(child => child);

    return visibleChildren.length === 0 ? null : visibleChildren.join(', ');
  },
  YesNo: (question, positive, child) => {
    const item = capitalize(
      question
        .replace(/\?/g, '')
        .replace(/^Do you have\s/g, '')
        .replace(/^Are you\s/g, '')
    );
    return positive ? `${item}${child ? ` (${child})` : ''}` : '';
  },
  OneOf: (label, options, answer) =>
    answer ? `${label.replace(/\?/g, '')}: ${answer.label}${answer.child ? ` (${answer.child})` : ''}` : null,
  Medication: value => value || null,
  BodyMassIndex: (heightFt, heightIn, weightLb) => {
    const values = [
      heightFt ? heightFt + "'" : null,
      heightIn ? heightIn + '"' : null,
      weightLb ? weightLb + 'lb' : null,
    ].filter(value => value !== null);

    return values.length > 0 ? values.join(' ') : null;
  },
  CityStateZip: (city, state, zipCode) => {
    const values = [city ? city : null, state ? state : null, zipCode ? zipCode : null].filter(value => value !== null);

    return values.length > 0 ? values.join(' ') : null;
  },
  DateOfBirth: date => (date ? format(date, 'MM/DD/YYYY') : null),
  Date: date => (date ? format(date, 'MM/DD/YYYY') : null),
});

export default toString;
