import React from 'react';
import { compose } from 'recompose';
import { makeStyles } from '@material-ui/core/styles';
import NumberInput from '../../../../se/components/inputs/NumberInput';
import { withLabel } from '../../../../se/components/Label';

const MondayInput = withLabel('Mon')(NumberInput);
const TuesdayInput = withLabel('Tue')(NumberInput);
const WednesdayInput = withLabel('Wed')(NumberInput);
const ThursdayInput = withLabel('Thu')(NumberInput);
const FridayInput = withLabel('Fri')(NumberInput);
const SaturdayInput = withLabel('Sat')(NumberInput);
const SundayInput = withLabel('Sun')(NumberInput);

const DailyCapacityInput = ({ name, value, onChange, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MondayInput
        name={name + '.monday'}
        value={value?.monday}
        onChange={v => onChange({ ...(value ?? {}), monday: v })}
      />
      <TuesdayInput
        name={name + '.tuesday'}
        value={value?.tuesday}
        onChange={v => onChange({ ...(value ?? {}), tuesday: v })}
      />
      <WednesdayInput
        name={name + '.wednesday'}
        value={value?.wednesday}
        onChange={v => onChange({ ...(value ?? {}), wednesday: v })}
      />
      <ThursdayInput
        name={name + '.thursday'}
        value={value?.thursday}
        onChange={v => onChange({ ...(value ?? {}), thursday: v })}
      />
      <FridayInput
        name={name + '.friday'}
        value={value?.friday}
        onChange={v => onChange({ ...(value ?? {}), friday: v })}
      />
      <SaturdayInput
        name={name + '.saturday'}
        value={value?.saturday}
        onChange={v => onChange({ ...(value ?? {}), saturday: v })}
      />
      <SundayInput
        name={name + '.sunday'}
        value={value?.sunday}
        onChange={v => onChange({ ...(value ?? {}), sunday: v })}
      />
    </div>
  );
};

export default compose(withLabel('Day by day capacity in minutes'))(DailyCapacityInput);

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    gap: '0.5em',
    marginBottom: '-1.5rem',
  },
}));
