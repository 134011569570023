import React, { Fragment } from 'react';
import { compose, withProps } from 'recompose';
import { withLabel } from '../../../se/components/Label';
import TextInput from '../../../se/components/inputs/TextInput';
import ObjectInput from '../../../se/components/inputs/ObjectInput';
import PhoneInput from '../../../se/components/inputs/PhoneInput';
import { EmailInput } from '../../../se/components/inputs/EmailInput';
import PhysicianSelectInput from '../../inputs/physician/PhysicianSelectInput';
import DateInputSO from '../../../se/components/inputs/DateInputSO';
import DateOfBirthInput from '../../../se/components/inputs/DateOfBirthInput';
import TimeInputControl from '../../../se/components/controls/TimeControl';
import Grid from '@material-ui/core/Grid';
import withExchangeOnly from '../../../util/withExchangeOnly';
import Box from '@material-ui/core/Box';
import { GraphqlSelectInput } from '../../inputs/graphqlSelectInput';
import { list } from '../../../graph/locations';
import { withScope } from '../../../contexts/ScopeContext';

const PatientInput = withProps({
  schema: {
    name: compose(
      withLabel('Patient Name'),
      withProps({ placeholder: 'e.g., Mark Nordstrom', required: true })
    )(TextInput),
    dateOfBirth: withProps({ required: true, style: { width: '100%' } })(withLabel('Date of Birth')(DateOfBirthInput)),
    cellPhone: withProps({ placeholder: 'e.g., (619) 412-9512' })(withLabel('Mobile Phone Number')(PhoneInput)),
    emailAddress: withProps({ placeholder: 'john@xyz.com' })(withLabel('Email Address')(EmailInput)),
  },
  render: props => {
    const { name, emailAddress, cellPhone, dateOfBirth } = props;
    return (
      <Fragment>
        {name && <Grid item>{name}</Grid>}
        {dateOfBirth && <Grid item>{dateOfBirth}</Grid>}
        <Grid item>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12} style={{ marginBottom: '-1.5rem' }}>
              {emailAddress}
            </Grid>
            <Grid item sm={6} xs={12}>
              {cellPhone}
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    );
  },
})(ObjectInput);

const InvitePatient = withProps({
  schema: {
    patient: PatientInput,
    physician: PhysicianSelectInput,
    consultationDate: withProps({ required: true, formatter: val => format(parse(val), 'MMMM D YYYY') })(
      withLabel('Consultation Date')(DateInputSO)
    ),
    consultationTime: withProps({ placeholder: '12:15', required: true })(
      withLabel('Consultation Time')(TimeInputControl)
    ),
    locationId: withScope(({ scope, ...rest }) => (
      <GraphqlSelectInput
        label={'The location of the surgical office'}
        entityName={'Location'}
        placeholder={'Location'}
        graphqlList={list}
        variables={{ organizationId: scope?.hospital?.id }}
        getOptionValue={option => (option ? parseInt(option.value, 10) : undefined)}
        isClearable={false}
        sortOptions={true}
        hideComponentIf={list => (list?.length ? list?.length < 2 : true)}
        {...rest}
      />
    )),
  },
  render: props => {
    const { consultationDate, consultationTime, patient, physician, locationId } = props;
    return (
      <Box mb={4}>
        {patient && <Grid item>{patient}</Grid>}
        {physician && <Grid item>{physician}</Grid>}
        <Grid item>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12} style={{ marginBottom: '-1.5rem' }}>
              {consultationDate}
            </Grid>
            <Grid item sm={6} xs={12}>
              {consultationTime}
            </Grid>
          </Grid>
        </Grid>
        {locationId && <Grid item>{locationId}</Grid>}
      </Box>
    );
  },
})(ObjectInput);

const InvitePatientExchangeOnly = withProps({
  schema: {
    patient: PatientInput,
    physician: PhysicianSelectInput,
  },
  render: props => {
    const { patient, physician } = props;
    return (
      <Fragment>
        {patient && <Grid item>{patient}</Grid>}
        {physician && <Grid item>{physician}</Grid>}
      </Fragment>
    );
  },
})(ObjectInput);

export default withExchangeOnly(({ exchangeOnly, ...rest }) => (
  <>{exchangeOnly ? <InvitePatientExchangeOnly {...rest} /> : <InvitePatient {...rest} />}</>
));
