import React, { forwardRef, SyntheticEvent } from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { TroubleshootingAlertProps } from '@/components/pages/kiosk/tablet/Troubleshooting/types';

interface TroubleshootingSnackbarProps {
  alert: TroubleshootingAlertProps;
  handleCloseSnackbar: (event: SyntheticEvent<Element, Event>) => void;
}

const TroubleshootingSnackbar = forwardRef(({ alert, handleCloseSnackbar }: TroubleshootingSnackbarProps, ref) => {
  return (
    <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
      <Alert onClose={handleCloseSnackbar} severity={alert.severity} variant="filled">
        {alert.message}
      </Alert>
    </Snackbar>
  );
});

export default TroubleshootingSnackbar;
