import React from 'react';
import styles from '../styles';
import MedicalPassportField from '../components/MedicalPassportField';
import MedicalPassportTextArea from '../components/MedicalPassportTextArea';
import MedicalPassportTextArea2 from '../components/MedicalPassportTextArea2';
import toAge from '../../../../../questionnaire/toAge';
import toBMI from '../../../../../questionnaire/toBMI';
import toWeight from '../../../../../questionnaire/toWeight';
import toHeight from '../../../../../questionnaire/toHeight';
import toSex from '../../../../../questionnaire/toSex';
import toHighlightsString4 from '../../../../../questionnaire/toHighlightsString4';
import toHighlightsString5 from '../../../../../questionnaire/toHighlightsString5';
import getAgeAndBmi from './pageUtils';
import get from 'lodash/get';
import { useValue } from '../ValueContext';
import Barcode from '../../Barcode';
import { additionalcontent } from '../golfCoast/pages/contentlimit';

const Page3 = ({ formName, pageNumber, showQRCode, hospitalName, value, defaultValue }) => {
  const providerId = defaultValue?.procedure?.patient?.providerId;
  const { age, bmi } = getAgeAndBmi(
    defaultValue?.demographicInformation?.dob,
    defaultValue?.demographicInformation?.bmi
  );
  const { questionnaireAnswers } = useValue();
  const drinking = get(
    questionnaireAnswers,
    'sections.Psychiatric.schema.psychiatric_list.checkboxes["Drink beer, wine or liquor"]',
    false
  );
  const drugs = get(
    questionnaireAnswers,
    'sections.Psychiatric.schema.psychiatric_list.checkboxes["History of using recreational or street drugs"]',
    false
  );

  const smoking = get(questionnaireAnswers, 'sections.basicscreening.schema["smoking status"].option[0]', '');
  const isSmoking = smoking === '' || smoking === 'N' ? false : true;

  const surgery = additionalcontent(toHighlightsString4, [['priorSurgeries'], ['anesthesia complications']], 200);
  const allergy = additionalcontent(toHighlightsString5, [['allergies']], 150);

  if (surgery == 'No additional info' && allergy == 'No additional info') {
    return '';
  } else {
    return (
      <div id="page3" className="page" style={{ fontSize: '11px', fontFamily: 'Arial' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '1px' }}>
            <h2>Patient Medical History</h2>
            <div>{hospitalName || ''}</div>
          </div>
          <div>
            {showQRCode && (
              <Barcode
                formName={formName}
                pageNumber={pageNumber}
                providerId={defaultValue?.procedure?.patient?.providerId}
                serviceTime={defaultValue?.procedure?.serviceTime}
              />
            )}
          </div>
        </div>
        <div style={{ border: '1px solid black', borderBottom: 0, borderLeft: 0 }}>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '55% 45%',
              borderBottom: '1px solid black',
            }}
          >
            <div>
              <div
                style={{
                  border: '1px solid black',
                  borderTop: 0,
                  minHeight: '60px',
                  height: '100%',
                  padding: '1px 0',
                  borderBottom: '0px',
                }}
              >
                <div
                  style={{
                    paddingBottom: '35px',
                  }}
                >
                  <MedicalPassportTextArea name="procedure.name" label="Procedure" />
                </div>
              </div>
            </div>
            <div>
              <div style={{ bottom: '5px', paddingLeft: '3px' }}>
                <div style={{ marginBottom: '1px' }}>
                  <MedicalPassportField
                    name="procedure.patient.name"
                    label="Patient"
                    fontWeight={'bold'}
                    fontSize={14}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '1px' }}>
                  <MedicalPassportField name="dob" label="DOB" path={['demographicInformation', 'dob']} fontSize={12} />
                  <MedicalPassportField name="procedure.serviceTime" label="DOS" fontWeight={700} fontSize={12} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '1px' }}>
                  <MedicalPassportField name="procedure.patient.providerId" label="MRN#" fontSize={12} />
                  <MedicalPassportField name="procedure.physician.name" label="Surgeon" fontSize={12} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '1px', paddingBottom: '1px' }}>
                  <MedicalPassportField
                    name="age"
                    label="Age"
                    path={['demographicInformation', 'dob']}
                    reducer={toAge}
                    warning={age > 70}
                    fontSize={12}
                  />
                  <MedicalPassportField
                    name="sex"
                    label="Sex"
                    path={['demographicInformation', 'sex']}
                    reducer={toSex}
                    fontSize={12}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '1px', paddingBottom: '1px' }}>
                  <MedicalPassportField
                    name="cardiologist"
                    label="Cardiologist"
                    path={['demographicInformation', 'cardiologist']}
                    fontSize={12}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '1px', paddingBottom: '1px' }}>
                  <MedicalPassportField
                    name="procedure.pcp"
                    path={['demographicInformation', 'primaryCareDoctor']}
                    label="PCP"
                    fontSize={12}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              borderLeft: '1px solid black',
              borderBottom: '1px solid black',
            }}
          >
            <MedicalPassportTextArea2
              label="Med Allergies/Sensitivities"
              name="allergies1"
              value={allergy}
              warning={true}
              fontSize={11}
            />
          </div>
          <div style={{ ...styles.medicationsInnerDiv }}>
            <MedicalPassportTextArea2 label="Surgical/Anesthesia Hx" name="anesthesia1" value={surgery} fontSize={11} />
          </div>
        </div>
      </div>
    );
  }
};

export default Page3;
