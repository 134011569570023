import React from 'react';
import styled from 'styled-components';
import LinkButton from '../../../se/components/LinkButton';

const ListInputLayout = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const FauxFooter = styled.div`
  display: flex;
  flex-flow: column;
  height: 0.9rem;
  width: 100%;
`;

const FooterWrapper = styled.div`
  display: flex;
  flex-flow: column;
  height: 2rem;
  > button {
    border: none;
    max-width: 10em;
    margin-bottom: 1em;
  }
`;

const Footer = ({ addAddress }) => (
  <FooterWrapper>
    <LinkButton type="button" onClick={addAddress} style={{ outline: 'none' }}>
      + Add Bracelet
    </LinkButton>
  </FooterWrapper>
);

export default (label, entity, Input, showFooter, options = {}) =>
  ({ items, onChange, onAdd, onRemove, ...rest }) => (
    <ListInputLayout>
      {label && <label style={options.labelStyles}>{label}</label>}
      {items.map((item, index) => (
        <Input
          key={`${entity}.${index}`}
          name={`${entity}.${index}`}
          value={item}
          onChange={value => onChange({ index, value })}
          onRemove={_ => onRemove(index)}
          {...rest}
        />
      ))}
      {showFooter && <Footer addAddress={onAdd} />}
      {!showFooter && options.paddedFooter && <FauxFooter />}
    </ListInputLayout>
  );
