import toString from './toString';
import { capitalize } from 'lodash';

const toHighlightsString4 = toString.with({
  List: (label, children) => {
    const visibleChildren = children.filter(child => child);

    return visibleChildren.length === 0
      ? null
      : visibleChildren
          .map(c => {
            const rest = c!.startsWith('· ') ? c!.substr(2) : c!;
            return `* ${rest.replace(/\n· /g, ', ')}`;
          })
          .join('\n');
  },
  YesNo: (question, positive, child) => {
    const item = capitalize(
      question
        .replace(/\?/g, '')
        .replace(
          /^Do you have allergies \(including medications, antibiotics, foods, bee sting, iodine\/shellfish, rubber, contrast dye, medical tape\)/g,
          ''
        )
        .replace(/^Do you have\s/g, 'Has ')
        .replace(/^Are you a\s/g, '')
        .replace(/^Any chance you may be\s/g, '')
        .replace(/^Did you\s/g, 'Has ')
        .replace(/^Any\s/g, 'Has ')
        .replace(/^Have you ever\s/g, '')
        .replace(/^Have you\s/g, 'Has ')
        .replace(/^Is it a\s/g, '')
        .replace(/^Do you\s/g, '')
        .replace(/^Are you allergic to\s/g, '')
    );
    return positive ? `${item}${child ? ` (${child})` : ''}` : '';
  },
});

export default toHighlightsString4;
