import React, { ReactNode } from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SurgeonFormPage from '../surgeonProcedures/HtmlForm';
import { ProcedureEvent } from '../../../types/ProcedureEvent';
import { CheckCircleTwoTone } from '@material-ui/icons';
import { Typography, useTheme } from '@material-ui/core';
import Groups from './Groups';

interface Form {
  id: number;
  name: string;
  isSigned: boolean;
}

interface FormsProps {
  patientId?: number;
  procedureId: number;
  forms: Array<Form>;
  lastPreOpCompletedEvent: ProcedureEvent;
  additionalButtons?: { title: string; icon?: ReactNode; onClick: () => void }[];
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}

const Forms = ({ procedureId, patientId, forms, lastPreOpCompletedEvent, additionalButtons }: FormsProps) => {
  const theme = useTheme();

  return (
    <Groups
      groups={forms.map(form => ({
        id: String(form.id),
        name: form.name,
        addon: form.isSigned ? (
          <Box display="flex" alignItems="center" style={{ gap: 4 }}>
            <CheckCircleTwoTone fontSize="small" />
            <Typography>Signed</Typography>
          </Box>
        ) : null,
        contents: (
          <SurgeonFormPage
            id={form.id}
            headerBackground={
              theme.palette.type === 'light' ? theme.palette.common.white : theme.palette.background.paper
            }
            patientId={patientId}
            procedureId={procedureId}
            lastPreOpCompletedEvent={lastPreOpCompletedEvent}
            headerOffset={theme.spacing(-4)}
          />
        ),
      }))}
      actions={additionalButtons?.map((additionalButton, i) => ({
        id: String(i),
        name: additionalButton.title,
        icon: additionalButton.icon,
        onClick: additionalButton.onClick,
      }))}
    />
  );
};

export default Forms;
