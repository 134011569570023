import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import AnalyticsPanel from './components/AnalyticsPanel';
import Distribution from './Distribution';

export default ({
  title,
  value,
  distribution,
  unit,
  ratio,
  hideValue,
  tooltipLabel,
  isDecimal,
  children,
  noValue,
  color = undefined,
}) => {
  const [hidden, setHidden] = useState(window.innerWidth < 500);
  const computedValue =
    noValue || hideValue ? false : [isDecimal ? value.toFixed(1) : value.toFixed(0), unit].join(' ');

  return (
    <AnalyticsPanel title={title}>
      {!noValue && <Typography variant="h3">{computedValue}</Typography>}
      {!hidden &&
        (children ? (
          children
        ) : (
          <Distribution
            color={color}
            distribution={distribution}
            unit={unit}
            label={title}
            ratio={ratio}
            tooltipLabel={tooltipLabel}
          />
        ))}
    </AnalyticsPanel>
  );
};
