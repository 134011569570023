import React, { useMemo } from 'react';
import set from 'lodash/set';
import get from 'lodash/fp/get';

import { getNestedValue } from '../../../se/utilities/data/object';
import GraphQLCRUD from '../../../se/components/GraphQLCRUD';

import UserInput from '../../inputs/user/UserInput';
import scheme from '../../../graph/users';
import {
  baseColumns,
  lastLogin,
  lastLoginNoAction,
  nameColumn,
  openPositionNotification,
  sensorEmailNotification,
  sensorPhoneNotification,
  representsStaffColumn,
} from './columns';
import { userRoleLabels } from '../role/labels';
import UserMobileListItem from './UserMobileListItem';
import ClearLockdownButton from '../../pages/user/ClearLockdownButton';

import { useScope } from '../../../hooks/useScope';

const Empty = { illustration: theme => theme.illustrations.users };

export const List = shouldFilter => ({
  tableKey: 'Users',
  columns: [
    nameColumn,
    ...baseColumns,
    lastLoginNoAction,
    ...(shouldFilter ? [] : [openPositionNotification, sensorEmailNotification, sensorPhoneNotification]),
    representsStaffColumn,
  ],
  MobileItemComponent: UserMobileListItem,
  Empty,
  simpleFilter: true,
});

export const Create = {
  actionTitleButton: 'Create & Send Invitation',
  Input: set(UserInput(userRoleLabels, true), 'defaultValue', { patientNameFormat: 'FullName' }),
  Empty,
};

export const Show = shouldFilter => ({
  columns: [
    ...baseColumns,
    lastLogin,
    ...(shouldFilter ? [] : [openPositionNotification, sensorEmailNotification, sensorPhoneNotification]),
    representsStaffColumn,
  ],
  Empty,
  Actions: props => <ClearLockdownButton {...props} refetch={scheme.item} />,
});

export const Edit = {
  Input: UserInput(userRoleLabels, false),
  mapEditItemProps: ({ data }) => ({
    data: data && {
      ...data,
      user: {
        ...data.user,
        role: getNestedValue('user.role', data),
      },
    },
  }),
  Empty,
};

export default props => {
  const scope = useScope();
  const hospitalType = get('hospital.type', scope);

  const UsersCRUD = useMemo(
    () =>
      GraphQLCRUD({
        entityName: 'User',
        scheme,
        List: List(hospitalType === 'SurgeonOffice'),
        Create,
        Show: Show(hospitalType === 'SurgeonOffice'),
        Edit,
      }),
    [hospitalType]
  );

  return <UsersCRUD {...props} />;
};
