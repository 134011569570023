export const cardiovascular = [
  ['Cardiac', 'AbnormalHeartRhythm'],
  ['Cardiac', 'CAD'],
  ['Cardiac', 'CHF'],
  ['Cardiac', 'ChestPain'],
  ['Cardiac', 'HeartAttack'],
  ['Cardiac', 'HeartValve'],
  ['Cardiac', 'HighBloodPressure'],
  ['Cardiac', 'VascularDisease'],
  ['Cardiac', 'cholesterol'],
  ['Cardiac', 'palpitations'],
  ['Cardiac', 'heartDefect'],
  ['Cardiac', 'CardiacCmt'],
  ['P_Cardiac', 'AbnormalHeartRhythm'],
  ['P_Cardiac', 'heartDefect'],
  ['P_Cardiac', 'CardiacCmt'],
  ['basicscreening', 'levelOfPhysical activity'],
  ['GeneralHistory', 'pacemaker'],
  ['GeneralHistory', 'AICD'],
  ['GeneralHistory', 'stent'],
];

export const vascular = [
  ['Vascular', 'Anemia'],
  ['Vascular', 'Bleeding1'],
  ['Vascular', 'Bleeding2'],
  ['Vascular', 'Glaucoma'],
  ['Vascular', 'DVT'],
  ['Vascular', 'HIV'],
  ['Vascular', 'bloodThinners'],
  ['Vascular', 'bloodTransfusion'],
  ['Vascular', 'varicose'],
  ['Vascular', 'VascularCmt'],
];

export const pulmonary = [
  ['basicscreening', 'smokingStatus'],
  ['basicscreening', 'smoking'],
  ['Pulmonary', 'Pneumonia'],
  ['Pulmonary', 'fibrosis'],
  ['Pulmonary', 'Emphysema'],
  ['Pulmonary', 'Asthma'],
  ['Pulmonary', 'Sleep apnea'],
  ['Pulmonary', 'snore'],
  ['Pulmonary', 'breathing'],
  ['Pulmonary', 'tired'],
  ['Pulmonary', 'oxygen'],
  ['Pulmonary', 'Tuberculosis'],
  ['Pulmonary', 'pulmonaryCmt'],
  ['P_Pulmonary', 'Asthma'],
  ['P_Pulmonary', 'Pneumonia'],
  ['P_Pulmonary', 'fibrosis'],
  ['P_Pulmonary', 'Sleep apnea'],
  ['P_Pulmonary', 'pulmonaryCmt'],
];

export const renal = [
  ['Renal', 'kidneyFailure'],
  ['Renal', 'kidneydefect'],
  ['Renal', 'dialysis'],
  ['Renal', 'kidney stones'],
  ['Renal', 'urinaryIncontinence'],
  ['Renal', 'renalCmt'],
  ['P_Renal', 'kidneyFailure'],
  ['P_Renal', 'kidneydefect'],
  ['P_Renal', 'renalCmt'],
];

export const hepatic = [
  ['Hepatic', 'liver failure'],
  ['Hepatic', 'Hepatitis'],
  ['Hepatic', 'cirrhosis'],
  ['Hepatic', 'HepaticCmt'],
];

export const neurological = [
  ['Neurologic', 'Neuropathy'],
  ['Neurologic', 'Sclerosis'],
  ['Neurologic', 'Seizures'],
  ['Neurologic', 'Stroke'],
  ['Neurologic', 'alzheimer'],
  ['Neurologic', 'dementia'],
  ['Neurologic', 'dizzy'],
  ['Neurologic', 'cerebral'],
  ['Neurologic', 'mentalDisability'],
  ['Neurologic', 'migraines'],
  ['Neurologic', 'numbness'],
  ['Neurologic', 'parkinson'],
  ['Neurologic', 'tourette'],
  ['Neurologic', 'downSynderome'],
  ['Neurologic', 'myasthenia'],
  ['Neurologic', 'recentHospitalization'],
  ['Neurologic', 'aphasia'],
  ['Neurologic', 'otherneurologic'],
  ['GeneralHistory', 'internalNerve'],
  ['Neurologic', 'NeurologicCmt'],
];

export const gastrointestinal = [
  ['GI', 'heartburn'],
  ['GI', 'StomachUlcers'],
  ['GI', 'esophageal'],
  ['GI', 'hiatalHernia'],
  ['GI', 'IBD'],
  ['GI', 'GICmt'],
];

export const endocrine = [
  ['Endocrine', 'Diabetes'],
  ['Endocrine', 'Thyroid disease'],
  ['Endocrine', 'EndocrineCmt'],
  ['Endocrine', 'Cancer'],
  ['Endocrine', 'OncologicCmt'],
];

export const musculoskeletal = [
  ['Musculoskeletal', 'Arthritis'],
  ['Musculoskeletal', 'arthritisJuvenile'],
  ['Musculoskeletal', 'dystrophy'],
  ['Musculoskeletal', 'Myopathy'],
  ['Musculoskeletal', 'immobilizing'],
  ['Musculoskeletal', 'musculoskeletalProblem'],
  ['Musculoskeletal', 'paralysis'],
  ['Musculoskeletal', 'MusculoskeletalCmt'],
  ['Musculoskeletal', 'skinbreakdown'],
  ['Musculoskeletal', 'skinCmt'],
  ['P_Musculoskeletal', 'arthritisJuvenile'],
  ['P_Musculoskeletal', 'dystrophy'],
  ['P_Musculoskeletal', 'MusculoskeletalCmt'],
  ['P_Musculoskeletal', 'skinbreakdown'],
  ['P_Musculoskeletal', 'skinCmt'],
];

export const psychiatric = [
  ['Psychiatric', 'BipolarDisorder'],
  ['Psychiatric', 'depression'],
  ['Psychiatric', 'panic'],
  ['Psychiatric', 'adhd'],
  ['Psychiatric', 'autism'],
  ['Psychiatric', 'schizophrenia'],
  ['Psychiatric', 'suicidalThoughts'],
  ['Psychiatric', 'ptsd'],
  ['Psychiatric', 'PsychiatricCmt'],
];

export const gynaecological = [
  ['gynecology', 'baby'],
  ['gynecology', 'birthControl'],
  ['gynecology', 'hormoneReplacement'],
  ['gynecology', 'menstrualPeriod'],
  ['gynecology', 'possibilityPregnant'],
  ['gynecology', 'pregnant'],
  ['gynecology', 'menses'],
  ['gynecology', 'gynecologicCmt'],
  ['P_gynecology', 'menses'],
  ['P_gynecology', 'menstrualPeriod'],
];

export const other = [
  ['other', 'C-diff'],
  ['other', 'VRE'],
  ['other', 'CMV'],
  ['other', 'antibioticResistant'],
  ['other', 'HIV'],
  ['other', 'Other conditions'],
  ['other', 'traveled'],
  ['other', 'Recently'],
  ['other', 'COVID19Test'],
  ['other', 'COVID19'],
  ['other', 'travledState'],
  ['other', 'immunocompromisedState'],
  ['other', 'infectiousCmt'],
  ['other', 'chronic pain'],
  ['other', 'glaucoma'],
  ['other', 'downSynderome'],
  ['other', 'OtherCmt'],
  ['P_other', 'breastFed'],
  ['P_other', 'bottleFed'],
  ['P_other', 'solidFood'],
  ['P_other', 'sippyCup'],
  ['P_other', 'regularDiet'],
  ['P_other', 'specialDiet'],
  ['P_other', 'nutritionCmt'],
  ['P_other', 'VRE'],
  ['P_other', 'CMV'],
  ['P_other', 'rsv'],
  ['P_other', 'HIV'],
  ['P_other', 'antibioticResistant'],
  ['P_other', 'Other conditions'],
  ['P_other', 'exposedOther'],
  ['P_other', 'traveled'],
  ['P_other', 'Recently'],
  ['P_other', 'COVID19Test'],
  ['P_other', 'downSynderome'],
  ['P_other', 'fullTerm'],
  ['P_other', 'cSection'],
  ['P_other', 'fetus'],
  ['P_other', 'newborn'],
  ['P_other', 'birthCmt'],
  ['P_other', 'chronic pain'],
  ['P_other', 'OtherCmt'],
  ['basicscreening', 'hxOfFalls'],
  ['basicscreening', 'problemBalance'],
  ['basicscreening', 'device'],
  ['basicscreening', 'assistance1'],
  ['basicscreening', 'assistance2'],
  ['GeneralHistory', 'nursingFacility'],
  ['GeneralHistory', 'confine'],
  ['GeneralHistory', 'neck'],
  ['GeneralHistory', 'trauma'],
  ['GeneralHistory', 'hospitalized'],
  ['GeneralHistory', 'vision1'],
  ['GeneralHistory', 'vision2'],
  ['GeneralHistory', 'vision3'],
  ['GeneralHistory', 'vision4'],
  ['GeneralHistory', 'vision5'],
  ['GeneralHistory', 'vision6'],
  ['GeneralHistory', 'vision7'],
  ['GeneralHistory', 'hearing1'],
  ['GeneralHistory', 'hearing2'],
  ['GeneralHistory', 'implant1'],
  ['GeneralHistory', 'implant2'],
  ['GeneralHistory', 'implant3'],
  ['GeneralHistory', 'implant4'],
];

export const drugs = [['basicscreening', 'drugs', 'yes']];

export const morbid = [
  ['Cardiac', 'AbnormalHeartRhythm'],
  ['P_Cardiac', 'AbnormalHeartRhythm'],
  ['Cardiac', 'ChestPain'],
  ['Cardiac', 'HighBloodPressure'],
  ['Cardiac', 'palpitations'],
  ['Neurologic', 'Seizures'],
  ['Neurologic', 'dizzy'],
  ['Neurologic', 'Stroke'],
  ['Neurologic', 'aphasia'],
  ['Neurologic', 'Sclerosis'],
  ['Neurologic', 'parkinson'],
  ['Neurologic', 'alzheimer'],
  ['Neurologic', 'dementia'],
  ['Endocrine', 'Diabetes'],
  ['Endocrine', 'Cancer', 'yes', 'treatment'],
  ['Vascular', 'Bleeding1'],
  ['Musculoskeletal', 'Arthritis'],
  ['P_Musculoskeletal', 'arthritisJuvenile'],
  ['Psychiatric', 'ptsd'],
  ['GeneralHistory', 'AICD'],
  ['GeneralHistory', 'pacemaker'],
  ['Neurologic', 'cerebral'],
  ['Neurologic', 'numbness'],
  ['Neurologic', 'Neuropathy'],
  ['Neurologic', 'mentalDisability'],
  ['Neurologic', 'tourette'],
  ['Neurologic', 'myasthenia'],
  ['Neurologic', 'recentHospitalization'],
  ['Neurologic', 'NeurologicCmt'],
];
export const morbidlist = [
  'sections.Cardiac.schema.AbnormalHeartRhythm.yes[0]',
  'sections.P_Cardiac.schema.AbnormalHeartRhythm.yes[0]',
  'sections.Cardiac.schema.ChestPain.yes[0]',
  'sections.Cardiac.schema.HighBloodPressure.yes[0]',
  'sections.Cardiac.schema.palpitations.yes',
  'sections.Neurologic.schema.Seizures.yes[0]',
  'sections.Neurologic.schema.dizzy.yes[0]',
  'sections.Neurologic.schema.Stroke.yes[0]',
  'sections.Neurologic.schema.aphasia.yes',
  'sections.Neurologic.schema.Sclerosis.yes',
  'sections.Neurologic.schema.parkinson.yes',
  'sections.Neurologic.schema.alzheimer.yes',
  'sections.Neurologic.schema.dementia.yes',
  'sections.Endocrine.schema.Diabetes.yes[0]',
  'sections.Endocrine.schema.Cancer.yes[1].schema.treatment.yes',
  'sections.Vascular.schema.Bleeding1.yes',
  'sections.Musculoskeletal.schema.Arthritis.yes[0]',
  'sections.P_Musculoskeletal.schema.arthritisJuvenile.yes',
  'sections.Psychiatric.schema.ptsd.yes',
  'sections.GeneralHistory.schema.AICD.yes[0]',
  'sections.GeneralHistory.schema.pacemaker.yes[0]',
  'sections.Neurologic.schema.cerebral.yes',
  'sections.Neurologic.schema.numbness.yes[0]',
  'sections.Neurologic.schema.Neuropathy.yes[0]',
  'sections.Neurologic.schema.mentalDisability.yes[0]',
  'sections.Neurologic.schema.tourette.yes',
  'sections.Neurologic.schema.myasthenia.yes',
  'sections.Neurologic.schema.recentHospitalization.yes',
  'sections.Neurologic.schema.NeurologicCmt.text',
];
