import { Mutation } from '@apollo/client/react/components';
import React from 'react';
import { setBlockNerve } from '../../../../graph/patients';

const withBlockNerve = Component => props => (
  <Mutation mutation={setBlockNerve}>
    {setBlockNerve => <Component {...props} setBlockNerve={setBlockNerve} />}
  </Mutation>
);

export default withBlockNerve;
