import React from 'react';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import merge from 'lodash/merge';
import { getNestedValue } from '../utilities/data/object';
import { Range } from '../../components/core/DatePicker';
import { LocalDate, Period } from '@js-joda/core';

const toInt = val => (val ? parseInt(val) : undefined);
const ifFinite = val => (val && isFinite(val) ? val : undefined);

const prefixPath = (prefix, path) => (prefix ? `${prefix}${path}` : path);

export const qsEncode = prefix => val => ({
  nm: getNestedValue('name', val),
  or: getNestedValue('operationRooms', val),
  ph: ifFinite(getNestedValue('physician', val)),
  py: getNestedValue('physicians', val),
  pt: ifFinite(getNestedValue('procedureType', val)),
  hp: ifFinite(getNestedValue('hospital', val)),
  prt: ifFinite(getNestedValue('partner', val)),
  st: getNestedValue('status', val),
  ist: getNestedValue('intakeStatus', val),
  ts: getNestedValue('transferStatus', val),
  sts: getNestedValue('scheduleTransferState', val),
  ps: getNestedValue('procedureStatus', val),
  ct: getNestedValue('category', val),
  sp: getNestedValue('speciality', val),
  rm: ifFinite(getNestedValue('room', val)),
  vw: getNestedValue('view', val),
  ...(() => {
    const dateRange = getNestedValue('dateRange', val);

    return dateRange
      ? {
          [prefixPath(prefix, 'sd')]: dateRange.firstIncluded().toEpochDay(),
          [prefixPath(prefix, 'ed')]: dateRange.lastExcluded().toEpochDay(),
        }
      : {};
  })(),
  [prefixPath(prefix, 'fi')]: getNestedValue('dateRange.filterId', val),
  [prefixPath(prefix, 'qf')]: getNestedValue('dateRange.quickFilter', val),
});

const LIMIT_DAYS = 200000;
const millisToDays = millis => millis / (1000 * 60 * 60 * 24);

export const decodeDateRange = prefix => query => {
  const from = getNestedValue(prefixPath(prefix, 'sd'), query);
  const to = getNestedValue(prefixPath(prefix, 'ed'), query);

  const validatedFrom = Math.floor(from && from > LIMIT_DAYS ? millisToDays(from) : from);
  const validatedTo = Math.floor(to && to > LIMIT_DAYS ? millisToDays(to) : to);

  if (validatedFrom && validatedTo) {
    return Range.of(LocalDate.ofEpochDay(parseInt(validatedFrom)), Period.ofDays(validatedTo - validatedFrom));
  } else {
    return undefined;
  }
};

export const qsDecode = prefix => query => {
  const or = getNestedValue('or', query);
  const py = getNestedValue('py', query);
  return {
    name: getNestedValue('nm', query),
    operationRooms: Array.isArray(or) ? or : or ? [or] : undefined,
    physician: toInt(getNestedValue('ph', query)),
    physicians: Array.isArray(py) ? py : py ? [py] : undefined,
    procedureType: toInt(getNestedValue('pt', query)),
    hospital: toInt(getNestedValue('hp', query)),
    partner: toInt(getNestedValue('prt', query)),
    status: getNestedValue('st', query),
    intakeStatus: getNestedValue('ist', query),
    transferStatus: getNestedValue('ts', query),
    scheduleTransferState: getNestedValue('sts', query),
    procedureStatus: getNestedValue('ps', query),
    dateRange: decodeDateRange(prefix)(query),
    category: getNestedValue('ct', query),
    speciality: getNestedValue('sp', query),
    room: toInt(getNestedValue('rm', query)),
    view: getNestedValue('vw', query),
  };
};

const withFilterQueryString =
  (defaultValue, filterPrefix, encode = qsEncode(filterPrefix), decode = qsDecode(filterPrefix)) =>
  Component =>
    withRouter(
      class extends React.Component {
        constructor(props) {
          super(props);
          const filter = decode(queryString.parse(this.props.location.search));
          this.state = { filter };
        }

        getValue = () => {
          const filter = merge({}, defaultValue, this.state.filter);
          return filter ? filter : defaultValue;
        };

        setValue = value => {
          const query = queryString.stringify(encode(value));
          window.history.replaceState(undefined, undefined, query.length > 0 ? `?${query}` : window.location.pathname);
          this.setState({ filter: value });
        };

        clearValue = () => {
          this.setValue(undefined);
        };

        render() {
          return (
            <Component
              {...this.props}
              filter={this.getValue()}
              setFilter={this.setValue}
              clearFilter={this.clearValue}
            />
          );
        }
      }
    );

export default withFilterQueryString;
