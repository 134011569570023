import React from 'react';
import * as XLSX from 'xlsx';
import Button from '@material-ui/core/Button';
import { SaveRounded } from '@material-ui/icons';

function ExcelGenerator({ excelFileName, data }: { excelFileName: string; data: any[] }) {
  const generateExcel = () => {
    const columnsToModify = new Set([
      'Daily Utilization',
      'Physician Turnover',
      'Patient In to Physician In',
      'Patient In to Start',
      'Ready for Anesthesia to Patient Ready',
      'Patient Ready to OR Entrance',
      'Wheels in to Ready For Surgery',
      'Ready For Surgery to Start',
      'Ready For Surgery to Physician In',
      'Closing Complete to Patient Out',
      'PO to cut',
      'Wheels-In, Wheels-Out',
      'Start to Close',
      'Scheduled Procedure Duration',
      'FCOTS Median',
      'OR Daily Usage Time',
      'Aggregate Daily Patients OR Time',
      'Pre Op to Exit Total',
      'Pre Op',
      'PIPO',
      'Recovery',
      'POSTOP',
      'Pre OP to Exit',
      'Patient In To Start',
      'Patient Ready To OR Entrance',
      'Wheels In To Ready For Surgery',
      'Ready For Surgery To Start',
      'WR',
      'Scheduled OR Time',
      'Actual OR Time',
    ]);

    const updatedHeaders = data[0].map(header => {
      if (columnsToModify.has(header)) {
        return `${header} (m)`;
      }
      if (header.includes('Med.')) {
        return header.replace(/Med\./g, 'Med. (m)');
      }
      if (header.includes('Avg.')) {
        return header.replace(/Avg\./g, 'Avg. (m)');
      }
      return header;
    });

    const processedData = [updatedHeaders, ...data.slice(1)].map(row =>
      row.map(cell => {
        if (cell === '-') {
          return null;
        }
        if (typeof cell === 'string' && cell.endsWith(' mins')) {
          return Number(cell.replace(' mins', ''));
        }
        if (typeof cell === 'string' && /^\d/.test(cell) && cell.endsWith('%')) {
          return Number(cell.replace('%', '')) / 100;
        }
        if (!isNaN(cell) && cell !== '') {
          return Number(cell);
        }
        return cell;
      })
    );

    // Create a new workbook
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(processedData);
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // Set column width to fit content
    const colWidths = processedData[0].map(col => ({
      wch: col?.toString().length + 2 || 10,
    }));
    ws['!cols'] = colWidths;

    // "%"-converted cells should have percentage format
    Object.keys(ws).forEach(cellRef => {
      if (cellRef.startsWith('!') || !ws[cellRef].v) return;

      const cellValue = ws[cellRef].v;
      if (typeof cellValue === 'number' && cellValue > 0 && cellValue < 1) {
        ws[cellRef].z = '0.00%';
      }
    });

    // Convert the workbook to a binary string
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

    // Create a Blob from the binary string
    const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });

    // Create a download link and trigger the download
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = `${excelFileName}.xlsx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  // Utility function to convert s to array buffer
  const s2ab = s => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  return (
    <Button color="primary" startIcon={<SaveRounded />} onClick={generateExcel}>
      Export as Excel file
    </Button>
  );
}

export default ExcelGenerator;
