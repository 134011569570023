import Switch from 'react-switch';
import React, { FC } from 'react';
import { isDefinedAndNotNull } from '../../utilities/check';
import LabelComponent from '../Label';

const Label = LabelComponent as any;

const ToggleInputShow: FC<{
  value: boolean;
  onChange: (val: boolean) => void;
  defaultValue?: boolean;
  isDisabled?: boolean;
  label: string;
  shouldShow?: boolean;
}> = ({ value, onChange, defaultValue = false, isDisabled = false, label, shouldShow }) => {
  const sw = shouldShow ? (
    <Switch
      checked={isDefinedAndNotNull(value) ? value : defaultValue}
      onChange={onChange}
      onColor="#86d3ff"
      onHandleColor="#2693e6"
      handleDiameter={22}
      uncheckedIcon={false}
      checkedIcon={false}
      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
      height={16}
      width={36}
      disabled={isDisabled}
    />
  ) : null;

  return label && shouldShow ? (
    <Label text={label} style={{ justifyContent: 'space-between' }}>
      {sw}
    </Label>
  ) : (
    sw
  );
};

export default ToggleInputShow;
