import React, { useEffect, useMemo, useState } from 'react';
import { createStore } from 'zustand';
import SelectionContext from './SelectionContext';

interface StoreState {
  selection: Record<string, boolean | undefined>;
  setSelection: (key: string, next: boolean) => void;
  setAllSelections: (keys: string[], next: boolean) => void;
}

const SelectionContextProvider = ({ children }) => {
  const value = useMemo<SelectionContext>(() => {
    const store = createStore<StoreState>((set, get) => ({
      selection: {},
      setSelection: (key: string, next: boolean) => {
        set({ selection: { ...get().selection, [key]: next } });
      },
      setAllSelections: (keys: string[], next: boolean) => {
        const updatedSelection = keys.reduce((acc, key) => ({ ...acc, [key]: next }), { ...get().selection });
        set({ selection: updatedSelection });
      },
    }));

    const useSelected = () => {
      const [selected, setSelected] = useState<string[]>(
        Object.keys(store.getState().selection).filter(key => store.getState().selection[key])
      );

      useEffect(
        () =>
          store.subscribe(({ selection }) => {
            setSelected(Object.keys(selection).filter(key => selection[key]));
          }),
        []
      );

      return selected || [];
    };

    const useSelection = (key: string) => {
      const [isSelected, setSelected] = useState<boolean>(store.getState().selection[key] || false);

      useEffect(
        () =>
          store.subscribe(({ selection }) => {
            setSelected(selection[key] || false);
          }),
        []
      );

      return [isSelected, (next: boolean) => store.getState().setSelection(key, next)] as const;
    };

    const useSetAllSelections = () => {
      return store.getState().setAllSelections;
    };

    return { useSelected, useSelection, useSetAllSelections };
  }, []);

  return <SelectionContext.Provider value={value}>{children}</SelectionContext.Provider>;
};

export default SelectionContextProvider;
