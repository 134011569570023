import React, { FC, useEffect, useMemo, useState } from 'react';
import { Chip, createStyles, makeStyles, MenuItem, MenuList, Popover, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import VendorMenuButton from './VendorMenuButton';
import { light } from '../../../../../theme';
import { Procedure } from '../../../../../types/Procedure';
import Vendor from '../../../../../types/Vendor';
import { useVendorProcedureContext } from './context/VendorProcedureContext';
import CheckCircle from '@material-ui/icons/CheckCircle';

interface VendorMenuProps {
  procedure?: Procedure;
  vendor: Vendor;
  toggleStaffMember: (vendor: Vendor) => (event: React.MouseEvent<HTMLElement>) => Promise<void>;
  editableStaff?: boolean;
}

const VendorMenuItem: FC<VendorMenuProps> = ({ vendor, procedure, toggleStaffMember }) => {
  const classes = useStyles();
  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    toggleStaffMember(vendor)(e);
  };

  const isAssigned = (procedure?.assignedVendors || []).filter(e => e.vendor?.id === vendor.id).length > 0;

  return (
    <MenuItem onKeyDown={e => e.stopPropagation()} key={vendor.id} onClick={onClick} className={classes.menuItem}>
      <Chip label={vendor?.name} size="small" />
      {isAssigned && <CheckCircle className={classes.checkmark} />}
    </MenuItem>
  );
};

const VendorMenu = ({ procedure }: { procedure?: Procedure }) => {
  const classes = useStyles();
  const searchEl = React.useRef<HTMLInputElement | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    if (open) {
      searchEl.current?.focus();
    } else {
      setSearch('');
    }
  }, [open]);

  const [search, setSearch] = useState('');

  const handleSearchUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.currentTarget.value);
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const vendorContext = useVendorProcedureContext();

  const toggleStaffMember = (vendor: Vendor) => async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if ((procedure?.assignedVendors || []).filter(e => e.vendor.id === vendor.id).length > 0) {
      await vendorContext.remove(vendor.id);
    } else {
      await vendorContext.assign(vendor.id);
    }
  };

  const vendors = useMemo(() => vendorContext.vendors || [], [vendorContext.vendors]);

  const filteredVendors = useMemo(() => {
    const chunks = search.toLowerCase().split(/\s+/);
    return (vendors || []).filter((vendor: Vendor) => {
      const vendorName = vendor.name.toLowerCase();
      return chunks.every(chunk => vendorName.indexOf(chunk) >= 0);
    });
  }, [search, vendors]);

  return (
    <>
      <VendorMenuButton onClick={handleButtonClick} />
      {open && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClick={e => e.stopPropagation()}
          onClose={handleClose}
          getContentAnchorEl={null}
          keepMounted
          classes={{
            paper: classes.popover,
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <>
            <TextField
              size="small"
              variant="outlined"
              value={search}
              onChange={handleSearchUpdate}
              inputRef={searchEl}
              InputProps={{
                startAdornment: <SearchIcon fontSize="small" className={classes.searchIcon} />,
                autoCapitalize: 'off',
                autoComplete: 'off',
                autoCorrect: 'off',
                autoFocus: true,
                classes: {
                  root: classes.inputRoot,
                  inputAdornedStart: classes.inputAdornedStart,
                },
              }}
              classes={{
                root: classes.textFieldRoot,
              }}
            />
            <MenuList onClick={e => e.stopPropagation()}>
              {filteredVendors.map((vendor: Vendor) => (
                <VendorMenuItem
                  key={vendor.id}
                  vendor={vendor}
                  procedure={procedure}
                  toggleStaffMember={toggleStaffMember}
                  editableStaff={true}
                />
              ))}
            </MenuList>
          </>
        </Popover>
      )}
    </>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    textFieldRoot: {
      margin: '1rem',
      marginBottom: 0,
      padding: 0,
    },
    inputRoot: {
      width: '14rem',
      fontSize: '0.8125em',
      padding: '0.25em',
      color: 'white',
    },
    searchIcon: {
      marginLeft: '0.25em',
      marginRight: '0.25em',
    },
    inputAdornedStart: {
      paddingLeft: 0,
    },
    spinnerContainer: {
      padding: '1rem',
    },
    spinner: {},
    vendor: {
      flex: 1,
    },
    popover: {
      backgroundColor: light.popover.background.string(),
    },
    checkmark: {
      marginLeft: '0.5rem',
      marginRight: '0.5rem',
      fontSize: '0.9rem',
      color: theme.palette.success.main,
    },
    menuItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  })
);

export default VendorMenu;
