import React, { useEffect } from 'react';
import format from 'date-fns/format';
import { TruncatedText } from '@/se/components/typography';
import { userRoleLabels } from '../role/labels';
import { getNestedValue } from '@/se/utilities/data/object';
import { options } from '../../inputs/PatientNameFormatInput';
import { sortDate } from '@/util/sort';
import { smsNotificationColumn } from '../physician/columns';
import {
  AdminNotificationsButton,
  SensorEmailNotificationsButton,
  SensorPhoneNotificationsButton,
} from '../../pages/user/admin/NotificationsButton';
import { gql, useMutation } from '@apollo/client';
import { useToaster } from '../../core/Toaster';
import Button from '@material-ui/core/Button';
import Representation from '@/components/entities/user/Representation';

export const nameColumn = {
  title: 'Name',
  key: 'name',
  lens: item => item.name,
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

export const lastLoginNoAction = {
  title: 'Last Login',
  lens: item => item,
  Component: ({ data: { lastLogin, hasPassword } }) => (
    <TruncatedText>
      {hasPassword !== false ? (lastLogin ? format(lastLogin, 'MM/DD/YYYY HH:mm:ss') : 'Never') : 'Never'}
    </TruncatedText>
  ),
};

export const lastLogin = {
  title: 'Last Login',
  lens: item => item,
  Component: ({ data: { id, lastLogin, hasPassword } }) => (
    <TruncatedText>
      {hasPassword !== false ? (
        <div style={{ display: 'flex', gap: '0.5em', alignItems: 'baseline' }}>
          <span>{lastLogin ? format(lastLogin, 'MM/DD/YYYY HH:mm:ss') : 'Never'}</span>
          <SendResetPassword userId={id} />
        </div>
      ) : (
        <div style={{ display: 'flex', gap: '0.5em', alignItems: 'baseline' }}>
          Never
          <ResendInvitation userId={id} />
        </div>
      )}
    </TruncatedText>
  ),
};

export const SendResetPassword = ({ userId }) => {
  const [sendResetPassword, { loading, error, called }] = useMutation(
    gql`
      mutation sendResetPassword($userId: Long!) {
        sendResetPassword(userId: $userId)
      }
    `,
    {
      variables: {
        userId,
      },
    }
  );

  const toaster = useToaster();

  useEffect(() => {
    if (error) {
      toaster.error('Unable to send reset password link…');
    }
  }, [error]);

  return (
    <Button
      onClick={sendResetPassword}
      disabled={loading || (called && !error)}
      style={{ display: 'flex', alignItems: 'baseline' }}
    >
      {loading
        ? 'Sending Reset Password Link…'
        : called && !error
          ? 'Reset Password Link Sent!'
          : 'Send Reset Password Link'}
    </Button>
  );
};

export const ResendInvitation = ({ userId }) => {
  const [resendInvitation, { loading, error, called }] = useMutation(
    gql`
      mutation resendInvitation($userId: Long!) {
        resendInvitation(userId: $userId)
      }
    `,
    {
      variables: {
        userId,
      },
    }
  );

  const toaster = useToaster();

  useEffect(() => {
    if (error) {
      toaster.error('Unable to send invitation…');
    }
  }, [error]);

  return (
    <Button
      onClick={resendInvitation}
      disabled={loading || (called && !error)}
      style={{ display: 'flex', alignItems: 'baseline' }}
    >
      {loading ? 'Sending Invitation…' : called && !error ? 'Invitation Sent!' : 'Resend Invitation'}
    </Button>
  );
};

export const baseColumns = [
  {
    title: 'Email',
    key: 'email',
    lens: item => item.email,
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
  smsNotificationColumn,
  {
    title: 'Role',
    key: 'role',
    lens: item => item.role,
    Component: props => <TruncatedText>{getNestedValue(props.data, userRoleLabels)}</TruncatedText>,
  },
  {
    title: 'Patient Name Format',
    lens: item => item.patientNameFormat,
    Component: props => <TruncatedText>{options[props.data] || props.data}</TruncatedText>,
  },
  {
    title: 'Created At',
    lens: item => item.dateCreated,
    sortFunction: (l, r) => sortDate(l.dateCreated, r.dateCreated),
    Component: props => <TruncatedText>{format(props.data, 'MM/DD/YYYY HH:mm:ss')}</TruncatedText>,
  },
];

export const openPositionNotification = {
  title: 'Receive Open Position Notifications',
  lens: item => item,
  Component: props => <AdminNotificationsButton {...props} />,
};

export const sensorEmailNotification = {
  title: 'Receive Sensor Alert Emails',
  lens: item => item,
  Component: props => <SensorEmailNotificationsButton {...props} />,
};

export const sensorPhoneNotification = {
  title: 'Receive Sensor Alert Phone Notifications',
  lens: item => item,
  Component: props => <SensorPhoneNotificationsButton {...props} />,
};

export const representsStaffColumn = {
  title: 'Represents',
  lens: item => ({ userName: item.name, userId: item.id, roles: item.roles }),
  Component: ({ data: { userName, userId, roles } }) => {
    const physicianRole = roles.find(item => item.match(physicianRegex));

    if (!physicianRole) {
      return <Representation userName={userName} userId={userId} />;
    }

    const physicianId = parseInt(physicianRole.match(physicianRegex)[1], 10);

    return <Representation userName={userName} userId={userId} physicianId={physicianId} />;
  },
};

const physicianRegex = /Physician\((\d+)\)/;
