import toString from '../questionnaire/toString';
import createSelector from '../questionnaire/selector';
import { get, toPath } from 'lodash';
import toHighlightsString from '../questionnaire/toHighlightsString';
import toHighlightsString2 from '../questionnaire/toHighlightsString2';
import toAge from '../questionnaire/toAge';
import toBoolean from '../questionnaire/toBoolean';
import toHeight from '../questionnaire/toHeight';
import toWeightLbs from '../questionnaire/toWeightLbs';
import toWeight from '../questionnaire/toWeight';
import toBMI from '../questionnaire/toBMI';
import toOptionalBoolean from '../questionnaire/toOptionalBoolean';
import toStringWithoutQuestions from '../questionnaire/toStringWithoutQuestions';
import toHighlightsString3 from '../questionnaire/toHighlightsString3';
import toHighlightsString4 from '../questionnaire/toHighlightsString4';
import toHighlightsStringNoDetails from '../questionnaire/toHighlightsStringNoDetails';
import toHighlightsString5 from '../questionnaire/toHighlightsString5';

export const select =
  <TReducer, TResult>(
    path: string,
    reducer: (questionnaire: unknown, questionnaireValue: unknown) => TReducer | undefined,
    result: (prev: TReducer | undefined) => TResult
  ) =>
  (questionnaire: unknown, questionnaireValue: unknown): TResult => {
    const selection = createSelector(toPath(path))(questionnaire, questionnaireValue);
    return selection ? result(reducer(...selection)) : result(undefined);
  };

export const selectRaw =
  (path: string) =>
  (questionnaire: unknown, questionnaireValue: unknown): unknown =>
    createSelector(toPath(path))(questionnaire, questionnaireValue)?.[1];

export const selectString =
  (...paths: string[]) =>
  (questionnaire: unknown, questionnaireValue: unknown): string => {
    const selections = paths
      .map(p => createSelector(toPath(p))(questionnaire, questionnaireValue))
      .flatMap(v => (v ? [toString(...v)] : []))
      .filter(Boolean);
    return selections.join(', ');
  };

export const selectStringWithPrefix =
  (prefix: string, ...paths: string[]) =>
  (questionnaire: unknown, questionnaireValue: unknown): string => {
    const result = selectString(...paths)(questionnaire, questionnaireValue);

    return `${prefix}${result}`;
  };

export const selectHighlightedString =
  (...paths: string[]) =>
  (questionnaire: unknown, questionnaireValue: unknown): string => {
    const selections = paths
      .map(p => createSelector(toPath(p))(questionnaire, questionnaireValue))
      .flatMap(v => (v ? [toHighlightsString(...v)] : []))
      .filter(Boolean);
    return selections.join(', ');
  };

export const selectHighlightedStringNoDetails =
  (...paths: string[]) =>
  (questionnaire: unknown, questionnaireValue: unknown): string => {
    const selections = paths
      .map(p => createSelector(toPath(p))(questionnaire, questionnaireValue))
      .flatMap(v => (v ? [toHighlightsStringNoDetails(...v)] : []))
      .filter(Boolean);
    return selections.join(', ');
  };

export const selectHighlightedString2 =
  (...paths: string[]) =>
  (questionnaire: unknown, questionnaireValue: unknown): string => {
    const selections = paths
      .map(p => createSelector(toPath(p))(questionnaire, questionnaireValue))
      .flatMap(v => (v ? [toHighlightsString2(...v)] : []))
      .filter(Boolean);
    return selections.join(', ');
  };

export const selectHighlightedString3 =
  (...paths: string[]) =>
  (questionnaire: unknown, questionnaireValue: unknown): string => {
    const selections = paths
      .map(p => createSelector(toPath(p))(questionnaire, questionnaireValue))
      .flatMap(v => (v ? [toHighlightsString3(...v)] : []))
      .filter(Boolean);
    return selections.join(', ');
  };

export const selectHighlightedString4 =
  (...paths: string[]) =>
  (questionnaire: unknown, questionnaireValue: unknown): string => {
    const selections = paths
      .map(p => createSelector(toPath(p))(questionnaire, questionnaireValue))
      .flatMap(v => (v ? [toHighlightsString4(...v)] : []))
      .filter(Boolean);
    return selections.join(', ');
  };

export const selectHighlightedString5 =
  (...paths: string[]) =>
  (questionnaire: unknown, questionnaireValue: unknown): string => {
    const selections = paths
      .map(p => createSelector(toPath(p))(questionnaire, questionnaireValue))
      .flatMap(v => (v ? [toHighlightsString5(...v)] : []))
      .filter(Boolean);
    return selections.join(', ');
  };

export const selectAge =
  (path: string) =>
  (questionnaire: unknown, questionnaireValue: unknown): string => {
    const selection = createSelector(toPath(path))(questionnaire, questionnaireValue);
    return (selection && toAge(...selection)) || '';
  };

export const selectHeight =
  (path: string) =>
  (questionnaire: unknown, questionnaireValue: unknown): string => {
    const selection = createSelector(toPath(path))(questionnaire, questionnaireValue);
    return (selection && toHeight(...selection)) || '';
  };

export const selectWeightLbs =
  (path: string) =>
  (questionnaire: unknown, questionnaireValue: unknown): string => {
    const selection = createSelector(toPath(path))(questionnaire, questionnaireValue);
    return (selection && toWeightLbs(...selection)) || '';
  };

export const selectWeight =
  (path: string) =>
  (questionnaire: unknown, questionnaireValue: unknown): string => {
    const selection = createSelector(toPath(path))(questionnaire, questionnaireValue);
    return (selection && toWeight(...selection)) || '';
  };

export const selectBMI =
  (path: string) =>
  (questionnaire: unknown, questionnaireValue: unknown): string => {
    const selection = createSelector(toPath(path))(questionnaire, questionnaireValue);
    return (selection && toBMI(...selection)) || '';
  };

export const selectOptionalBoolean =
  (path: string) =>
  (questionnaire: unknown, questionnaireValue: unknown): boolean | undefined => {
    const selection = createSelector(toPath(path))(questionnaire, questionnaireValue);
    return selection && toOptionalBoolean(...selection);
  };

export const selectBoolean =
  (path: string) =>
  (questionnaire: unknown, questionnaireValue: unknown): boolean => {
    const selection = createSelector(toPath(path))(questionnaire, questionnaireValue);
    return (selection && toBoolean(...selection)) || false;
  };

export const selectBooleanText =
  (path: string) =>
  (questionnaire: unknown, questionnaireValue: unknown): string => {
    const value = selectOptionalBoolean(path)(questionnaire, questionnaireValue);
    return value === true ? 'Yes' : value === false ? 'No' : '';
  };

export const selectList =
  (path: string) =>
  (questionnaire: unknown, questionnaireValue: unknown): unknown[] => {
    const selection = createSelector(toPath(path))(questionnaire, questionnaireValue);
    return (Array.isArray(selection) && selection[1] && Array.isArray(selection[1].list) && selection[1].list) || [];
  };

export const selectIsNeck = (questionnaire: unknown, questionnaireAnswers: unknown) => {
  const neckSize = get(questionnaireAnswers, 'sections.sleepApneaQ.schema.SleepApneaQ_q5.option', '');

  return neckSize ? neckSize === 'Yes' : undefined;
};

export const selectIsMale = (questionnaire: unknown, questionnaireAnswers: unknown) => {
  const male = get(questionnaireAnswers, 'sections.demographicInformation.schema.sex.option', '');

  return male ? male === 'Male' : undefined;
};

export const selectIsOlderThan = (value: number) => (questionnaire: unknown, questionnaireAnswers: unknown) => {
  const birthDay = get(questionnaireAnswers, 'sections.demographicInformation.schema.dob.dateOfBirth.day', '');
  const birthMonth = get(questionnaireAnswers, 'sections.demographicInformation.schema.dob.dateOfBirth.month', '');
  const birthYear = get(questionnaireAnswers, 'sections.demographicInformation.schema.dob.dateOfBirth.year', '');

  if (!birthDay || !birthMonth || !birthYear) {
    return undefined;
  }

  // Get the current date
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Months are zero-indexed
  const currentDay = currentDate.getDate();

  // Calculate the age
  let age = currentYear - birthYear;

  // Adjust the age based on the birth month and day
  if (currentMonth < birthMonth || (currentMonth === birthMonth && currentDay < birthDay)) {
    age--;
  }

  // Check if the age is greater than 50
  return age > value;
};

export const selectIsBmiGreaterThan = (value: number) => (questionnaire: unknown, questionnaireAnswers: unknown) => {
  const heightFeet = get(questionnaireAnswers, 'sections.demographicInformation.schema.bmi.bodyMassIndex.heightFt', '');
  const heightInches = get(
    questionnaireAnswers,
    'sections.demographicInformation.schema.bmi.bodyMassIndex.heightIn',
    ''
  );
  const weight = get(questionnaireAnswers, 'sections.demographicInformation.schema.bmi.bodyMassIndex.weightLb', '');

  // Convert input strings to numbers
  const feet = parseFloat(heightFeet);
  const inches = parseFloat(heightInches);
  const weightNum = parseFloat(weight);

  // Check if the conversion was successful
  if (isNaN(feet) || isNaN(inches) || isNaN(weightNum)) {
    // Handle invalid input
    return undefined;
  }

  // Convert height to inches
  const totalHeightInInches = feet * 12 + inches;

  // Calculate BMI (weight in pounds / (height in inches * height in inches)) * 703
  const bmi = (weightNum / (totalHeightInInches * totalHeightInInches)) * 703;

  // Check if BMI is greater than specified value
  return bmi > value;
};

export const selectEmploymentStatus =
  (path: string) =>
  (questionnaire: unknown, questionnaireValue: unknown): string => {
    const selection = createSelector(toPath(path))(questionnaire, questionnaireValue);
    return selection ? toStringWithoutQuestions(...selection) || '' : '';
  };

export const selectStringWithoutQuestions =
  (path: string) =>
  (questionnaire: unknown, questionnaireValue: unknown): string => {
    const selection = createSelector(toPath(path))(questionnaire, questionnaireValue);
    return selection ? toStringWithoutQuestions(...selection) || '' : '';
  };
