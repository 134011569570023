import React, { useState } from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Sector } from 'recharts';

export interface DelayReason {
  reason: string;
  count: number;
}

interface DelaysBreakdownProps {
  reasons: DelayReason[];
}

const COLORS = ['#2B3C87', '#74AC48', '#B7DD9A', '#A6AFD9', '#6A78B6', '#485796', '#2B3C87'];

const DelaysBreakdown = (props: DelaysBreakdownProps) => {
  const totalCount = props.reasons.reduce((acc, cur) => acc + cur.count, 0);
  const title = String(totalCount);
  const subtitle = totalCount === 1 ? 'delay' : 'delays';

  const [selection, setSelection] = useState<number | undefined>();

  const onPieEnter = (_, index) => {
    setSelection(index);
  };
  const renderActiveShape = props => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      label,
      textColor,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <text fontSize={12} fontWeight="bold" x={cx} y={cy} dy={110} textAnchor="middle" fill={textColor || fill}>
          {payload.label}
        </text>
        <text fontSize={12} x={cx} y={cy} dy={126} textAnchor="middle" fill={textColor || fill}>
          {Math.round(props.value)} {Math.round(props.value) === 1 ? 'delay' : 'delays'}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />{' '}
        {/*<path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />*/}{' '}
        {/*<circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />*/}{' '}
        {/*<text fontSize={12} x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#fff">*/}{' '}
        {/*  {label}*/} {/*</text>*/} {/*<text*/} {/*  opacity={0.5}*/} {/*  fontSize={12}*/}{' '}
        {/*  x={ex + (cos >= 0 ? 1 : -1) * 12}*/} {/*  y={ey}*/} {/*  dy={18}*/} {/*  textAnchor={textAnchor}*/}{' '}
        {/*  fill="#fff"*/} {/*>*/} {/*  {`(${(percent * 100).toFixed(2)}%)`}*/} {/*</text>*/}
      </g>
    );
  };

  const data = props.reasons.map(reason => ({
    label: reason.reason,
    value: reason.count,
    color: stringToColour(reason.reason),
  }));

  return (
    <>
      <ResponsiveContainer width="100%" height="100%" aspect={2}>
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            innerRadius="60%"
            outerRadius="100%"
            fill="#8884d8"
            stroke="none"
            dataKey="value"
            startAngle={90}
            endAngle={-270}
            paddingAngle={4}
            cornerRadius={2}
            onMouseEnter={onPieEnter}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <text
            x="50%"
            y="46%"
            fontSize="1.2vw"
            fontWeight={400}
            fill="white"
            textAnchor="middle"
            dominantBaseline="auto"
          >
            {title}
          </text>
          <text
            x="50%"
            y="50%"
            fontSize=".7vw"
            fontWeight={500}
            fill="rgb(125	163	206)"
            textAnchor="middle"
            dominantBaseline="hanging"
          >
            {subtitle}
          </text>
          <Legend content={'Utilization'} align="left" verticalAlign="bottom" iconType="circle" iconSize={8} />
        </PieChart>
      </ResponsiveContainer>
    </>
  );
};

export default DelaysBreakdown;

const stringToColour = (str: string) => {
  let hash = 0;
  str.split('').forEach(char => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  });
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += value.toString(16).padStart(2, '0');
  }
  return colour;
};
