import React, { Fragment } from 'react';
import styled from 'styled-components';
import HtmlInput from '../html/Input';
import InputError from '../errors/InputError';
import { isDefinedAndNotNull, isNumber } from '../../utilities/check';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  label {
    margin: auto;
    margin-left: -4.8em;
    font-size: 1em;
    opacity: 0.8;
  }
`;

export default ({
  Input = HtmlInput,
  Error = InputError,
  placeholder = '',
  isHidden,
  autoFocus,
  value,
  onChange,
  isDisabled,
  name = 'number',
  error,
  hasError,
  defaultValue,
  unitForValue,
  allowEmpty,
  scope,
  ...rest
}) => {
  return (
    scope?.hospital?.modules?.hasPostop && (
      <>
        <div style={{ marginBottom: '1.5rem' }}>
          <div
            style={{
              marginBottom: '0.75em',
              fontSize: '1.1em',
              fontWeight: '500',
              opacity: '0.68',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            PostOp Time Threshold
          </div>
          <Fragment>
            <Wrapper>
              <Input
                id={name}
                type={isHidden ? 'hidden' : 'number'}
                name={name}
                autoComplete="on"
                autoCapitalize="off"
                autoCorrect="off"
                placeholder={placeholder}
                autoFocus={autoFocus || false}
                value={isDefinedAndNotNull(value) ? value : ''}
                onChange={e => {
                  const value = parseFloat(e.target.value);
                  return isNumber(value) && !isNaN(value)
                    ? onChange(value)
                    : allowEmpty
                      ? onChange('')
                      : onChange(undefined);
                }}
                disabled={isDisabled}
                hasError={hasError}
                {...rest}
              />
              {unitForValue && <label>{unitForValue(value)}</label>}
            </Wrapper>
            <Error isVisible={hasError} id={name}>
              {hasError ? error : ''}
            </Error>
          </Fragment>
        </div>
      </>
    )
  );
};
