import { Mutation } from '@apollo/client/react/components';
import React from 'react';
import { setPatientReadyInPOSTOP } from '../../../../graph/patients';

const withSetPatientReadyInPOSTOP = Component => props => (
  <Mutation mutation={setPatientReadyInPOSTOP}>
    {setPatientReadyInPOSTOP => <Component {...props} setPatientReadyInPOSTOP={setPatientReadyInPOSTOP} />}
  </Mutation>
);

export default withSetPatientReadyInPOSTOP;
