import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@/se/components/Button';
import { withSession } from '@/state/Session';

interface HospitalNameProps {
  compressed: boolean;
  children: ReactNode;
  memberships: any;
  selectMembership: any;
}

const HospitalName = ({ compressed, children, memberships, selectMembership }: HospitalNameProps) => {
  const classes = useStyles();

  return (
    <Typography
      color="textSecondary"
      className={clsx(classes.hospitalName, {
        [classes.hideName]: compressed,
      })}
    >
      {children}{' '}
      {Array.isArray(memberships) && memberships.length > 1 && (
        <Button onClick={() => selectMembership()}>Change</Button>
      )}
    </Typography>
  );
};

export default withSession(context => ({
  memberships: context.session?.user?.memberships,
  selectMembership: context.selectMembership,
}))(HospitalName);

const lineClamp = 2;

const useStyles = makeStyles(theme => ({
  hospitalName: {
    display: '-webkit-box',
    boxOrient: 'vertical',
    minHeight: `calc(${theme.typography.body1.fontSize} * ${theme.typography.body1.lineHeight} * ${lineClamp})`,
    whiteSpace: 'normal',
    lineClamp: lineClamp,
    overflow: 'hidden',
    transitionDelay: theme.transitions.duration.standard,
    transition: theme.transitions.create('opacity', {
      delay: theme.transitions.duration.standard,
    }),
  } as any,
  hideName: {
    opacity: 0,
    transition: theme.transitions.create('none', {
      delay: 0,
    }),
  } as any,
}));
