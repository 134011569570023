import React from 'react';
import Header from './components/Header';
import MedicalPassportField from '../components/MedicalPassportField';
import MedicalPassportCheckbox from '../components/MedicalPassportCheckbox';
import Footer from './components/Footer';
import { getGridChildStyles, getGridChildStyles3, getGridHeaderChildStyles, getGridParentStyles } from './styles';
import styles from './styles';
import toAge from '../../../../../questionnaire/toAge';
import get from 'lodash/get';
import { useValue } from '../ValueContext';
import toHighlightsString from '../../../../../questionnaire/toHighlightsString';
import MedicalPassportTextArea from '../components/MedicalPassportTextArea';

const Page1 = () => {
  const { questionnaireAnswers } = useValue();
  let preferred = get(questionnaireAnswers, 'sections.demographicInformation.schema.preferred.option', '') + 'Phone';
  let employmentStatusEmployed = get(
    questionnaireAnswers,
    'sections.demographicInformation.schema.employment_info.option[0]'
  );
  let employmentStatusNotEmployed = get(
    questionnaireAnswers,
    'sections.demographicInformation.schema.employment_info.option'
  );

  let occupation = get(
    questionnaireAnswers,
    'sections.demographicInformation.schema.employment_info.option[1].schema.employment_occupation.text'
  );
  let company = get(
    questionnaireAnswers,
    'sections.demographicInformation.schema.employment_info.option[1].schema.employment_company.text'
  );
  let phone = get(
    questionnaireAnswers,
    'sections.demographicInformation.schema.employment_info.option[1].schema.employment_phone.text'
  );
  function isEmployed(value1, value2) {
    if (value1 === 'Full time' || value1 === 'Part time') {
      return value1;
    }
    return value2;
  }
  function addComma(value) {
    if (value != '') {
      return value + ', ';
    }
    return value;
  }
  let address1 = get(
    questionnaireAnswers,
    'sections.demographicInformation.schema.employment_info.option[1].schema.employment_address.text',
    ''
  );
  let city1 = get(
    questionnaireAnswers,
    'sections.demographicInformation.schema.employment_info.option[1].schema.employment_additinalAddress.cityStateZip.city',
    ''
  );
  let state1 = get(
    questionnaireAnswers,
    'sections.demographicInformation.schema.employment_info.option[1].schema.employment_additinalAddress.cityStateZip.state',
    ''
  );
  let zipCode1 = get(
    questionnaireAnswers,
    'sections.demographicInformation.schema.employment_info.option[1].schema.employment_additinalAddress.cityStateZip.zipCode',
    ''
  );
  let address = addComma(address1) + addComma(city1) + addComma(state1) + zipCode1;
  let address2 = get(questionnaireAnswers, 'sections.demographicInformation.schema.address.text', '');
  let city2 = get(
    questionnaireAnswers,
    'sections.demographicInformation.schema.additinalAddress.cityStateZip.city',
    ''
  );
  let state2 = get(
    questionnaireAnswers,
    'sections.demographicInformation.schema.additinalAddress.cityStateZip.state',
    ''
  );
  let zipCode2 = get(
    questionnaireAnswers,
    'sections.demographicInformation.schema.additinalAddress.cityStateZip.zipCode',
    ''
  );
  let address3 = addComma(address2) + addComma(city2) + addComma(state2) + zipCode2;

  return (
    <div id="page1" className="page" style={{ ...styles.root, fontFamily: 'Arial' }}>
      <Header pageNumber={1} pageCount={12} />
      <div style={getGridParentStyles(8, true)}>
        <div style={getGridHeaderChildStyles(8)}>
          <b>PATIENT INFO</b>
        </div>
        <MedicalPassportField
          name="procedure.patient.name"
          label="NAME"
          style={getGridChildStyles3(4)}
          path={['demographicInformation', 'name']}
        />
        <MedicalPassportField
          name="patientMaidenName"
          label="MAIDEN NAME"
          style={getGridChildStyles3(4)}
          path={['demographicInformation', 'maidenName']}
        />
        <MedicalPassportField
          name="gender"
          label="GENDER"
          style={getGridChildStyles3(2)}
          path={['demographicInformation', 'sex']}
          reducer={toHighlightsString}
        />
        <MedicalPassportField
          name="age"
          label="AGE"
          style={getGridChildStyles3(2)}
          path={['demographicInformation', 'dob']}
          reducer={toAge}
        />
        <MedicalPassportField
          name="dob"
          label="DOB"
          style={getGridChildStyles3(2)}
          path={['demographicInformation', 'dob']}
        />
        <MedicalPassportField
          name="ssn"
          label="SSN"
          style={getGridChildStyles3(2)}
          path={['demographicInformation', 'ssn']}
        />
        <MedicalPassportField
          name="email"
          label="E-mail"
          style={getGridChildStyles3(4)}
          path={['demographicInformation', 'email']}
        />
        <MedicalPassportField
          name="preferred"
          label="PREFERRED #"
          style={getGridChildStyles3(4)}
          path={['demographicInformation', preferred]}
        />
        <MedicalPassportField
          name="cellPhone"
          label="CELL #"
          style={getGridChildStyles3(3)}
          path={['demographicInformation', 'CellPhone']}
        />
        <MedicalPassportField
          name="homePhone"
          label="HOME #"
          style={getGridChildStyles3(2)}
          path={['demographicInformation', 'HomePhone']}
        />
        <MedicalPassportField
          name="workPhone"
          label="WORK #"
          style={getGridChildStyles3(3)}
          path={['demographicInformation', 'WorkPhone']}
        />
        <MedicalPassportField name="address" label="ADDRESS #" style={getGridChildStyles3(8)} value={address3} />
        <MedicalPassportField
          name="maritalStatus"
          label="MARITAL STATUS"
          style={getGridChildStyles3(3)}
          path={['demographicInformation', 'MaritalStatus']}
          reducer={toHighlightsString}
        />
        <MedicalPassportField
          name="spouse"
          label="SPOUSE / SIGNIFICANT OTHER NAME"
          style={getGridChildStyles3(5)}
          path={['demographicInformation', 'spouse']}
        />
        <MedicalPassportField
          name="race"
          label="RACE"
          style={getGridChildStyles3(3)}
          path={['demographicInformation', 'race']}
          reducer={toHighlightsString}
        />
        <MedicalPassportField
          name="ethnicity"
          label="ETHNICITY"
          style={getGridChildStyles3(2)}
          path={['demographicInformation', 'ethnicity']}
        />
        <MedicalPassportField
          name="religion"
          label="RELIGION"
          style={getGridChildStyles3(3)}
          path={['demographicInformation', 'religion']}
          reducer={toHighlightsString}
        />
        <MedicalPassportField
          name="primaryLanguage"
          label="PRIMARY LANGUAGE"
          style={getGridChildStyles3(4)}
          path={['demographicInformation', 'primaryLanguage']}
          reducer={toHighlightsString}
        />
        <MedicalPassportCheckbox
          name="requiresInterpreter"
          label="REQUIRES INTERPRETER"
          style={getGridChildStyles3(4)}
          path={['demographicInformation', 'interpreter']}
        />
        <MedicalPassportCheckbox
          name="advanceDirective"
          label="ADVANCE DIRECTIVE"
          style={getGridChildStyles3(8)}
          path={['demographicInformation', 'AdvanceDirective']}
        />
        <MedicalPassportCheckbox
          name="leaveMessageOnHomePhone"
          label="MAY WE LEAVE MESSAGE ON HOME PHONE?"
          style={getGridChildStyles3(4)}
          path={['demographicInformation', 'consent2', 'yes', 'consent2_home']}
        />
        <MedicalPassportCheckbox
          name="leaveMessageOnEmail"
          label="MAY WE E-MAIL A MESSAGE?"
          style={getGridChildStyles3(4)}
          path={['demographicInformation', 'consent2', 'yes', 'consent2_email']}
        />
        <MedicalPassportCheckbox
          name="leaveMessageToAnotherPerson"
          label="MAY WE LEAVE MESSAGE WITH ANOTHER PERSON?"
          style={getGridChildStyles3(4)}
          path={['demographicInformation', 'consent2', 'yes', 'consent2_person']}
        />
        <MedicalPassportField
          name="leaveMessageToAnotherPersonName"
          label="IF YES, THEIR NAME"
          style={getGridChildStyles3(4)}
          path={['demographicInformation', 'consent2', 'yes', 'consent2_person', 'yes']}
        />

        <div style={getGridHeaderChildStyles(8)}>
          <b>EMERGENCY CONTACT INFO</b>
        </div>
        <MedicalPassportField
          name="emergencyInfo.name"
          label="NAME"
          style={getGridChildStyles3(4)}
          path={['demographicInformation', 'emergencyInfo', 'yes', 'emergency_name']}
        />
        <MedicalPassportField
          name="employmentInfo.relationship"
          label="RELATIONSHIP"
          style={getGridChildStyles3(4)}
          path={['demographicInformation', 'emergencyInfo', 'yes', 'emergency_relationship']}
        />

        <MedicalPassportField
          name="emergencyInfo.phone"
          label="PHONE"
          style={getGridChildStyles3(8)}
          path={['demographicInformation', 'emergencyInfo', 'yes', 'emergency_phone']}
        />

        <div style={getGridHeaderChildStyles(8)}>
          <b>EMPLOYMENT INFO</b>
        </div>
        <MedicalPassportField
          name="employmentInfo.status"
          label="EMPLOYMENT STATUS"
          style={getGridChildStyles3(4)}
          value={isEmployed(employmentStatusEmployed, employmentStatusNotEmployed)}
        />
        <MedicalPassportField
          name="employmentInfo.occupation"
          label="OCCUPATION"
          style={getGridChildStyles3(4)}
          value={occupation}
        />

        <MedicalPassportField
          name="employmentInfo.company"
          label="COMPANY"
          style={getGridChildStyles3(5)}
          value={company}
        />
        <MedicalPassportField name="employmentInfo.phone" label="PHONE" style={getGridChildStyles3(3)} value={phone} />

        <MedicalPassportField
          name="employmentInfo.address"
          label="ADDRESS"
          style={getGridChildStyles3(8)}
          value={address}
        />

        <div style={getGridHeaderChildStyles(8)}>
          <b>INSURANCE</b>
        </div>
        <MedicalPassportTextArea name="procedure.name" label="PROCEDURE" style={getGridChildStyles3(8)} fontSize={12} />
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>

      <Footer pageNumber={1} pageCount={12} />
    </div>
  );
};

export default Page1;
