import toString from './toString';
import { capitalize } from 'lodash';

const toQuestionYesNo = toString.with({
  Object: fields => {
    const visibleFields = fields.filter(field => field.child);

    return visibleFields.length === 0
      ? null
      : visibleFields.length === 1
        ? visibleFields[0].child
        : visibleFields
            .map(field => field.child)
            .map(c => {
              const rest = c!.startsWith('· ') ? c!.substr(2) : c!;
              return '· ' + rest;
            })
            .join('\n');
  },
  YesNo: (question, positive, child) => {
    if (positive && child) {
      return capitalize(question.concat(': Yes (' + child + ')'));
    } else if (positive) {
      return capitalize(question.concat(': Yes'));
    } else {
      return capitalize(question.concat(': No'));
    }
  },
});

export default toQuestionYesNo;
