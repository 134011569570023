import { Divider, Paper, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import React from 'react';
import { valueNames } from '../PieCharts';
import pluralize from 'pluralize';

const CustomTooltipPhysician = ({ active, payload, label, unit, useTitle, decimal, physicians }) => {
  if (active && payload && payload.length) {
    return (
      <Paper>
        <Box p={2}>
          <Typography gutterBottom>{payload.name}</Typography>
          {payload.map((p, i) => (
            <>
              <Box key={i} display="flex" alignItems="center" style={{ gap: 12 }}>
                <Box width="1ch" height="1ch" borderRadius="100%" bgcolor={p.fill} />
                <Typography variant="subtitle2" style={{ width: '16ch' }}>
                  {useTitle ? p.payload.title : valueNames[p.dataKey]}
                </Typography>
                <Typography>
                  {decimal ? parseFloat(p.value).toFixed(1) : Math.round(p.value)}{' '}
                  {unit && unit !== '%'
                    ? Math.round(p.value) === 1
                      ? unit
                      : pluralize.plural(unit)
                    : unit === '%'
                      ? '%'
                      : 'm'}
                </Typography>
              </Box>
              <Divider />
              <ul>
                {p?.payload?.physicians?.map((physician, index) => (
                  <li key={index}>
                    <Box display="flex" alignItems="center" style={{ gap: 12 }}>
                      <Typography variant="body2" style={{ flex: 1 }}>
                        {physician.physician}
                      </Typography>{' '}
                      <Typography variant="body2">
                        {physician.count} {pluralize(unit, physician.count)}
                      </Typography>
                    </Box>
                    <Divider />
                  </li>
                ))}
              </ul>
            </>
          ))}
        </Box>
      </Paper>
    );
  }

  return null;
};

export default CustomTooltipPhysician;
