import React, { createContext, useContext, useState } from 'react';

const DictationTemplateContext = createContext<{
  value: string;
  onChange: (value: string) => void;
} | null>(null);

export const useDictationTemplate = () => useContext(DictationTemplateContext);

export const DictationTemplateProvider = ({
  children,
  defaultValue,
  onChangeValue,
}: {
  children?: React.ReactNode;
  defaultValue: string;
  onChangeValue: any;
}) => {
  const [value, setValue] = useState(defaultValue);

  const onChange = (v: string) => {
    setValue(v);
    onChangeValue(v);
  };

  return <DictationTemplateContext.Provider value={{ value, onChange }}>{children}</DictationTemplateContext.Provider>;
};
