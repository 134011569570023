import startOfDay from 'date-fns/start_of_day';
import isBefore from 'date-fns/is_before';
import isYesterday from 'date-fns/is_yesterday';
import distanceInWords from 'date-fns/distance_in_words';
import format from 'date-fns/format';
import { sortBy, sortDatesAsc, sortDatesDesc, sortNumberDesc } from '../../../se/utilities/data/array';
import differenceInSeconds from 'date-fns/difference_in_seconds';
import { isDefinedAndNotNull } from '../../../se/utilities/check';

export const sortById = sortBy('id');
export const sortByCreatedAt = sortBy('createdAt');

export const sortByIdDesc = sortById(sortNumberDesc);
export const sortByCreatedAtAsc = sortByCreatedAt(sortDatesAsc);
export const sortByCreatedAtDesc = sortByCreatedAt(sortDatesDesc);

const subString = (word, c) => word.slice(0, c);

const truncateWord = (word, parts, idx, lastNameChars, fullFistName) => {
  if (idx === 0 && fullFistName) {
    return [word, ' '].join('');
  } else if (parts - 1 === idx) {
    return [subString(word, lastNameChars).toUpperCase(), '.'].join('');
  } else {
    return [subString(word, 1).toUpperCase(), '.'].join('');
  }
};

export const getInitials = (name, lastNameChars, fullFistName, fullLastName) => {
  let words = name.match(/[\u0041-\u005A\u0100-\u024F\u0400-\u052F]+/gim) || [];
  if (fullLastName && words.length > 1) {
    const [last, ...rest] = words.reverse();
    words = [last, ...rest.reverse()];
  }
  return words.map((word, i) => truncateWord(word, words.length, i, lastNameChars, fullFistName)).join('');
};

export const formatTimeEntered = date => {
  const today = startOfDay(new Date());
  const notToday = isBefore(date, today);
  const yesterday = isYesterday(date);

  if (yesterday) {
    return 'yesterday';
  } else if (notToday) {
    return distanceInWords(today, date, { addSuffix: true });
  }

  return ` at ${format(date, `HH:mm`)}`;
};

export const formatTimeDifference = seconds => {
  const days = Math.floor(seconds / 86400);
  const hours = Math.floor((seconds - days * 86400) / 3600);
  const minutes = Math.floor((seconds - days * 86400 - hours * 3600) / 60);
  return [
    [days, 'd'],
    [hours, 'h'],
    [minutes, 'm'],
  ].reduce((acc, [val, suffix]) => (val > 0 ? `${acc}${acc === '' ? '' : ' '}${val}${suffix}` : acc), '');
};

export const calculateDuration = (startAt, endAt) =>
  formatTimeDifference(differenceInSeconds(endAt || Date.now(), startAt));

export const calculateDurationInMinutes = (startAt, endAt) =>
  Math.round(differenceInSeconds(endAt || Date.now(), startAt) / 60);

export const getRoom = room =>
  isDefinedAndNotNull(room)
    ? room
    : {
        id: null,
        name: 'Unknown',
      };
