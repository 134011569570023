import React, { FC } from 'react';
import { Procedure as ProcedureT } from '../../../../../../types/Procedure';
import get from 'lodash/get';
import { Anesthesiologist, Physician } from '../../anesthesiologist/Personnel';
import { Bed, PatientNameWithSession } from '../../Patient';
import { PatientStat } from '../../shared/PatientStat';
import { getColor } from '../Procedure';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const Label = ({ text, backgroundColor }: { text: string; backgroundColor: string }) => (
  <Box borderRadius={4} bgcolor={backgroundColor} style={{ fontWeight: 'bold', color: 'white' }} px={0.5}>
    {text}
  </Box>
);

const CardCompactHeader: FC<{
  procedure: ProcedureT;
  physicianName?: string;
  anesthesiologistName?: string;
  anesthesiaType?: string;
  color: string;
  status: string;
  showBedNumber: boolean;
  inOrStatus: string;
  hospitalId?: number;
  isKiosk?: boolean;
}> = ({
  procedure,
  physicianName,
  anesthesiologistName,
  anesthesiaType,
  color,
  status,
  showBedNumber,
  inOrStatus,
  hospitalId,
  isKiosk,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.header}>
      <Box className={classes.row}>
        <Physician physicianName={physicianName} color={color} inline />
        <Box className={classes.info}>
          <Anesthesiologist
            procedureId={procedure.id}
            anesthesiologistName={anesthesiologistName}
            anesthesiaType={anesthesiaType}
            inline
            style={{ fontWeight: 400 }}
          />
          <PatientStat inline right>
            {/* {isCanceled ? 'Canceled' : noShow ? 'NoShow' : status} */}
            <Label backgroundColor={getColor(status, hospitalId) || ''} text={inOrStatus ? inOrStatus : status} />
            {showBedNumber && !!procedure?.patient?.bed && <Bed bed={procedure?.patient?.bed} />}
          </PatientStat>
        </Box>
      </Box>
      <Box className={classes.secondRow}>
        <PatientNameWithSession
          id={procedure?.patient?.id}
          name={
            isKiosk
              ? procedure?.patient?.name || procedure?.patientName
              : procedure?.patient?.name
                  ?.split(' ')
                  .map(n => n[0])
                  .join('.') ||
                procedure?.patientName
                  ?.split(' ')
                  .map(n => n[0])
                  .join('')
          }
          age={get(procedure, 'patientAge')}
          sex={get(procedure, 'patientSex')}
          hospitalId={hospitalId}
          isTimeline
          inline
          isKiosk
        />
        <Box className={classes.procedure}>{procedure?.procedureType?.replaceAll('\n', ' ')}</Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    lineHeight: 'normal',
    paddingLeft: '0.375em',
    height: '100%',
    gap: '0.25em',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    '& > *': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },
  secondRow: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.75),
  },
  procedure: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'block',
  },
}));

export default CardCompactHeader;
