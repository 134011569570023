import { graphql } from '@apollo/client/react/hoc';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { compose, mapProps, withProps } from 'recompose';
import { Link, withRouter } from 'react-router-dom';
import { distanceInWords } from 'date-fns';
import { getNestedValue } from '@/se/utilities/data/object';
import GraphQLCRUD from '../../../se/components/GraphQLCRUD';
import Modal from '../../../se/components/Modal';
import RouterLink from '../../../se/components/RouterLink';
import { TruncatedText } from '@/se/components/typography';
import { isArray, isObject } from '@/se/utilities/check';
import AutoSubmittingForm from '../../../se/components/AutoSubmittingForm';
import { sortBy, sortUndefinedValuesFirst } from '@/se/utilities/data/array';

import scheme, { approveScreen, removeScreen } from '../../../graph/screens';
import { list as roomList } from '../../../graph/rooms';
import { OR, ROOM_TYPE_LABELS, ROOM_TYPES } from '../room/enums';
import {
  ANALYTICS,
  CHARTING_TABLET,
  EXIT_DISPLAY,
  INTERNAL_MONITOR,
  INTERNAL_MONITOR_LABELS,
  OPERATION_ROOM_MONITOR,
  PACU_TABLET,
  POST_OP_TABLET,
  PREP_TABLET,
  REVIEW_DISPLAY,
  SCHEDULE_MONITOR,
  WAITING_ROOM_MONITOR,
  WAITING_ROOM_TABLET,
  HOSPITAL_OVERVIEW,
} from './enums';
import AssignMonitoringScreenInput from '../../inputs/screen/AssignMonitoringScreenInput';
import { unpackSessionObject } from '../../pages/unpackSessionObject';
import ScreenConfig from './ScreenConfig';
import Tooltip from '../../Tooltip';
import { withSession } from '@/state/Session';
import ScreenMobileListItem from './ScreenMobileListItem';
import { DefaultTitle } from '@/se/components/entity/EntityRouter';
import pluralize from 'pluralize';
import { titlecase } from 'stringcase';
import { fade, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Alert } from '@material-ui/lab';
import config from '../../../config';
import { withScope } from '@/contexts/ScopeContext';

const Status = withStyles(theme => ({
  root: {
    width: '.875rem',
    height: '.875rem',
    borderRadius: '50%',
    marginRight: theme.spacing(1),
    backgroundColor: props => (props.active ? theme.palette.success.main : fade(theme.palette.text.disabled, 0.25)),
  },
}))(Box);

export const Address = withStyles(theme => ({
  root: {
    display: 'inline-block',
    backgroundColor: theme.palette.grey.A100,
    borderRadius: theme.shape.borderRadius,
    fontFamily: 'monospace',
    fontWeight: 'bold',
    padding: theme.spacing(0.5, 1),
    margin: theme.spacing(-0.5, 0.5),
    userSelect: 'all',
  },
}))(Box);

const mapOperationRooms = props => ({
  ...props,
  operationRooms: isArray(getNestedValue('data.rooms', props)) ? props.data.rooms.filter(room => room.type === OR) : [],
  pacuRooms: isArray(getNestedValue('data.rooms', props))
    ? props.data.rooms.filter(room => room.type === ROOM_TYPES.PACU)
    : [],
  prepRooms: isArray(getNestedValue('data.rooms', props))
    ? props.data.rooms.filter(room => room.type === ROOM_TYPES.PRE_OP)
    : [],
  postOpRooms: isArray(getNestedValue('data.rooms', props))
    ? props.data.rooms.filter(room => room.type === ROOM_TYPES.POST_OP)
    : [],
});

const mapAssignOptions = props => ({
  ...props,
  options: [
    {
      value: { type: INTERNAL_MONITOR },
      label: INTERNAL_MONITOR_LABELS[INTERNAL_MONITOR],
    },
    {
      value: { type: WAITING_ROOM_MONITOR },
      label: INTERNAL_MONITOR_LABELS[WAITING_ROOM_MONITOR],
    },
    {
      value: { type: SCHEDULE_MONITOR },
      label: INTERNAL_MONITOR_LABELS[SCHEDULE_MONITOR],
    },
    ...(getNestedValue('operationRooms', props) || []).reduce(
      (acc, or) => [
        ...acc,
        {
          value: { type: OPERATION_ROOM_MONITOR, room: or.id },
          label: `${INTERNAL_MONITOR_LABELS[OPERATION_ROOM_MONITOR]} - ${or.name}`,
        },
      ],
      []
    ),
    ...(getNestedValue('prepRooms', props) || []).reduce(
      (acc, prepRooms) => [
        ...acc,
        {
          value: { type: PREP_TABLET, room: prepRooms.id },
          label: `${INTERNAL_MONITOR_LABELS[PREP_TABLET]} - ${prepRooms.name}`,
        },
      ],
      []
    ),
    ...(getNestedValue('pacuRooms', props) || []).reduce(
      (acc, pacuRooms) => [
        ...acc,
        {
          value: { type: PACU_TABLET, room: pacuRooms.id },
          label: `${INTERNAL_MONITOR_LABELS[PACU_TABLET]} - ${pacuRooms.name}`,
        },
      ],
      []
    ),
    ...(props?.scope?.hospital?.modules?.hasPostop
      ? (getNestedValue('postOpRooms', props) || []).reduce(
          (acc, postOpRooms) => [
            ...acc,
            {
              value: { type: POST_OP_TABLET, room: postOpRooms.id },
              label: `${INTERNAL_MONITOR_LABELS[POST_OP_TABLET]} - ${postOpRooms.name}`,
            },
          ],
          []
        )
      : []),
    {
      value: { type: CHARTING_TABLET },
      label: INTERNAL_MONITOR_LABELS[CHARTING_TABLET],
    },
    {
      value: { type: REVIEW_DISPLAY },
      label: INTERNAL_MONITOR_LABELS[REVIEW_DISPLAY],
    },
    {
      value: { type: EXIT_DISPLAY },
      label: INTERNAL_MONITOR_LABELS[EXIT_DISPLAY],
    },
    {
      value: { type: WAITING_ROOM_TABLET },
      label: INTERNAL_MONITOR_LABELS[WAITING_ROOM_TABLET],
    },
    {
      value: { type: ANALYTICS },
      label: INTERNAL_MONITOR_LABELS[ANALYTICS],
    },
    {
      value: { type: HOSPITAL_OVERVIEW },
      label: INTERNAL_MONITOR_LABELS[HOSPITAL_OVERVIEW],
    },
  ],
});

const ScreenAddress = ({ organizationId }) => <Address>{`${config.publicURL}/kiosk/${organizationId}`}</Address>;

const withOrganizationId = Component =>
  compose(
    withSession(unpackSessionObject),
    withRouter,
    withProps(props => ({
      organizationId: props.isSuperAdmin ? props.match.params['organizationId'] : props.organization,
    }))
  )(Component);

const ScreenAddressHint = withOrganizationId(({ organizationId }) => (
  <Alert severity="info">
    {'Configure one of the displays to open '}
    <ScreenAddress organizationId={organizationId} />
    {' to set up a screen.'}
  </Alert>
));

const Empty = {
  illustration: theme => theme.illustrations.waiting,
  hint: <ScreenAddressHint />,
};

const screenStatusContent = (active, clientVersion, lastDisconnectedAt) =>
  active
    ? `Client version: ${(clientVersion || 'Unknown').substring(0, 8)}`
    : `Last Reported: ${
        lastDisconnectedAt ? distanceInWords(new Date(), lastDisconnectedAt, { addSuffix: true }) : 'Never'
      }`;

const ScreenStatus = ({ type, active, clientVersion, lastDisconnectedAt }) => (
  <Box display="flex" alignItems="center">
    <Tooltip content={screenStatusContent(active, clientVersion, lastDisconnectedAt)}>
      <Status active={active ? 1 : 0} />
    </Tooltip>

    <TruncatedText>
      {[
        INTERNAL_MONITOR,
        WAITING_ROOM_MONITOR,
        SCHEDULE_MONITOR,
        PACU_TABLET,
        PREP_TABLET,
        POST_OP_TABLET,
        CHARTING_TABLET,
        REVIEW_DISPLAY,
        EXIT_DISPLAY,
        WAITING_ROOM_TABLET,
        ANALYTICS,
        HOSPITAL_OVERVIEW,
      ].includes(type.type)
        ? INTERNAL_MONITOR_LABELS[type.type]
        : type.type === OPERATION_ROOM_MONITOR
          ? getNestedValue('room.name', type) || ROOM_TYPE_LABELS.OR
          : 'Unknown monitoring screen'}
    </TruncatedText>
  </Box>
);
const AssignAction = withRouter(({ match, data }) => (
  <RouterLink to={`${match.url}/assign/${data.code}`}>Assign</RouterLink>
));

const ConfigAction = withRouter(({ match, data }) => (
  <Box my={-1}>
    <Button color="primary" component={Link} to={`${match.url}/configure/${data.code}`}>
      Configure
    </Button>
  </Box>
));

const AssignScreen = ({ match, history, baseUrl, options, approveScreen }) => {
  const screenId = getNestedValue('params.id', match);
  const handleSubmit = value => {
    approveScreen({ variables: { screenCode: screenId, screenType: value } });
    history.push(baseUrl);
  };

  const Input = ({ onChange, value }) => (
    <AssignMonitoringScreenInput
      value={value}
      name={'assign-monitoring-screen'}
      getOptionValue={value => (isObject(value) ? `${value.type}${value.room ? value.room : ''}` : undefined)}
      options={options}
      onChange={onChange}
    />
  );

  return (
    <Modal title="Assign Screen" closeTo={baseUrl}>
      <AutoSubmittingForm input={Input} onSubmit={handleSubmit} />
    </Modal>
  );
};

const RemoveScreen = ({ data, removeScreen }) => (
  <Box my={-1}>
    <Button
      color="primary"
      onClick={() =>
        window.confirm('Deleting a screen is permanent. Proceed?') && removeScreen({ variables: { screenCode: data } })
      }
    >
      Remove
    </Button>
  </Box>
);

const Title = ({ entityName, isEmpty }) => (
  <Box display="flex" alignItems="center">
    <Box mr={2}>
      <DefaultTitle>{titlecase(pluralize(entityName))}</DefaultTitle>
    </Box>
    {!isEmpty && <ScreenAddressHint />}
  </Box>
);

export default GraphQLCRUD({
  entityName: 'Screen',
  scheme,
  List: {
    Title,
    tableKey: 'Screens',
    MobileItemComponent: ScreenMobileListItem,
    columns: [
      {
        key: 'code',
        lens: item => item.code,
        Component: props => <Typography>{props.data}</Typography>,
      },
      {
        key: 'connection',
        lens: item => item,
        Component: props => {
          const connection = getNestedValue('data.connection', props);
          const clientVersion = getNestedValue('data.connection.clientVersion', props);

          return connection ? (
            <ScreenStatus {...connection} clientVersion={clientVersion} />
          ) : (
            <AssignAction {...props} />
          );
        },
      },
      {
        key: 'configuration.description',
        lens: item => getNestedValue('configuration.description', item),
        Component: ({ data }) => <TruncatedText>{data}</TruncatedText>,
      },
      {
        key: 'data.connection',
        lens: item => item,
        Component: props => {
          const connection = getNestedValue('data.connection', props);
          return connection && <ConfigAction {...props} />;
        },
      },
      {
        key: 'removeScreen',
        lens: item => item.code,
        Component: compose(graphql(removeScreen, { name: 'removeScreen' }))(RemoveScreen),
      },
    ],
    Empty,
    additionalRoutes: [
      {
        key: 'assign-screen',
        path: '/assign/:id',
        Component: compose(
          graphql(roomList),
          withScope,
          mapProps(mapOperationRooms),
          mapProps(mapAssignOptions),
          graphql(approveScreen, { name: 'approveScreen' })
        )(AssignScreen),
      },
      {
        key: 'config-screen',
        path: '/configure/:id',
        Component: ScreenConfig,
      },
    ],
    hideColumns: true,
    mapListProps: ({ data, getList, entityNamePlural }) => ({
      data: {
        ...data,
        [entityNamePlural]: (getList(data) || []).sort(sortBy('connection')(sortUndefinedValuesFirst)),
      },
    }),
  },
});
