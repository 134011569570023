import React, { useMemo, useEffect, useState, useRef } from 'react';
import Header from './components/Header';
import MedicalPassportTextArea from '../components/MedicalPassportTextArea';
import MedicationTextArea from '../components/MedicationTextArea';
import MedicalPassportField from '../components/MedicalPassportField';
import MedicalPassportCheckbox from '../components/MedicalPassportCheckbox';
import styles from '../styles';
import toHighlightsString from '../../../../../questionnaire/toHighlightsString';
import toHighlightsString2 from '../../../../../questionnaire/toHighlightsString2';
import {
  cardiovascular,
  endocrine,
  gastrointestinal,
  gynaecological,
  hematological,
  hepatic,
  musculoskeletal,
  neurological,
  other,
  psychiatric,
  pulmonary,
  renal,
  derm,
  infectious,
  liver,
  assist,
  substance,
  ob,
} from './paths';
import toAge from '../../../../../questionnaire/toAge';
import toHeight from '../../../../../questionnaire/toHeight';
import toWeight from '../../../../../questionnaire/toWeight';
import toBMI from '../../../../../questionnaire/toBMI';
import toWeightLbs from '../../../../../questionnaire/toWeightLbs';
import get from 'lodash/get';
import { useValue } from '../ValueContext';

import toHighlightsString4 from '../../../../../questionnaire/toHighlightsString4';

export const ReviewofSystemWrapper = ({ label, value }) => (
  <div
    style={{
      lineHeight: '12px',
      borderLeft: 0,
      borderRight: 0,
      borderTop: 0,
    }}
  >
    <div style={styles.systemrow2}>
      <div
        style={{
          borderRight: '1px solid black',
          paddingLeft: '5px',
          paddingTop: '3px',
          fontSize: '12px',
          width: '95px',
        }}
      >
        {label}
      </div>
      <div
        style={{
          display: 'flex',
          padding: '1px',
        }}
      >
        <MedicalPassportTextArea
          name={label + 'name'}
          paths={value}
          style={{ width: '100%', minHeight: '33px' }}
          fontSize={'11px'}
          reducer={toHighlightsString2}
        />
      </div>
    </div>
  </div>
);

const Page7 = () => {
  const { questionnaireAnswers } = useValue();

  function yesOrNo(value) {
    if (value === true) {
      return 'yes';
    } else if (value === false) {
      return 'no';
    }
    return '';
  }
  let patientProblem = get(
    questionnaireAnswers,
    'sections.PreProcedureAssessment.schema.associatedProblem.yes[1].schema.associatedProblem_text2.text',
    ''
  );

  let familyProblem = get(
    questionnaireAnswers,
    'sections.PreProcedureAssessment.schema.associatedProblem.yes[1].schema.associatedProblem_text.text',
    ''
  );

  const medicationList = get(
    questionnaireAnswers,
    'sections.medications.schema.medicationList.yes[1].schema.medication_list.list',
    []
  );

  // allergy
  const allergyList = get(questionnaireAnswers, 'sections.allergies.schema.q1.yes[1].schema.allergy_list.list', []);
  const allergyList2 = get(questionnaireAnswers, 'sections.allergies.schema.q2.yes[0]', false);
  const allergyList3 = get(questionnaireAnswers, 'sections.allergies.schema.q3.yes[0]', false);

  const allergyName = [];
  const reaction = [];

  for (let i = 0; i < allergyList.length; i++) {
    allergyName.push(get(allergyList[i], 'schema.q1_name.text', ''));
    reaction.push(get(allergyList[i], 'schema.q1_reaction.text', ''));
  }

  if (allergyList2) {
    allergyName.push('Latex');
    reaction.push(get(questionnaireAnswers, 'sections.allergies.schema.q2.yes[1].schema.q2_reaction.text', ''));
  }

  if (allergyList3) {
    allergyName.push(get(questionnaireAnswers, 'sections.allergies.schema.q3.yes[1].schema.q3_name.text', ''));
    reaction.push(get(questionnaireAnswers, 'sections.allergies.schema.q3.yes[1].schema.q3_reaction.text', ''));
  }

  function combineArrays(arr1, arr2) {
    let result = '';

    for (let i = 0; i < arr1.length && i < arr2.length; i++) {
      result += arr1[i] + ', ' + arr2[i] + '\n';
    }

    return result;
  }

  const pageRef = useRef(null);
  const [pageStyle, setPageStyle] = useState({});

  useEffect(() => {
    const handleResize = () => {
      if (pageRef.current) {
        const pageHeight = pageRef.current.offsetHeight;
        if (pageHeight > 1086) {
          setPageStyle({ minHeight: 2150 });
        } else {
          setPageStyle({ minHeight: 1085 });
        }
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      id="page7"
      className="page"
      style={{ fontFamily: 'Arial', fontSize: '12px', ...pageStyle, paddingTop: '0', paddingBottom: '0' }}
    >
      <div ref={pageRef}>
        <section>
          <Header pageNumber={7} pageCount={12} />
          <h2>Pre Operative Anesthesia Assessment</h2>
          <div style={styles.twoNotEqualColumns}>
            <div>
              <div style={{ ...styles.twoNotEqualColumns1, border: '1px solid black' }}>
                <div>Planned Procedure</div>
                <MedicalPassportTextArea name="procedure.name" fontSize={12} />
              </div>
              <div style={{ ...styles.sixEqualColumns, borderTop: '0', fontSize: '11px' }}>
                <MedicalPassportField
                  label="Age"
                  name="page5.age"
                  path={['demographicInformation', 'dob']}
                  reducer={toAge}
                  fontSize={11}
                />
                <MedicalPassportField
                  label="Sex"
                  name="page5.sex"
                  path={['demographicInformation', 'sex']}
                  reducer={toHighlightsString}
                  fontSize={11}
                />
                <MedicalPassportField
                  label="Ht"
                  name="page5.Ht"
                  path={['demographicInformation', 'bmi']}
                  reducer={toHeight}
                  fontSize={11}
                />
                <MedicalPassportField
                  label="Wt"
                  name="page5.Wt"
                  path={['demographicInformation', 'bmi']}
                  reducer={toWeightLbs}
                  fontSize={10}
                />
                <MedicalPassportField
                  label="KG"
                  name="page5.KG"
                  path={['demographicInformation', 'bmi']}
                  reducer={toWeight}
                  fontSize={11}
                />
                <MedicalPassportField
                  label="BMI"
                  name="page5.BMI"
                  path={['demographicInformation', 'bmi']}
                  reducer={toBMI}
                  fontSize={11}
                />
              </div>
              <div style={{ fontSize: '.75rem' }}>Printed data provided through online survey:</div>
            </div>
            <div>
              <div
                style={{
                  borderTop: '1px solid black',
                  borderLeft: '1px solid black',
                  borderRight: '1px solid black',
                  fontSize: '.8rem',
                }}
              >
                <MedicalPassportField label="Patient Name" name="procedure.patient.name" fontSize={12} />
                <MedicalPassportField
                  label="DOB"
                  name="page5.DOB"
                  path={['demographicInformation', 'dob']}
                  fontSize={12}
                />
                <MedicalPassportField label="Physician" name="procedure.physician.name" fontSize={12} />
                <MedicalPassportField label="DOS" name="procedure.serviceTime" fontSize={12} />
              </div>
            </div>
          </div>
          <div style={styles.twoNotEqualColumns}>
            <div>
              <div style={styles.greyBackground}>History of Anesthesia Complications</div>
              <div style={styles.oneColumnLeftAlign}>
                <div style={{ display: 'grid', gridTemplateColumns: '13% 87%' }}>
                  Patient:
                  <MedicationTextArea name="page7.anesthesiaPatientName" value={patientProblem} />
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: '24% 76%' }}>
                  Family Member:
                  <MedicationTextArea name="page7.anesthesiaFamilyMember" value={familyProblem} />
                </div>
              </div>
              <div style={styles.greyBackground}>Review of Systems</div>
              <section>
                <div
                  style={{
                    border: '1px solid black',
                    lineHeight: '1.8em',
                    borderLeft: 0,
                    borderRight: 0,
                  }}
                >
                  <ReviewofSystemWrapper label="Cardiovascular" value={cardiovascular} />
                  <ReviewofSystemWrapper label="Pulmonary" value={pulmonary} />
                  <ReviewofSystemWrapper label="Renal" value={renal} />
                  <ReviewofSystemWrapper label="Hepatic" value={liver} />
                  <ReviewofSystemWrapper label="Neurological" value={neurological} />
                  <ReviewofSystemWrapper label="Gastrointestinal" value={gastrointestinal} />
                  <ReviewofSystemWrapper label="Hematological" value={hematological} />
                  <ReviewofSystemWrapper label="Endocrine / Metabolic" value={endocrine} />
                  <ReviewofSystemWrapper label="Musculo-skeletal" value={musculoskeletal} />
                  <ReviewofSystemWrapper label="Psychiatric" value={psychiatric} />
                  <ReviewofSystemWrapper label="OB/Gyn" value={ob} />
                  <ReviewofSystemWrapper label="Coronavirus" value={infectious} />
                  <ReviewofSystemWrapper label="ENT / Dermatological" value={derm} />
                  <ReviewofSystemWrapper label="Substance Use" value={substance} />
                  <ReviewofSystemWrapper label="Other MRSA, glasses, contacts, assistive devices" value={assist} />
                </div>
              </section>
            </div>
            <div style={{ paddingLeft: '5px' }}>
              <div style={styles.greyBackground}>Allergies</div>
              <div style={{ fontSize: '11px' }}>
                <MedicationTextArea label="" name="allergies1" value={combineArrays(allergyName, reaction)} />
              </div>
              <div style={styles.greyBackground}>Current Medications</div>
              <div>
                <MedicalPassportTextArea
                  label={''}
                  name="medications1"
                  path={['medications']}
                  reducer={toHighlightsString}
                  fontSize={11}
                />
              </div>
              <div style={{ pageBreakInside: 'avoid' }}>
                <div style={{ ...styles.greyBackground }}>Previous Surgical History</div>
                <div>
                  <MedicalPassportTextArea
                    label=""
                    name="anesthesia1"
                    path={['priorSurgeries']}
                    reducer={toHighlightsString}
                    fontSize={11}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section style={{ marginBottom: '20px', breakInside: 'avoid' }}>
          <div style={styles.greyBackground}>Physical Exam / Labs / Anesthesia Plan</div>
          <div style={styles.twoNotEqualColumns4}>
            <div>
              <div style={styles.threeEqualColumns3}>
                <div style={{ borderRight: '1px solid black' }}>
                  <strong>
                    <u>Neuro</u>
                  </strong>
                  <MedicalPassportCheckbox name="page6.oriented" label="Oriented" />
                </div>
                <div style={{ borderRight: '1px solid black' }}>
                  <strong>
                    <u>Cardiac</u>
                  </strong>
                  <MedicalPassportCheckbox name="page6.normalRhythm" label="Normal Rhythm" />
                  <MedicalPassportCheckbox name="page6.noMurmurs" label="No Murmurs" />
                </div>
                <div style={{ borderRight: '1px solid black' }}>
                  <strong>
                    <u>Pulmonary</u>
                  </strong>
                  <MedicalPassportCheckbox name="page6.clear" label="Clear to Auscultation Wheezes/Rhonchi/Rales" />
                </div>
              </div>
              <div>
                <div style={styles.twoNotEqualColumnsNoBorder}>
                  <div style={{ borderTop: '1px solid black', borderRight: '1px solid black', paddingRight: '10px' }}>
                    <strong>
                      <u>EKG</u>
                    </strong>
                  </div>
                  <div style={{ borderTop: '1px solid black', borderRight: '1px solid black', paddingRight: '10px' }}>
                    <strong>
                      <u>Labs</u>
                    </strong>
                    <div style={styles.threeNotEqualColumns}>
                      <MedicalPassportField label="Na" name="page7.Na" fontSize={10} />
                      <MedicalPassportField label="Hgb" name="page7.Hgb" fontSize={10} />
                      <MedicalPassportField label="Accucheck" name="page7.Accucheck" fontSize={10} />
                    </div>

                    <div style={styles.threeEqualColumns3}>
                      <MedicalPassportField label="K" name="page7.k" fontSize={10} />
                      <MedicalPassportField label="Hct" name="page7.Hct" fontSize={10} />
                    </div>
                    <div style={styles.threeEqualColumns3}>
                      <MedicalPassportField label="Cr" name="page7.Cr" fontSize={10} />
                      <MedicalPassportField label="PLT" name="page7.PLT" fontSize={10} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <strong>
                <u>Airway</u>
              </strong>
              <MedicalPassportField label="Class" name="page7.Class" fontSize={12} />
              <div style={{ display: 'flex' }}>
                OralOpening
                <MedicalPassportCheckbox name="page7.NormalAir" label="Normal" />
              </div>
              Dentition
              <div style={{ display: 'flex' }}>
                <MedicalPassportCheckbox name="page7.normalDentition" label="Normal" />
                <MedicalPassportCheckbox name="page7.dentures" label="Dentures" />
              </div>
              <MedicalPassportCheckbox name="page7.chipped" label="Chipped / Broken" />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Page7;
