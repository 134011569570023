import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { analyticsConfigMutation, analyticsConfigQuery } from '../../../../graph/dashboard';
import { useScope } from '../../../../hooks/useScope';
import toArray from 'lodash/toArray';
import get from 'lodash/get';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import { Box, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import AnalyticsConfigInputV1 from './AnalyticsConfigInputV1';
import AnalyticsConfigInputV2, {
  AverageCleaningTimeConfiguration,
  AveragePreOpTimeConfiguration,
  AverageReadyForAnesthesiaToReadyForORConfiguration,
  AverageReadyForORtoPreOpExitConfiguration,
  AverageWaitingRoomTimeConfiguration,
  TotalPatientsReceivedConfiguration,
  AverageORTimeConfiguration,
  AverageClosingTimeConfiguration,
  AveragePopiTimeConfiguration,
  AveragePacuTimeConfiguration,
  AveragePostOpTimeConfiguration,
} from './AnalyticsConfigInputV2';
import { CenteredSpinner } from '../../../../se/components/Spinner';
import Form from '../../../../se/components/Form';
import useModal from '../../../../hooks/useModal';

interface ConfigurationProps {
  title?: string;
  configType?: 'totalPatientsReceived' | 'averageWaitingRoomTime';
  size?: 'normal' | 'small';
}

const Configuration = ({ title, configType, size = 'normal' }: ConfigurationProps) => {
  const { open, openModal, closeModal } = useModal();
  const [mutate] = useMutation(analyticsConfigMutation);
  const { data, loading } = useQuery(analyticsConfigQuery);
  const scope = useScope();
  const analyticsV2 = scope?.hospital?.modules?.analyticsV2;

  const handleSubmit = async values => {
    const monthlyReportRecipients = toArray(get(values, 'monthlyReportRecipients'));
    const weeklyReportRecipients = toArray(get(values, 'weeklyReportRecipients'));

    const totalPatientsThreshold = get(values, 'totalPatientsThreshold');
    const waitingRoomTimeThreshold = get(values, 'waitingRoomTimeThreshold');
    const preopTimeThreshold = get(values, 'preopTimeThreshold');
    const readyForAnesthesiaThreshold = get(values, 'readyForAnesthesiaThreshold');
    const readyForORThreshold = get(values, 'readyForORThreshold');
    // const orUtilThreshold = get(values, 'orUtilThreshold');
    const orTimeThreshold = get(values, 'orTimeThreshold');
    const pacuTimeThreshold = get(values, 'pacuTimeThreshold');
    const cleaningTimeThreshold = get(values, 'cleaningTimeThreshold');
    const closingTimeThreshold = get(values, 'closingTimeThreshold');
    const popiTimeThreshold = get(values, 'popiTimeThreshold');
    const postOpTimeThreshold = get(values, 'postOpTimeThreshold');
    const pipoMinThreshold = get(values, 'pipoMinThreshold');
    const pipoMaxThreshold = get(values, 'pipoMaxThreshold');
    const popiMinThreshold = get(values, 'popiMinThreshold');
    const popiMaxThreshold = get(values, 'popiMaxThreshold');
    const wrMinThreshold = get(values, 'wrMinThreshold');
    const wrMaxThreshold = get(values, 'wrMaxThreshold');
    const preOpMinThreshold = get(values, 'preOpMinThreshold');
    const preOpMaxThreshold = get(values, 'preOpMaxThreshold');
    const cleaningMinThreshold = get(values, 'cleaningMinThreshold');
    const cleaningMaxThreshold = get(values, 'cleaningMaxThreshold');
    const pacuMinThreshold = get(values, 'pacuMinThreshold');
    const pacuMaxThreshold = get(values, 'pacuMaxThreshold');
    const postOpMinThreshold = get(values, 'postOpMinThreshold');
    const postOpMaxThreshold = get(values, 'postOpMaxThreshold');
    const preOpToDischargedMinThreshold = get(values, 'preOpToDischargedMinThreshold');
    const preOpToDischargedMaxThreshold = get(values, 'preOpToDischargedMaxThreshold');
    const inOrMinThreshold = get(values, 'inOrMinThreshold');
    const inOrMaxThreshold = get(values, 'inOrMaxThreshold');
    const inSurgeryMinThreshold = get(values, 'inSurgeryMinThreshold');
    const inSurgeryMaxThreshold = get(values, 'inSurgeryMaxThreshold');
    const closingMinThreshold = get(values, 'closingMinThreshold');
    const closingMaxThreshold = get(values, 'closingMaxThreshold');
    const procedureCompleteMinThreshold = get(values, 'procedureCompleteMinThreshold');
    const procedureCompleteMaxThreshold = get(values, 'procedureCompleteMaxThreshold');
    const orReadyMinThreshold = get(values, 'orReadyMinThreshold');
    const orReadyMaxThreshold = get(values, 'orReadyMaxThreshold');
    const piToStartMinThreshold = get(values, 'piToStartMinThreshold');
    const piToStartMaxThreshold = get(values, 'piToStartMaxThreshold');
    const poToCutMinThreshold = get(values, 'poToCutMinThreshold');
    const poToCutMaxThreshold = get(values, 'poToCutMaxThreshold');
    const startToCloseMinThreshold = get(values, 'startToCloseMinThreshold');
    const startToCloseMaxThreshold = get(values, 'startToCloseMaxThreshold');
    const physicianPopiMinThreshold = get(values, 'physicianPopiMinThreshold');
    const physicianPopiMaxThreshold = get(values, 'physicianPopiMaxThreshold');
    const fcotsGracePeriod = get(values, 'fcotsGracePeriod');
    const piToPhyiMinThreshold = get(values, 'piToPhyiMinThreshold');
    const piToPhyiMaxThreshold = get(values, 'piToPhyiMaxThreshold');
    const closingCompleteToPatientOutMinThreshold = get(values, 'closingCompleteToPatientOutMinThreshold');
    const closingCompleteToPatientOutMaxThreshold = get(values, 'closingCompleteToPatientOutMaxThreshold');
    const samePhysicianORTurnover = get(values, 'samePhysicianORTurnover');

    const result = await mutate({
      variables: {
        monthlyReportRecipients,
        weeklyReportRecipients,
        totalPatientsThreshold,
        waitingRoomTimeThreshold,
        preopTimeThreshold,
        readyForAnesthesiaThreshold,
        readyForORThreshold,
        // orUtilThreshold,
        orTimeThreshold,
        pacuTimeThreshold,
        cleaningTimeThreshold,
        closingTimeThreshold,
        popiTimeThreshold,
        postOpTimeThreshold,
        pipoMinThreshold,
        pipoMaxThreshold,
        popiMinThreshold,
        popiMaxThreshold,
        wrMinThreshold,
        wrMaxThreshold,
        preOpMinThreshold,
        preOpMaxThreshold,
        cleaningMinThreshold,
        cleaningMaxThreshold,
        pacuMinThreshold,
        pacuMaxThreshold,
        postOpMinThreshold,
        postOpMaxThreshold,
        preOpToDischargedMinThreshold,
        preOpToDischargedMaxThreshold,
        inOrMinThreshold,
        inOrMaxThreshold,
        inSurgeryMinThreshold,
        inSurgeryMaxThreshold,
        closingMinThreshold,
        closingMaxThreshold,
        procedureCompleteMinThreshold,
        procedureCompleteMaxThreshold,
        orReadyMinThreshold,
        orReadyMaxThreshold,
        piToStartMinThreshold,
        piToStartMaxThreshold,
        poToCutMinThreshold,
        poToCutMaxThreshold,
        startToCloseMinThreshold,
        startToCloseMaxThreshold,
        physicianPopiMinThreshold,
        physicianPopiMaxThreshold,
        fcotsGracePeriod,
        piToPhyiMinThreshold,
        piToPhyiMaxThreshold,
        closingCompleteToPatientOutMinThreshold,
        closingCompleteToPatientOutMaxThreshold,
        samePhysicianORTurnover,
      },
      refetchQueries: [{ query: analyticsConfigQuery }],
    });

    if (get(result, 'data.updateAnalyticsConfiguration')) {
      closeModal();
    }
  };

  const configTypeMap = {
    totalPatientsReceived: TotalPatientsReceivedConfiguration,
    averageWaitingRoomTime: AverageWaitingRoomTimeConfiguration,
    averagePreOPTime: AveragePreOpTimeConfiguration,
    averageReadyForAnesthesiatoReadyForOR: AverageReadyForAnesthesiaToReadyForORConfiguration,
    averageReadyForORtoPreOpExit: AverageReadyForORtoPreOpExitConfiguration,
    averageORTime: AverageORTimeConfiguration,
    averageCleaningTime: AverageCleaningTimeConfiguration,
    averageClosingTime: AverageClosingTimeConfiguration,
    averagePopiTime: AveragePopiTimeConfiguration,
    averagePACUTime: AveragePacuTimeConfiguration,
    averagePostOpTime: AveragePostOpTimeConfiguration,
  };

  return (
    <Box>
      <IconButton onClick={openModal} aria-label="settings" size={size === 'small' ? 'small' : 'medium'}>
        <SettingsIcon fontSize={size === 'small' ? 'small' : 'medium'} />
      </IconButton>
      <Dialog open={open} onClose={closeModal} fullWidth maxWidth="sm">
        <DialogTitle>{title || 'Analytics Global Configuration'}</DialogTitle>
        {!loading ? (
          <DialogContent>
            <Box pb={2}>
              {analyticsV2 && (
                <Form
                  autoFocus
                  initialValue={get(data, 'analyticsConfiguration')}
                  input={
                    configType
                      ? configTypeMap[configType]
                      : analyticsV2
                        ? AnalyticsConfigInputV2
                        : AnalyticsConfigInputV1
                  }
                  label={'Save'}
                  onSubmit={handleSubmit}
                  onCancel={closeModal}
                />
              )}
            </Box>
          </DialogContent>
        ) : (
          <CenteredSpinner />
        )}
      </Dialog>
    </Box>
  );
};

export default Configuration;
