import React, { useEffect, useMemo } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  Checkbox,
  createStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  useTheme,
} from '@material-ui/core';
import { AccessoriesTableAnswer } from '../../../../types/Answer';
import { alpha, withStyles } from '@material-ui/core/styles';
import { RadioButtonUncheckedTwoTone } from '@material-ui/icons';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useSave } from './SaveProvider';
import { useSlideIn } from '../../../../form/Form';

interface AccessoriesTableProps {
  value?: AccessoriesTableAnswer;
  onChange: (value: AccessoriesTableAnswer) => void;
}

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
    },
    root: {
      '&:nth-of-type(odd)': {
        // backgroundColor: theme.palette.action.hover,
      },
      '&:last-of-type': {
        // backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      borderRight: `2px solid ${theme.palette.divider}`,
      '&:last-of-type': {
        borderRight: 'none',
      },
    },
    body: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      borderRight: `2px solid ${theme.palette.divider}`,
      borderBottom: 'none',
      '&:first-of-type': {
        // borderRight: 0,
      },
      '&:last-of-type': {
        borderRight: 'none',
      },
    },
  })
)(TableCell);

type Key = 'wallet' | 'glasses' | 'hearingAid' | 'clothing';
const rows: { name: Key; label: string }[] = [
  {
    name: 'wallet',
    label: 'Wallet/Purse/Phone',
  },
  {
    name: 'glasses',
    label: 'Glasses/Contact Lens',
  },
  {
    name: 'hearingAid',
    label: 'Hearing Aid',
  },
  {
    name: 'clothing',
    label: 'Clothing',
  },
];

const isCompleted = (value: AccessoriesTableAnswer) =>
  rows.every(row => value[row?.name] !== null && value[row?.name] !== undefined);

const isCompletedRow = (key: Key, value: AccessoriesTableAnswer) => value[key] !== null && value[key] !== undefined;

const AccessoriesTable = ({ value: answer, onChange }: AccessoriesTableProps) => {
  const { validationRequest } = useSave();
  const classes = useStyles();
  const slideIn = useSlideIn();
  const theme = useTheme();

  const value = useMemo(
    () =>
      answer || {
        wallet: null,
        glasses: null,
        hearingAid: null,
        clothing: null,
      },
    [answer]
  );

  useEffect(() => {
    if (validationRequest) {
      if (!value || !isCompleted(value)) {
        slideIn();
      }
    }
  }, [validationRequest, value, slideIn]);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <StyledTableRow className={classes.mainRow}>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell>Kept in bag</StyledTableCell>
            <StyledTableCell>Give to escort</StyledTableCell>
            <StyledTableCell>None</StyledTableCell>
          </StyledTableRow>
        </TableHead>

        <TableBody>
          {rows.map((row, i) => (
            <StyledTableRow className={classes.mainRow}>
              <StyledTableCell
                padding="none"
                rowSpan={1}
                style={{
                  color: validationRequest
                    ? isCompletedRow(row?.name, value)
                      ? 'inherit'
                      : theme.palette.error.light
                    : 'inherit',
                }}
              >
                {row?.label}
              </StyledTableCell>

              {['bag', 'givenToEscort', 'none'].map(columnValue => {
                const key = row?.name;
                return (
                  <BlueCheckbox
                    checked={value?.[key]?.value === columnValue}
                    onChange={() =>
                      onChange({
                        ...value,
                        [key]:
                          columnValue === 'none'
                            ? {
                                yes: false,
                                value: 'none',
                              }
                            : {
                                yes: true,
                                value: columnValue,
                              },
                      })
                    }
                  />
                );
              })}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const useStyles = makeStyles(theme => ({
  mainRow: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
}));

export default AccessoriesTable;

const BlueCheckbox = withStyles({
  root: {
    color: 'rgba(0, 0, 0, 0.54)',
    '&$checked': {
      color: 'rgb(25, 118, 210)',
    },
  },
  checked: {},
})(props => (
  <StyledTableCell style={{ textAlign: 'center' }}>
    <Checkbox
      icon={<RadioButtonUncheckedTwoTone color="primary" />}
      checkedIcon={<CheckCircleIcon color="primary" />}
      {...props}
      style={{ padding: 0 }}
    />
  </StyledTableCell>
));
