import React, { useContext } from 'react';
import blanksContext from './blanksContext';

interface BlankProps {
  type?: 'date';
  children: string;
}

const Blank = ({ type, children }: BlankProps) => {
  const { labelsPosition } = useContext(blanksContext);

  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        gap: labelsPosition === 'below' ? undefined : '0.5em',
        flexDirection: labelsPosition === 'below' ? 'column' : undefined,
        flexFlow: labelsPosition === 'below' ? 'column-reverse' : undefined,
        alignItems: labelsPosition === 'below' ? 'center' : undefined,
      }}
    >
      <div>{children}</div>
      <div
        style={{
          flex: 1,
          borderBottom: '1px solid black',
          height: '1.5em',
          alignSelf: labelsPosition === 'below' ? 'stretch' : undefined,
          display: 'flex',
          alignItems: 'end',
          justifyContent: 'space-evenly',
          lineHeight: 1,
          whiteSpace: 'pre',
          maxWidth: '20em',
        }}
      >
        {type === 'date' ? (
          <>
            <span>/</span>
            <span>/</span>
          </>
        ) : undefined}
      </div>
    </div>
  );
};

export default Blank;
