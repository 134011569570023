import React from 'react';
import posed, { PoseGroup } from 'react-pose';
import styled, { css, keyframes } from 'styled-components';
import { useScope } from '../../hooks/useScope';
import { withTheme } from '../../se/theme';
import responsive from '../../se/utilities/responsive';
import isMiddleEast from '../../util/isMiddleEast';
import useTime from '../../util/useTime';
import { STATUSES } from '../entities/patient/enums';
import { ROOM_TYPES, STATUSES as OR_STATUSES } from '../entities/room/enums';
import { OR_TIMES_S } from '../inputs/timeEdit/TimeEditModal';
import Appendix from '../pages/kiosk/schedule/overview/Appendix';
import { getDelayInMinutes, getOrLateEntryInMinutes, isDelayed } from '../pages/kiosk/schedule/shared/procedureUtils';
import CleaningTime from '../pages/kiosk/tablet/CleaningTime';
import { isSameDate } from '../pages/kiosk/tablet/TurnoverClock';
import { isOrTimeActive, optionalFormat, showValueIf } from '../pages/kiosk/tablet/utils';

const emphasize = keyframes`
  0% {
    border-color: rgba(255, 0, 0, 0);
    box-shadow: 0 0 4px rgba(255, 0, 0, 0);
  }
  100% {
    border-color: rgba(255, 0, 0, 1);
    box-shadow: 0 0 40px rgba(255, 0, 0, 1);
  }
`;

export const Container = styled.div`
  background-color: ${withTheme(theme => theme.list.backgroundColor.string())};
  border-radius: 0.125rem;
  display: flex;
  flex-flow: column;
  flex: 1 0 0;
  padding: ${props => (props.compact ? '1.25rem 1.5rem' : '1.5vh')};
  overflow-x: hidden;
  position: relative;
  border: ${props => (props.procedureColor ? `5px solid ${props.procedureColor}` : 'transparent')};
  ${props =>
    props.isHelp &&
    css`
      border: 4px solid transparent;
      animation: 1.2s ${emphasize} ease-in alternate infinite;
    `}

  @media (max-height: 900px) {
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
  }

  ${props => props.fusePanels && 'margin-right: -0.25rem !important;'}

  ${responsive.lg.andSmaller`
    padding-left: 2vw;
    padding-right: 2vw;
  `};

  ${responsive.sm.andSmaller`
    grid-template-columns: auto 100% auto;
    padding-left: 1rem;
    padding-right: 1rem;
  `};

  ${props =>
    props.noPadding &&
    css`
      padding-left: 0;
      padding-right: 0;
    `}

  h3 {
    opacity: 1;
  }

  ${props =>
    props.miniView &&
    css`
      padding: 0.5rem 1rem;
      flex: 1 1 calc(100% / ${props => props.numberOfPanels} - 0.5rem);
    `}
`;

const Header = styled.div`
  min-height: 3.65ch;
  ${props =>
    props.noPadding &&
    css`
      padding-left: ${props => (props.compact ? '1.5rem' : '2rem')};
      padding-right: ${props => (props.compact ? '1.5rem' : '2rem')};
    `}
  margin-bottom: ${props => (props.compactView ? '0' : '1.5rem')};

  @media (max-height: 900px) {
    margin-bottom: 1.5vh;
  }
`;

const Main = styled.div`
  flex: 1;
`;

const Info = styled.div`
  display: flex;
  align-items: ${props => (!props.timeEntered ? 'center' : 'end')};
  justify-content: space-between;
  flex: 1 0 auto;
`;

export const Status = posed(styled.h2`
  font-size: 2em;
  line-height: 1.3;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin: 0;
  // margin-top: -1.5rem;
  // color: white;

  color: ${withTheme(theme => theme.textColor.string())};

  @media (max-height: 900px) {
    font-size: 1.125rem;
    // margin-top: -1.5vh;
  }

  ${responsive.lg.andSmaller`
    font-size: 2.5vw;
  `};

  ${responsive.sm.andSmaller`
    font-size: 1rem;
  `};

  @media (max-height: 600px) {
    font-size: 3vh;
  }

  ${props =>
    props.miniView &&
    css`
      font-size: 1rem;
    `}
`)({
  enter: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1000,
      ease: [0.0, 0.01, 0.01, 0.99],
      delay: 1000,
    },
  },
  exit: {
    y: 10,
    opacity: 0,
    transition: {
      duration: 1000,
      ease: [0.0, 0.01, 0.01, 0.99],
    },
  },
});

export const Title = styled.h3`
  font-size: ${props => (props.compact ? '.875em' : '1.5em')};
  line-height: ${props => (props.compact ? '1rem' : '1.55')};
  text-transform: ${props => (props.compact ? 'uppercase' : 'none')};
  letter-spacing: ${props => (props.compact ? '.44px' : '0')};
  font-weight: ${props => (props.compact ? '500' : 'initial')};
  margin-bottom: ${props => (props.compact ? '.875rem' : '0')};
  color: ${props => (!!props.color ? props.color : withTheme(theme => theme.textColor.string()))};
  opacity: 0.5;
  margin-top: 0;
  margin-right: 0.5em;

  @media (max-height: 900px) {
    font-size: 1rem;
  }

  ${responsive.lg.andSmaller`
    font-size: 2vw;
  `};

  ${responsive.sm.andSmaller`
    font-size: .875rem;
  `};

  @media (max-height: 600px) {
    font-size: 3vh;
  }
`;

const Meta = styled.span`
  font-size: 1.125em;
  color: ${withTheme(theme => theme.textColor.string())};
  opacity: 0.5;

  @media (max-height: 600px) {
    font-size: 3vh;
  }
`;

const Times = styled.div`
  flex-flow: column;
  color: white;
  white-space: nowrap;
  display: ${props => (props.compactView ? 'none' : 'initial')};

  > * + * {
    margin-top: 0.75rem;
  }
`;

const Entry = styled.div`
  display: flex;
  color: #abe3ff;
  justify-content: flex-end;

  > * {
    flex: 1 0 65%;
  }
`;

const Label = styled.div`
  margin-right: 0.5rem;
  text-align: right;
  opacity: 0.5;
`;

const Time = styled.div`
  color: white;
  text-align: right;
  flex: 1 0 35%;
`;

const HeaderTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  text-overflow: ellipsis;

  ${props =>
    props.miniView &&
    css`
      font-size: 0.75rem;
    `}
`;

export const getColor = (status, hospitalId) => {
  const applyColor =
    (window.location.hostname === 'localhost' && hospitalId === 10) ||
    (window.location.hostname === 'testing.ospitek.com' && hospitalId === 10) ||
    (window.location.hostname === 'view2.ospitek.com' && hospitalId === 7) ||
    (window.location.hostname === 'view3.ospitek.com' && hospitalId === 3) ||
    (window.location.hostname === 'view4.ospitek.com' && hospitalId === 2) ||
    (window.location.hostname === 'view4.ospitek.com' && hospitalId === 3);

  if (applyColor) {
    switch (status) {
      case 'InOr':
        return '#0047AB';
      case 'AnestStart':
      case 'TimeOut':
      case 'Ready':
      case 'Ongoing':
        return '#00FF00';
      case 'Closing':
        return '#ffbf00';
      case 'Dressing':
      case 'DressingOn':
        return '#A020F0';
      case 'End':
      case 'ProcedureEnd':
      case 'AnestEnd':
        return '#F55323';
      default:
        return null;
    }
  }

  return null;
};

const PanelContainer = ({
  title,
  capacity,
  timeEntered,
  patients,
  children,
  status,
  compact,
  timeoutTime,
  enteredTime,
  procedureStartTime,
  closingTime,
  statusLabel,
  compactView,
  miniView,
  scheduleProcedure,
  numberOfPanels,
  Stats,
  style,
  noPadding,
  titleColor = undefined,
  fusePanels = false,
  preOpFocused,
  pacuFocused,
  isHelp,
  room,
  messagingView,
  hideTurnoverClock,
}) => {
  const scope = useScope();
  const hospitalId = scope?.hospital?.id;
  const currentTime = useTime(5000);
  const isVacant = status === OR_STATUSES.VACANT;
  const isCleaning = status === OR_STATUSES.CLEANING;
  const procedureColor = scheduleProcedure?.color || getColor(status, hospitalId);
  const SIX_HOURS_IN_SECONDS = 6 * 3600;

  return (
    <Container
      fusePanels={fusePanels}
      compact={compact}
      miniView={miniView}
      numberOfPanels={numberOfPanels}
      style={style}
      noPadding={noPadding}
      isHelp={isHelp}
      procedureColor={isVacant || isCleaning ? null : procedureColor}
    >
      <Header compactView={compactView} noPadding={noPadding}>
        <Info capacity={capacity}>
          <Main miniView={miniView}>
            <HeaderTitle miniView={miniView}>
              {title ? ( // It’s hackish
                <Title color={titleColor} compact={compact}>
                  {title}
                </Title>
              ) : (
                <Title hidden color={'transparent'} compact={compact}>
                  Stub title
                </Title>
              )}
              {Stats && Stats}
            </HeaderTitle>
            <PoseGroup animateOnMount>
              <Status miniView={miniView} key={status + 'status'} isReady={status === STATUSES.READY}>
                {statusLabel}
              </Status>
            </PoseGroup>
            {(status === 'Cleaning' || (status === 'Vacant' && room?.turnoverStartedAt)) &&
              !hideTurnoverClock &&
              isSameDate(new Date(room?.cleaningStartedAt), new Date()) && <CleaningTime room={room} />}
            <Appendix
              isHelp={scheduleProcedure?.patient?.room?.type === ROOM_TYPES.OR && isHelp}
              late={getOrLateEntryInMinutes(scheduleProcedure, isMiddleEast(hospitalId))}
              isDelayed={isDelayed(scheduleProcedure, isMiddleEast(hospitalId))}
              delay={getDelayInMinutes(scheduleProcedure, currentTime)}
              style={{ backgroundColor: 'transparent' }}
              messagingView={messagingView}
            />
          </Main>
          {capacity || !timeEntered ? (
            !fusePanels && (
              <Meta>
                {patients}
                {capacity ? '/' : ''}
                {capacity}
              </Meta>
            )
          ) : (
            <Times compactView={compactView}>
              {(preOpFocused || pacuFocused) && (
                <Entry>
                  <Label>Entered</Label>
                  <Time>
                    {showValueIf(optionalFormat(enteredTime, 'HH:mm'), isOrTimeActive(status, OR_TIMES_S.ENTERED_AT))}
                  </Time>
                </Entry>
              )}
              <Entry>
                <Label>Time Out</Label>
                <Time>
                  {showValueIf(optionalFormat(timeoutTime, 'HH:mm'), isOrTimeActive(status, OR_TIMES_S.TIMEOUT_START))}
                </Time>
              </Entry>
              <Entry>
                <Label>Procedure</Label>
                <Time>
                  {showValueIf(
                    optionalFormat(procedureStartTime, 'HH:mm'),
                    isOrTimeActive(status, OR_TIMES_S.PROCEDURE_START)
                  )}
                </Time>
              </Entry>
              <Entry>
                <Label>Closing</Label>
                <Time>
                  {showValueIf(optionalFormat(closingTime, 'HH:mm'), isOrTimeActive(status, OR_TIMES_S.CLOSING_START))}
                </Time>
              </Entry>
            </Times>
          )}
        </Info>
      </Header>
      {children}
    </Container>
  );
};

export default PanelContainer;
