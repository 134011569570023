import TextField from '@material-ui/core/TextField';
import React, { FC, useState } from 'react';
import Button from '@material-ui/core/Button';
import { Box, Popover, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { searchProcedures } from '../../../../../../graph/procedures';
import { nextPatientMRN } from '../../../../../../graph/patients';
import { useScope } from '../../../../../../hooks/useScope';

function padNumber(n: number, c: number): string {
  let numberString: string = n.toString();
  let paddingLength: number = c - numberString.length;
  if (paddingLength <= 0) {
    return numberString;
  } else {
    return '0'.repeat(paddingLength) + numberString;
  }
}

const generateProdNumber = (): string => {
  const v = window.location.hostname.split('.')?.[0].match(/^view(\d+)$/);
  return padNumber(0, 2);
};

const generateHospitalNumber = (hospitalId: number): string => padNumber(hospitalId, 2);

const generateMedicalRecordNumber = (hospitalId: number, incr: number): string => {
  const prod = generateProdNumber();
  const hospital = generateHospitalNumber(hospitalId);
  return `${prod}-${hospital}-${padNumber(incr, 6)}`;
};

const GenerateMRNNumberButton: FC<{ onGenerate: (mrn: string) => void }> = ({ onGenerate }) => {
  const scope = useScope();
  const hospitalId = scope?.hospital?.id;
  const [nextMRN, setNextMRN] = useState<number | null>(null);

  const { refetch } = useQuery(nextPatientMRN, {
    skip: true,
  });

  const onClick = async () => {
    if (!nextMRN) {
      const { data } = await refetch();
      const mrn = data?.nextPatientMRN && generateMedicalRecordNumber(hospitalId, data?.nextPatientMRN);
      mrn && onGenerate(mrn);
      mrn && setNextMRN(mrn);
    }
  };

  return (
    <Button variant="contained" color="primary" onClick={onClick}>
      Generate MRN
    </Button>
  );
};

export type FilteredPatient = {
  id: number;
  patientHstId: string;
  patientName: string;
  patientDateOfBirthISO: string;
  startTime: string;
  visit: string;
  physicianName: string;
  procedureType: string;
};

export type PatientFilter = {
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string | null;
  phoneNumber?: string | null;
};

const MedicalRecordNumberInput: FC<{
  filter: PatientFilter;
  value: string | null;
  onChange: (val: string) => void;
  error: string | null;
}> = ({ filter, value, onChange, error }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState<FilteredPatient | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (item: FilteredPatient) => {
    setSelectedItem(item);
    onChange(item?.patientHstId);
    handleClose();
  };

  const [data, setData] = useState<FilteredPatient[]>([]);

  const { refetch } = useQuery(searchProcedures, {
    variables: { filter },
    skip: true,
  });

  const handleClick = async event => {
    setAnchorEl(event.currentTarget);
    if (filter?.firstName || filter?.lastName || filter?.phoneNumber || filter?.dateOfBirth) {
      const { data } = await refetch({
        filter: {
          firstName: filter?.firstName || null,
          lastName: filter?.lastName || null,
          phoneNumber: filter?.phoneNumber || null,
          dateOfBirth: filter?.dateOfBirth || null,
        },
      });
      setData(data?.searchProcedures || []);
    }
  };

  return (
    <>
      <TextField
        required
        error={!!error}
        helperText={error}
        value={value}
        label="Medical Record Number"
        variant="filled"
        margin="dense"
        fullWidth
        onClick={handleClick}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>MRN</TableCell>
              <TableCell>Full Name</TableCell>
              <TableCell>DOB</TableCell>
              <TableCell>DOS</TableCell>
              <TableCell>Visit</TableCell>
              <TableCell>Physician</TableCell>
              <TableCell>Procedure Name</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(p => (
              <TableRow>
                <TableCell>{p.patientHstId}</TableCell>
                <TableCell>{p.patientName}</TableCell>
                <TableCell>{p.patientDateOfBirthISO}</TableCell>
                <TableCell>{p.startTime}</TableCell>
                <TableCell>{p.visit}</TableCell>
                <TableCell>{p.physicianName}</TableCell>
                <TableCell>{p.procedureType}</TableCell>
                <TableCell>
                  <Button variant="contained" color="primary" onClick={() => handleItemClick(p)}>
                    Choose
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Box display="flex" flexDirection="row" justifyContent="flex-end" p={1}>
          <Box>
            <GenerateMRNNumberButton onGenerate={mrn => onChange(mrn)} />
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default MedicalRecordNumberInput;
