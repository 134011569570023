import { Patient, PatientStatus } from '../../../../../types/Patient';
import { QuestionnaireAndAnswers, QuestionnaireType } from '../../../../../types/Questionnaire';
import { formatOrNull } from '../../tablet/utils';
import {
  patientDischarged,
  patientEnteredRoomTypeTime,
  patientProcedureStatusTime,
} from '../../../../entities/patient/util/patientEvents';
import React, { Ref, useEffect, useRef, useState } from 'react';
import { Answer } from '../../../../../types/Answer';
import { Question } from '../../../../../types/Question';
import { useApolloClient, useMutation } from '@apollo/client';
import { checkIfCompleted, createQuestionnaireAnswers, updateQuestionnaireAnswers } from '../../../../../graph/forms';
import SaveProvider, { SaveProviderHandle } from '../SaveProvider';
import { DischargeTimeContext, MedicationsContext, renderEditableFormUI, Tags } from '../../../../../form/Form';
import { alpha, Box, Button, Divider, Snackbar, useTheme, Zoom } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { TransitionProps } from '@material-ui/core/transitions';
import Slide from '@material-ui/core/Slide';
import NotesContextProvider from '../NotesContextProvider';
import { RoomType } from '../../../../../types/Room';
import DialogHeader from '../../tablet/components/DialogHeader';
import { SaveRounded } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { differenceInYears, parse } from 'date-fns';
import Fab from '@material-ui/core/Fab';
import MyPatientInfo from '../PatientInfo';
import set from 'lodash/set';
import { tryParseJson } from '../../../../../util/parseJson';
import isEqual from 'lodash/isEqual';
import { DefaultSlideContext } from '../../../../../form/components/Slides';
import { getEnteredAt } from '../screens/ChartingProfile';
import { defaultsDeep } from 'lodash';
import PatientIdContextProvider from '../PatientIdContextProvider';
import ProcedureIdContextProvider from '../ProcedureIdContextProvider';
import { useConnectionProviderContext } from '../../connectionProviderContext';
import { useChartingSession, useKeepAliveChartingSession } from '../modules/hooks';
import renderFormNode from '../../../../../form-v2/renderFormNode';
import DefaultValueContext from '../../../../../form-v2/DefaultValueContext';
import FormContext from '../../../../../form-v2/FormContext';
import { IntlProvider } from 'react-intl';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const addDefinedField = (obj, fieldName, fieldValue) =>
  fieldName && fieldValue !== null && fieldValue !== undefined ? set(obj, fieldName, fieldValue) : obj;

const getAgeOption = (age: number) =>
  age < 60
    ? { name: 'q1_opt0', label: 'Less than 60' }
    : age >= 60 && age <= 69
      ? { name: 'q1_opt1', label: '60-69' }
      : age >= 70 && age <= 79
        ? { name: 'q1_opt2', label: '70-79' }
        : age >= 80
          ? { name: 'q1_opt3', label: 'greater than or equal to 80 years' }
          : null;

const getGenderOption = (gender: string) =>
  gender.toLowerCase() === 'f' || gender.toLowerCase() === 'female'
    ? { name: 'q2_opt2', label: 'Female' }
    : gender.toLowerCase() === 'm' || gender.toLowerCase() === 'male'
      ? { name: 'q2_opt1', label: 'Male' }
      : null;

const isEmptyObject = (obj: any) => obj && Object.keys(obj).length === 0 && obj.constructor === Object;

const FormDialog = ({
  patient,
  form,
  open,
  handleClickOpen,
  handleClose,
  questionnaireType,
  defaultSlide,
}: {
  index: number;
  patient: Patient;
  form: QuestionnaireAndAnswers;
  open: boolean;
  handleClickOpen: (form: QuestionnaireAndAnswers) => void;
  handleClose: () => void;
  questionnaireType: QuestionnaireType;
  defaultSlide: number;
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const questions = JSON.parse(form?.questionnaire?.content) || {};
  const defaultAnswers = form?.defaultAnswers ? JSON.parse(form?.defaultAnswers) : {};
  const answers = form?.questionnaireExchange?.answers ? JSON.parse(form?.questionnaireExchange?.answers) : {};

  const { connected } = useConnectionProviderContext();

  const initialValues = defaultsDeep(
    addDefinedField({}, 'admissionTime', formatOrNull(patientEnteredRoomTypeTime(patient, RoomType.PreOp), 'HH:mm')),
    addDefinedField({}, 'pacuTime', formatOrNull(patientEnteredRoomTypeTime(patient, RoomType.PACU), 'HH:mm')),
    addDefinedField({}, 'dischargeTime', formatOrNull(patientDischarged(patient), 'HH:mm')),
    addDefinedField({}, 'procedure_name', patient?.procedureType?.name),
    addDefinedField({}, 'procedureName', patient?.procedureType?.name),
    addDefinedField({}, 'diagnosis', patient?.procedure?.diagnosis),
    addDefinedField({}, 'object1.surgeon', patient?.physician?.name),
    addDefinedField({}, 'staff.surgeon', patient?.physician?.name),
    addDefinedField({}, 'object1.anesthesiologist', patient?.procedure?.anesthesiologist?.name),
    addDefinedField({}, 'staff.anesthesiologist', patient?.procedure?.anesthesiologist?.name),
    addDefinedField(
      {},
      'fallRisk_q1',
      patient?.procedure?.patientDateOfBirthISO
        ? getAgeOption(differenceInYears(new Date(), parse(patient?.procedure?.patientDateOfBirthISO)))
        : null
    ),
    addDefinedField(
      {},
      'fallRisk_q2',
      patient?.procedure?.patientSex ? getGenderOption(patient?.procedure?.patientSex) : null
    ),
    {
      anesthesiaTableAndChart: {
        startTime: formatOrNull(patientEnteredRoomTypeTime(patient, RoomType.OR), 'HH:mm'),
      },
    },
    isEmptyObject(answers) ? defaultAnswers : answers,
    {
      time: {
        ...addDefinedField({}, 'orRoom', patient?.procedure?.or || null),
        ...addDefinedField({}, 'timeInOR', formatOrNull(patientEnteredRoomTypeTime(patient, RoomType.OR), 'HH:mm')),
        ...addDefinedField(
          {},
          'timeInAntibiotic',
          formatOrNull(patientProcedureStatusTime(patient, PatientStatus.AnestheticStart), 'HH:mm')
        ),
        ...addDefinedField(
          {},
          'timeInIncision',
          formatOrNull(patientProcedureStatusTime(patient, PatientStatus.Ongoing), 'HH:mm')
        ),
        ...addDefinedField(
          {},
          'timeInClosing',
          formatOrNull(patientProcedureStatusTime(patient, PatientStatus.Closing), 'HH:mm')
        ),
        ...addDefinedField(
          {},
          'procedureEnd',
          formatOrNull(patientProcedureStatusTime(patient, PatientStatus.ProcedureEnd), 'HH:mm')
        ),
        ...addDefinedField(
          {},
          'timeInOutOfOR',
          formatOrNull(patientEnteredRoomTypeTime(patient, RoomType.PACU), 'HH:mm')
        ),
        ...(answers?.time || {}),
      },
    }
  );

  const [answer, setAnswer] = useState<Answer<Question> | undefined>(initialValues);
  const [createAnswers] = useMutation(createQuestionnaireAnswers);
  const [updateAnswers] = useMutation(updateQuestionnaireAnswers);
  const [isCompleted] = useMutation(checkIfCompleted);
  const [busy, setBusy] = useState(false);

  const defaultValuesForConditionFields = defaultsDeep(answer, {
    CareBegin: {
      none: false,
      CareBegin_at: {
        ifYes: formatOrNull(patientEnteredRoomTypeTime(patient, RoomType.OR), 'HH:mm'),
        value: true,
      },
    },
  });

  const [forceSave, setForceSave] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [connectionInfo, setConnectionInfo] = useState<{ type: 'error' | 'success'; text: string } | null>(null);

  useEffect(() => {
    if (!error) {
      return;
    }

    const timeoutId = setTimeout(() => {
      setError(undefined);
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, [error]);

  useEffect(() => {
    if (!connectionInfo || connectionInfo?.type === 'error') {
      return;
    }

    const timeoutId = setTimeout(() => {
      setConnectionInfo(null);
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, [connectionInfo]);

  useEffect(() => {
    if (!connected) {
      setConnectionInfo({
        type: 'error',
        text: 'The system is currently offline. Attempting to restore connection… Entered values won’t be saved!',
      });
    } else {
      if (connectionInfo?.type === 'error') {
        setConnectionInfo({ type: 'success', text: 'Server connection restored!' });
      }
    }
  }, [connected]);

  const saveProviderRef = useRef<SaveProviderHandle | undefined>();

  useEffect(() => {
    setForceSave(false);
  }, [answer]);

  const chartingSession = useChartingSession();

  const save = async () => {
    if (!!form?.questionnaireExchange?.id) {
      await updateAnswers({
        variables: {
          questionnaireExchangeId: form?.questionnaireExchange?.id,
          answers: JSON.stringify(answer),
          pin: chartingSession?.pin,
          baseAnswers: JSON.stringify(answers),
        },
      });
    } else {
      await createAnswers({
        variables: {
          procedureId: patient?.procedure?.id,
          questionnaireId: form?.questionnaire?.id,
          answers: JSON.stringify(answer),
          pin: chartingSession?.pin,
        },
      });
    }
  };

  const saveAnswers = async () => {
    setBusy(true);
    try {
      if (!forceSave) {
        const result = await isCompleted({
          variables: {
            questions: JSON.stringify(questions),
            answers: JSON.stringify(answer),
          },
        });

        if (result?.data?.checkIfCompleted !== 'Completed') {
          setError('Some fields are not filled.');
          saveProviderRef.current!.requestValidation();
          setForceSave(true);
          // await save();
          return;
        }
      }

      await save();

      close();
    } catch (e) {
      console.error('error:', e);
    } finally {
      setBusy(false);
    }
  };

  const [confirmClose, setConfirmClose] = useState(false);

  const saveAndClose = () => {
    save();
    handleClose();
  };

  const close = () => {
    setForceSave(false);
    saveProviderRef.current!.clearValidationRequest();
    handleClose();
  };

  const forceClose = () => {
    setAnswer({
      ...initialValues,
      ...answers,
    });
    setConfirmClose(false);
    close();
  };

  useKeepAliveChartingSession(Boolean(open));

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const fallRiskAssessment = patient.procedure?.fallRiskAssessment;
  const fallRiskWarning = !Boolean(fallRiskAssessment)
    ? undefined
    : fallRiskAssessment!.toLowerCase().indexOf('high') > -1
      ? 'high'
      : fallRiskAssessment!.toLowerCase().indexOf('low') > -1
        ? 'low'
        : undefined;

  const [showPatientDetails, setShowPatientDetails] = useState(false);
  const enteredAt = getEnteredAt(questionnaireType, patient);
  return (
    <>
      {/* <Dialog open={confirmClose} title="Confirm Close" onClose={() => setConfirmClose(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Are you sure you want to close without saving?</DialogTitle>
        <DialogContent>
          <DialogContentText>Changes you have made will be lost.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={forceClose} variant="outlined">
            Close without saving
          </Button>
          <Button onClick={() => setConfirmClose(false)} variant="contained" color="primary">
            Continue editing
          </Button>
        </DialogActions>
      </Dialog> */}

      <Dialog fullScreen open={open} onClose={close} TransitionComponent={Transition}>
        <DialogHeader title={form?.questionnaire?.name || 'Patient Form'}>
          <Box mb={-2}>
            <Box my={2}>
              <Divider />
            </Box>
            <MyPatientInfo
              compact
              onBack={saveAndClose}
              patientName={patient?.name}
              physicianName={patient?.physician?.name}
              anesthesiologistName={patient?.procedure?.anesthesiologist?.name}
              anesthesiaType={patient?.procedure?.anesthesiaType}
              anesthesiaReviewed={patient?.procedure?.anesthesiaReviewed}
              procedureTypeName={patient?.procedureType?.name}
              dateOfBirth={patient?.procedure?.patientDateOfBirth}
              age={patient?.procedure?.patientAge}
              sex={patient?.procedure?.patientSex}
              hstId={patient?.procedure?.patientHstId}
              fallRiskAssessment={
                !Boolean(fallRiskAssessment)
                  ? undefined
                  : fallRiskAssessment!.toLowerCase().indexOf('high') > -1
                    ? 'high'
                    : fallRiskAssessment!.toLowerCase().indexOf('low') > -1
                      ? 'low'
                      : undefined
              }
              allergies={patient?.procedure?.allergies}
              enteredAt={enteredAt}
              save={save}
            />
          </Box>
        </DialogHeader>
        <Box className={classes.viewport}>
          <Box className={classes.container}>
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              open={!!(error || connectionInfo)}
              autoHideDuration={connectionInfo?.type === 'error' ? null : 6000}
            >
              <Alert severity={error ? 'warning' : connectionInfo?.type} variant="filled">
                {error || connectionInfo?.text}
              </Alert>
            </Snackbar>
            <ProcedureIdContextProvider procedureId={patient?.procedure?.id}>
              <PatientIdContextProvider patientId={patient?.id}>
                <DefaultSlideContext.Provider value={defaultSlide}>
                  <DischargeTimeContext.Provider
                    value={(patient as any).dischargedAt ? new Date((patient as any).dischargedAt) : undefined}
                  >
                    <MedicationsContext.Provider value={tryParseJson(patient.procedure?.medications)}>
                      <NotesContextProvider>
                        <SaveProvider
                          ref={saveProviderRef as Ref<SaveProviderHandle>}
                          forceSave={forceSave}
                          onSave={saveAnswers}
                          onClearForceSave={() => setForceSave(false)}
                        >
                          <Box px={5} display="flex" flexDirection="column" flex={1}>
                            {questions['v2'] === true ? (
                              <DefaultValueContext.Provider value={{}}>
                                <FormContext.Provider
                                  value={{
                                    type: 'PreOp' as any,
                                    value: defaultValuesForConditionFields,
                                    onChange: setAnswer as any,
                                    busy: false,
                                    save: null,
                                    complete: false,
                                    procedureId: patient?.procedure?.id,
                                  }}
                                >
                                  <IntlProvider locale="en" defaultLocale="en" messages={{}}>
                                    <Box py={5} display="flex" flexDirection="column" flex={1}>
                                      {renderFormNode(questions)}
                                    </Box>
                                  </IntlProvider>
                                </FormContext.Provider>
                              </DefaultValueContext.Provider>
                            ) : (
                              renderEditableFormUI(
                                {} as Tags,
                                questions as unknown as Question,
                                defaultValuesForConditionFields,
                                setAnswer
                              )
                            )}
                          </Box>
                        </SaveProvider>
                      </NotesContextProvider>
                    </MedicationsContext.Provider>
                  </DischargeTimeContext.Provider>
                </DefaultSlideContext.Provider>
              </PatientIdContextProvider>
            </ProcedureIdContextProvider>
            <Zoom
              in={true}
              timeout={transitionDuration}
              style={{
                transitionDelay: `${transitionDuration.exit}ms`,
              }}
              unmountOnExit
            >
              <Fab
                variant="extended"
                size="large"
                color="primary"
                className={classes.fab}
                onClick={saveAnswers}
                disabled={busy}
              >
                <Box mr={1} display="flex" alignItems="center">
                  <SaveRounded color="secondary" />
                </Box>
                {forceSave ? 'Save anyway' : 'Save'}
              </Fab>
            </Zoom>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  viewport: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    minHeight: 0,
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(5),
    right: theme.spacing(5),
  },
  actionBar: {
    display: 'flex',
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(3, 5),
    position: 'sticky',
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    backdropFilter: 'blur(10px)',
    alignItems: 'center',
    justifyContent: 'flex-end',
    zIndex: 1,
  },
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0,
  },
  accordion: {
    backgroundColor: 'transparent',
  },
  avatar: {
    backgroundColor: alpha(theme.palette.common.white, 1),
  },
  patientCard: {
    padding: theme.spacing(1, 2),
    paddingLeft: theme.spacing(1.8),
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    borderRadius: 32,
    cursor: 'pointer',
  },
}));

export default FormDialog;
