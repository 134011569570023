import { sortAge, sortDate, sortNumber, sortString } from '../../../util/sort';
import { TruncatedText } from '../../../se/components/typography';
import get from 'lodash/get';
import React from 'react';
import { differenceInYears, format } from 'date-fns';
import ExpandableText from '../../core/ExpandableText';
import { formatPhoneNumber } from '../../../se/components/inputs/PhoneInput';
import { FormFulfillmentCell, ResendActionsQuestionnaireWrapper } from '../procedures/columns';
import isFinite from 'lodash/isFinite';
import { surgeonProcedureStatusLabels } from '../surgeonProcedures/enums';
import Box from '@material-ui/core/Box';
import { Chip } from '@material-ui/core';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ChangeStatusButton from './ChangeStatusButton';
import { ZonedDateTime } from '@js-joda/core';
import { Mutation } from '@apollo/client/react/components';
import { sendRatingInvite } from '../../../graph/surgeon/procedures';
import LinkButton from '../../../se/components/LinkButton';
import secretValue from '../../../util/secretValue';
import { useQuery } from '@apollo/client';
import { getNotificationTemplate } from '../../../graph/notificationTemplates';
import Tooltip from '../../Tooltip';
import { SendingSetting } from '../notificationTemplate/NotificationTemplates';

const useStyles = makeStyles(theme => ({
  warning: {
    backgroundColor: theme.palette.warning.dark,
    color: theme.palette.getContrastText(theme.palette.warning.dark),
  },
  info: {
    backgroundColor: theme.palette.info.dark,
    color: theme.palette.getContrastText(theme.palette.info.dark),
  },
}));

const PatientNameColumn = ({ Text = TruncatedText, data }) => <Text>{data.name}</Text>;

export const patientIdColumn = {
  title: '#',
  lens: data => data.id,
  sortFunction: (l, r) => sortNumber(l.id, r.id),
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

export const patientVisitColumn = {
  title: 'Visit',
  lens: data => data.visit,
  sortFunction: (l, r) => sortNumber(l.visit, r.visit),
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

export const patientNameColumn = {
  title: 'Name',
  lens: data => ({ name: get(data, 'patient.name'), id: get(data, 'patient.id') || 'F' }),
  Component: PatientNameColumn,
  sortFunction: (l, r) => sortString(get(l, 'patient.name'), get(r, 'patient.name')),
};

export const consultationDateColumn = {
  title: 'Consultation Date',
  lens: data => data.consultationDateTime,
  Component: props =>
    props.data ? (
      <TruncatedText>
        {format(ZonedDateTime.parse(props.data).toLocalDateTime().toString(), 'MM/DD/YYYY HH:mm')}
      </TruncatedText>
    ) : null,
  sortFunction: (l, r) => sortDate(l.consultationDateTime, r.consultationDateTime),
};

export const surgeryDateColumn = {
  title: 'Date of Service',
  lens: data => data.serviceDateTime,
  Component: props =>
    props.data ? (
      <TruncatedText>
        {format(ZonedDateTime.parse(props.data).toLocalDateTime().toString(), 'MM/DD/YYYY HH:mm')}
      </TruncatedText>
    ) : null,
  sortFunction: (l, r) => sortDate(l.serviceDateTime, r.serviceDateTime),
};

export const physicianColumn = {
  title: 'Physician',
  lens: data => get(data, 'patient.physician.name'),
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

export const surgeonOfficeLocation = {
  title: 'Surgeon Office Location',
  lens: data => data?.organizationLocation?.name,
  span: 2,
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

export const procedureTypeColumn = {
  title: 'Procedure Type',
  lens: data => data.procedureType,
  span: 2,
  style: { overflow: 'hidden', maxWidth: '30em' },
  Component: props => <ExpandableText textStyle={{ whiteSpace: 'normal' }} text={props.data} />,
};

export const patientCellPhoneNumberColumn = {
  title: 'Patient Cell Phone Number',
  lens: data => get(data, 'patient.cellPhone'),
  Component: props => (
    <TruncatedText>{props.data !== secretValue ? formatPhoneNumber(props.data) : secretValue}</TruncatedText>
  ),
};

export const patientHomePhoneNumberColumn = {
  title: 'Patient Home Phone Number',
  lens: data => get(data, 'patient.homePhone'),
  Component: props => (
    <TruncatedText>{props.data !== secretValue ? formatPhoneNumber(props.data) : secretValue}</TruncatedText>
  ),
};

export const patientEmailAddressColumn = {
  title: 'Patient Email Address',
  lens: data => get(data, 'patient.emailAddress'),
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

export const patientDobColumn = {
  title: 'Date of Birth',
  lens: data => get(data, 'patient.dateOfBirth'),
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

export const patientAgeColumn = {
  title: 'Age',
  lens: data => get(data, 'patient.dateOfBirthISO'),
  Component: props => {
    const age = props.data && differenceInYears(new Date(), new Date(props.data));
    return <TruncatedText style={{ color: age > 65 ? 'red' : 'inherit' }}>{age || '-'}</TruncatedText>;
  },
};

export const patientSexColumn = {
  title: 'Sex',
  lens: data => get(data, 'patient.sex'),
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

export const formFulfillmentColumn = {
  title: 'Form %',
  lens: data => get(data, 'entryQuestionnaireProgress'),
  sortFunction: (l, r) => sortNumber(get(l, 'entryQuestionnaireProgress'), get(r, 'entryQuestionnaireProgress')),
  Component: props => <FormFulfillmentCell percentage={isFinite(props.data) ? Math.round(props.data * 100) : 0} />,
};

export const questionnaireInviteColumn = {
  title: 'Questionnaire Invite',
  lens: data => ({
    id: data?.id,
    patientCellPhone: data?.patient?.cellPhone,
    patientEmailAddress: data?.patient?.emailAddress,
    entryQuestionnaireProgress: data?.entryQuestionnaireProgress,
    questionnaireInviteSMSCount: data?.questionnaireInviteSMSCount,
    questionnaireInviteEmailCount: data?.questionnaireInviteEmailCount,
    lastQuestionnaireInviteSMS: data?.lastQuestionnaireInviteSMS,
  }),
  Component: ResendActionsQuestionnaireWrapper,
};

export const questionnaireInviteAtColumn = {
  title: 'Last Invite',
  lens: data => get(data, 'questionnaireInviteSentAt'),
  Component: ({ data }) => (
    <TruncatedText>
      {data ? format(ZonedDateTime.parse(data).toLocalDateTime().toString(), 'MM/DD/YYYY HH:mm') : 'Never'}
    </TruncatedText>
  ),
  sortFunction: (l, r) => sortDate(get(l, 'questionnaireInviteSentAt'), get(r, 'questionnaireInviteSentAt')),
};

export const State = ({ status, state }) => (
  <>
    {status && status.indexOf('Consultation') === 0
      ? surgeonProcedureStatusLabels[status] || status || '-'
      : !state
        ? 'Surgery Planned'
        : state?.__typename === 'Requested'
          ? 'Surgery Scheduled'
          : state?.__typename === 'Confirmed'
            ? 'Surgery Confirmed'
            : state?.__typename === 'Cancelled'
              ? 'Surgery Canceled'
              : state?.__typename === 'Rescheduled'
                ? 'Surgery Rescheduled'
                : state?.__typename || 'Unknown'}
  </>
);

export const NotesStatus = {
  NewNoteFromSC: 'NEW_NOTE_FROM_SC',
  NewNoteFromSO: 'NEW_NOTE_FROM_SO',
  NoNewNotes: 'NO_NEW_NOTES',
};

export const documentsStatusAndRequestStateColumn = {
  title: 'Status',
  lens: data => ({
    status: data.status,
    state: data?.scheduleRequest ? JSON.parse(data.scheduleRequest.state) : null,
    scheduleTransferStatus: data?.scheduleRequest?.status || null,
    scheduleTransferIssues: data?.scheduleRequest?.issues || [],
    notesStatus: data?.scheduleRequest?.notesStatus || NotesStatus.NoNewNotes,
  }),
  Component: ({ data }) => {
    const classes = useStyles();
    const isNotCanceled = data?.state?.__typename !== 'Cancelled';
    const isNew = data?.state?.__typename === 'Requested' && !data?.state?.seen;
    const needDocs =
      isNotCanceled &&
      (data?.scheduleTransferIssues || []).some(issue => JSON.parse(issue.state).__typename === 'Requested');
    const documentsStatusLabel = needDocs ? 'Missing Docs' : '';
    const isNewNote = isNotCanceled && data?.notesStatus === NotesStatus.NewNoteFromSC;

    return (
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box>
          <Chip label={<State {...data} />} />
        </Box>

        {isNew && (
          <Box ml={1}>
            <Chip label="New" size="small" />
          </Box>
        )}

        {isNewNote && (
          <Box ml={1}>
            <Chip label="Note" size="small" />
          </Box>
        )}

        {needDocs && (
          <Box ml={1} display="flex" alignItems="center">
            {
              <Chip
                label={documentsStatusLabel}
                size="small"
                className={clsx({
                  [classes.warning]: needDocs,
                })}
              />
            }
          </Box>
        )}
      </Box>
    );
  },
  style: {
    width: '1%',
  },
};

export const statusColumn = {
  title: 'Status',
  lens: data => ({
    id: data.id,
    status: data.status,
    state: data.scheduleRequest ? JSON.parse(data.scheduleRequest.state) : null,
  }),
  Component: ({ data }) => <Chip label={<State {...data} />} />,
  style: {
    width: '1%',
  },
};

export const actionsColumn = {
  title: 'Actions',
  headerCellStyle: {
    justifyContent: 'flex-end',
  },
  lens: data => ({
    id: data.id,
    transferId: data?.scheduleRequest?.id,
    status: data.status,
    state: data.scheduleRequest ? JSON.parse(data.scheduleRequest.state) : null,
    consultation: {
      consultationDate: data.consultationDate,
      consultationTime: data.consultationTime,
    },
  }),
  Component: ChangeStatusButton,
  useThemeBackgroundColor: true,
  style: {
    position: window.innerWidth > 768 ? 'sticky' : 'initial', // TODO use theme media queries
    right: 0,
    width: '1%',
  },
};

const RatingLink = ({ id, ratingInviteSentAt, patientCellPhone }) => {
  const { data: smsPatientReadyNotification } = useQuery(getNotificationTemplate, {
    variables: { trigger: 'ConsultationComplete', channel: 'SMS' },
  });
  const smsNotificationData = smsPatientReadyNotification?.getNotificationTemplate[0] || null;

  if (ratingInviteSentAt)
    return <TruncatedText>{`Sent on ${format(ratingInviteSentAt, 'MM/DD/YYYY HH:mm')}`}</TruncatedText>;
  else
    return (
      <Mutation mutation={sendRatingInvite}>
        {(mutate, { loading }) => {
          if (!(smsNotificationData?.sendingSetting !== SendingSetting.Off) || !patientCellPhone) {
            // Had to do it like this - It had a bug with passing '' as a content to tooltip (Old value 'Patient mess....' was still displayed even condition was good.
            return (
              <Tooltip
                content={
                  !(smsNotificationData?.sendingSetting !== SendingSetting.Off)
                    ? 'Patient messaging has been turned off. Go to message configuration to enable it.'
                    : 'Patient does not have a phone number.'
                }
              >
                <LinkButton
                  onClick={() => mutate({ variables: { id } })}
                  disabled={
                    loading || !(smsNotificationData?.sendingSetting !== SendingSetting.Off) || !patientCellPhone
                  }
                >
                  Send Rating Invite SMS
                </LinkButton>
              </Tooltip>
            );
          } else
            return (
              <LinkButton
                onClick={() => mutate({ variables: { id } })}
                disabled={loading || !(smsNotificationData?.sendingSetting !== SendingSetting.Off)}
              >
                Send Rating Invite SMS
              </LinkButton>
            );
        }}
      </Mutation>
    );
};

const ratingInviteColumn = {
  title: 'Rating Invite',
  lens: data => data,
  Component: ({ data }) => (
    <RatingLink
      id={data.id}
      patientCellPhone={get(data, 'patientCellPhone')}
      ratingInviteSentAt={get(data, 'patient.ratingInviteSentAt')}
    />
  ),
  sortFunction: (l, r) => sortDate(get(l, 'patient.ratingInviteSentAt'), get(r, 'patient.ratingInviteSentAt')),
};

export const viewColumns = [
  consultationDateColumn,
  surgeryDateColumn,
  physicianColumn,
  patientEmailAddressColumn,
  patientCellPhoneNumberColumn,
  patientHomePhoneNumberColumn,
  patientDobColumn,
  patientSexColumn,
  patientAgeColumn,
  ratingInviteColumn,
  { ...procedureTypeColumn, style: undefined },
  surgeonOfficeLocation,
];

export const consultationColumns = [
  patientIdColumn,
  patientVisitColumn,
  patientNameColumn,
  consultationDateColumn,
  physicianColumn,
  procedureTypeColumn,
  patientDobColumn,
  patientAgeColumn,
  patientSexColumn,
  patientEmailAddressColumn,
  patientCellPhoneNumberColumn,
  patientHomePhoneNumberColumn,
  formFulfillmentColumn,
  questionnaireInviteColumn,
  questionnaireInviteAtColumn,
  documentsStatusAndRequestStateColumn,
  actionsColumn,
];

export const plannedSurgeryColumns = [
  patientIdColumn,
  patientVisitColumn,
  patientNameColumn,
  consultationDateColumn,
  physicianColumn,
  procedureTypeColumn,
  patientDobColumn,
  patientAgeColumn,
  patientSexColumn,
  patientEmailAddressColumn,
  patientCellPhoneNumberColumn,
  patientHomePhoneNumberColumn,
  formFulfillmentColumn,
  questionnaireInviteAtColumn,
  statusColumn,
  actionsColumn,
];

export const scheduledSurgeryColumns = [
  patientIdColumn,
  patientVisitColumn,
  patientNameColumn,
  surgeryDateColumn,
  physicianColumn,
  procedureTypeColumn,
  patientDobColumn,
  patientAgeColumn,
  patientSexColumn,
  patientEmailAddressColumn,
  patientCellPhoneNumberColumn,
  patientHomePhoneNumberColumn,
  formFulfillmentColumn,
  questionnaireInviteAtColumn,
  documentsStatusAndRequestStateColumn,
];
