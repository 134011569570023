import { gql } from '@apollo/client';

export const fragments = {
  all: gql`
    fragment NotificationData on NotificationTemplate {
      id
      name
      content
      description
      trigger
      destination
      subject
      channel
      enabled
      additionalInfo
      sendingSetting
      hasManualSending
    }
  `,
};

export const list = gql`
  query notificationTemplates {
    notificationTemplates {
      ...NotificationData
    }
  }
  ${fragments.all}
`;

export const update = gql`
  mutation editNotificationTemplate(
    $id: Long!
    $name: String!
    $content: String!
    $description: String!
    $trigger: NotificationTemplateTrigger!
    $destination: NotificationTemplateDestination!
    $subject: String
    $additionalInfo: String
  ) {
    editNotificationTemplate(
      id: $id
      name: $name
      content: $content
      description: $description
      trigger: $trigger
      destination: $destination
      subject: $subject
      additionalInfo: $additionalInfo
    ) {
      id
      name
      content
      description
      trigger
      destination
      enabled
      subject
      additionalInfo
      hasManualSending
    }
  }
`;

export const updateNotificationTemplateSendingSetting = gql`
  mutation updateNotificationTemplateSendingSetting($id: Long!, $sendingSetting: NotificationTemplateSendingSetting!) {
    updateNotificationTemplateSendingSetting(id: $id, sendingSetting: $sendingSetting) {
      id
      name
      content
      description
      trigger
      destination
      sendingSetting
      hasManualSending
    }
  }
`;

export const getNotificationTemplate = gql`
  query getNotificationTemplate(
    $trigger: NotificationTemplateTrigger!
    $channel: NotificationTemplateChannel
    $destination: NotificationTemplateDestination
  ) {
    getNotificationTemplate(trigger: $trigger, channel: $channel, destination: $destination) {
      ...NotificationData
    }
  }
  ${fragments.all}
`;

export default {
  list,
  update,
};
