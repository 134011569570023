import React, { Children, ReactNode } from 'react';
import blanksContext from './blanksContext';

interface BlanksProps {
  labelsPosition?: 'before' | 'below';
  children: ReactNode;
}

const Blanks = ({ labelsPosition, children }: BlanksProps) => (
  <div
    style={{
      display: 'grid',
      gridTemplateColumns: `repeat(${Children.count(children)}, 1fr)`,
      gap: '1em',
      paddingTop: labelsPosition === 'below' ? '2.5em' : '1em',
    }}
  >
    <blanksContext.Provider value={{ labelsPosition: labelsPosition ?? 'before' }}>
      {Children.map(children, (child, i) => (
        <div key={i} style={{ display: 'flex', alignItems: 'stretch', justifyContent: 'stretch' }}>
          {child}
        </div>
      ))}
    </blanksContext.Provider>
  </div>
);

export default Blanks;
