import React, { useMemo, useEffect, useState, useRef } from 'react';
import styles from '../../styles';
import MedicalPassportTextArea from '../../components/MedicalPassportTextArea';
import MedicalPassportAllergiesRow from '../../components/MedicalPassportAllergiesRow';
import MedicalPassportField from '../../components/MedicalPassportField';
import MedicalPassportCheckbox from '../../components/MedicalPassportCheckbox';
import toHeight from '../../../../../../questionnaire/toHeight';
import toHighlightsString from '../../../../../../questionnaire/toHighlightsString';
import toWeightKgLbs from '../../../../../../questionnaire/toWeightKgLbs';
import toAge from '../../../../../../questionnaire/toAge';
import toSex from '../../../../../../questionnaire/toSex';
import getAgeAndBmi from './pageUtils';
import { testReducer } from '../../../../../../questionnaire/fallRiskReducers';
import Barcode from '../../../Barcode';
import toHighlightsString4 from '../../../../../../questionnaire/toHighlightsString4';
import { getcontent } from '../../golfCoast/pages/contentlimit';
import toHighlightsString5 from '../../../../../../questionnaire/toHighlightsString5';

const columnStyle = { textAlign: 'left', paddingLeft: '0.5em' };

const activityAssessment = [
  {
    label: 'Ambulatory',
    name: 'ambulatory',
  },
  {
    label: 'Ambulatory w/ assistance',
    name: 'ambulatoryWithAssistance',
  },
];

const assistiveDevice = [
  {
    label: 'Crutches',
    name: 'crutches',
  },
  {
    label: 'Walker',
    name: 'walker',
  },
  {
    label: 'Wheelchair',
    name: 'wheelchair',
  },
  {
    label: 'Cane (See Fall Assessment Record)',
    name: 'cane',
  },
];

const checkboxesPage5 = [
  {
    text: 'Level of Consciousness',
    checkboxes: [
      {
        label: 'Alert/Oriented',
        name: 'page5.alertOriented',
      },
      {
        label: 'See Notes',
        name: 'page5.seeNotes1',
      },
    ],
  },
  {
    text: 'Emotional Status',
    checkboxes: [
      {
        label: 'Calm',
        name: 'page5.calm',
      },
      {
        label: 'Anxious',
        name: 'page5.anxious',
      },
      {
        label: 'See Notes',
        name: 'page5.seeNotes2',
      },
    ],
  },
  {
    text: 'Respiratory Status',
    checkboxes: [
      {
        label: 'Spontaneous',
        name: 'page5.spontaneous',
      },
      {
        label: 'See Notes',
        name: 'page5.seeNotes3',
      },
      {
        label: 'Cardiac',
        name: 'page5.cardiac',
      },
      {
        label: 'Pacemaker/AICD',
        name: 'page5.pacemaker',
      },
      {
        label: 'Stents',
        name: 'page5.stents',
      },
    ],
  },
  {
    text: 'Skin Condition',
    checkboxes: [
      {
        label: 'Warm/dry/intact',
        name: 'page5.warmDryIntact',
      },
      {
        label: 'Other:',
        name: 'page5.otherSkinCondition',
      },
    ],
  },
];

const CommonPartOne = () => (
  <div>
    <div style={styles.sectionHeader}>NURSING NOTES</div>
    {[...Array(5)].map((e, index) => (
      <div style={styles.sectionEmptyRow} key={'note' + index}>
        {''}
      </div>
    ))}
    <div style={styles.sectionRow}>
      <div style={{ display: 'flex' }}>
        <MedicalPassportCheckbox name="page5.crutchesYes" label="Crutches provided & training completed" />
        <MedicalPassportCheckbox name="page5.crutchesNo" label="N/A" />
      </div>
    </div>
  </div>
);

export const CommonPartP5P6 = ({ providerId, showQRCode, age }) => (
  <div style={{ ...styles.border, position: 'relative' }}>
    <div style={{ bottom: '5px', paddingLeft: '3px' }}>
      <div style={{ marginBottom: '2px' }}>
        <MedicalPassportField name="procedure.patient.name" label="Patient" fontWeight={'bold'} fontSize={14} />
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '2px' }}>
        <MedicalPassportField name="dob" label="DOB" path={['demographicInformation', 'dob']} fontSize={12} />
        <MedicalPassportField name="procedure.serviceTime" label="DOS" fontWeight={700} fontSize={12} />
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '2px' }}>
        <MedicalPassportField name="procedure.patient.providerId" label="MRN#" fontSize={12} />
        <MedicalPassportField name="procedure.physician.name" label="Surgeon" fontSize={12} />
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '2px', paddingBottom: '1px' }}>
        <MedicalPassportField
          name="age"
          label="Age"
          path={['demographicInformation', 'dob']}
          reducer={toAge}
          warning={age > 70}
          fontSize={12}
        />
        <MedicalPassportField
          name="sex"
          label="Sex"
          path={['demographicInformation', 'sex']}
          reducer={toSex}
          fontSize={12}
        />
      </div>
    </div>
  </div>
);

const Page5 = ({ formName, pageNumber, showQRCode, hospitalName, value, defaultValue }) => {
  const providerId = defaultValue?.procedure?.patient?.providerId;
  const weightLbs = parseInt(defaultValue?.demographicInformation?.bmi?.weightLb);
  const { age } = getAgeAndBmi(defaultValue?.demographicInformation?.dob, defaultValue?.demographicInformation?.bmi);

  const pageRef = useRef(null);
  const [pageStyle, setPageStyle] = useState({});

  useEffect(() => {
    const handleResize = () => {
      if (pageRef.current) {
        const pageHeight = pageRef.current.offsetHeight;
        if (pageHeight > 1079) {
          setPageStyle({ minHeight: 2114 });
        } else {
          setPageStyle({});
        }
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div id="page5" className="page" style={{ fontSize: '11px', fontFamily: 'Arial', ...pageStyle }}>
      <div ref={pageRef}>
        <div style={styles.twoColumns}>
          <div>
            <div>{hospitalName || ''}</div>
            <h2>Pre-op Nursing Assessment</h2>
          </div>
          <div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '2px' }}>
              {showQRCode && (
                <Barcode
                  formName={formName}
                  pageNumber={pageNumber}
                  providerId={defaultValue?.procedure?.patient?.providerId}
                  serviceTime={defaultValue?.procedure?.serviceTime}
                />
              )}
            </div>
            <CommonPartP5P6 age={age} showQRCode={showQRCode} providerId={providerId} />
          </div>
        </div>
        <div style={styles.allergiesSection}>
          <div style={styles.activeTab}>ALLERGIES</div>
          <MedicalPassportTextArea
            name="allergies2"
            path={['allergies']}
            reducer={toHighlightsString5}
            warning={true}
            fontSize={12}
          />
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
          <div style={{ lineHeight: '15px' }}>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '3fr 1fr 1fr 1fr 4fr',
                fontWeight: 'bold',
                border: '1px solid black',
                borderTop: 0,
                borderBottom: 0,
                lineHeight: '1.6em',
                padding: '0 0.1rem',
              }}
            >
              <div style={{ borderRight: '1px solid black' }}>&nbsp;</div>
              <div style={{ borderRight: '1px solid black', padding: '0 0.1rem' }}>Yes</div>
              <div style={{ borderRight: '1px solid black', padding: '0 0.1rem' }}>No</div>
              <div style={{ borderRight: '1px solid black', padding: '0 0.1rem' }}>N/A</div>
              <div style={{ padding: '0 0.1rem' }}>Comments:</div>
            </div>
            <MedicalPassportAllergiesRow text="Caps/Crowns" name="page5.capsCrowns" />
            <MedicalPassportAllergiesRow text="Loose Teeth" name="page5.looseTeeth" />
            <MedicalPassportAllergiesRow text="Dentures" name="page5.dentures" />
            <MedicalPassportAllergiesRow text="Glasses" name="page5.glasses" />
            <MedicalPassportAllergiesRow text="Contact Lenses" name="page5.contactLenses" />
            <MedicalPassportAllergiesRow text="Hearing Aids" name="page5.hearingAids" />
            <MedicalPassportAllergiesRow text="Jewelry/Piercings" name="page5.jewelry" />
            <MedicalPassportAllergiesRow text="Pre-Op Clipping" name="page5.preopClipping" />
            <div style={{ border: '1px solid black', padding: '0 0.1rem' }}>
              <MedicalPassportField name="npoTime" label="NPO Time" />
            </div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '2fr 1fr 1fr 3fr',
                borderLeft: '1px solid black',
              }}
            >
              <div>Interpreter needed?</div>

              <MedicalPassportCheckbox
                name="page5.interpreterNeededYes"
                path={['basicscreening', 'interpreter']}
                label="Yes"
              />
              <MedicalPassportCheckbox name="page5.interpreterNeededNo" label="N/A" />
              <MedicalPassportField name="interpreterText" path={['basicscreening', 'interpreter', 'yes']} />
            </div>
          </div>
          <div
            style={{
              lineHeight: '1.8em',
              padding: '0 0.1rem 0 0.2rem',
              borderRight: '1px solid black',
            }}
          >
            <div style={{ display: 'flex', gap: '8px' }}>
              <MedicalPassportField name="page5.timeIn" label="Time In" underline />
              <MedicalPassportField name="page5.weightDOS" label="Weight DOS" underline />
            </div>
            <div style={{ display: 'flex', gap: '8px' }}>
              <MedicalPassportField
                name="ht"
                label="Height"
                path={['demographicInformation', 'bmi']}
                reducer={toHeight}
                underline
              />
              <MedicalPassportField
                name="wt"
                label="Weight"
                path={['demographicInformation', 'bmi']}
                reducer={toWeightKgLbs}
                warning={weightLbs > 500}
                underline
              />
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: '2fr 2fr 1fr', gap: '1em' }}>
              <MedicalPassportField name="page5.voided" label="Voided" underline />
              <MedicalPassportField name="page5.hcg" label="HCG" underline />
              <MedicalPassportCheckbox name="page5.waiver" label="Waiver" />
            </div>
            <div style={{ display: 'flex', gap: '5px' }}>
              <MedicalPassportField name="page5.painScale" label="Pain" underline />
              <MedicalPassportField name="page5.locationPainScale" label="Location" underline />
            </div>
            <div>
              <MedicalPassportField name="page5.painInfo" label="" underline />
            </div>
            <div style={{ display: 'flex', gap: '5px' }}>
              <div>IV:</div>
              <MedicalPassportCheckbox name="page5.lr" label="LR @ 30ml/hr" />
              <MedicalPassportCheckbox name="page5.ns" label="NS @ 30ml/hr" />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'baseline',
                }}
              >
                <MedicalPassportCheckbox name="page5.other3" label="Other: " />
                <div>
                  <input type="text" name="handoff" style={{ ...styles.inputField, width: '55px' }} />
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', gap: '5px' }}>
              <div>Cath:</div>
              <MedicalPassportCheckbox name="page5.22g" label="22G" />
              <MedicalPassportCheckbox name="page5.20g" label="20G" />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'baseline',
                }}
              >
                <MedicalPassportCheckbox name="page5.otherCath" label="Other: " />
                <MedicalPassportField name="page5.otherCathText" underline />
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <div>Site:</div>
              <MedicalPassportCheckbox name="page5.right" label="Right" />
              <MedicalPassportCheckbox name="page5.left" label="Left" />
              <MedicalPassportCheckbox name="page5.hand" label="Hand" />
              <MedicalPassportCheckbox name="page5.forearm" label="Forearm" />
              <MedicalPassportCheckbox name="page5.anticubital" label="Anticubital" />
              <MedicalPassportCheckbox name="page5.other4" label="Other" />
            </div>
            <div style={{ display: 'flex', gap: '4px' }}>
              <MedicalPassportField name="page5.insertedBy" label="Inserted By" underline />
              <MedicalPassportCheckbox name="page5.ivOrdered" label="NO IV ordered" />
            </div>
            <div>
              <MedicalPassportCheckbox name="page5.ivStarted" label="IV started by Anesthesia" />
              <div style={{ display: 'flex', gap: '4px', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex' }}>
                  <MedicalPassportCheckbox name="page5.metalOnTheBody" label="Metal in the body" />
                  <MedicalPassportField name="page5.locationMetalOnTheBody" label="Location" underline />
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <MedicalPassportCheckbox name="page5.glucose" label="Glucose  " />
                <span> &nbsp; __________________</span>
                <MedicalPassportCheckbox name="page5.nA" label="N/A" style={{ marginRight: '1em' }} />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            borderRight: '1px solid black',
            borderLeft: '1px solid black',
          }}
        >
          <div>
            <div style={{ display: 'flex', gap: '4px' }}>
              BP __________ , P __________ , R __________ , 02 Sat _____________ % T _____________
            </div>
          </div>
        </div>
        <div style={styles.border}>
          {checkboxesPage5.map((e, i) => (
            <div style={{ borderBottom: '1px solid black' }} key={'allergies' + i}>
              <div
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  marginRight: '1.5rem',
                  marginLeft: '2px',
                  fontWeight: 'bold',
                }}
              >
                {e.text}
              </div>
              {e.checkboxes.map(e => (
                <MedicalPassportCheckbox
                  name={e.name}
                  label={e.label}
                  key={e.name}
                  style={{ display: 'inline-flex', marginRight: '1.5rem' }}
                />
              ))}
            </div>
          ))}
        </div>
        <div style={{ ...styles.sectionHeader }}>ACTIVITY ASSESSMENT</div>
        <div style={styles.border}>
          {activityAssessment.map(e => (
            <MedicalPassportCheckbox
              name={e.name}
              label={e.label}
              key={'assessment' + e.name}
              style={{ display: 'inline-block', marginRight: '0.5rem' }}
            />
          ))}
          {assistiveDevice.map(e => (
            <MedicalPassportCheckbox
              name={e.name}
              label={e.label}
              key={'assessment' + e.name}
              style={{ display: 'inline-block', marginRight: '0.5rem' }}
              path={['basicscreening', 'device', 'yes', 'list']}
              reducer={testReducer(e.name.toLowerCase())}
            />
          ))}

          <MedicalPassportCheckbox
            name="page5.fallPreventionGuidelines"
            label="Verbal and written Fall Prevention Guidelines given to Patient & Family"
          />
          <MedicalPassportCheckbox
            name="page5.DVTPreventionInstructions"
            label="Verbal and written DVT Prevention instructions given to patient"
          />
        </div>
        <div style={{ ...styles.sectionHeader }}>PREOPERATIVE MEDICATIONS</div>
        <div style={styles.border}>
          <div style={styles.fiveColumns}>
            <div>Medication</div>
            <div>Dose</div>
            <div>Route</div>
            <div>Time</div>
            <div>Initials</div>
          </div>
          {[...Array(3)].map((e, i) => (
            <div style={styles.fiveColumns} key={'medication' + i}>
              <MedicalPassportField name={`medication${i}`} label={`${i + 1}`} underline />
              <MedicalPassportField name={`dose${i}`} underline />
              <MedicalPassportField name={`route${i}`} underline />
              <MedicalPassportField name={`time${i}`} underline />
              <MedicalPassportField name={`initial${i}`} underline />
            </div>
          ))}
        </div>

        <div style={styles.twoNotEqualColumns}>
          <CommonPartOne />
          <div>
            <div style={styles.sectionHeader}>ADDITIONAL VITAL SIGNS</div>
            <div style={styles.twoNotEqualColumns2}>
              <div style={{ ...styles.sectionRow, ...styles.twoColumns }}>
                <div style={{ borderRight: '1px solid black', textAlign: 'left', paddingLeft: '0.5em' }}>Time:</div>
                <div style={{ textAlign: 'left', paddingLeft: '0.5em' }}>BP</div>
              </div>
              <div style={{ ...styles.sectionRow, textAlign: 'left', paddingLeft: '0.5em' }}>P</div>
              <div style={{ ...styles.sectionRow, textAlign: 'left', paddingLeft: '0.5em' }}>SaO2</div>
            </div>
            {[...Array(5)].map((e, index) => (
              <div style={styles.twoNotEqualColumns2} key={'vitalSign' + index}>
                <div style={{ ...styles.sectionRow, ...styles.twoColumns }}>
                  <div style={{ borderRight: '1px solid black', ...columnStyle, height: '20px' }}>{''}</div>
                  <div style={{ ...columnStyle, height: '20px' }}>{''}</div>
                </div>
                <div style={{ ...styles.sectionRow, ...columnStyle, height: '20px' }}>{''}</div>
                <div style={{ ...styles.sectionRow, ...columnStyle, height: '20px' }}>{''}</div>
              </div>
            ))}

            <div style={{ display: 'flex', flexDirection: 'column', padding: '0.25em' }}>
              <div style={{ display: 'flex' }}>
                <span>Hand-Off Completed</span>
                <MedicalPassportCheckbox name="page5.handOffCompleted" />
              </div>
              <span>PAMS(Procedure/Allergies/Medications/Special Meds)</span>
              <div style={{ display: 'flex' }}>
                <span>Site marked by:</span>
                <MedicalPassportCheckbox name="page5.surgeonPa" label="Surgeon/PA (circle)" />
                <MedicalPassportCheckbox name="page5.surgeonPaNa" label="N/A" />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            spaceBetween: 'justify-content',
          }}
        >
          <div style={styles.signatureBox1}>
            <div>
              <div style={{ paddingTop: '1.5rem' }}>______________________________</div>
              <div>Pre-Op RN/CNA/MA Signature</div>
            </div>
            <div>
              <div style={{ marginLeft: '1rem', marginTop: '1.5rem' }}>____________________________________</div>
              <div>Date</div>
            </div>
          </div>

          <div style={{ paddingTop: '1.5rem', marginLeft: 'auto' }}>
            <div>____________________________________</div>
            <div style={{ padding: '0.25em', textAlign: 'center' }}>Circulator RN Signature</div>
          </div>
        </div>
        <MedicalPassportCheckbox name="page5.ekgStrip" label="EKG Strip" />
      </div>
    </div>
  );
};

export default Page5;
