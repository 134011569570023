import React, { FC, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { sendAnalyticsReport } from '../../../../graph/dashboard';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AnalyticsPdfMenu from '../pdf/AnalyticsPdfMenu';
import { YearMonth } from '@js-joda/core';
import Configuration from './Configuration';
import Form from '../../../../se/components/Form';
import useModal from '../../../../hooks/useModal';
import { RouterlessModal } from '../../../../se/components/Modal';
import { withProps } from 'recompose';
import { breakpoint } from '../../../../se/utilities/responsive';
import { withLabel } from '../../../../se/components/Label';
import TextInput from '../../../../se/components/inputs/TextInput';
import ObjectInput from '../../../../se/components/inputs/ObjectInput';

const MonthYearInput = withProps({
  schema: {
    monthYear: withLabel('Enter month')(TextInput),
  },
})(ObjectInput);

const Header: FC<{ filter: any; thresholds: any }> = ({ filter, thresholds }) => {
  const [lastDetailedReport, setLastDetailedReport] = React.useState<any>(null);
  const { open, openModal, closeModal } = useModal();
  const [sendReport] = useMutation(sendAnalyticsReport);

  useEffect(() => {
    if (lastDetailedReport !== null) {
      const timeout = setTimeout(() => {
        setLastDetailedReport(null);
      }, 60000);

      return () => clearTimeout(timeout);
    }
  }, [lastDetailedReport]);

  const handleSubmit = async values => {
    await sendReport({
      variables: {
        monthYear: values.monthYear,
      },
    });
    closeModal();
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="h1">Analytics</Typography>
      <Box display="flex">
        <AnalyticsPdfMenu filter={filter} thresholds={thresholds} />
        <Configuration />
        {open && (
          <RouterlessModal
            title={'Send Analytics Report'}
            onClose={closeModal}
            fullWindow={window.innerWidth < breakpoint.md}
          >
            <Form
              autoFocus
              initialValue={{ monthYear: YearMonth.now().toString() }}
              input={MonthYearInput}
              label={'Send'}
              onSubmit={handleSubmit}
              onCancel={closeModal}
            />
          </RouterlessModal>
        )}
      </Box>
    </Box>
  );
};

export default Header;
