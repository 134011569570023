import React, { ReactNode } from 'react';
import clsx from 'clsx';

interface SectionProps {
  title?: ReactNode;
  outlined?: boolean;
  dense?: boolean;
  denseComfy?: boolean;
  children?: ReactNode;
  className?: string;
}

const Section = ({ title, outlined, dense, children, denseComfy, className }: SectionProps) => (
  <div
    className={clsx('f-section f-cols-12', { outlined: outlined, dense: dense, 'dense-comfy': denseComfy }, className)}
  >
    {title && <h2 className="f-uppercase f-span-12 f-bg-light f-center">{title}</h2>}
    {children}
  </div>
);

export default Section;
