import React, { useContext, useEffect, useRef, useState } from 'react';
import IMask from 'imask';
import { Box, FormLabel, TextField } from '@material-ui/core';
import { TextAnswer } from '../../types/Answer';
import { TextQuestion } from '../../types/Question';
import { useSave } from '../../components/pages/kiosk/charting/SaveProvider';
import { CompletionContext, ConditionContext, DischargeTimeContext, getAllergies, useSlideIn } from '../Form';
import { format as formatDate } from 'date-fns';
import Typography from '@material-ui/core/Typography';
import ReactHtmlParser from 'react-html-parser';
import { useQuery } from '@apollo/client';
import { procedureSteps } from '../../graph/procedureSteps';
import { usePatientIdContext } from '../../components/pages/kiosk/charting/PatientIdContextProvider';
import { useChartingSession } from '../../components/pages/kiosk/charting/modules/hooks';
import { usePreOpCharts } from '../../components/pages/kiosk/charting/PreOpChartsContext';

type TextInputProps = TextQuestion['props'] & {
  name?: string;
  answer?: TextAnswer;
  onChange?: (newAnswer: TextAnswer) => void;
};

export const parseHeight = (val?: string) => {
  const match = val?.match(/((\d*)ft)*\s*((\d*)in)*/);
  return [match?.[2], match?.[4]];
};

const LimitHelper = ({ limit, used }) => {
  if (used > 0) {
    const remaining = limit - used;
    return (
      <Typography variant="caption">{`${
        remaining === 1 ? 'One character' : `${remaining} other characters`
      } remaining`}</Typography>
    );
  } else {
    return (
      <Typography variant="caption">{`Maximum length: ${
        limit === 1 ? 'One character' : `${limit} characters`
      }`}</Typography>
    );
  }
};

const TextInput = ({
  name,
  optional,
  preFill,
  preFillWith,
  useNurseName,
  label,
  format,
  answer,
  onChange,
  maxLength,
  maxRows,
  width,
}: TextInputProps) => {
  const inputRef1 = useRef<HTMLInputElement | undefined>();
  const inputRef2 = useRef<HTMLInputElement | undefined>();

  const onComplete = useContext(CompletionContext);

  const isNumeric =
    format === 'LBS' ||
    format === 'ML' ||
    format === 'AMT' ||
    format === 'AdmissionO2' ||
    format === 'BMI' ||
    format === 'BloodGlucose' ||
    format === 'BloodPressure' ||
    format === 'Drain' ||
    format === 'HeartRate' ||
    format === 'Height' ||
    format === 'Hemoglobin' ||
    format === 'IVRate' ||
    format === 'IVSize' ||
    format === 'MilitaryTime' ||
    format === 'DateTime' ||
    format === 'Date' ||
    format === 'Outcome' ||
    format === 'Pain' ||
    format === 'RespirationRate' ||
    format === 'Saturation' ||
    format === 'Temperature' ||
    format === 'TemperatureF' ||
    format === 'Totals' ||
    format === 'Weight';
  const session = useChartingSession();

  useEffect(() => {
    if (preFill && format === 'MilitaryTime' && (answer === null || answer === undefined)) {
      onChange?.(formatDate(new Date(), 'HH:mm'));
    } else if (preFill && format === 'DateTime' && (answer === null || answer === undefined)) {
      onChange?.(formatDate(new Date(), 'MM/DD/YYYY HH:mm'));
    } else if (preFill && format === 'Date' && (answer === null || answer === undefined)) {
      onChange?.(formatDate(new Date(), 'MM/DD/YYYY'));
    } else if (useNurseName && session?.name && !answer) {
      onChange?.(session?.name);
    }
  });

  useEffect(() => {
    if (!inputRef1.current) {
      return;
    }

    if (format === 'BloodPressure') {
      const bloodPressureMask = IMask(inputRef1.current, {
        mask: [
          {
            mask: '#0/@0',
            definitions: {
              '#': /[3-9]/,
              '@': /[2-9]/,
            },
          },
          {
            mask: '#0/100',
            definitions: {
              '1': /1/,

              '#': /[3-9]/,
              '@': /[2-9]/,
            },
          },
          {
            mask: '100/@0',
            definitions: {
              '1': /[12]/,
              '@': /[2-9]/,
            },
          },
          {
            mask: '100/100',
            definitions: {
              '1': /[12]/,
            },
          },
        ],
      });

      bloodPressureMask.on('complete', () => {
        requestAnimationFrame(() => {
          onComplete();
        });
      });

      return () => bloodPressureMask.destroy();
    }

    if (format === 'Hemoglobin') {
      const hemoglobinMask = IMask(inputRef1.current, {
        mask: '00{.}0',
      });

      return () => hemoglobinMask.destroy();
    }

    if (format === 'LBS' || format === 'ML') {
      const hemoglobinMask = IMask(inputRef1.current, {
        mask: /^(\d*\.?\d+)|(\d+)$/,
      });

      return () => hemoglobinMask.destroy();
    }

    if (format === 'AdmissionO2' || format === 'Outcome') {
      const mask = /^\d$/;
      const digitsMask = IMask(inputRef1.current, {
        mask,
      });

      return () => digitsMask.destroy();
    }

    if (format === 'Pain') {
      const digitsMask = IMask(inputRef1.current, {
        mask: [
          {
            mask: '!0',
            definitions: {
              '!': /[1]/,
              '0': /[0]/,
            },
          },
          {
            mask: '!',
            definitions: {
              '!': /[2-9]/,
            },
          },
        ],
      });

      digitsMask.on('complete', () => {
        requestAnimationFrame(() => {
          onComplete();
        });
      });

      return () => digitsMask.destroy();
    }

    if (format === 'IVSize' || format === 'Drain') {
      const mask = /^\d{1,2}$/;
      const digitsMask = IMask(inputRef1.current, {
        mask,
      });

      return () => digitsMask.destroy();
    }

    if (format === 'HeartRate') {
      const digitsMask = IMask(inputRef1.current, {
        mask: [
          {
            mask: '!00',
            definitions: {
              '!': /[12]/,
            },
          },
          {
            mask: '!0',
            definitions: {
              '!': /[3-9]/,
            },
          },
        ],
      });

      digitsMask.on('complete', () => {
        requestAnimationFrame(() => {
          onComplete();
        });
      });

      return () => digitsMask.destroy();
    }

    if (format === 'IVRate' || format === 'BloodGlucose') {
      const mask = /^\d{1,3}$/;
      const digitsMask = IMask(inputRef1.current, {
        mask,
      });

      return () => digitsMask.destroy();
    }

    if (format === 'AMT' || format === 'Totals') {
      const mask = /^\d{1,4}$/;
      const digitsMask = IMask(inputRef1.current, {
        mask,
      });

      return () => digitsMask.destroy();
    }

    if (format === 'Weight') {
      const digitsMask = IMask(inputRef1.current, {
        mask: '000',
      });

      digitsMask.on('complete', () => {
        requestAnimationFrame(() => {
          onComplete();
        });
      });

      return () => digitsMask.destroy();
    }

    if (format === 'Saturation') {
      const digitsMask = IMask(inputRef1.current, {
        mask: [
          {
            mask: '!zz',
            definitions: {
              '!': /1/,
              z: /0/,
            },
          },
          {
            mask: '@0',
            definitions: {
              '@': /[2-9]/,
            },
          },
        ],
      });

      digitsMask.on('complete', () => {
        requestAnimationFrame(() => {
          onComplete();
        });
      });

      return () => digitsMask.destroy();
    }

    if (format === 'RespirationRate') {
      const digitsMask = IMask(inputRef1.current, {
        mask: '00',
      });

      digitsMask.on('complete', () => {
        requestAnimationFrame(() => {
          onComplete();
        });
      });

      return () => digitsMask.destroy();
    }

    if (format === 'Height') {
      if (!inputRef2.current) {
        return;
      }

      const digitsMask1 = IMask(inputRef1.current, {
        mask: '4',
        definitions: {
          '4': /[4-8]/,
        },
      });

      const digitsMask2 = IMask(inputRef2.current, {
        mask: [
          {
            mask: 'n',
            definitions: {
              n: /[2-9]/,
            },
          },
          {
            mask: '1!',
            definitions: {
              '1': /1/,
              '!': /[01]/,
            },
          },
        ],
      });

      digitsMask1.on('complete', () => {
        inputRef2.current?.focus();
      });

      digitsMask2.on('complete', () => {
        requestAnimationFrame(() => {
          onComplete();
        });
      });

      return () => {
        digitsMask1.destroy();
        digitsMask2.destroy();
      };
    }

    if (format === 'Temperature') {
      const input = inputRef1.current;

      if (!input) {
        return;
      }

      const digitsMask = IMask(input, {
        mask: [
          {
            mask: '!00.0',
            definitions: {
              '!': /1/,
              '.': /\./,
            },
          },
          {
            mask: '@0.0',
            definitions: {
              '@': /[2-9]/,
              '.': /\./,
            },
          },
        ],
      });

      digitsMask.on('accept', () => {
        if (digitsMask.value.length < 1) {
          return;
        }

        if (digitsMask.value.startsWith('1')) {
          if (digitsMask.value.length === 3) {
            digitsMask.value += '.';
            requestAnimationFrame(() => {
              digitsMask.updateCursor(4);
            });
          }
        } else {
          if (digitsMask.value.length === 2) {
            digitsMask.value += '.';
            requestAnimationFrame(() => {
              digitsMask.updateCursor(3);
            });
          }
        }
      });

      digitsMask.on('complete', () => {
        requestAnimationFrame(() => {
          onComplete();
        });
      });

      return () => digitsMask.destroy();
    }

    if (format === 'TemperatureF') {
      const input = inputRef1.current;

      if (!input) {
        return;
      }

      const digitsMask = IMask(input, {
        mask: [
          {
            mask: '!00.0',
            definitions: {
              '!': /1/,
              '.': /\./,
            },
          },
          {
            mask: '@0.0',
            definitions: {
              '@': /[2-9]/,
              '.': /\./,
            },
          },
        ],
      });

      digitsMask.on('accept', () => {
        if (digitsMask.value.length < 1) {
          return;
        }

        if (digitsMask.value.startsWith('1')) {
          if (digitsMask.value.length === 3) {
            digitsMask.value += '.';
            requestAnimationFrame(() => {
              digitsMask.updateCursor(4);
            });
          }
        } else {
          if (digitsMask.value.length === 2) {
            digitsMask.value += '.';
            requestAnimationFrame(() => {
              digitsMask.updateCursor(3);
            });
          }
        }
      });

      digitsMask.on('complete', () => {
        requestAnimationFrame(() => {
          onComplete();
        });
      });

      return () => digitsMask.destroy();
    }

    if (format === 'BMI') {
      const digitsMask = IMask(inputRef1.current, {
        mask: '00{.}0',
      });

      return () => digitsMask.destroy();
    }

    if (format === 'Date') {
      const dateMask = IMask(inputRef1.current, {
        mask: 'MM{/}DD{/}YYYY',
        blocks: {
          MM: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 12,
          },
          DD: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 31,
          },
          YYYY: {
            mask: IMask.MaskedRange,
            from: 1900,
            to: 2100,
          },
        },
      });

      dateMask.on('complete', () => {
        requestAnimationFrame(() => {
          onComplete();
        });
      });

      return () => dateMask.destroy();
    }

    if (format === 'DateTime') {
      const dateTimeMask = IMask(inputRef1.current, {
        mask: 'MM{/}DD{/}YYYY{ }HH{:}mm',
        blocks: {
          MM: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 12,
          },
          DD: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 31,
          },
          YYYY: {
            mask: IMask.MaskedRange,
            from: 1900,
            to: 2100,
          },
          HH: {
            mask: IMask.MaskedRange,
            from: 0,
            to: 23,
          },
          mm: {
            mask: IMask.MaskedRange,
            from: 0,
            to: 59,
          },
        },
      });

      dateTimeMask.on('complete', () => {
        requestAnimationFrame(() => {
          onComplete();
        });
      });

      return () => dateTimeMask.destroy();
    }

    if (format === 'MilitaryTime') {
      const militaryTimeMask = IMask(inputRef1.current, {
        mask: 'HH{:}MM',
        blocks: {
          HH: {
            mask: IMask.MaskedRange,
            from: 0,
            to: 23,
          },
          MM: {
            mask: IMask.MaskedRange,
            from: 0,
            to: 59,
          },
        },
      });

      militaryTimeMask.on('complete', () => {
        requestAnimationFrame(() => {
          onComplete();
        });
      });

      return () => militaryTimeMask.destroy();
    }
  }, [format]);

  const [invalid, setInvalid] = useState(false);

  const { validationRequest } = useSave();
  const slideIn = useSlideIn();

  const autofocus = useContext(ConditionContext);

  useEffect(() => {
    if (optional) {
      return;
    }

    if (validationRequest) {
      if (!answer) {
        setInvalid(true);
        slideIn();
      } else if (invalid) {
        setInvalid(false);
      }
    }
  }, [validationRequest, invalid, answer, optional, slideIn]);

  if (format === 'Height') {
    const [fts, inches] = parseHeight(answer);

    return (
      <Box display="flex" flexDirection="row" alignItems="center" style={{ maxWidth: '20rem' }}>
        <Box style={{ marginRight: '1em' }}>
          <FormLabel>HT</FormLabel>
        </Box>
        <TextField
          inputRef={inputRef1}
          type="text"
          autoComplete="off"
          label={'Feet'}
          variant="filled"
          aria-label={'Feet'}
          name={name}
          inputProps={{
            inputMode: isNumeric ? 'numeric' : undefined,
          }}
          fullWidth={false}
          value={fts ?? ''}
          onChange={e => {
            const text = inches ? `${e.target.value}ft ${inches}in` : `${e.target.value}ft`;
            onChange?.(text);
            setInvalid(false);
          }}
          style={{ marginRight: '1em' }}
          error={invalid}
        />
        <TextField
          inputRef={inputRef2}
          autoComplete="off"
          type="text"
          label={'Inches'}
          variant="filled"
          aria-label={'Inches'}
          name={name}
          inputProps={{
            inputMode: isNumeric ? 'numeric' : undefined,
          }}
          value={inches ?? ''}
          onChange={e => {
            const text = fts ? `${fts}ft ${e.target.value}in` : `${e.target.value}in`;
            onChange?.(text);
            setInvalid(false);
          }}
          error={invalid}
          fullWidth={false}
        />
      </Box>
    );
  }

  useEffect(() => {
    if (!autofocus || Boolean(answer)) {
      return;
    }

    let unmounted = false;

    const loop = (retriesLeft: number) =>
      requestAnimationFrame(() => {
        if (unmounted) {
          return;
        }

        const input = inputRef1.current;

        if (!input) {
          if (retriesLeft <= 0) {
            return;
          }

          return loop(retriesLeft - 1);
        }

        if (document.activeElement === input) {
          return;
        }

        input.focus();

        if (document.activeElement !== input) {
          if (retriesLeft <= 0) {
            return;
          }

          return loop(retriesLeft - 1);
        }
      });

    loop(60);

    return () => {
      unmounted = true;
    };
  }, [autofocus]);

  const dischargeTime = useContext(DischargeTimeContext);

  const patientId = usePatientIdContext();

  const preOpCharts = usePreOpCharts();

  const { data: procedureStepsData } = useQuery(procedureSteps, {
    variables: {
      patientId,
    },
    skip:
      patientId === undefined ||
      Boolean(answer) ||
      !onChange ||
      ['PACUTime', 'AnesthesiaEndTime'].indexOf(preFillWith?.type!) < 0,
  });

  useEffect(() => {
    if (answer || !onChange) {
      return;
    }

    switch (preFillWith?.type) {
      case 'DischargeTime':
        if (!dischargeTime) {
          break;
        }

        onChange(formatDate(dischargeTime, `HH:mm`));
        break;
      case 'PACUTime':
        if (!Array.isArray(procedureStepsData?.procedureSteps)) {
          break;
        }

        const pacuIn = procedureStepsData.procedureSteps.find(i => i.type === 'PACU_IN');

        if (!pacuIn?.timestamp) {
          break;
        }

        onChange(formatDate(pacuIn.timestamp, `HH:mm`));
        break;
      case 'AnesthesiaEndTime':
        if (!Array.isArray(procedureStepsData?.procedureSteps)) {
          break;
        }

        const anestheticEnd = procedureStepsData.procedureSteps.find(i => i.type === 'ANESTHETIC_END');

        if (!anestheticEnd?.timestamp) {
          break;
        }

        onChange(formatDate(anestheticEnd.timestamp, `HH:mm`));
        break;
      case 'Allergies':
        const allergies = getAllergies(preFillWith?.questionnaireId || 0, preOpCharts);
        if (!allergies) {
          break;
        }

        onChange(allergies);
        break;
    }
  }, [preFillWith, answer, onChange, dischargeTime, procedureStepsData, preOpCharts]);

  return (
    <>
      <TextField
        inputRef={inputRef1}
        type="text"
        autoComplete="off"
        autoFocus={autofocus && !Boolean(answer)}
        label={label && ReactHtmlParser(label)}
        variant="filled"
        fullWidth={true}
        aria-label={label}
        name={name}
        inputProps={{
          placeholder:
            format === 'BloodPressure'
              ? 'SYS/DIA'
              : format === 'MilitaryTime'
                ? 'HH:MM'
                : format === 'DateTime'
                  ? 'MM/DD/YYYY HH:MM'
                  : format === 'Date'
                    ? 'MM/DD/YYYY'
                    : undefined,
          inputMode: isNumeric ? 'numeric' : undefined,
          maxLength,
        }}
        InputProps={{
          endAdornment:
            format === 'BloodPressure' ? (
              <span style={{ whiteSpace: 'nowrap' }}>mm Hg</span>
            ) : format === 'HeartRate' ? (
              'bpm'
            ) : format === 'Temperature' ? (
              '°C'
            ) : format === 'TemperatureF' ? (
              '°F'
            ) : format === 'Saturation' ? (
              '%'
            ) : format === 'Weight' ? (
              'lbs'
            ) : format === 'AdmissionO2' ? (
              'L/MIN'
            ) : format === 'Drain' ? (
              'cc'
            ) : format === 'BloodGlucose' ? (
              'mg/dL'
            ) : format === 'Hemoglobin' ? (
              'g/dL'
            ) : undefined,
          style: { width: width ? width : '100%' },
        }}
        value={answer ?? ''}
        onChange={e => {
          onChange?.(e.target.value);
          setInvalid(false);
        }}
        multiline={(maxRows || 0) > 0}
        minRows={maxRows}
        maxRows={maxRows}
        error={invalid}
        helperText={<>{(maxLength || 0) > 0 && <LimitHelper limit={maxLength || 0} used={answer?.length || 0} />}</>}
      />
    </>
  );
};

export default TextInput;
