import { format, isAfter, parse } from 'date-fns';
import { getNestedValue } from '../../../../se/utilities/data/object';
import { OR_FLOW, PATIENT_EVENT_TYPES } from '../../../entities/patient/enums';
import { OR_TIMES_STATUS_FROM } from '../../../inputs/timeEdit/TimeEditModal';
import { ZonedDateTime } from '@js-joda/core';
import { PACU, POST_OP, PRE_OP, OR } from '../../../entities/room/enums';
import { alphabet } from '../pacuAndPrep/PatientBedPicker';

export const takeFirst = array => (array && array.length > 0 ? array[0] : undefined);

export const getLogs = patient => getNestedValue('log', patient) || [];

export const optionalParse = value => (value ? parse(value) : undefined);

export const patientEnteredTime = (patient, room) => {
  const entry = getLogs(patient)
    .filter(log =>
      log.entries.find(
        entry =>
          entry.type === PATIENT_EVENT_TYPES.ENTERED && getNestedValue('room.id', entry) === getNestedValue('id', room)
      )
    )
    .map(event => ({ ...event, timestamp: parse(event.timestamp), createdAt: parse(event.createdAt) }))
    .sort((l, r) => (isAfter(l.createdAt, r.createdAt) ? -1 : 1));

  const selected = takeFirst(entry) || {};
  return optionalParse(getNestedValue('manualTimestamp', takeFirst(selected.entries))) || selected.timestamp;
};

export const getLogEntries = patient =>
  getLogs(patient).reduce(
    (acc, log) =>
      acc.concat(
        log.entries.map(e => ({
          ...e,
          timestamp: log.timestamp,
          id: log.id,
        }))
      ),
    []
  );

export const sortByNewest = entries => entries.sort((l, r) => new Date(r.timestamp) - new Date(l.timestamp));

const readyStatus = {
  [PATIENT_EVENT_TYPES.BECAME_FAMILY_READY_IN_PREP]: true,
  [PATIENT_EVENT_TYPES.BECAME_READY_IN_PREP]: true,
  [PATIENT_EVENT_TYPES.BECAME_READY_IN_PACU]: true,
  [PATIENT_EVENT_TYPES.BECAME_READY_IN_POSTOP]: true,
  [PATIENT_EVENT_TYPES.READY_FOR_ANESTHESIA]: true,
  [PATIENT_EVENT_TYPES.READY_FOR_SURGEON]: true,
  [PATIENT_EVENT_TYPES.HOLD_PROCEDURE]: true,
  [PATIENT_EVENT_TYPES.BLOCK_NERVE]: true,
  [PATIENT_EVENT_TYPES.HELP]: true,
  [PATIENT_EVENT_TYPES.READY_FOR_SURGEON_NOTIFY]: true,
  [PATIENT_EVENT_TYPES.READY_FOR_ANESTHESIOLOGIST_NOTIFY]: true,
  [PATIENT_EVENT_TYPES.NOT_READY_IN_PACU]: false,
  [PATIENT_EVENT_TYPES.NOT_READY_IN_POSTOP]: false,
  [PATIENT_EVENT_TYPES.NOT_READY_IN_PREP]: false,
  [PATIENT_EVENT_TYPES.NOT_READY_FOR_ANESTHESIA]: false,
  [PATIENT_EVENT_TYPES.NOT_READY_FOR_SURGEON]: false,
  [PATIENT_EVENT_TYPES.UNDO_HOLD_PROCEDURE]: false,
  [PATIENT_EVENT_TYPES.UNDO_BLOCK_NERVE]: false,
  [PATIENT_EVENT_TYPES.UNDO_HELP]: false,
  [PATIENT_EVENT_TYPES.FAMILY_READY_PACU]: false,
};

const isPatientReadyInPrep = (entries = []) =>
  readyStatus[
    (
      entries.find(
        entry =>
          entry.type === PATIENT_EVENT_TYPES.BECAME_READY_IN_PREP ||
          entry.type === PATIENT_EVENT_TYPES.NOT_READY_IN_PREP ||
          (entry.type === PATIENT_EVENT_TYPES.ENTERED && entry.room.type === PRE_OP)
      ) || {}
    ).type
  ] || false;

const isPatientReadyInPacu = (entries = []) =>
  readyStatus[
    (
      entries.find(
        entry =>
          entry.type === PATIENT_EVENT_TYPES.BECAME_READY_IN_PACU ||
          entry.type === PATIENT_EVENT_TYPES.NOT_READY_IN_PACU
      ) || {}
    ).type
  ] || false;

const isPatientReadyInPostOP = (entries = []) =>
  readyStatus[
    (
      entries.find(
        entry =>
          entry.type === PATIENT_EVENT_TYPES.BECAME_READY_IN_POSTOP ||
          entry.type === PATIENT_EVENT_TYPES.NOT_READY_IN_POSTOP
      ) || {}
    ).type
  ] || false;

export const isReadyForAnesthesiaFn = (entries = []) =>
  readyStatus[
    (
      sortByNewest(entries).find(
        entry =>
          entry.type === PATIENT_EVENT_TYPES.READY_FOR_ANESTHESIA ||
          entry.type === PATIENT_EVENT_TYPES.NOT_READY_FOR_ANESTHESIA
      ) || {}
    ).type
  ] || false;

export const isPatientReadyForSurgeonFn = (entries = []) =>
  readyStatus[
    (
      sortByNewest(entries).find(
        entry =>
          entry.type === PATIENT_EVENT_TYPES.READY_FOR_SURGEON ||
          entry.type === PATIENT_EVENT_TYPES.NOT_READY_FOR_SURGEON
      ) || {}
    ).type
  ] || false;

export const isPatientReadyForSurgeonNotifyFn = (entries = []) =>
  readyStatus[
    (sortByNewest(entries).find(entry => entry.type === PATIENT_EVENT_TYPES.READY_FOR_SURGEON_NOTIFY) || {}).type
  ] || false;

export const isPatientReadyForAnesthesiologistNotifyFn = (entries = []) =>
  readyStatus[
    (sortByNewest(entries).find(entry => entry.type === PATIENT_EVENT_TYPES.READY_FOR_ANESTHESIOLOGIST_NOTIFY) || {})
      .type
  ] || false;

export const isHoldProcedureFn = (entries = []) =>
  readyStatus[
    (
      sortByNewest(entries).find(
        entry =>
          entry.type === PATIENT_EVENT_TYPES.HOLD_PROCEDURE || entry.type === PATIENT_EVENT_TYPES.UNDO_HOLD_PROCEDURE
      ) || {}
    ).type
  ] || false;

export const isBlockNerveFn = (entries = []) =>
  readyStatus[
    (
      sortByNewest(entries).find(
        entry => entry.type === PATIENT_EVENT_TYPES.BLOCK_NERVE || entry.type === PATIENT_EVENT_TYPES.UNDO_BLOCK_NERVE
      ) || {}
    ).type
  ] || false;

export const isHelpFn = (entries = []) =>
  readyStatus[
    (
      sortByNewest(entries).find(
        entry => entry.type === PATIENT_EVENT_TYPES.HELP || entry.type === PATIENT_EVENT_TYPES.UNDO_HELP
      ) || {}
    ).type
  ] || false;

export const isPatientReady =
  (logEntries = []) =>
  roomType => {
    switch (roomType) {
      case PRE_OP:
        return isPatientReadyInPrep(sortByNewest(logEntries));
      case PACU:
        return isPatientReadyInPacu(sortByNewest(logEntries));
      case POST_OP:
        return isPatientReadyInPostOP(sortByNewest(logEntries));
      default:
        return false;
    }
  };

export const isPreOpAsPacu = (roomType, logEntries = []) => {
  if (roomType !== PRE_OP) return false;
  const sorted = sortByNewest(logEntries).filter(e => e?.type === 'Entered');
  const lastEvent = sorted?.[0];
  const nextToLastEvent = sorted?.[1];
  if (lastEvent?.room?.type !== PRE_OP) return false;

  switch (nextToLastEvent?.room?.type) {
    case PACU:
      return true;
    case OR:
      return true;
    default:
      return false;
  }
};

export const isPreOpAsDPU = (roomType, logEntries = []) => {
  if (roomType !== PRE_OP) return false;
  const sorted = sortByNewest(logEntries).filter(e => e?.type === 'Entered');
  const lastEvent = sorted?.[0];
  const nextToLastEvent = sorted?.[1];
  if (lastEvent?.room?.type !== PRE_OP) return false;

  switch (nextToLastEvent?.room?.type) {
    case OR:
      return true;
    case PACU:
      return true;
    default:
      return false;
  }
};

export const isPreOpAsDPUOptimized = (currentRoomType, patientEventTimes) => {
  if (currentRoomType !== PRE_OP) return false;

  return !!patientEventTimes?.dpuAt;
};

export const getFrameColor = (roomType, logEntries = []) => {
  if (roomType !== PRE_OP) return 'transparent';
  const sorted = sortByNewest(logEntries).filter(
    e =>
      e?.entries?.[0]?.type === 'Entered' ||
      e?.entries?.[0]?.type === 'ReadyToSeeFamilyPreOp' ||
      e?.entries?.[0]?.type === 'NotReadyToSeeFamilyPreOp'
  );

  const lastEvent = sorted?.[0]?.entries?.[0];
  const nextToLastEvent = sorted?.[1]?.entries?.[0];
  if (lastEvent?.type === 'ReadyToSeeFamilyPreOp') return '#00B63E';
  if (lastEvent?.room?.type !== PRE_OP) return 'transparent';

  switch (nextToLastEvent?.room?.type) {
    case PACU:
      return 'purple';
    case OR:
      return 'purple'; // same for now
    default:
      return 'transparent';
  }
};

export const isReadyForFamilyPreOp =
  (logEntries = []) =>
  roomType => {
    switch (roomType) {
      case PRE_OP:
        return (
          readyStatus[
            (
              sortByNewest(logEntries).find(
                entry =>
                  entry?.type === PATIENT_EVENT_TYPES.BECAME_FAMILY_READY_IN_PREP ||
                  entry?.type === PATIENT_EVENT_TYPES.NOT_FAMILY_READY_IN_PREP
              ) || {}
            )?.type
          ] || false
        );
      default:
        return false;
    }
  };

export const isReadyForPickupPACU = (entries = []) => !!entries.find(entry => entry.type === 'FamilyReadyPACU');
export const isReadyForPickupPOSTOP = (entries = []) => !!entries.find(entry => entry.type === 'FamilyReadyPOSTOP');

export const getBeds = (patients, capacity) =>
  Array.from({ length: capacity }).map((_, index) => ({
    bedNumber: index + 1,
    occupied:
      !!patients.find(p => p.bed === `${index + 1}`) ||
      !!patients.find(p => alphabet.indexOf(p.bed?.toLowerCase()) + 1 === index + 1),
  }));

export const eventTime = (event, patient) => {
  const entry = getLogs(patient)
    .filter(log =>
      log.entries.find(entry => entry.type === PATIENT_EVENT_TYPES.PROCEDURE_STATUS_SET && entry.status === event)
    )
    .map(event => ({ ...event, timestamp: parse(event.timestamp), createdAt: parse(event.createdAt) }))
    .sort((l, r) => (isAfter(l.createdAt, r.createdAt) ? -1 : 1));

  const selected = takeFirst(entry) || {};
  return optionalParse(getNestedValue('manualTimestamp', takeFirst(selected.entries))) || selected.timestamp;
};

export const optionalFormat = (time, fmt = 'HH:mm', fallback = '-') => (time ? format(time, fmt) : fallback);

export const formatOrNull = (time, fmt = 'HH:mm') => (time ? format(time, fmt) : null);

export const optionalFormatWithTimezone = (time, fmt = 'HH:mm', fallback = '-') =>
  time ? format(ZonedDateTime.parse(time).toLocalDateTime().toString(), fmt) : fallback;

export const showValueIf = (value, condition, fallback = '-') => (condition ? value : fallback);

export const isOrTimeActive = (status, orTime) =>
  OR_FLOW.indexOf(status) >= OR_FLOW.indexOf(OR_TIMES_STATUS_FROM[orTime]);

export const optionalText = (value, fallback = '-') => value ?? fallback;
