import React, { Fragment, useCallback, useEffect, useState } from 'react';
import GraphQLCRUD from '../../../se/components/GraphQLCRUD';
import { listColumns, viewColumns } from './columns';
import schema, {
  markRequestTransferChangeAsSeen,
  procedureRequestsStatistics,
  updateTransferStatus as updateTransferStatusMutation,
} from '../../../graph/scheduleRequests';
import EntityView from '../../../se/components/entity/EntityView';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Paper, TextField, Typography } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { format } from 'date-fns';
import RequestDocumentsButton from './RequestDocumentsButton';
import RequestedDocumentsContainer from './RequestedDocumentsContainer';
import { withProps } from 'recompose';
import get from 'lodash/get';
import { DashboardFilter } from './DashboardFilter';
import Filters from '../../pages/analytics/Filters';
import UploadedForms from './UploadedForms';
import DocViewer from './PdfViewer';
import { ScheduleTransferStatus } from './enums';
import { DefaultTitle } from '../../../se/components/entity/EntityRouter';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import { useMutation } from '@apollo/client';
import ModalWithActions from '../../ModalWithActions';
import useModal from '../../../hooks/useModal';
import { NamedRange } from '../../core/DatePicker';
import ScheduleRequestMobileListItem from './ScheduleRequestMobileListItem';
import pick from 'lodash/fp/pick';
import clsx from 'clsx';
import MenuItem from '@material-ui/core/MenuItem';
import ProcedureEventLog from '../procedures/ProcedureEventLog';
import Grid from '@material-ui/core/Grid';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import ChangeStatusButton from '../surgeonOffice/ChangeStatusButton';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { ZonedDateTime } from '@js-joda/core';
import Divider from '@material-ui/core/Divider';
import ProcedureNotes from './ProcedureNotes';
import { NotesStatus } from '../surgeonOffice/columns';

const Empty = { illustration: theme => theme.illustrations.patients };

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    flexShrink: 0,
  },
  chips: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginLeft: theme.spacing(1),
    },
  },
  warning: {
    color: theme.palette.error.main,
  },
  info: {
    backgroundColor: theme.palette.info.dark,
    color: theme.palette.getContrastText(theme.palette.info.dark),
  },
  canceled: {
    textDecoration: 'line-through',
  },
}));

const ScheduleRequestView = props => {
  const classes = useStyles();
  const { forDate, duration, sourceProcedureId, fromOrg, state, updates = [], notes = [] } = props.data;
  const requestId = props.data.id;
  const s3PatientFile = get(props, 'data.payload.s3PatientFile.url');
  const forms = get(props, 'data.payload.forms');
  const transferIssues = get(props, 'data.issues', []);
  const patientExternalProviderId = get(props, 'data.externalProvider.id', '');
  const externalProviderName = get(props, 'data.externalProvider.provider', '');
  const transferState = state ? JSON.parse(state) : null;
  const isCanceled = transferState.__typename === 'Cancelled';

  return (
    <Fragment>
      <Box my={2}>
        <Grid container spacing={3}>
          <Grid item lg={9} md={8}>
            <Box mb={3}>
              <Paper>
                {props.data && (
                  <Box display="flex" alignItems="center" justifyContent="space-between" p={2}>
                    <Box>
                      <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                        Schedule
                      </Typography>
                      <Box display="flex" alignItems="center">
                        {isCanceled ? <EventBusyIcon color="disabled" /> : <DateRangeIcon color="disabled" />}
                        <Box ml={1}>
                          <Typography
                            variant="h5"
                            color={isCanceled ? 'textSecondary' : 'textPrimary'}
                            className={clsx({
                              [classes.canceled]: isCanceled,
                            })}
                          >
                            {get(fromOrg, 'name')} ·{' '}
                            {format(ZonedDateTime.parse(forDate).toLocalDateTime().toString(), 'MM/DD/YYYY · HH:mm')} ·{' '}
                            {duration}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>

                    {!isCanceled && patientExternalProviderId && (
                      <Box display="flex" alignItems="center">
                        <label style={{ fontWeight: 500 }}>
                          {`${externalProviderName} ID: ${patientExternalProviderId}`}
                        </label>
                      </Box>
                    )}
                  </Box>
                )}
                {!isCanceled && (
                  <Box borderTop={1} borderColor="divider">
                    <RequestedDocumentsContainer requestedDocuments={transferIssues} isSO={false} />
                    <Box mt={2}>
                      <RequestDocumentsButton requestId={requestId} disabled={isCanceled} />
                    </Box>
                  </Box>
                )}
              </Paper>
            </Box>
            <EntityView {...props} />
            {!isCanceled && (
              <UploadedForms forms={get(props, 'data.payload.uploads')} procedureId={sourceProcedureId} />
            )}
            {!isCanceled && s3PatientFile && forms.length !== 0 && <DocViewer url={s3PatientFile} />}
          </Grid>
          <Grid item lg={3} md={4}>
            <ProcedureNotes requestId={requestId} procedureNotes={notes} />
            <Box my={3}>
              <Divider />
            </Box>
            <ProcedureEventLog events={updates} name="Updates" />
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

const CustomFilter = withProps({
  textSearchKey: 'name',
  hideProcedureTypeSelectInput: true,
  hidePhysicianSelectInput: true,
  hideSurgeryStatusHospitalSelectInput: false,
  futurePicker: true,
  defaultValue: {
    dateRange: NamedRange.next90Days(),
  },
})(Filters);

const CustomDashboardFilter = withProps({
  subscription: procedureRequestsStatistics,
  CustomFilter: CustomFilter,
  items: [
    {
      title: 'Total to be Scheduled',
      getter: data => get(data, 'procedureRequestsStatistics.totalRequested', '-'),
    },
    {
      title: 'Total Scheduled',
      getter: data => get(data, 'procedureRequestsStatistics.totalScheduled', '-'),
    },
    {
      title: 'Missing Documents',
      getter: data => get(data, 'procedureRequestsStatistics.missingInformation', '-'),
    },
  ],
})(DashboardFilter);

const Title = ({ data }) => {
  const patientName = get(data, 'payload.patient.name', '');

  return (
    <Box display="flex" flex={1} flexDirection="row" justifyContent="space-between" alignItems="center">
      <Box>
        <DefaultTitle>{patientName}</DefaultTitle>
      </Box>
    </Box>
  );
};

const Actions = ({ data }) => {
  const classes = useStyles();
  const { forDate, externalProvider } = data;
  const transferId = data.id;
  const procedureId = data?.sourceProcedureId;
  const isNewNote = data?.notesStatus === NotesStatus.NewNoteFromSO;
  const state = data?.state ? JSON.parse(data?.state) : null;

  const needDocs = (data.issues || []).some(issue => JSON.parse(issue.state).__typename === 'Requested');
  const docsUpdated = (data.issues || []).some(issue => JSON.parse(issue.state).__typename === 'Resolved');
  const isNotCanceled = state?.__typename !== 'Cancelled';
  const documentsStatus = isNotCanceled && (needDocs || docsUpdated);
  const documentsStatusLabel = docsUpdated ? 'Docs Updated' : needDocs ? 'Missing Docs' : '';

  const statusLabel =
    state?.__typename === 'Requested'
      ? 'To be Scheduled'
      : state?.__typename === 'Confirmed'
        ? 'Case Scheduled'
        : state?.__typename === 'Rescheduled'
          ? 'Surgery Rescheduled'
          : state?.__typename === 'Cancelled'
            ? 'Surgery Canceled'
            : state?.__typename === 'Cancelled'
              ? 'Surgery Canceled'
              : state?.__typename || 'Unknown';

  const hasUnseenChange = data?.hasUnseenChange;

  const [acknowledgeUpdate] = useMutation(markRequestTransferChangeAsSeen);
  const onAcknowledgeUpdate = useCallback(
    () => acknowledgeUpdate({ variables: { scheduleTransferId: transferId } }),
    [acknowledgeUpdate, transferId]
  );

  useEffect(() => {
    if (hasUnseenChange || isNewNote) {
      onAcknowledgeUpdate().then(() => {});
    }
  }, [isNewNote, hasUnseenChange, onAcknowledgeUpdate]);

  return (
    <Box className={classes.chips}>
      {hasUnseenChange && <Chip size="medium" label={'Updated'} />}
      {documentsStatus && (
        <Chip icon={<ErrorOutlineIcon className={classes.warning} />} size="small" label={documentsStatusLabel} />
      )}
      <Chip size="small" label={statusLabel} />
      <Box pl={1} borderLeft={1} borderColor="divider">
        <ChangeStatusButton
          data={{
            id: procedureId,
            transferId: transferId,
            state,
            scheduleData: {
              dateOfService: forDate,
              externalProvider,
            },
            variant: 'contained',
          }}
        />
      </Box>
    </Box>
  );
};

export const CancelTransfer = ({ isSO, ...props }) => {
  const { id, status } = props.data;
  const { open, closeModal, openModal } = useModal();
  const [updateTransferStatus] = useMutation(updateTransferStatusMutation);
  const [input, setInput] = useState('');
  const cancelTransfer = async () => {
    await updateTransferStatus({
      variables: {
        id: id,
        scheduleTransferRequestStatus: ScheduleTransferStatus.Canceled,
        scheduleTransferStatusMessage: input,
      },
    });
  };
  const isCanceled = status?.typename === 'Cancelled';
  return (
    <Fragment>
      {!isSO ? (
        <Button color="secondary" onClick={openModal} disabled={isCanceled} variant="outlined">
          Cancel Procedure
        </Button>
      ) : (
        <MenuItem onClick={openModal} disabled={isCanceled}>
          Cancel Procedure
        </MenuItem>
      )}
      <ModalWithActions
        open={open}
        handleCancel={closeModal}
        handleConfirmAction={async () => {
          await cancelTransfer();
          closeModal();
        }}
        confirmActionLabel="Confirm Cancellation"
        title="Cancel Procedure"
        size="sm"
      >
        <Box display="flex" flexDirection="column">
          <Typography color="textSecondary">Please write below reason for canceling this procedure.</Typography>
          <Box mb={2} />
          <TextField
            multiline
            rowsMax={4}
            rows={4}
            variant="outlined"
            onChange={event => {
              setInput(event.target.value);
            }}
            value={input}
          />
        </Box>
      </ModalWithActions>
    </Fragment>
  );
};

const ScheduledRequests = GraphQLCRUD({
  entityName: 'ScheduleRequest',
  scheme: schema,
  List: {
    tableKey: 'ScheduleTransferRequest',
    titleProvider: () => 'Schedule Request',
    MobileItemComponent: ScheduleRequestMobileListItem,
    columns: listColumns,
    FilterComponent: CustomDashboardFilter,
    FilterPrefix: 'sr',
    defaultFilterValues: {
      dateRange: NamedRange.next90Days(),
    },
    pickFilter: pick([
      'name',
      'physician',
      'procedureType',
      'speciality',
      'hospital',
      'dateRange',
      'transferStatus',
      'scheduleTransferState',
    ]),
    useColumnSelection: true,
    defaultDisplayColumns: ['#', 'Request From', 'Physician', 'Patient Name', 'Date of Service', 'Status', 'Alert'],
    Empty,
  },
  Show: {
    columns: viewColumns,
    View: ScheduleRequestView,
    Title: Title,
    titleProvider: data => `${get(data, 'payload.patient.name', '-')} `,
    Actions: Actions,
    Empty,
  },
});

export default ScheduledRequests;
