import { css } from 'styled-components';

const lessThan =
  max =>
  (...args) => css`
    @media (max-width: ${max - 1}px) {
      ${css(...args)};
    }
  `;

const greaterThanOrEqualToAndLessThan =
  (min, max) =>
  (...args) => css`
    @media (min-width: ${min}px) and (max-width: ${max - 1}px) {
      ${css(...args)};
    }
  `;

const greaterThanOrEqualTo =
  min =>
  (...args) => css`
    @media (min-width: ${min}px) {
      ${css(...args)};
    }
  `;

/**
 * Contains smallest viewport width in pixels for each size.
 *
 *        xs < 576
 *  576 ≤ sm < 768
 *  768 ≤ md < 992
 *  992 ≤ lg < 1200
 * 1200 ≤ xl
 */
export const breakpoint = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

const xs = lessThan(breakpoint.sm);
const sm = greaterThanOrEqualToAndLessThan(breakpoint.sm, breakpoint.md);
const md = greaterThanOrEqualToAndLessThan(breakpoint.md, breakpoint.lg);
const lg = greaterThanOrEqualToAndLessThan(breakpoint.lg, breakpoint.xl);
const xl = greaterThanOrEqualTo(breakpoint.xl);

xs.andSmaller = lessThan(breakpoint.sm);
sm.andSmaller = lessThan(breakpoint.md);
md.andSmaller = lessThan(breakpoint.lg);
lg.andSmaller = lessThan(breakpoint.xl);
xl.andSmaller = css;

xs.andLarger = css;
sm.andLarger = greaterThanOrEqualTo(breakpoint.sm);
md.andLarger = greaterThanOrEqualTo(breakpoint.md);
lg.andLarger = greaterThanOrEqualTo(breakpoint.lg);
xl.andLarger = greaterThanOrEqualTo(breakpoint.xl);

export default {
  xs,
  sm,
  md,
  lg,
  xl,
};

export const isSafariLike = () => navigator.vendor && navigator.vendor.indexOf('Apple') > -1;
