import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import pluralize from 'pluralize';
import titlecase from 'stringcase/lib/titlecase';
import styled from 'styled-components';
import { pure } from 'recompose';

import Row from './Row';
import { H2 } from '../typography';
import StackView from '../StackView';
import TitleAction from '../TitleAction';
import ButtonRouterLink from '../ButtonRouterLink';
import EntityLoadingState from '../entity-states/EntityLoadingState';
import EntityErrorState from '../entity-states/EntityErrorState';
import EntityEmptyState from '../entity-states/EntityEmptyState';

import { isArray } from '../../utilities/check';
import responsive from '../../utilities/responsive';
import RegularList from './RegularList';

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  //grid-template-columns: auto;
`;

const State = styled.div`
  grid-column: 1 / span 1;
`;

const DefaultTitle = styled(H2)`
  margin: 0.5em 0;
  ${responsive.md.andSmaller`
    font-size: 1.25rem;
  `}
`;

// https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-networkStatus
const apolloNetworkStatus = {
  onVariablesChange: 2,
};

const DefaultTitleAndActions = ({
  Title,
  Actions,
  canCreate,
  entityName,
  createUrlProvider,
  createActionTitle,
  isEmpty,
}) => (
  <TitleAction
    Title={
      Title
        ? () => <Title entityName={entityName} isEmpty={isEmpty} />
        : () => <DefaultTitle>{titlecase(pluralize(entityName))}</DefaultTitle>
    }
  >
    <Fragment>
      {Actions ? <Actions entityName={entityName} /> : null}
      {canCreate && createUrlProvider ? (
        <ButtonRouterLink to={{ pathname: createUrlProvider(), search: window.location.search }}>
          {createActionTitle || `Create ${titlecase(entityName)}`}
        </ButtonRouterLink>
      ) : null}
    </Fragment>
  </TitleAction>
);

const asState = Component => props => (
  <State>
    <Component {...props} />
  </State>
);

const StateWrapper = ({ data, getList, Loading, Error, Empty, children }) => {
  const isLoading = data.loading && data.networkStatus !== apolloNetworkStatus.onVariablesChange;
  const hasError = !!data.error;
  const isEmpty = !isArray(getList(data)) || !getList(data).length;

  const ErrorState = asState(Error);
  const LoadingState = asState(Loading);
  const EmptyState = asState(Empty);

  if (isLoading && isEmpty) {
    return <LoadingState />;
  } else if (hasError) {
    return <ErrorState error={data.error} />;
  } else if (isEmpty) {
    return <EmptyState />;
  } else {
    return children;
  }
};

const CollectionList = ({
  match,
  location,
  history,
  data,
  getList,
  entityName,
  columns,
  hideColumns,
  TitleAndActions,
  Create,
  Title,
  Actions,
  View,
  onViewClick,
  createBackUrlProvider,
  createUrlProvider,
  createPathProvider,
  showUrlProvider,
  idProvider,
  Loading,
  Error,
  Empty,
  FilterComponent,
  filter,
  setFilter,
  additionalRoutes,
  highlightedProvider,
  highlightedRowStyles,
  highlightedRowStyleProvider,
  AdditionalBlock,
  containsSeparator,
  useColumnSelection,
  useCSVExport,
  MobileItemComponent,
  tableKey,
  simpleFilter,
  createActionTitle,
  defaultSort,
}) => {
  const canCreate = Create && createUrlProvider;
  return (
    <StackView>
      <MainWrapper>
        {TitleAndActions ? (
          <TitleAndActions entityName={entityName} />
        ) : (
          <DefaultTitleAndActions
            entityName={entityName}
            canCreate={canCreate}
            createUrlProvider={createUrlProvider}
            createActionTitle={createActionTitle}
            Title={Title}
            Actions={Actions}
            isEmpty={!isArray(getList(data)) || !getList(data).length}
          />
        )}

        {FilterComponent && setFilter && <FilterComponent onChange={setFilter} value={filter} />}

        <StateWrapper Loading={Loading} Error={Error} Empty={Empty} getList={getList} data={data}>
          <Fragment>
            {AdditionalBlock && <AdditionalBlock filter={filter} />}
            <RegularList
              Component={View}
              getList={getList}
              data={data}
              history={history}
              location={location}
              hideColumns={hideColumns}
              columns={columns}
              showUrlProvider={showUrlProvider}
              idProvider={idProvider}
              onViewClick={onViewClick}
              highlightedProvider={highlightedProvider}
              highlightedRowStyles={highlightedRowStyles}
              highlightedRowStyleProvider={highlightedRowStyleProvider}
              containsSeparator={containsSeparator}
              tableKey={tableKey}
              defaultSort={defaultSort}
              useColumnSelection={useColumnSelection}
              useCSVExport={useCSVExport}
              MobileItemComponent={MobileItemComponent}
              simpleFilter={!(FilterComponent && setFilter) && simpleFilter}
            />
          </Fragment>
        </StateWrapper>
      </MainWrapper>

      <Switch>
        {(additionalRoutes || []).map(({ key, path, Component, ...rest }) => (
          <Route
            {...rest}
            key={key}
            path={match.path + path}
            render={props => <Component {...props} listData={data} baseUrl={match.url} />}
          />
        ))}
        {Create ? (
          <Route
            path={createPathProvider()}
            render={props => <Create {...props} backUrlProvider={createBackUrlProvider} />}
          />
        ) : null}
      </Switch>
    </StackView>
  );
};

CollectionList.defaultProps = {
  data: {},
  View: Row,
  Loading: EntityLoadingState,
  Error: EntityErrorState,
  Empty: EntityEmptyState,
  containsSeparator: false,
  useColumnSelection: false,
  useCSVExport: false,
};

CollectionList.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      lens: PropTypes.func.isRequired,
      Component: PropTypes.func.isRequired,
    }).isRequired
  ).isRequired,
  data: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.any,
  }).isRequired,
  Create: PropTypes.func,
  TitleAndActions: PropTypes.func,
  Title: PropTypes.func,
  Actions: PropTypes.func,
  View: PropTypes.func,
  onViewClick: PropTypes.func,
  createUrlProvider: PropTypes.func,
  createPathProvider: PropTypes.func,
  showUrlProvider: PropTypes.func,
  filter: PropTypes.object,
  setFilter: PropTypes.func,
  highlightedProvider: PropTypes.func,
  highlightedRowStyles: PropTypes.object,
  highlightedRowStyleProvider: PropTypes.func,
  AdditionalBlock: PropTypes.func,
  containsSeparator: PropTypes.bool,
  tableKey: PropTypes.string,
  useColumnSelection: PropTypes.bool,
  simpleFilter: PropTypes.bool,
  defaultSort: PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.oneOf(['ASC', 'DESC']),
  }),
};

export default pure(CollectionList);
