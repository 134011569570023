import React, { useCallback, useMemo } from 'react';
import ReactHtmlParser from 'react-html-parser';
import get from 'lodash/get';
import set from 'lodash/set';
import { int as randomInt } from '../../../se/utilities/random/index.jsx';
import Handlebars from 'handlebars';
import { LocalDate, Period } from '@js-joda/core';
import safeEval from 'safe-eval';
import flow from 'lodash/fp/flow';
import update from 'lodash/fp/update';
import { format } from 'date-fns';
import { getBarcodeText, getBarcodeTextSrc } from './Barcode';

import { useScope } from '../../../hooks/useScope';

const convertTimezone = (utcDateString, targetTimezone) => {
  const date = new Date(utcDateString);

  if (!utcDateString || !targetTimezone) {
    return '';
  }

  return date.toLocaleString('en-US', {
    timeZone: targetTimezone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
};

const renderAge = dob => {
  try {
    const [monthStr, dayStr, yearStr] = dob.split('/');
    const month = parseInt(monthStr, 10);
    const day = parseInt(dayStr, 10);
    const year = parseInt(yearStr, 10);
    const date = LocalDate.of(year, month, day);
    return Period.between(date, LocalDate.now()).years();
  } catch (e) {
    return undefined;
  }
};

Handlebars.registerHelper('json', function () {
  return JSON.stringify(this, undefined, 2);
});

Handlebars.registerHelper('eval', function (code) {
  return safeEval(code, this);
});

const transformSomeValues = flow(
  update('demographicInformation', demographicInformation => ({
    ...demographicInformation,
    age: renderAge(demographicInformation?.dob),
  })),
  update('measurments', measurments =>
    Array.isArray(measurments)
      ? (measurments || []).map(e => ({
          ...e,
          time: e.time ? e.time : format(e.timestamp ? new Date(e.timestamp) : new Date(), 'HH:mm'),
          bp: e.bp ? e.bp : `${e?.sys}/${e?.dia}`,
        }))
      : measurments
  ),
  update('DoSMed.ifYes.DoSMedList', meds => (Array.isArray(meds) ? (meds || []).filter(e => e?.transfer) : meds))
);

const expandType = value => {
  if (value === 'false') {
    return false;
  } else if (value === 'true') {
    return true;
  } else {
    return value;
  }
};

const DatabaseHtmlForm = ({
  showQRCode,
  defaultValue,
  formName,
  pageNumber,
  content,
  value: initialValue,
  setValue,
  signature,
  signedAt,
  editable,
  isTablet,
}) => {
  const value = transformSomeValues(initialValue);
  const scope = useScope();
  const timezone = scope?.hospital?.timezone?.name;

  const onChange = id => e => {
    if (e.target.type === 'checkbox') {
      const newVal = { ...value };
      set(newVal, id, e.target.checked);
      setValue(newVal);
    } else if (e.target.type === 'radio') {
      const newVal = { ...value };
      set(newVal, id, expandType(e.target.value));
      setValue(newVal);
    } else {
      const newVal = { ...value };
      set(newVal, id, e.target.value);
      setValue(newVal);
    }
  };

  const memoizedOnChange = useCallback(onChange, [onChange]);

  const transform = useCallback(
    node => {
      const { type, name } = node;
      if (type === 'tag' && name === 'input') {
        const { id, type, value: inputValue, name } = node.attribs;

        if (type === 'radio') {
          const fieldVal = get(value, node.attribs.name);
          const attrs = { checked: String(fieldVal) === node.attribs.value };
          return (
            <input
              {...node.attribs}
              onChange={memoizedOnChange(node.attribs.name)}
              key={`${node.attribs.name}-${id}`}
              {...attrs}
              disabled={!editable}
            />
          );
        } else if (type === 'checkbox') {
          const fieldVal =
            inputValue !== undefined && inputValue !== null && name
              ? String(get(value, name)) === String(inputValue)
              : get(value, id);
          const attrs = { checked: !!fieldVal };
          return <input {...node.attribs} onChange={memoizedOnChange(id)} key={id} {...attrs} disabled={!editable} />;
        } else {
          if ('signedat' in node.attribs) {
            const signedAtTime = convertTimezone(signedAt, timezone);
            return (
              <input
                {...node.attribs}
                onChange={memoizedOnChange(id)}
                key={id}
                value={signedAtTime}
                disabled={!editable}
              />
            );
          } else {
            const fieldVal = get(value, id);
            const attrs = { value: fieldVal !== undefined && fieldVal !== null ? fieldVal : '' };
            return <input {...node.attribs} onChange={memoizedOnChange(id)} key={id} {...attrs} disabled={!editable} />;
          }
        }
      } else if (type === 'tag' && name === 'textarea') {
        const { id } = node.attribs;
        return (
          <textarea
            {...node.attribs}
            onChange={memoizedOnChange(id)}
            value={get(value, id)}
            key={id}
            disabled={!editable}
          />
        );
      } else if (
        type === 'tag' &&
        name === 'img' &&
        ('data-signature' in node.attribs ||
          'data-signature-md' in node.attribs ||
          'data-signature-chart' in node.attribs)
      ) {
        const signatureKey =
          node.attribs['data-signature'] || node.attribs['data-signature-md'] || node.attribs['data-signature-chart'];
        const src = signatureKey ? get(value, signatureKey) : signature;
        const alt = signatureKey ? signatureKey : 'patient signature';
        if (src) {
          return <img src={src} {...node.attribs} alt={alt} key={`signature-${randomInt()()}`} />;
        }
      } else if (type === 'tag' && name === 'img' && 'barcode-img' in node.attribs && 'page-number' in node.attribs) {
        const alt = 'barcode';
        const pageNumber = node.attribs['page-number'];
        const providerId = defaultValue?.procedure?.patient?.providerId;
        const serviceTime = defaultValue?.procedure?.serviceTime;
        const src = getBarcodeTextSrc(getBarcodeText(providerId, serviceTime, formName, pageNumber));

        return showQRCode ? <img {...node.attribs} src={src} alt={alt} /> : null;
      }
    },
    [value, memoizedOnChange, signature, editable]
  );

  const renderedContent = useMemo(() => {
    const template = Handlebars.compile(content || '');
    return template(value);
  }, [content, value]);

  return useMemo(() => ReactHtmlParser(renderedContent, { transform }), [renderedContent, transform]);
};

export default DatabaseHtmlForm;
