import toString from './toString';
import { capitalize } from 'lodash';

const toHighlightsString5 = toString.with({
  Object: fields => {
    const visibleFields = fields.filter(field => field.child);

    return visibleFields.length === 0
      ? null
      : visibleFields.length === 1
        ? visibleFields[0].child
        : visibleFields
            .map(field => field.child)
            .map(c => {
              const rest = c!.startsWith('· ') ? c!.substr(2) : c!;
              return '· ' + rest;
            })
            .join('\n');
  },
  Checkboxes: (label, checkboxes) => {
    const visibleCheckboxes = checkboxes.filter(checkbox => checkbox.checked);

    if (visibleCheckboxes.length === 0) {
      return null;
    } else if (visibleCheckboxes.length === 1 && visibleCheckboxes[0].label === 'Other Allergies') {
      return visibleCheckboxes[0].child ? `${visibleCheckboxes[0].child}` : '';
    } else {
      return visibleCheckboxes
        .map(
          checkbox =>
            `· ${checkbox.label.replace(/\?/g, '')}${checkbox.child ? `:\n${checkbox.child.replaceAll('* · ', '* ').replaceAll(', ·', ', ')}` : ''}`
        )
        .join('\n');
    }
  },
  List: (label, children) => {
    const visibleChildren = children.filter(child => child);

    return visibleChildren.length === 0
      ? null
      : visibleChildren
          .map(c => {
            const rest = c!.startsWith('· ') ? c!.substr(2) : c!;
            return `${rest.replace(/\n· /g, ', ')}`;
          })
          .join('\n');
  },
  YesNo: (question, positive, child) => {
    if (child) {
      if (
        question ==
        'Do you currently have a central venous line, such as chemo and/or antibiotic port, PICC line, or dialysis catheter?'
      ) {
        return 'Currently have central venous line(' + child + ')';
      }
      return child;
    } else if (positive) {
      return capitalize(
        question
          .replace(
            /^Have you had surgery in an operating room within 30 days of the upcoming procedure/g,
            'Surgery within 30 days of upcoming procedure'
          )
          .replace(
            /^Long car trip or long airplane flight \(more than 4 hours\) planned within 7 days after procedure/g,
            'Long trip planned after procedure'
          )
          .replace(/^Has the patient had any surgical\/medical procedures that required anesthesia\?/g, '')
          .replace(
            /^Do you currently take any prescription medications \(including inhalers, blood thinners, or birth control\)\?/g,
            ''
          )
          .replace(
            /^Do you currently take any oral or injectable medications for weight loss \(like phentermine, Ozempic, semaglutide, Mounjaro, Contrave, etc\)\?/g,
            ''
          )
          .replace(/^Do you currently take any over the counter, herbal, or alternative medicines\?/g, '')
          .replace(
            /^Do you currently take any vitamins or supplements \(including fish oil, vitamin E or diet pills\)\?/g,
            ''
          )
          .replace(/\?/g, '')
          .replace(/^Do you have\s/g, '')
          .replace(/^Are you\s/g, '')
          .replace(/^Were you\s/g, '')
          .replace(/\?/g, '')
          .replace(/^Any chance you may be\s/g, '')
          .replace(/^Did you\s/g, '')
          .replace(/^Any\s/g, '')
          .replace(/^Have you ever\s/g, '')
          .replace(/^Have you\s/g, '')
          .replace(/^Is it a\s/g, '')
          .replace(/^Do you\s/g, '')
          .replace(
            /^any other known allergy \(or allergies\)\, adverse reactions or sensitivities your medical team should be aware of/g,
            ''
          )
      );
    } else {
      return '';
    }
  },
  OneOf: (label, options, answer) =>
    answer
      ? `${answer.label
          .replace(/^mg - milligrams/g, 'mg')
          .replace(/^mcg - micrograms/g, 'mcg')
          .replace(/^meq - milliequivalent/g, 'meq')
          .replace(/^mL - milliliters/g, 'mL')}${answer.child ? ` (${answer.child}) ` : ''}`
      : null,
});

export default toHighlightsString5;
