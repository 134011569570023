import React from 'react';
import styled from 'styled-components';
import LinkButton from '../../../../se/components/LinkButton';
import { DataTitle, DataValue } from '../../../../se/components/entity/EntityView';
import { isDefinedAndNotNull } from '../../../../se/utilities/check';
import { DataFieldCustom, Panel, Status, StatusIndicator } from './components';
import DischargeAction from './DischargeAction';
import Grid from '@material-ui/core/Grid';
import { Card, CircularProgress, Paper, Slider } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { getHoursAndMinutesTextForSlider } from '../../../../util/procedureAccuracy';
import get from 'lodash/fp/get';
import { useScope } from '../../../../hooks/useScope';

const useStyles = makeStyles(theme => ({
  cardRoot: {
    height: '100%',

    '& > :last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  cardContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  sliderRoot: {
    // padding: theme.spacing(0),
  },
  thumb: {
    width: 2,
    marginLeft: 0,
    borderRadius: 0,
  },
  track: {
    height: 4,
    marginTop: -1,
  },
  circularProgressRoot: {
    color: theme.palette.text.disabled,
  },
  progressBase: {
    position: 'absolute',
    left: 0,
    top: 0,
    opacity: 0.15,
  },
}));

const ActionSeparator = styled.span`
  margin: 0 0.5rem;
  opacity: 0.25;
`;

const minutesIf = value => (value > 0 ? `${value}min` : undefined);
const hoursIf = value => (value > 0 ? `${value}h` : undefined);

const prettyDuration = duration => {
  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;
  return [hoursIf(hours), minutesIf(minutes)].filter(_ => !!_).join(' ');
};
const timeOrPlaceholder = duration =>
  !isDefinedAndNotNull(duration) || duration === 0 ? '-' : prettyDuration(duration);

const CardTitle = ({ title }) => (
  <Typography variant="subtitle2" color="textSecondary" gutterBottom>
    {title}
  </Typography>
);

export const CustomCard = ({ title, children }) => {
  const classes = useStyles();

  return (
    <Card classes={{ root: classes.cardRoot }}>
      <CardContent className={classes.cardContent}>
        <CardTitle title={title} />
        {children}
      </CardContent>
    </Card>
  );
};

const RangeSlider = ({ range, min, max, color }) => {
  const classes = useStyles();

  return (
    <Slider
      classes={{
        root: classes.sliderRoot,
        thumb: classes.thumb,
        track: classes.track,
      }}
      color={color}
      defaultValue={0}
      valueLabelDisplay="auto"
      valueLabelFormat={getHoursAndMinutesTextForSlider}
      value={range}
      aria-labelledby="discrete-slider-small-steps"
      step={min === 6 && max === 18 ? 1 : 0.25}
      marks
      min={min}
      max={max}
    />
  );
};
export const DurationBreakdown = ({ totalTime, waitingRoomTime, prepTime, orTime, pacuTime, postopTime }) => {
  const scope = useScope();
  const hasPostOp = get('hospital.modules.hasPostop')(scope);
  return (
    <Panel style={{ flexGrow: 0 }}>
      <DataFieldCustom>
        <DataTitle>Visit Duration</DataTitle>
        <DataValue>{totalTime}</DataValue>
      </DataFieldCustom>
      <DataFieldCustom>
        <DataTitle>Waiting Room</DataTitle>
        <DataValue>{timeOrPlaceholder(waitingRoomTime)}</DataValue>
      </DataFieldCustom>
      <DataFieldCustom>
        <DataTitle>PRE-OP</DataTitle>
        <DataValue>{timeOrPlaceholder(prepTime)}</DataValue>
      </DataFieldCustom>
      <DataFieldCustom>
        <DataTitle>OR</DataTitle>
        <DataValue>{timeOrPlaceholder(orTime)}</DataValue>
      </DataFieldCustom>
      <DataFieldCustom>
        <DataTitle>Recovery</DataTitle>
        <DataValue>{timeOrPlaceholder(pacuTime)}</DataValue>
      </DataFieldCustom>
      {hasPostOp && (
        <DataFieldCustom>
          <DataTitle>PostOp</DataTitle>
          <DataValue>{timeOrPlaceholder(postopTime)}</DataValue>
        </DataFieldCustom>
      )}
    </Panel>
  );
};

export const BraceletPanel = ({ braceletId, setEditModalOpen, withActionButton, isAssociated }) => (
  <CustomCard title="Bracelet">
    <Box display="flex" justifyContent="space-between">
      <DataValue>
        <Status>
          <StatusIndicator isAssociated={isAssociated && braceletId !== 'manual'} />
          {braceletId !== 'manual' ? (isAssociated ? 'Associated' : 'Not Associated') : 'Manual Tracking'} &nbsp;
          {braceletId !== 'manual' && <span style={{ opacity: 0.3 }}>{braceletId}</span>}
        </Status>
      </DataValue>
      {withActionButton && braceletId !== 'manual' && (
        <LinkButton onClick={() => setEditModalOpen({ braceletId: true })}>Change</LinkButton>
      )}
    </Box>
  </CustomCard>
);

export const LocationPanel = ({ room, patientId, setEditModalOpen, withActionButton, isChangeLocationDisabled }) => (
  <Paper variant="outlined">
    <Box px={2} py={2}>
      <Typography variant="subtitle2" color="textSecondary" gutterBottom>
        Current Location
      </Typography>
      <Box display="flex" justifyContent="space-between">
        <Typography>{room.name}</Typography>
        {withActionButton && !isChangeLocationDisabled && (
          <Box>
            <LinkButton onClick={() => setEditModalOpen({ room: true })}>Change</LinkButton>
            <ActionSeparator>|</ActionSeparator>
            <DischargeAction id={patientId} />
          </Box>
        )}
      </Box>
    </Box>
  </Paper>
);

export const ProcedureSchedulingAccuracyPanel = ({ accuracy, scheduledRange, actualRange, sliderRange }) => {
  const classes = useStyles();
  const accuracyToPercentage = accuracy && parseInt(accuracy.slice(0, -1), 10);
  const [from, to] = sliderRange;

  return (
    <CustomCard title="Procedure Scheduling Accuracy">
      <Box display="flex" alignItems="center" flex={1}>
        <Box>
          {accuracy ? (
            <Box position="relative" display="inline-flex" mr={4}>
              <CircularProgress variant="determinate" value={accuracyToPercentage} size={80} />
              <CircularProgress className={classes.progressBase} variant="determinate" value={100} size={80} />
              <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography component="div">{accuracyToPercentage}%</Typography>
              </Box>
            </Box>
          ) : (
            <Box position="relative" display="inline-flex" mr={4}>
              <CircularProgress
                variant="determinate"
                value={100}
                size={80}
                classes={{ root: classes.circularProgressRoot }}
              />
              <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography component="div">N/A</Typography>
              </Box>
            </Box>
          )}
        </Box>
        <Box flex={1}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography display="inline" variant="body2">
                Scheduled OR Time vs Actual OR Time
              </Typography>
              <RangeSlider disabled range={scheduledRange} min={from} max={to} />
              <RangeSlider disabled range={actualRange} min={from} max={to} color="secondary" />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </CustomCard>
  );
};

export const ActivePatientPanel = ({
  patientId,
  room,
  setEditModalOpen,
  braceletId,
  procedureSchedulingAccuracy,
  scheduledRange,
  actualRange,
  sliderRange,
  showBracelet,
  showProcedureSchedulingAccuracy,
  withActionButton,
  isChangeLocationDisabled,
}) => (
  <Grid container spacing={2} alignItems="stretch">
    <Grid item md={showProcedureSchedulingAccuracy ? 6 : 12}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <LocationPanel
            room={room}
            patientId={patientId}
            setEditModalOpen={setEditModalOpen}
            withActionButton={withActionButton && !isChangeLocationDisabled}
          />
        </Grid>
        {!!showBracelet && (
          <Grid item xs={12}>
            <BraceletPanel braceletId={braceletId} setEditModalOpen={setEditModalOpen} withActionButton isAssociated />
          </Grid>
        )}
      </Grid>
    </Grid>
    <Grid item md={6}>
      {!!showProcedureSchedulingAccuracy && (
        <ProcedureSchedulingAccuracyPanel
          accuracy={procedureSchedulingAccuracy}
          scheduledRange={scheduledRange}
          actualRange={actualRange}
          sliderRange={sliderRange}
        />
      )}
    </Grid>
  </Grid>
);

export const DischargedPatientPanel = ({
  patientId,
  braceletId,
  procedureSchedulingAccuracy,
  scheduledRange,
  actualRange,
  sliderRange,
  showBracelet,
  showProcedureSchedulingAccuracy,
}) => (
  <Grid container spacing={2} alignItems="stretch">
    <Grid item md={showProcedureSchedulingAccuracy ? 6 : 12}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <LocationPanel room={{ name: 'Discharged' }} patientId={patientId} withActionButton={false} />
        </Grid>
        {!!showBracelet && (
          <Grid item xs={12}>
            <BraceletPanel braceletId={braceletId} withActionButton={false} isAssociated={false} />
          </Grid>
        )}
      </Grid>
    </Grid>
    <Grid item md={6}>
      {!!showProcedureSchedulingAccuracy && (
        <ProcedureSchedulingAccuracyPanel
          accuracy={procedureSchedulingAccuracy}
          scheduledRange={scheduledRange}
          actualRange={actualRange}
          sliderRange={sliderRange}
        />
      )}
    </Grid>
  </Grid>
);

export const CanceledPatientPanel = ({
  patientId,
  braceletId,
  procedureSchedulingAccuracy,
  scheduledRange,
  actualRange,
  sliderRange,
  showBracelet,
  showProcedureSchedulingAccuracy,
}) => (
  <Grid container spacing={2} alignItems="stretch">
    <Grid item md={showProcedureSchedulingAccuracy ? 6 : 12}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <LocationPanel room={{ name: 'Visit Canceled' }} patientId={patientId} withActionButton={false} />
        </Grid>
        {!!showBracelet && (
          <Grid item xs={12}>
            <BraceletPanel braceletId={braceletId} withActionButton={false} isAssociated={false} />
          </Grid>
        )}
      </Grid>
    </Grid>
    <Grid item md={6}>
      {!!showProcedureSchedulingAccuracy && (
        <ProcedureSchedulingAccuracyPanel
          accuracy={procedureSchedulingAccuracy}
          scheduledRange={scheduledRange}
          actualRange={actualRange}
          sliderRange={sliderRange}
        />
      )}
    </Grid>
  </Grid>
);

export const TrackingPanel = ({
  patientId,
  room,
  setEditModalOpen,
  braceletId,
  isActive,
  isDischarged,
  isCanceled,
  procedureSchedulingAccuracy,
  scheduledRange,
  actualRange,
  sliderRange,
  showBracelet,
  showProcedureSchedulingAccuracy,
  withActionButton,
  isChangeLocationDisabled,
}) => {
  if (isActive) {
    return (
      <ActivePatientPanel
        patientId={patientId}
        room={room}
        setEditModalOpen={setEditModalOpen}
        braceletId={braceletId}
        procedureSchedulingAccuracy={procedureSchedulingAccuracy}
        scheduledRange={scheduledRange}
        actualRange={actualRange}
        sliderRange={sliderRange}
        showBracelet={showBracelet}
        showProcedureSchedulingAccuracy={showProcedureSchedulingAccuracy}
        withActionButton={withActionButton}
        isChangeLocationDisabled={isChangeLocationDisabled}
      />
    );
  } else if (isCanceled) {
    return (
      <CanceledPatientPanel
        braceletId={braceletId}
        procedureSchedulingAccuracy={procedureSchedulingAccuracy}
        scheduledRange={scheduledRange}
        actualRange={actualRange}
        sliderRange={sliderRange}
        showBracelet={showBracelet}
        showProcedureSchedulingAccuracy={showProcedureSchedulingAccuracy}
      />
    );
  } else if (isDischarged) {
    return (
      <DischargedPatientPanel
        braceletId={braceletId}
        procedureSchedulingAccuracy={procedureSchedulingAccuracy}
        scheduledRange={scheduledRange}
        actualRange={actualRange}
        sliderRange={sliderRange}
        showBracelet={showBracelet}
        showProcedureSchedulingAccuracy={showProcedureSchedulingAccuracy}
      />
    );
  }
  return null;
};
