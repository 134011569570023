import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { compose, withState } from 'recompose';
import styled, { ThemeProvider } from 'styled-components';
import queryString from 'query-string';
import posed, { PoseGroup } from 'react-pose';
import identity from 'lodash/identity';

import Form from '../../../se/components/Form';
import { isBoolean } from '../../../se/utilities/check';

import Copyright from '../../Copyright';
import loginIllustration from '../../../assets/images/surgery_center.svg';
import responsive from '../../../se/utilities/responsive';
import Logo from '../../Logo';
import { light as lightTheme } from '../../../theme';
import { withSession } from '../../../state/Session';
import PWAInstallBanner from '../../../se/components/PWAInstallBanner';
import Typography from '@material-ui/core/Typography';
import ResetPasswordInput from '../../../se/components/inputs/ResetPasswordInput';
import { Alert } from '@material-ui/lab';

const Wrapper = styled.div`
  display: grid;
  height: 100vh;
  grid-template-columns: auto 25rem auto;
  grid-template-rows: 1fr 2fr 1fr;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.textColor.string()};
  padding-left: 1em;
  padding-right: 1em;

  ${responsive.sm.andSmaller`
    grid-template-columns: auto 100% auto;
    grid-template-rows: auto auto auto;
  `};
`;

const Container = posed(styled.div`
  grid-column: 2;
`)({
  enter: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1000,
      ease: [0.0, 0.01, 0.01, 0.99],
    },
  },
  exit: {
    x: -20,
    opacity: 0,
  },
});

const Heading = posed(styled.div`
  grid-column: 2;
`)({
  enter: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1000,
      ease: [0.0, 0.01, 0.01, 0.99],
    },
  },
  exit: {
    x: -20,
    opacity: 0,
  },
});

const Footer = posed(Copyright)({
  enter: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1000,
      ease: [0.0, 0.01, 0.01, 0.99],
    },
  },
  exit: {
    x: -20,
    opacity: 0,
  },
});
const Page = styled.div`
  background-color: white;
  display: grid;
  grid-template-columns: 50% auto;

  ${responsive.md.andSmaller`
    grid-template-columns: auto;
  `};
`;

const Illustration = posed(styled.img`
  width: 30vw;
  margin-left: 4vw;
  margin-bottom: 2vh;
  align-self: center;

  ${responsive.md.andSmaller`
      display: none;
    `};
`)({
  enter: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1000,
      ease: [0.0, 0.01, 0.01, 0.99],
    },
  },
  exit: {
    x: 40,
    opacity: 0,
  },
});

const SetPasswordPage = ({ session, location, setBusy, verifyResetPasswordToken, resetPasswordWithToken }) => {
  const [validSession, setValidSession] = useState(null);
  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  useEffect(() => {
    verifyResetPasswordToken(params?.token)
      .then(r => {
        setValidSession(r.valid);
      })
      .catch(e => {
        console.log(e);
      });
  }, [params?.token, verifyResetPasswordToken]);

  const resetPassword = async ({ newPassword, repeatedPassword }) => {
    if (newPassword !== repeatedPassword) {
      throw Error('You did not repeat the password correctly!');
    } else {
      setBusy(true);
      try {
        await resetPasswordWithToken(params?.token, newPassword);
      } catch (e) {
        throw e;
      }
      setBusy(false);
    }
  };

  const isSessionValid = session && session.valid;

  const nextUrl = () => queryString.parse(location.search).next || '/';

  if (isSessionValid) {
    return <Redirect to={nextUrl()} />;
  }

  const isExpiredLink = isBoolean(validSession) && !validSession;

  return (
    <ThemeProvider theme={lightTheme}>
      <Page>
        <Wrapper>
          <PoseGroup animateOnMount>
            <Heading key={0}>
              <Logo />
            </Heading>
            <Container key={1}>
              <PWAInstallBanner />
              {isExpiredLink && (
                <Alert severity="warning">
                  The time to set your password has expired, this link is no longer valid!
                </Alert>
              )}
              {!isExpiredLink && (
                <>
                  <Typography variant="h2" gutterBottom>
                    Enter a new password
                  </Typography>
                  <Form autoFocus input={ResetPasswordInput} label="Confirm" onSubmit={resetPassword} />
                </>
              )}
            </Container>
            <Footer key={2} />
          </PoseGroup>
        </Wrapper>
        <PoseGroup animateOnMount={true}>
          <Illustration key={0} src={loginIllustration} />
        </PoseGroup>
      </Page>
    </ThemeProvider>
  );
};

export default compose(withSession(identity), withState('busy', 'setBusy', false))(SetPasswordPage);
