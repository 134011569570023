import React from 'react';

import GroupedTabNavigation from '../pages/kiosk/charting/GroupedTabNavigation';
import { withRouter } from 'react-router-dom';
import useTabs from '../pages/kiosk/charting/modules/useTabs';
import { Accordion, AccordionDetails, AccordionSummary, useMediaQuery } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTheme } from '@material-ui/core/styles';

export default withRouter(({ history, location, match }) => {
  const tabs = useTabs();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return tabs ? (
    isMobile ? (
      <Accordion style={{ width: '100%', flex: 1 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Charting</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <GroupedTabNavigation tabs={tabs} history={history} location={location} match={match} />
        </AccordionDetails>
      </Accordion>
    ) : (
      <GroupedTabNavigation tabs={tabs} history={history} location={location} match={match} />
    )
  ) : null;
});
