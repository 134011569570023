import { FormType } from '../../../../types/Form';
import { useQuery } from '@apollo/client';
import React from 'react';
import SurgeonFormPage from '../../../entities/surgeonProcedures/HtmlForm';
import { findAllByFormType } from './ChartingForms';
import { list } from '../../../../graph/surgeon/forms';
import { TemplatesMenu } from '../../../entities/surgeonProcedures/predefinedForms/anesthesiaCharting/DictationForm';

const PhysicianChartingForm = ({ patientId, procedureId }: { patientId: number; procedureId: number }) => {
  const { data, loading } = useQuery(list);

  const form = findAllByFormType(data?.forms, [FormType.PhysicianChartForm])?.find(e => e?.name === 'Dictation Form');

  return (
    <>
      {!loading && (
        <SurgeonFormPage
          id={form?.id}
          patientId={patientId}
          procedureId={procedureId}
          MoreActionsComponent={TemplatesMenu}
        />
      )}
    </>
  );
};

export default PhysicianChartingForm;
