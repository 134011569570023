import React from 'react';
import styles from '../styles';
import MedicalPassportField from '../../components/MedicalPassportField';

const Footer = ({ pageNumber, pageCount, style }) => (
  <div style={style ? { ...style, ...styles.footer } : styles.footer}>
    <div style={styles.rowSpaceBetween}>
      <MedicalPassportField
        name="procedure.patient.name"
        label="Patient Name"
        path={['demographicInformation', 'name']}
        style={{ width: '60%' }}
      />
      <MedicalPassportField name="dob" label="DOB" path={['demographicInformation', 'dob']} />
      <MedicalPassportField name="procedure.serviceTime" label="Date of Service" />
    </div>
    <div style={{ textAlign: 'left' }}>
      Page {pageNumber} of {pageCount}
    </div>
  </div>
);

export default Footer;
