import React, { useEffect, useState, useRef } from 'react';
import Header from './components/Header';
import PatientInfo from './components/PatientInfo';
import styles, { getGridParentStyles, getGridChildStyles2 } from './styles';
import MedicalPassportCheckbox from '../components/MedicalPassportCheckbox';
import { useValue } from '../ValueContext';
import get from 'lodash/get';
import Footer from './components/Footer';
import MedicationTextArea from '../components/MedicationTextArea';

const Page3 = () => {
  const { questionnaireAnswers } = useValue();

  // medication
  function checkedCont(value) {
    if (value === 'Continue Med') {
      return true;
    }
    return false;
  }
  function checkedHold(value) {
    if (value === 'Hold medication') {
      return true;
    }
    return false;
  }
  function checkedNew(value) {
    if (value === 'New Prescription given') {
      return true;
    }
    return false;
  }
  function checkedPCP(value) {
    if (value === 'Check with PCP') {
      return true;
    }
    return false;
  }

  function formatDate(inputDate) {
    const day = inputDate.substr(0, 2);
    const month = inputDate.substr(2, 2);
    const year = inputDate.substr(4);

    return `${day}/${month}/${year}`;
  }

  const medicationList = get(
    questionnaireAnswers,
    'sections.medications.schema.medicationList.yes[1].schema.medication_list.list',
    []
  );

  let nameArr = [];
  let doseArr = [];
  let routeArr = [];
  let freqArr = [];
  let result = [];
  let purposeArr = [];
  let last = [];
  let status = [];

  for (let i = 0; i < medicationList.length; i++) {
    nameArr[i] = get(medicationList[i], 'schema.name.text', '');
    doseArr[i] = get(medicationList[i], 'schema.dosage.text', '');
    routeArr[i] = get(medicationList[i], 'schema.route.option', '');
    freqArr[i] = get(medicationList[i], 'schema.frequency.option', '');
    result[i] = nameArr[i] + ',' + doseArr[i] + ',' + routeArr[i] + ',' + freqArr[i];
    last[i] = get(medicationList[i], 'schema.lastTaken.text', '');

    purposeArr[i] = get(medicationList[i], 'schema.purpose.text', '');
    status[i] = get(medicationList[i], 'schema.status.option');
  }

  const pageRef = useRef(null);
  const [pageStyle, setPageStyle] = useState({});

  useEffect(() => {
    const handleResize = () => {
      if (pageRef.current) {
        const pageHeight = pageRef.current.offsetHeight;
        if (pageHeight > 1086) {
          setPageStyle({ minHeight: 2150 });
        } else {
          setPageStyle({ minHeight: 1085 });
        }
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div id="page3" className="page" style={{ ...styles.root, ...pageStyle, fontFamily: 'Arial', gap: '10px' }}>
      <div ref={pageRef}>
        <Header pageNumber={3} pageCount={12} />
        <div>
          <b>MEDICATION RECONCILIATION</b>
        </div>
        <PatientInfo />
        <div style={{ fontSize: '14px' }}>Medications Prior to Admission</div>
        <p style={{ textAlign: 'left', fontSize: '14px' }}>
          List all medications, vitamins, nutritional and/or herbal supplements, including medications administrated via
          pump, or patch, prior to admission.
        </p>
        <div style={medicationTable}>
          <div style={{ fontSize: '14px', borderRight: '1px solid black' }}>Medication, dosage, route, frequency</div>
          <div style={{ fontSize: '14px', textAlign: 'right' }}>Last </div>
          <div style={{ fontSize: '14px', textAlign: 'left', borderRight: '1px solid black' }}>&nbsp;Taken</div>
          <div style={{ fontSize: '14px', borderRight: '1px solid black' }}>Reason</div>
          <div>
            <div style={{ fontSize: '11px' }}>Cont = Continue Med</div>
            <div style={{ fontSize: '11px' }}>Hold=Hold medication</div>
            <div style={{ fontSize: '11px' }}>New = New Prescription given</div>
            <div style={{ fontSize: '11px' }}>PCP = Check with PCP</div>
          </div>
          {[...Array(medicationList.length)].map((_, i) => (
            <>
              <div
                style={{
                  border: '1px solid black',
                  borderBottom: 0,
                  borderRight: 0,
                  borderLeft: 0,
                  wordBreak: 'break-all',
                  fontSize: '12px',
                }}
              >
                <MedicationTextArea name={`medication.r${i}.c0`} value={result[i]} />
              </div>
              <div style={{ border: '1px solid black', borderBottom: 0, borderRight: 0, fontSize: '12px' }}>
                <MedicationTextArea name={`medication.r${i}.c1`} value={last[i]} />
              </div>
              <div style={{ border: '1px solid black', borderBottom: 0, borderRight: 0, fontSize: '12px' }}>
                <MedicationTextArea name={`medication.r${i}.c4`} />
              </div>
              <div style={{ border: '1px solid black', borderBottom: 0, borderRight: 0, fontSize: '12px' }}>
                <MedicationTextArea name={`medication.r${i}.c2`} value={purposeArr[i]} />
              </div>
              <div style={{ display: 'flex' }}>
                <div style={{ border: '1px solid black', borderBottom: 0, borderRight: 0, paddingRight: '7px' }}>
                  <input type="checkbox" name={`medication.r${i}.cont`} label="Cont" />
                  <div style={{ fontSize: '10px' }}>Cont</div>
                </div>
                <div style={{ border: '1px solid black', borderBottom: 0, borderRight: 0, paddingRight: '7px' }}>
                  <input type="checkbox" name={`medication.r${i}.hold`} label="Hold" />
                  <div style={{ fontSize: '10px' }}>Hold</div>
                </div>
                <div style={{ border: '1px solid black', borderBottom: 0, borderRight: 0, paddingRight: '7px' }}>
                  <input type="checkbox" name={`medication.r${i}.new`} label="New" />
                  <div style={{ fontSize: '10px' }}>New</div>
                </div>
                <div style={{ border: '1px solid black', borderBottom: 0, borderRight: 0, paddingRight: '7px' }}>
                  <input type="checkbox" name={`medication.r${i}.pcp`} label="PCP" />
                  <div style={{ fontSize: '10px' }}>PCP</div>
                </div>
              </div>
            </>
          ))}
        </div>
        <div style={{ ...getGridParentStyles(6, false), fontSize: '14px', margin: '10px 0' }}>
          <div style={getGridChildStyles2(4)}>Pre-Op Nurse Signature: ________________________</div>
          <div style={getGridChildStyles2(2)}>Date: _________________</div>
        </div>
        <div style={{ ...prescriptionTable, fontSize: '12px', pageBreakBefore: 'always' }}>
          <div style={{ fontSize: '14px', padding: '2px 0', borderRight: '1px solid black' }}>
            Prescriptions Given at Discharge
          </div>
          <div style={{ fontSize: '14px', padding: '2px 0' }}>Next Dose</div>
          {[...Array(7)].map((_, i) => (
            <>
              <div
                style={{ border: '1px solid black', borderBottom: 0, borderRight: 0, borderLeft: 0, fontSize: '10px' }}
              >
                <MedicationTextArea name={`prescription.r${i}.c0`} />
              </div>
              <div style={{ border: '1px solid black', borderBottom: 0, borderRight: 0, fontSize: '10px' }}>
                <MedicationTextArea name={`prescription.r${i}.c1`} />
              </div>
            </>
          ))}
        </div>
        <div style={{ fontSize: '14px', margin: '10px 0' }}>
          <MedicalPassportCheckbox
            name="discharge"
            label="Discharge Medications reviewed with patient or patient representative."
          />
        </div>
        <div style={{ ...getGridParentStyles(6, false), fontSize: '14px' }}>
          <div style={getGridChildStyles2(4)}>Post-Op Nurse Signature: ________________________</div>
          <div style={getGridChildStyles2(2)}>Date/Time: _________________</div>
          <div style={getGridChildStyles2(4)}>Patient or Responsible Party Signature: ________________________</div>
          <div style={getGridChildStyles2(2)}>Date/Time: _________________</div>
        </div>
        <br />
        <br />
        <Footer pageNumber={3} pageCount={12} />
      </div>
    </div>
  );
};

const medicationTable = { display: 'grid', border: '1px solid black', gridTemplateColumns: '45% 10% 10% 20% 15%' };
const prescriptionTable = { display: 'grid', border: '1px solid black', gridTemplateColumns: '50% 50%' };

export default Page3;
