import { gql } from '@apollo/client';

export const reviewIntegrationFragments = {
  base: `
    fragment ReviewBase on ReviewIntegration {
      link
      enabled
    }
  `,
};

export const fragments = {
  small: gql`
    fragment HospitalDataS on Hospital {
      id
      name
      address
      contact
      type
    }
  `,

  all: gql`
    fragment HospitalData on Hospital {
      id
      name
      address
      contact
      notes
      type
      monitors {
        id
        name
      }
      groupAdmins {
        id
        name
      }
      minBracelets
      sessionTimeout
      questionnaireValidMonths
      passwordRefreshDays
      scheduleProvider
      timezone {
        id
        name
        offset
      }
      exchangeOnly
      modules {
        temperatureReporting
        patientRegistration
        blockSchedule
        airFiltrationTimer
        automaticPatientTracking
        lightMode
        acceptsProcedureTransfer
        hasPostop
        hasPatientTypes
        createProcedureSchedule
        noteTablet
        patientCharting
        preOpStatusReset
        orTabletV2
        preOpPriority {
          hasPreOpPriority
          priorityCounter
        }
        schedulePDFs {
          label
          value
        }
        analyticsPDFs {
          label
          value
        }
        chartingIPAddressWhitelistEnabled
        chartingIPAddressWhitelist
        ipAddressWhitelistEnabled
        ipAddressWhitelist
        inTakeForms
        dictation
        showQRCode
        analyticsV2
        analyticsV2PieCharts
        showStaffCost
        showPreOpNote
        showCSVUpload
        showWeekends
        secondaryRoomAnesthesiologist
      }
      reviewIntegrations {
        google {
          ...ReviewBase
        }
        yelp {
          ...ReviewBase
        }
      }
      caretakerReviewIntegrations {
        google {
          ...ReviewBase
        }
        yelp {
          ...ReviewBase
        }
      }
      ospitekOrdersIntegrations {
        externalLink {
          link
          enabled
        }
      }
      covidScreening {
        id
        question
      }
      scanChart
    }
    ${reviewIntegrationFragments.base}
  `,
};

export const list = gql`
  query hospitals {
    hospitals {
      ...HospitalDataS
    }
  }
  ${fragments.small}
`;

export const hospitalOptions = gql`
  query hospitals {
    hospitals {
      id
      name
    }
  }
`;

export const item = gql`
  query hospital($id: Long!) {
    hospital(id: $id) {
      ...HospitalData
    }
  }
  ${fragments.all}
`;

export const create = gql`
  mutation createHospitals(
    $name: String!
    $address: String
    $contact: String
    $monitors: [Long!]
    $groupAdmins: [Long!]
    $notes: String
    $minBracelets: Int
    $sessionTimeout: Int!
    $questionnaireValidMonths: Int!
    $passwordRefreshDays: Int
    $reviewIntegrations: ReviewIntegrationsInput
    $caretakerReviewIntegrations: ReviewIntegrationsInput
    $ospitekOrdersIntegrations: OspitekOrdersIntegrationsInput
    $scheduleProvider: ScheduleProvider
    $timezone: String
  ) {
    createHospital(
      name: $name
      address: $address
      contact: $contact
      monitors: $monitors
      groupAdmins: $groupAdmins
      notes: $notes
      minBracelets: $minBracelets
      sessionTimeout: $sessionTimeout
      questionnaireValidMonths: $questionnaireValidMonths
      passwordRefreshDays: $passwordRefreshDays
      reviewIntegrations: $reviewIntegrations
      caretakerReviewIntegrations: $caretakerReviewIntegrations
      ospitekOrdersIntegrations: $ospitekOrdersIntegrations
      scheduleProvider: $scheduleProvider
      timezone: $timezone
    ) {
      ...HospitalData
    }
  }
  ${fragments.all}
`;

export const update = gql`
  mutation editHospital(
    $id: Long!
    $name: String
    $address: String
    $contact: String
    $monitors: [Long!]
    $groupAdmins: [Long!]
    $notes: String
    $minBracelets: Int
    $sessionTimeout: Int!
    $questionnaireValidMonths: Int!
    $passwordRefreshDays: Int
    $modules: HospitalModulesInput
    $reviewIntegrations: ReviewIntegrationsInput
    $caretakerReviewIntegrations: ReviewIntegrationsInput
    $ospitekOrdersIntegrations: OspitekOrdersIntegrationsInput
    $scheduleProvider: ScheduleProvider
    $timezone: String
  ) {
    editHospital(
      id: $id
      name: $name
      address: $address
      contact: $contact
      monitors: $monitors
      groupAdmins: $groupAdmins
      notes: $notes
      minBracelets: $minBracelets
      sessionTimeout: $sessionTimeout
      questionnaireValidMonths: $questionnaireValidMonths
      passwordRefreshDays: $passwordRefreshDays
      modules: $modules
      reviewIntegrations: $reviewIntegrations
      caretakerReviewIntegrations: $caretakerReviewIntegrations
      ospitekOrdersIntegrations: $ospitekOrdersIntegrations
      scheduleProvider: $scheduleProvider
      timezone: $timezone
    ) {
      ...HospitalData
    }
  }
  ${fragments.all}
`;

export const remove = gql`
  mutation deleteHospital($id: Long!) {
    deleteHospital(id: $id)
  }
`;

export const externalScheduleProviders = gql`
  query externalScheduleProviders {
    externalScheduleProviders
  }
`;

export const timezoneOptions = gql`
  query timezones {
    timezones {
      id
      name
    }
  }
`;

export const hospitalDocumentSections = gql`
  query hospitalDocumentSections($organizationId: Long!) {
    hospitalDocumentSections(organizationId: $organizationId) {
      id
      order
      name
      content
      mainCategory
    }
  }
`;

export const uploadDocumentRequest = gql`
  query uploadDocumentRequest($sectionId: Long!, $organizationId: Long!, $fileName: String!, $contentType: String!) {
    uploadRequest: uploadDocumentRequest(
      sectionId: $sectionId
      organizationId: $organizationId
      fileName: $fileName
      contentType: $contentType
    )
  }
`;

export const confirmDocumentUpload = gql`
  mutation confirmDocumentUpload($sectionId: Long!, $organizationId: Long!, $fileName: String!, $value: Json!) {
    confirmDocumentUpload(sectionId: $sectionId, organizationId: $organizationId, fileName: $fileName, value: $value)
  }
`;

export const removeHospitalUploadedDocument = gql`
  mutation removeUploadedDocument($sectionId: Long!, $organizationId: Long!, $fileName: String!, $value: Json!) {
    removeUploadedDocument(sectionId: $sectionId, organizationId: $organizationId, fileName: $fileName, value: $value)
  }
`;

export const hospitalUploadedDocuments = gql`
  query hospitalUploadedDocuments($sectionId: Long!, $organizationId: Long!) {
    uploadedForms: hospitalUploadedDocuments(sectionId: $sectionId, organizationId: $organizationId)
  }
`;

export default {
  list,
  item,
  create,
  update,
  remove,
};
