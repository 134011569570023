import { chain } from './any';

export const get = (key, defaultValue) => object => (key in object ? object[key] : defaultValue);
export const set = (key, value) => object => ({ ...object, [key]: value });
export const update = (key, updater) => object => ({
  ...object,
  [key]: updater(object[key]),
});

export const filterKeysAndTransformValues = schema => value =>
  Object.entries(schema).reduce((acc, [key, f]) => ({ ...acc, [key]: f(value[key]) }), {});

export const renameKey = (from, to) => value => ({
  ...value,
  [from]: undefined,
  [to]: value[from],
});

export const freeze = value => Object.freeze(value);

export const filterKeysTransformValuesAndFreeze = schema => chain(filterKeysAndTransformValues(schema), freeze);

export const call =
  methodName =>
  (...args) =>
  value =>
    value[methodName](...args);

/**
 * Pass object and keys you want to exclude from that object
 * Example:
 * const obj = { name: 'Spice', lastName: 'Factory' };
 * excludeFromMap(obj, ['lastName']); // { name: 'Spice' }
 *
 * @param map
 * @param keys
 * @returns {Object}
 */
export const excludeFromMap = (map, keys) =>
  Object.keys(map)
    .filter(key => !keys.includes(key))
    .reduce((obj, key) => ({ ...obj, [key]: map[key] }), {});

/**
 * @param {Array.<string|number>|String} path – e.g ['key1', 0, 'key2'] or a string such as 'key1.0.key2
 * @param {Object|Array} object
 * @returns {*}
 */
export const getNestedValue = (path, object) => {
  if (!Array.isArray(path) && typeof path !== 'string') {
    throw Error(
      'The package get-nested-value received a non-contract path. Please provide a string, number, or an array of those!'
    );
  }
  let searchPath = path;
  if (!Array.isArray(path)) {
    searchPath = searchPath.split('.');
  }
  return searchPath.reduce((prev, cur) => {
    if (prev && prev[cur] !== undefined) {
      return prev[cur];
    }
    return undefined;
  }, object);
};

/**
 * Helps to transform object to array.
 * Using key of a prop as an id.
 *
 * Example:
 * { list: { path: '/list' }, create: { path: '/create' } }
 * transform to:
 * [{ id: 'list', path: '/list' }, { id: 'create', path: '/create' }]
 *
 * @param keyName
 * @returns {function}
 */
export const mapOfMapsToListOfMaps = keyName => map =>
  Object.entries(map || {}).map(([id, value]) => ({ ...value, [keyName]: id }));

export const toListOfMapsWithIds = mapOfMapsToListOfMaps('id');
