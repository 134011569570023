import { Mutation } from '@apollo/client/react/components';
import React from 'react';
import { setFamilyReadyForPickupPOSTOP } from '../../../../graph/patients';

const withSetFamilyReadyForPickupPOSTOP = Component => props => (
  <Mutation mutation={setFamilyReadyForPickupPOSTOP}>
    {setFamilyReadyForPickup => <Component {...props} setFamilyReadyPOSTOP={setFamilyReadyForPickup} />}
  </Mutation>
);

export default withSetFamilyReadyForPickupPOSTOP;
