import React from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import { analyticsReportQuery, sendAnalyticsReport } from '../../../../graph/dashboard';
import useModal from '../../../../hooks/useModal';
import { MenuItem } from '@material-ui/core';
import DetailedReportModal from './DetailedReportModal';
import objectHash from 'object-hash/dist/object_hash';

const DetailedReportButton = ({
  pdf,
  filter,
  onBusy,
  thresholds,
}: {
  pdf?: { label: string; value: string };
  filter: any;
  onBusy: () => () => void;
  thresholds: any;
}) => {
  const client = useApolloClient();
  const [reportQueryWorking, setReportQueryWorking] = React.useState<boolean>(false);
  const [lastDetailedReport, setLastDetailedReport] = React.useState<any>(null);
  const { open, openModal, closeModal } = useModal();
  const [sendReport] = useMutation(sendAnalyticsReport);

  return (
    <>
      <MenuItem
        disabled={open}
        onClick={async event => {
          if (event.altKey) {
            openModal();
          } else {
            if (reportQueryWorking) {
              return;
            }

            const filterHash = objectHash(JSON.parse(JSON.stringify(filter)));

            if (lastDetailedReport !== null && lastDetailedReport[0] === filterHash) {
              window.open(lastDetailedReport[1], '_blank');
            } else {
              setReportQueryWorking(true);
              const onDone = onBusy();

              try {
                const result = await client.query({
                  query: analyticsReportQuery,
                  variables: { filter, pdf: pdf?.value ?? '', ...thresholds },
                });

                setLastDetailedReport([filterHash, result.data.detailedReport]);
                window.open(result.data.detailedReport, '_blank');
              } finally {
                setReportQueryWorking(false);
                onDone();
              }
            }

            closeModal();
          }
        }}
      >
        {pdf?.label ?? 'Detailed Report'}
      </MenuItem>

      {open && <DetailedReportModal onBusy={onBusy} onClose={() => closeModal()} />}
    </>
  );
};

export default DetailedReportButton;
