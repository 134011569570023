import React from 'react';
import Button from '@material-ui/core/Button';
import { withRouter, RouteComponentProps, generatePath } from 'react-router';
import { matchPath } from 'react-router-dom';
import { useSave } from '../../components/pages/kiosk/charting/SaveProvider';

interface AnesthesiaDocumentsButtonProps extends RouteComponentProps {}

const AnesthesiaDocumentsButton = ({ history }: AnesthesiaDocumentsButtonProps) => {
  const match = matchPath<{
    id: string;
    chart: string;
    chartId: string;
  }>(history.location.pathname, '/kiosk/:id(\\d+)/charting/:chart(.+-chart)/:chartId(\\d+)');

  const { save } = useSave();

  return (
    match && (
      <Button
        component="a"
        href={
          generatePath('/kiosk/:id/charting/:chart/:patientId', {
            id: match.params.id,
            chart: match.params.chart.replace('-chart', ''),
            patientId: match.params.chartId,
          }) + history.location.search
        }
        variant="contained"
        size="small"
        onClick={async e => {
          e.preventDefault();
          await save();
          history.push(
            generatePath('/kiosk/:id/charting/:chart/:patientId', {
              id: match.params.id,
              chart: match.params.chart.replace('-chart', ''),
              patientId: match.params.chartId,
            }) + history.location.search
          );
        }}
        style={{ minHeight: '5em' }}
      >
        Documents
      </Button>
    )
  );
};

export default withRouter(AnesthesiaDocumentsButton);
