import createReducer from './reducer';

function emptyReducer<T>(defaultValue: T) {
  return createReducer<T>({
    Sections: () => defaultValue,
    Object: () => defaultValue,
    Text: () => defaultValue,
    LongText: () => defaultValue,
    Checkboxes: () => defaultValue,
    List: () => defaultValue,
    YesNo: () => defaultValue,
    OneOf: () => defaultValue,
    Medication: () => defaultValue,
    BodyMassIndex: () => defaultValue,
    CityStateZip: () => defaultValue,
    DateOfBirth: () => defaultValue,
    Date: () => defaultValue,
    DocumentsApproval: () => defaultValue,
  });
}

export default emptyReducer;
