import { Mutation } from '@apollo/client/react/components';
import React from 'react';
import { setNotReadyForAnesthesia } from '../../../../graph/patients';

const withSetNotReadyForAnesthesia = Component => props => (
  <Mutation mutation={setNotReadyForAnesthesia}>
    {setNotReadyForAnesthesia => <Component {...props} setNotReadyForAnesthesia={setNotReadyForAnesthesia} />}
  </Mutation>
);

export default withSetNotReadyForAnesthesia;
