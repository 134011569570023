import React, { FC, useEffect, useState, useMemo } from 'react';
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Menu,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ExcelGenerator from '../ExcelGenerator';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/core/styles';
import isString from 'lodash/isString';
import { InfoOutlined, DragHandle } from '@material-ui/icons';
import procedures from '../../assets/images/illustrations/procedures.svg';
import EntityState from '../../se/components/EntityState';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { tryParseJson, tryStringifyJson } from '../../util/parseJson';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

// Utility to move an item in an array
function arrayMove<T>(array: T[], from: number, to: number): T[] {
  const newArray = array.slice();
  const [movedItem] = newArray.splice(from, 1);
  newArray.splice(to, 0, movedItem);
  return newArray;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  table: {
    width: '100%',
    overflowX: 'auto',
  },
  tableRow: {
    cursor: 'pointer',
  },
  paper: {
    width: '100%',
  },
  popover: {
    pointerEvents: 'none',
  },
  popoverPaper: {
    padding: theme.spacing(1),
  },
  menuItems: {
    maxHeight: '600px',
    overflowY: 'auto',
  },
  dragHandle: {
    marginRight: theme.spacing(1),
    cursor: 'grab',
  },
  columnItem: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

// --- Sortable Column Item used in drag-drop ---
type SortableColumnItemProps = {
  column: string;
  isSelected: boolean;
  onToggle: (column: string) => void;
};

const SortableColumnItem: FC<SortableColumnItemProps> = ({ column, isSelected, onToggle }) => {
  const classes = useStyles();
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: column,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} className={classes.columnItem}>
      <div {...attributes} {...listeners} className={classes.dragHandle}>
        <DragHandle fontSize="small" />
      </div>
      <FormControlLabel
        control={<Checkbox checked={isSelected} onChange={() => onToggle(column)} value={column} />}
        label={column}
      />
    </div>
  );
};

interface ColumnSelectionProps {
  columns: string[];
  selectedColumns: string[];
  handleColumnToggle: (column: string) => void;
  handleReorder: (columns: string[]) => void;
}

const ColumnSelection: FC<ColumnSelectionProps> = ({ columns, selectedColumns, handleColumnToggle, handleReorder }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // On drag end, update order via the handler.
  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    if (!over || active.id === over.id) return;

    const oldIndex = columns.indexOf(active.id as string);
    const newIndex = columns.indexOf(over.id as string);

    if (oldIndex !== -1 && newIndex !== -1) {
      const newOrder = arrayMove(columns, oldIndex, newIndex);
      handleReorder(newOrder);
    }
  };

  return (
    <div>
      <IconButton aria-controls="settings-menu" aria-haspopup="true" onClick={handleClick} color="inherit">
        <SettingsIcon />
      </IconButton>
      <Menu id="dropdown-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <div className={classes.menuItems}>
          <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext items={columns} strategy={verticalListSortingStrategy}>
              {columns.map(column => (
                <SortableColumnItem
                  key={column}
                  column={column}
                  isSelected={selectedColumns.includes(column)}
                  onToggle={handleColumnToggle}
                />
              ))}
            </SortableContext>
          </DndContext>
        </div>
      </Menu>
    </div>
  );
};

export type AlignType = 'left' | 'right';
export type OnClickFn = (id: string) => void;

export type ColumnType =
  | {
      text: string;
      colSpan?: number;
      align?: AlignType;
      InfoComponent?: FC;
    }
  | string;

export type RowType = {
  id: string;
  onClick?: OnClickFn;
  columns: ColumnType[];
};

export type TableType = {
  configHeader: ColumnType[];
  headers: RowType[];
  rows: RowType[];
};

interface ColumnConfig {
  name: string;
  visible: boolean;
}

const toText = (column: ColumnType): string => (isString(column) ? column : column?.text);
const toColSpan = (column: ColumnType): number => (isString(column) ? 1 : column?.colSpan || 1);
const toAlign = (column: ColumnType, defaultAlign: AlignType = 'left'): AlignType =>
  isString(column) ? defaultAlign : column?.align || 'left';
const toInfoComponent = (column: ColumnType): FC | null => (isString(column) ? null : column?.InfoComponent || null);
const toExcelData = (headers: RowType[], rows: RowType[], config: number[]): string[][] => [
  ...headers.map(e => e.columns.filter((e, i) => config.includes(i)).map(e => toText(e))),
  ...rows.map(e => e.columns.filter((e, i) => config.includes(i)).map(e => toText(e))),
];

const MyTableCell: FC<{ column: ColumnType; defaultAlign: AlignType }> = ({ column, defaultAlign = 'left' }) => {
  const classes = useStyles();
  const InfoComponent = toInfoComponent(column);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [showMore, setShowMore] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  if (InfoComponent) {
    return (
      <>
        <TableCell align={toAlign(column, defaultAlign)} colSpan={toColSpan(column)}>
          <Box display="flex" flexDirection="row" alignItems="right" justifyContent="right" style={{ gap: '0.5em' }}>
            <Box whiteSpace="nowrap">{toText(column)}</Box>
            <IconButton size="small" aria-describedby={id} onClick={handleClick}>
              <InfoOutlined fontSize="small" color="primary" />
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <InfoComponent />
            </Popover>
          </Box>
        </TableCell>
      </>
    );
  }

  const text = toText(column);
  const isLargerText = text?.length > 38;

  if (isLargerText) {
    return (
      <TableCell align={toAlign(column, defaultAlign)} colSpan={toColSpan(column)}>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          {isLargerText && (
            <IconButton onClick={() => setShowMore(v => !v)}>
              {showMore ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </IconButton>
          )}
          <Box
            style={
              showMore
                ? { width: '20em' }
                : {
                    whiteSpace: 'nowrap',
                    maxWidth: '20em',
                    overflow: 'hidden',
                  }
            }
          >
            {text}
          </Box>
        </Box>
      </TableCell>
    );
  }

  return (
    <TableCell style={{ whiteSpace: 'nowrap' }} align={toAlign(column, defaultAlign)} colSpan={toColSpan(column)}>
      {text}
    </TableCell>
  );
};

const TableWithColumnSelector: FC<{
  tableId: string;
  configName: string;
  configHeader: ColumnType[];
  headers: RowType[];
  rows: RowType[];
  tableName: string;
  excelFileName?: string;
  withExportFile?: boolean;
}> = ({ tableId, configName, configHeader, headers, rows, tableName, excelFileName, withExportFile = true }) => {
  const classes = useStyles();
  // Memoize tableHeader to avoid unnecessary re-renders
  const tableHeader: string[] = useMemo(() => configHeader?.map(toText) || [], [configHeader]);

  // Single unified state for column configuration (order + visibility)
  const [columnConfig, setColumnConfig] = useState<ColumnConfig[]>([]);

  // Derive selectedColumns from the unified config
  const selectedColumns = useMemo(() => columnConfig.filter(c => c.visible).map(c => c.name), [columnConfig]);

  // Load saved configuration and merge with current header list.
  useEffect(() => {
    try {
      // Try loading from the new key; fallback to the old key if necessary
      let saved = tryParseJson(localStorage.getItem(`${configName}-withOrder`));
      if (!saved) {
        // load old configuration to migrate to new key
        saved = tryParseJson(localStorage.getItem(configName));
      }

      let loadedConfig: ColumnConfig[] = [];
      if (Array.isArray(saved)) {
        if (saved.length > 0 && typeof saved[0] === 'object' && 'name' in saved[0] && 'visible' in saved[0]) {
          // New format: array of objects
          loadedConfig = saved;
        } else if (saved.every(x => typeof x === 'string')) {
          // Old format: array of visible column names
          loadedConfig = tableHeader.map(col => ({
            name: col,
            visible: saved.includes(col),
          }));
        }
      }
      // Merge with current tableHeader:
      // Preserve order from loadedConfig, and append any new columns at the end with visible=true.
      const loadedNames = loadedConfig.map(c => c.name);
      const mergedConfig = [
        // Keep columns that exist in both saved config and current header (preserving saved order)
        ...loadedConfig.filter(c => tableHeader.includes(c.name)),
        // Append new columns not in saved config
        ...tableHeader.filter(col => !loadedNames.includes(col)).map(col => ({ name: col, visible: true })),
      ];
      // In case mergedConfig is empty (or saved config is invalid), fallback to default config.
      if (mergedConfig.length === 0) {
        mergedConfig.push(...tableHeader.map(col => ({ name: col, visible: true })));
      }
      setColumnConfig(mergedConfig);
      // Save back to new key for future loads
      const configString = tryStringifyJson(mergedConfig);
      if (configString) {
        localStorage.setItem(`${configName}-withOrder`, configString);
      }
    } catch (e) {
      // On error, fallback to default config showing all columns.
      const defaultConfig = tableHeader.map(col => ({ name: col, visible: true }));
      setColumnConfig(defaultConfig);
    }
  }, [configName, tableHeader]);

  // Handle toggling column visibility
  const handleColumnToggle = (column: string) => {
    const newConfig = columnConfig.map(col => (col.name === column ? { ...col, visible: !col.visible } : col));
    setColumnConfig(newConfig);
    // Save updated configuration to localStorage
    const configString = tryStringifyJson(newConfig);
    if (configString) {
      localStorage.setItem(`${configName}-withOrder`, configString);
    }
  };

  // Handle reordering columns. It rebuilds config using the new order and preserves visibility.
  const handleReorder = (newOrder: string[]) => {
    const newConfig = newOrder.map(colName => {
      const existing = columnConfig.find(c => c.name === colName);
      return existing ? { ...existing } : { name: colName, visible: true };
    });
    setColumnConfig(newConfig);
    const configString = tryStringifyJson(newConfig);
    if (configString) {
      localStorage.setItem(`${configName}-withOrder`, configString);
    }
  };

  if (tableHeader.length <= 0) {
    return null;
  }

  // Calculate which column indexes should be displayed.
  // Filter out any indexes that are -1 (in case of mismatches)
  const tableIndexes: number[] = tableHeader.reduce((res, col, i) => {
    if (selectedColumns.includes(col)) {
      res.push(i);
    }
    return res;
  }, [] as number[]);

  // Ordered columns are derived from the unified configuration.
  const orderedColumns = columnConfig.map(c => c.name);

  return (
    <Paper className={classes.paper}>
      <Toolbar className={classes.root}>
        <Box display="flex" flex={1} flexDirection="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">{tableName}</Typography>
          <Box display="flex">
            {withExportFile && (
              <ExcelGenerator
                excelFileName={excelFileName ?? 'export'}
                data={toExcelData(headers, rows, tableIndexes)}
              />
            )}
            <ColumnSelection
              columns={orderedColumns}
              selectedColumns={selectedColumns}
              handleColumnToggle={handleColumnToggle}
              handleReorder={handleReorder}
            />
          </Box>
        </Box>
      </Toolbar>
      <TableContainer>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            {headers.map(row => {
              const rowId = row?.id;
              // Map each column in our ordered config to an index in tableHeader.
              // Filter out invalid indexes.
              const orderedIndexes = orderedColumns
                .filter(col => selectedColumns.includes(col))
                .map(col => tableHeader.indexOf(col))
                .filter(idx => idx >= 0);
              const configured = orderedIndexes.map(idx => row.columns[idx]);
              return (
                <TableRow key={`${tableId}-${rowId}`}>
                  {configured.map((column, i) => (
                    <MyTableCell
                      key={`${tableId}-${rowId}-${i}`}
                      column={column}
                      defaultAlign={i === 0 ? 'left' : 'right'}
                    />
                  ))}
                </TableRow>
              );
            })}
          </TableHead>
          <TableBody>
            {rows && rows.length > 0 ? (
              rows.map(row => {
                const rowId = row?.id;
                const orderedIndexes = orderedColumns
                  .filter(col => selectedColumns.includes(col))
                  .map(col => tableHeader.indexOf(col))
                  .filter(idx => idx >= 0);
                const configured = orderedIndexes.map(idx => row.columns[idx]);
                return (
                  <TableRow
                    className={classes.tableRow}
                    key={`${tableId}-${rowId}`}
                    onClick={() => row?.onClick?.(rowId)}
                    hover
                  >
                    {configured.map((column, i) => (
                      <MyTableCell
                        key={`${tableId}-${rowId}-${i}`}
                        column={column}
                        defaultAlign={i === 0 ? 'left' : 'right'}
                      />
                    ))}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={headers[0].columns.length}>
                  <Box py={20}>
                    <EntityState title="There is no data for this period" illustration={procedures} />
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TableWithColumnSelector;
