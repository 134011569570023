import React, { ReactNode } from 'react';
import Textarea, { TextareaProps } from './Textarea';
import clsx from 'clsx';

interface FieldProps {
  label?: ReactNode;
  rowspan?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  colspan?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  vertical?: boolean;
  highlight?: boolean;
}

const Field = ({ label, rowspan, colspan, vertical, highlight, ...textareaProps }: FieldProps & TextareaProps) => (
  <label
    className={clsx(`f-span-${colspan ?? 12}`, { 'f-flex-col': vertical }, { 'f-bg-light': highlight })}
    style={{ gridRow: rowspan ? `auto / span ${rowspan}` : undefined }}
  >
    {label && <span className="f-label-text f-text-bold">{label}:</span>}
    <Textarea {...textareaProps} />
  </label>
);

export default Field;
