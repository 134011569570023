import { compose, withProps } from 'recompose';
import ObjectInput from '../../../se/components/inputs/ObjectInput';

import DailyHoursCapacity from '../DailyHoursCapacity';
import { withLabel } from '../../../se/components/Label';
import NumberInput from '../../../se/components/inputs/NumberInput';
import ToggleInput from '../../../se/components/inputs/ToogleInput';
import DailyCapacityInput from '../../entities/room/inputs/DailyCapacityInput';

export default withProps({
  schema: {
    availability: DailyHoursCapacity,
    dailyAvailability: DailyCapacityInput,
    openType: withProps({ label: 'Open Type' })(ToggleInput),
    airFiltrationDurationMin: compose(
      withLabel('Air filtration turnaround time in minutes'),
      withProps({ placeholder: 'e.g., 45', required: true })
    )(NumberInput),
    isHideInternalScreen: withProps({ label: 'Hide room on Internal screen' })(ToggleInput),
    isHideScheduleScreen: withProps({ label: 'Hide room on Schedule screen' })(ToggleInput),
    isHideLiaisonScreen: withProps({ label: 'Hide room on Liaison screen' })(ToggleInput),
    hideTurnoverClock: withProps({ label: 'Hide room Turnover Clock' })(ToggleInput),
    warningThreshold: compose(
      withLabel('Turnover Clock warning threshold 1'),
      withProps({ placeholder: 'e.g., 20' })
    )(NumberInput),
    alertThreshold: compose(
      withLabel('Turnover Clock alert threshold 2'),
      withProps({ placeholder: 'e.g., 25' })
    )(NumberInput),
    order: compose(withLabel('Order'))(NumberInput),
  },
})(ObjectInput);
