// @ts-ignore
import messageIcon from '../../../../../assets/images/icons/message.svg';
import React, { useState } from 'react';
import Tooltip from '../../../../Tooltip';
import { Destination } from '../../../../../types/NotificationTemplate';
import { useQuery } from '@apollo/client';
import { getNotificationTemplate } from '../../../../../graph/notificationTemplates';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import { CaretakerChatDialog } from '../../../../chat/ChatDialog';
import { Patient } from '../../../../../types/Patient';
import { CustomModal } from './OperationRoomTabletV2';
import { SendingSetting } from '../../../../entities/notificationTemplate/NotificationTemplates';
import MessageIcon from '@material-ui/icons/Message';

export const Text = styled.div`
  font-size: 1rem;
  line-height: 1.2rem;
  color: #00a7f7;
  opacity: 0.5;
`;

export const ChatButton = ({ patient }: { patient?: Patient }) => {
  const [modal, setModal] = useState(false);

  const tooltipText = !patient?.caretakerConsent
    ? 'No consent'
    : !patient?.notificationNumbers
      ? 'No phone number'
      : 'Send Message';

  const NotifyButtonComponent = () => (
    <IconButton
      size="small"
      onClick={() => setModal(true)}
      disabled={!patient?.caretakerConsent || !patient?.notificationNumbers}
    >
      <MessageIcon color="secondary" />
    </IconButton>
  );

  return (
    <>
      <Tooltip content={tooltipText} delay={0}>
        <NotifyButtonComponent />
      </Tooltip>
      {patient && <CaretakerChatDialog patient={patient} open={modal} handleClose={() => setModal(false)} />}
    </>
  );
};

const NotifyButton = ({
  trigger,
  destination,
  notify,
  profileMessagingEnabled,
  hasPhoneNumber,
  notified,
}: {
  trigger: string;
  destination: Destination;
  notify?: () => Promise<void>;
  profileMessagingEnabled: boolean;
  hasPhoneNumber: boolean;
  notified: boolean;
}) => {
  const [modal, setModal] = useState(false);

  const { data } = useQuery(getNotificationTemplate, {
    variables: { trigger, destination },
  });

  const notification = data?.getNotificationTemplate?.[0];
  const globalMessagingEnabled = notification?.sendingSetting !== SendingSetting.Off;

  const Modal = CustomModal(notification?.description || '');
  const NotifyButtonComponent = () => (
    <IconButton disabled={!globalMessagingEnabled || !hasPhoneNumber || !profileMessagingEnabled}>
      <img src={messageIcon} alt="Message Icon" onClick={() => setModal(true)} />
    </IconButton>
  );

  const tooltipText = !globalMessagingEnabled
    ? `${destination} messaging has been turned off. Go to message configuration to enable it.`
    : !hasPhoneNumber
      ? `${destination} does not have a phone number.`
      : profileMessagingEnabled
        ? `Notify ${destination}`
        : `${destination} messaging has been turned off. ${destination} doesn’t want to receive notification.`;

  const showButton = !modal;

  return (
    <>
      {notified ? (
        <Text>Notified</Text>
      ) : showButton ? (
        <Tooltip content={tooltipText} delay={0}>
          <NotifyButtonComponent />
        </Tooltip>
      ) : null}
      {modal && (
        <Modal
          messageToSend={notification?.content}
          handleConfirm={async () => {
            await notify?.();
            setModal(false);
          }}
          handleCancel={() => setModal(false)}
        />
      )}
    </>
  );
};

export default NotifyButton;
