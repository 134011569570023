import { Mutation } from '@apollo/client/react/components';
import React from 'react';
import { setPatientNotReadyInPREP } from '../../../../graph/patients';

export const withSetPatientNotReadyInPREP = Component => props => (
  <Mutation mutation={setPatientNotReadyInPREP}>
    {setPatientNotReadyInPREP => <Component {...props} setPatientNotReadyInPREP={setPatientNotReadyInPREP} />}
  </Mutation>
);

export default withSetPatientNotReadyInPREP;
