import { Mutation } from '@apollo/client/react/components';
import React from 'react';
import startPositionSessionQuery from './startPositioningSession';
import stopPositionSessionQuery from './stopPositioningSession';
import enterRoomInPositioningSessionQuery from './enterRoomInPositioningSession';
import exitRoomInPositioningSessionQuery from './exitRoomInPositioningSession';

export const withStartPositionSessionQuery = Component => props => (
  <Mutation mutation={startPositionSessionQuery}>
    {startPositionSession => <Component {...props} startPositionSession={startPositionSession} />}
  </Mutation>
);

export const withStopPositionSessionQuery = Component => props => (
  <Mutation mutation={stopPositionSessionQuery}>
    {stopPositionSession => <Component {...props} stopPositionSession={stopPositionSession} />}
  </Mutation>
);

export const withEnterRoomInPositioningSessionQuery = Component => props => (
  <Mutation mutation={enterRoomInPositioningSessionQuery}>
    {enterRoomInPositioningSession => (
      <Component {...props} enterRoomInPositioningSession={enterRoomInPositioningSession} />
    )}
  </Mutation>
);

export const withExitRoomInPositioningSessionQuery = Component => props => (
  <Mutation mutation={exitRoomInPositioningSessionQuery}>
    {exitRoomInPositioningSession => (
      <Component {...props} exitRoomInPositioningSession={exitRoomInPositioningSession} />
    )}
  </Mutation>
);
