import React from 'react';
import styles from '../styles';
import MedicalPassportField from '../components/MedicalPassportField';
import MedicalPassportTextArea from '../components/MedicalPassportTextArea';
import getAgeAndBmi from './pageUtils';
import toAge from '../../../../../questionnaire/toAge';
import toSex from '../../../../../questionnaire/toSex';
import toHighlightsString from '../../../../../questionnaire/toHighlightsString';
import Barcode from '../../Barcode';
import toHighlightsString4 from '../../../../../questionnaire/toHighlightsString4';
import { additionalcontent } from '../golfCoast/pages/contentlimit';
import MedicalPassportTextArea2 from '../components/MedicalPassportTextArea2';
import toStringWithoutQuestions3 from '../../../../../questionnaire/toStringWithoutQuestions3';
import {
  cardiovascular,
  endocrine,
  gastrointestinal,
  hepatic,
  infectious,
  musculoskeletal,
  neurological,
  oncologic,
  pain,
  psychiatric,
  pulmonary,
  renal,
  vascular,
} from './paths';

export const ReviewofSystemWrapper = ({ label, value }) => (
  <div
    style={{
      lineHeight: '12px',
      borderLeft: 0,
      borderRight: 0,
      borderTop: 0,
    }}
  >
    <div style={styles.systemrow2}>
      <div
        style={{
          borderRight: '1px solid black',
          paddingLeft: '5px',
          paddingTop: '3px',
          fontSize: '12px',
        }}
      >
        {label}
      </div>
      <div
        style={{
          display: 'flex',
          padding: '1px',
        }}
      >
        <MedicalPassportTextArea2
          value={value}
          style={{ width: '100%', minHeight: '33px' }}
          fontSize={'11px'}
          nobackground
          placeholder={'No'}
        />
      </div>
    </div>
  </div>
);

const Page4 = ({ formName, pageNumber, showQRCode, hospitalName, value, defaultValue }) => {
  const { age, bmi } = getAgeAndBmi(
    defaultValue?.demographicInformation?.dob,
    defaultValue?.demographicInformation?.bmi
  );

  const allergy = additionalcontent(toHighlightsString, [['allergies']], 120);
  const surgery = additionalcontent(toHighlightsString, [['priorSurgeries']], 120);
  const anesthesia = additionalcontent(toHighlightsString4, [['anesthesia complications']], 120);
  const medication = additionalcontent(toHighlightsString, [['medications']], 120);

  const cardiacList = additionalcontent(toStringWithoutQuestions3, cardiovascular, 200);
  const endocrineList = additionalcontent(toStringWithoutQuestions3, endocrine, 200);
  const GIList = additionalcontent(toStringWithoutQuestions3, gastrointestinal, 200);
  const hepaticList = additionalcontent(toStringWithoutQuestions3, hepatic, 200);
  const infectiousList = additionalcontent(toStringWithoutQuestions3, infectious, 200);
  const musculoskeletalList = additionalcontent(toStringWithoutQuestions3, musculoskeletal, 200);
  const neurologicalList = additionalcontent(toStringWithoutQuestions3, neurological, 200);
  const oncologicList = additionalcontent(toStringWithoutQuestions3, oncologic, 200);
  const painList = additionalcontent(toStringWithoutQuestions3, pain, 200);
  const psychiatricList = additionalcontent(toStringWithoutQuestions3, psychiatric, 200);
  const pulmonaryList = additionalcontent(toStringWithoutQuestions3, pulmonary, 200);
  const renalList = additionalcontent(toStringWithoutQuestions3, renal, 200);
  const vascularList = additionalcontent(toStringWithoutQuestions3, vascular, 200);

  if (
    allergy === 'No additional info' &&
    surgery === 'No additional info' &&
    anesthesia === 'No additional info' &&
    medication === 'No additional info'
  ) {
    return '';
  } else {
    return (
      <div id="page4" className="page" style={{ fontSize: '11px', fontFamily: 'Arial' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '1px' }}>
            <h2>ANESTHESIA ASSESSMENT</h2>
            <div>{hospitalName || ''}</div>
          </div>
          <div>
            {showQRCode && (
              <Barcode
                formName={formName}
                pageNumber={pageNumber}
                providerId={defaultValue?.procedure?.patient?.providerId}
                serviceTime={defaultValue?.procedure?.serviceTime}
              />
            )}
          </div>
        </div>
        <div style={{ border: '1px solid black', borderBottom: 0, borderLeft: 0 }}>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '55% 45%',
              borderBottom: '1px solid black',
            }}
          >
            <div>
              <div
                style={{
                  border: '1px solid black',
                  borderTop: 0,
                  minHeight: '60px',
                  height: '100%',
                  padding: '1px 2px 0 0',
                  borderBottom: '0px',
                }}
              >
                <div
                  style={{
                    paddingBottom: '35px',
                  }}
                >
                  <MedicalPassportTextArea name="procedure.name" label="Procedure" fontSize={14} />
                </div>
                <div
                  style={{
                    border: '1px solid black',
                    padding: '0.2rem 0.1rem 0 0',
                    borderBottom: 0,
                    borderRight: 0,
                    borderLeft: 0,
                  }}
                >
                  <MedicalPassportField
                    name="page4.activityLevel"
                    label="Activity Level"
                    path={['basicscreening', 'level of physical activity']}
                    reducer={toHighlightsString}
                    fontSize={12}
                  />
                </div>
              </div>
            </div>
            <div>
              <div style={{ bottom: '5px', paddingLeft: '3px' }}>
                <div style={{ marginBottom: '2px' }}>
                  <MedicalPassportField
                    name="procedure.patient.name"
                    label="Patient"
                    fontWeight={'bold'}
                    fontSize={14}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '2px' }}>
                  <MedicalPassportField name="dob" label="DOB" path={['demographicInformation', 'dob']} fontSize={11} />
                  <MedicalPassportField name="procedure.serviceTime" label="DOS" fontWeight={700} fontSize={11} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '2px' }}>
                  <MedicalPassportField name="procedure.patient.providerId" label="MRN#" fontSize={11} />
                  <MedicalPassportField name="procedure.physician.name" label="Surgeon" fontSize={11} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '2px', paddingBottom: '1px' }}>
                  <MedicalPassportField
                    name="age"
                    label="Age"
                    path={['demographicInformation', 'dob']}
                    reducer={toAge}
                    warning={age > 70}
                    fontSize={11}
                  />
                  <MedicalPassportField
                    name="sex"
                    label="Sex"
                    path={['demographicInformation', 'sex']}
                    reducer={toSex}
                    fontSize={11}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '2px', paddingBottom: '1px' }}>
                  <MedicalPassportField
                    name="cardiologist"
                    label="Cardiologist"
                    path={['demographicInformation', 'cardiologist']}
                    fontSize={11}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '2px', paddingBottom: '1px' }}>
                  <MedicalPassportField
                    name="procedure.pcp"
                    path={['demographicInformation', 'primaryCareDoctor']}
                    label="PCP"
                    fontSize={11}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              borderLeft: '1px solid black',
              borderBottom: '1px solid black',
            }}
          >
            <MedicalPassportTextArea2
              label="Med Allergies/Sensitivities"
              name="allergies1"
              value={allergy}
              warning={true}
              fontSize={10}
            />
          </div>

          <div style={styles.medicationsInnerDiv}>
            <MedicalPassportTextArea2
              label="Current Medications"
              name="medications1"
              value={medication}
              fontSize={10}
            />
          </div>
          <div style={{ ...styles.medicationsInnerDiv }}>
            <MedicalPassportTextArea2
              label="Surgical/Anesthesia Hx"
              name="anesthesia1"
              f
              value={surgery}
              fontSize={10}
            />
          </div>
        </div>
        <div style={{ borderLeft: '1px solid black', borderRight: '1px solid black', fontSize: '12px' }}>
          <MedicalPassportTextArea2
            label={'Hx of Anesthesia Complications'}
            name="page4.hxAnesthesiaComplications"
            value={anesthesia}
            fontSize={10}
          />
        </div>
        <section style={{ breakInside: 'avoid' }}>
          <div style={styles.greyBackground}>
            <div>Review of Systems</div>
          </div>
          <ReviewofSystemWrapper label="Cardiovascular" value={cardiacList} />
          <ReviewofSystemWrapper label="Pulmonary" value={pulmonaryList} />
          <ReviewofSystemWrapper label="Genitourinary" value={renalList} />
          <ReviewofSystemWrapper label="Neurological" value={neurologicalList} />
          <ReviewofSystemWrapper label="Vascular" value={vascularList} />
          <div style={{ borderBottom: '1px solid black' }}>
            <ReviewofSystemWrapper label="Infectious Disease" value={infectiousList} />
          </div>
        </section>
      </div>
    );
  }
};

export default Page4;
