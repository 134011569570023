import { Box, Paper, Typography } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';

interface AnalyticsPanelProps {
  title?: string;
  action?: ReactNode;
  children: ReactNode;
}

const AnalyticsPanel = ({ title, action, children }: AnalyticsPanelProps) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Box className={classes.container}>
        <Box className={classes.titleContainer}>
          {title && (
            <Typography variant="subtitle2" gutterBottom className={classes.leftTitle}>
              {title}
            </Typography>
          )}
          {action && <Box>{action}</Box>}
        </Box>
        {children}
      </Box>
    </Paper>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flex: 1,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: theme.spacing(1.5),
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  leftTitle: {
    flex: 1,
    textAlign: 'left',
  },
  centerTitle: {
    flex: 1,
    textAlign: 'center',
  },
  rightTitle: {
    flex: 1,
    textAlign: 'right',
  },
}));

export default AnalyticsPanel;
