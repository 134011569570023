import { PatientStatus } from '../../../../../types/Patient';
import { ProcedureStep, ProcedureStepType } from '../../../../../types/ProcedureStep';
import { STATUSES as ROOM_STATUSES } from '../../../../entities/room/enums';
import { setHours, setMinutes } from 'date-fns';

export const OrTabletPatientIgnoreStatuses = [
  PatientStatus.Admitted,
  PatientStatus.Discharged,
  PatientStatus.Canceled,
  PatientStatus.Done,
  ROOM_STATUSES.VACANT,
  ROOM_STATUSES.CLEANING,
];

export const toPatientStatus = (procedureStepType?: ProcedureStepType | null): PatientStatus | null => {
  switch (procedureStepType) {
    case ProcedureStepType.OrIn:
      return PatientStatus.InOr;
    case ProcedureStepType.AnestheticStart:
      return PatientStatus.AnestheticStart;
    case ProcedureStepType.TimeOut:
      return PatientStatus.TimeOut;
    case ProcedureStepType.ReadyForSurgery:
      return PatientStatus.ReadyForSurgery;
    case ProcedureStepType.PhysicianIn:
      return PatientStatus.PhysicianIn;
    case ProcedureStepType.OperationStart:
      return PatientStatus.Ongoing;
    case ProcedureStepType.CallNextPatient:
      return PatientStatus.CallNextPatient;
    case ProcedureStepType.Closing:
      return PatientStatus.Closing;
    case ProcedureStepType.SurgeonLeftOR:
      return PatientStatus.SurgeonLeftOR;
    case ProcedureStepType.DressingOn:
      return PatientStatus.DressingOn;
    case ProcedureStepType.OperationEnd:
      return PatientStatus.ProcedureEnd;
    case ProcedureStepType.AnestheticEnd:
      return PatientStatus.AnestheticEnd;
    default:
      return null;
  }
};

export const toProcedureStepType = (patientStatus?: PatientStatus | null): ProcedureStepType | null => {
  switch (patientStatus) {
    case PatientStatus.InOr:
      return ProcedureStepType.OrIn;
    case PatientStatus.AnestheticStart:
      return ProcedureStepType.AnestheticStart;
    case PatientStatus.ReadyForSurgery:
      return ProcedureStepType.ReadyForSurgery;
    case PatientStatus.Ready:
      return ProcedureStepType.OrIn;
    case PatientStatus.TimeOut:
      return ProcedureStepType.TimeOut;
    case PatientStatus.TimeOut2:
      return ProcedureStepType.TimeOut;
    case PatientStatus.PhysicianIn:
      return ProcedureStepType.PhysicianIn;
    case PatientStatus.Ongoing:
      return ProcedureStepType.OperationStart;
    case PatientStatus.CallNextPatient:
      return ProcedureStepType.CallNextPatient;
    case PatientStatus.Closing:
      return ProcedureStepType.Closing;
    case PatientStatus.SurgeonLeftOR:
      return ProcedureStepType.SurgeonLeftOR;
    case PatientStatus.DressingOn:
      return ProcedureStepType.DressingOn;
    case PatientStatus.ProcedureEnd:
      return ProcedureStepType.OperationEnd;
    case PatientStatus.AnestheticEnd:
      return ProcedureStepType.AnestheticEnd;
    default:
      return null;
  }
};

export interface ProcedureState {
  steps: (ProcedureStep & { highlight: boolean; show: boolean })[] | null;
  index: number | null;
  prev: (() => Promise<void>) | null;
  next: (() => Promise<void>) | null;
  nextLabel: string | null;
}

export const getProcedureState = (
  procedureSteps: ProcedureStep[],
  patientStatus: PatientStatus
): {
  index: number;
  prev: ProcedureStep | null;
  current: ProcedureStep | null;
  next: ProcedureStep | null;
} | null => {
  const currentIndex = procedureSteps.findIndex(e => e.type === toProcedureStepType(patientStatus));

  if (currentIndex < 0) {
    return null;
  }

  const prevIndex = currentIndex - 1;
  const nextIndex = currentIndex + 1;
  return {
    index: currentIndex,
    prev: prevIndex >= 0 ? procedureSteps[prevIndex] : null,
    current: procedureSteps[currentIndex],
    next: nextIndex < procedureSteps.length ? procedureSteps[nextIndex] : null,
  };
};

/**
 * set hours and minutes to date obeject
 * @param date Date
 * @param time string format hh:mm
 */
export const setTimeToDate = (date = new Date(), time?: string) => {
  if (time) {
    const [hour, min] = time.split(':').map(x => parseInt(x));
    if (isFinite(hour) && isFinite(min)) {
      return setHours(setMinutes(date, min), hour);
    } else {
      return date;
    }
  }

  return date;
};
