import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles';
import { gql, useMutation, useSubscription } from '@apollo/client';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import humanFileSize from '../../../util/humanFileSize';
import { format } from 'date-fns';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Paper } from '@material-ui/core';
import { Card } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Spinner from '../../../se/components/Spinner';
import { RouteComponentProps } from 'react-router';

interface CurrentTrackingSessionsProps {
  history: RouteComponentProps['history'];
  baseUrl: string;
}

const CurrentTrackingSession = ({ history, baseUrl }: CurrentTrackingSessionsProps) => {
  const classes = useStyles();

  const { loading, data } = useSubscription<{
    currentTrackingSession: { id: string; startedBy: number; startedAt: string; bytesLogged: number };
  }>(gql`
    subscription currentTrackingSession {
      currentTrackingSession {
        id
        startedBy
        startedAt
        bytesLogged
      }
    }
  `);

  const [enableTracking, trackingEnabling] = useMutation(gql`
    mutation startTrackingSession {
      startTrackingSession {
        id
        startedBy
        startedAt
      }
    }
  `);

  const [disableTracking, trackingDisabling] = useMutation(
    gql`
      mutation stopTrackingSession($trackingSessionId: String!) {
        stopTrackingSession(trackingSessionId: $trackingSessionId) {
          id
          startedBy
          startedAt
        }
      }
    `,
    {
      variables: { trackingSessionId: data?.currentTrackingSession?.id },
    }
  );

  const session = data?.currentTrackingSession;

  const startTracking = async () => {
    const result = await enableTracking();

    const id = result.data?.startTrackingSession?.id;

    if (id) {
      history.push(baseUrl + '/' + id);
    }
  };

  return (
    <Paper className={classes.root}>
      <Card className={classes.root}>
        <CardContent>
          <Typography color="textSecondary" gutterBottom>
            Current Tracking Insights Session
          </Typography>
          {loading ? (
            <Spinner />
          ) : session ? (
            <>
              <Typography variant="h5" component="h2" gutterBottom className={classes.title}>
                <span>
                  Tracking Insights session is{' '}
                  <strong className={clsx(classes.statusLabel, classes.statusLabelEnabled)}>running</strong>
                </span>
                <Button onClick={() => disableTracking()} disabled={trackingDisabling.loading}>
                  Stop
                </Button>
              </Typography>
              <div key={session.id} className={classes.session}>
                <div className={classes.sessionStartedAt}>{format(session.startedAt, 'MMMM D, YYYY; HH:mm')}</div>
                <div className={classes.sessionBytesLogged}>{humanFileSize(session.bytesLogged, true)} logged</div>
                <Link component={RouterLink} to={baseUrl + '/' + session.id}>
                  View Live Insights →
                </Link>
              </div>
            </>
          ) : (
            <Typography variant="h5" component="h2" className={classes.title}>
              <span>
                Tracking Insights are{' '}
                <strong className={clsx(classes.statusLabel, classes.statusLabelDisabled)}>stopped</strong>
              </span>
              <Button onClick={startTracking} disabled={trackingEnabling.loading}>
                Start
              </Button>
            </Typography>
          )}
        </CardContent>
      </Card>
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  title: {
    display: 'flex',
    alignItems: 'baseline',
    gap: theme.spacing(2),
  },
  status: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'start',
    gap: theme.spacing(1),
    fontSize: theme.typography.subtitle1.fontSize,
  },
  statusLabel: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: 'white',
  },
  statusLabelEnabled: {
    color: theme.palette.warning.light,
  },
  statusLabelDisabled: {},
  session: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  sessionStartedAt: {
    fontWeight: 'bold',
  },
  sessionBytesLogged: {},
}));

export default CurrentTrackingSession;
