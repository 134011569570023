import React, { ReactNode, useMemo } from 'react';
import SelectInput from '../../../../se/components/inputs/SelectInput';
import { STATUSES, STATUS_LABELS } from '../../../../components/entities/patient/enums';
import { useQuery } from '@apollo/client';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import { listXS as rooms } from '../../../../graph/rooms';
import { orTabletProcedureSteps } from '../../../../graph/procedureSteps';
import { toPatientStatus } from '../../../pages/kiosk/tablet/util/procedureSteps';

export const getEventText = (event, procedureStep, extras) => {
  switch (event.__type) {
    case 'Created$':
      return 'Admitted';
    case 'Discharged$':
      return `Discharged`;
    case 'BlockNerve$':
      return `Block Nerve`;
    case 'CleaningCompleted$':
      return `OR Room Ready`;
    case 'Entered':
      return `Entered ${extras.roomName}`;
    case 'Exited':
      return `Exited ${extras.roomName}`;
    case 'ProcedureStatusSet': {
      return extras.status;
    }
    default:
      return event.__type;
  }
};

const Label = styled.div`
  margin-bottom: 0.75rem;
  font-size: 1rem;
`;

interface PatientEventInputProps {
  patientId: unknown;
  value: any;
  onChange: (value: any) => void;
  label?: string;
  placeholder?: string;
  required?: boolean;
}

export const PatientEventInput = ({
  patientId,
  value,
  onChange,
  label = 'Patient Event',
  placeholder,
  required,
}: PatientEventInputProps) => {
  const roomsQuery = useQuery(rooms);
  const orTabletProcedureStepsQuery = useQuery(orTabletProcedureSteps, { variables: { patientId } });

  const loading = roomsQuery.loading || orTabletProcedureStepsQuery.loading;
  const error = roomsQuery.error || orTabletProcedureStepsQuery.error;

  const options = useMemo(() => {
    if (!roomsQuery.data || !orTabletProcedureStepsQuery.data) {
      return [];
    }

    const event = (type: string, extras: Record<string, any> = {}) => ({
      __type: type,
      ...extras,
    });

    const roomEvent = (type: string, roomId: unknown) =>
      event(type, { roomId, manualEntryBy: null, manualTimestamp: null });

    const statusEvent = (type: string, status: string) =>
      event(type, { status: { [status]: {} }, manualEntryBy: null, manualTimestamp: null });

    const optionForEvent = (event, extras = {}) => ({
      [JSON.stringify(event)]: label(event, extras),
    });

    const label = (event, extras = {}) =>
      getEventText(
        event,
        orTabletProcedureStepsQuery.data.orTabletProcedureSteps.find(
          e => toPatientStatus(e.type) === Object.keys(event.status ?? {})[0]
        ),
        extras
      );

    return {
      ...optionForEvent(event('Created$')),
      ...optionForEvent(event('Discharged$')),
      ...optionForEvent(event('BlockNerve$')),
      ...optionForEvent(event('CleaningCompleted$')),
      ...roomsQuery.data.rooms.reduce(
        (acc, room) => ({ ...acc, ...optionForEvent(roomEvent('Entered', room.id), { roomName: room.name }) }),
        {}
      ),
      ...roomsQuery.data.rooms.reduce(
        (acc, room) => ({ ...acc, ...optionForEvent(roomEvent('Exited', room.id), { roomName: room.name }) }),
        {}
      ),
      ...Object.values(STATUSES).reduce<{}>(
        (acc, status) => ({
          ...acc,
          ...optionForEvent(statusEvent('ProcedureStatusSet', status as string), { status: STATUS_LABELS[status] }),
        }),
        {}
      ),
    };
  }, [roomsQuery.data, orTabletProcedureStepsQuery.data]);

  const procedureSteps = orTabletProcedureStepsQuery.data.orTabletProcedureSteps;

  return (
    <Box>
      <Label>{label}</Label>
      <SelectInput
        options={options}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        isLoading={loading}
        error={error}
      />
    </Box>
  );
};

export default PatientEventInput;
