import { gql } from '@apollo/client';

export const fragments = {
  all: gql`
    fragment StatisticsData on Statistics {
      id
      title
      unit
      value
      distribution {
        name
        value
        date
      }
    }
  `,
  groupStatistics: gql`
    fragment GroupStatisticsData on GroupStatistics {
      id
      title
      unit
      value
      data {
        name
        value
      }
    }
  `,
};

export const dashboardQuery = gql`
  query dashboard(
    $filter: Filter
    $wrMinThreshold: Long
    $wrMaxThreshold: Long
    $preOpMinThreshold: Long
    $preOpMaxThreshold: Long
    $inOrMinThreshold: Long
    $inOrMaxThreshold: Long
    $cleaningMinThreshold: Long
    $cleaningMaxThreshold: Long
    $closingMinThreshold: Long
    $closingMaxThreshold: Long
    $popiMinThreshold: Long
    $popiMaxThreshold: Long
    $pacuMinThreshold: Long
    $pacuMaxThreshold: Long
    $postOpMinThreshold: Long
    $postOpMaxThreshold: Long
    $samePhysicianORTurnover: Boolean
  ) {
    dashboard(
      filter: $filter
      wrMinThreshold: $wrMinThreshold
      wrMaxThreshold: $wrMaxThreshold
      preOpMinThreshold: $preOpMinThreshold
      preOpMaxThreshold: $preOpMaxThreshold
      inOrMinThreshold: $inOrMinThreshold
      inOrMaxThreshold: $inOrMaxThreshold
      cleaningMinThreshold: $cleaningMinThreshold
      cleaningMaxThreshold: $cleaningMaxThreshold
      closingMinThreshold: $closingMinThreshold
      closingMaxThreshold: $closingMaxThreshold
      popiMinThreshold: $popiMinThreshold
      popiMaxThreshold: $popiMaxThreshold
      pacuMinThreshold: $pacuMinThreshold
      pacuMaxThreshold: $pacuMaxThreshold
      postOpMinThreshold: $postOpMinThreshold
      postOpMaxThreshold: $postOpMaxThreshold
      samePhysicianORTurnover: $samePhysicianORTurnover
    ) {
      ...StatisticsData
    }
  }
  ${fragments.all}
`;

export const totalCancellationsQuery = gql`
  query totalCancellations($filter: Filter) {
    totalCancellations(filter: $filter) {
      ...GroupStatisticsData
    }
  }
  ${fragments.groupStatistics}
`;

export const patientJourneyQuery = gql`
  query patientJourney(
    $filter: Filter
    $wrMinThreshold: Long
    $wrMaxThreshold: Long
    $preOpMinThreshold: Long
    $preOpMaxThreshold: Long
    $inOrMinThreshold: Long
    $inOrMaxThreshold: Long
    $pacuMinThreshold: Long
    $pacuMaxThreshold: Long
    $postOpMinThreshold: Long
    $postOpMaxThreshold: Long
  ) {
    patientJourney(
      filter: $filter
      wrMinThreshold: $wrMinThreshold
      wrMaxThreshold: $wrMaxThreshold
      preOpMinThreshold: $preOpMinThreshold
      preOpMaxThreshold: $preOpMaxThreshold
      inOrMinThreshold: $inOrMinThreshold
      inOrMaxThreshold: $inOrMaxThreshold
      pacuMinThreshold: $pacuMinThreshold
      pacuMaxThreshold: $pacuMaxThreshold
      postOpMinThreshold: $postOpMinThreshold
      postOpMaxThreshold: $postOpMaxThreshold
    ) {
      ...GroupStatisticsData
    }
  }
  ${fragments.groupStatistics}
`;

export const dashboardQueryPrev = gql`
  query dashboardPrev(
    $filter: Filter
    $wrMinThreshold: Long
    $wrMaxThreshold: Long
    $preOpMinThreshold: Long
    $preOpMaxThreshold: Long
    $inOrMinThreshold: Long
    $inOrMaxThreshold: Long
    $cleaningMinThreshold: Long
    $cleaningMaxThreshold: Long
    $closingMinThreshold: Long
    $closingMaxThreshold: Long
    $popiMinThreshold: Long
    $popiMaxThreshold: Long
    $pacuMinThreshold: Long
    $pacuMaxThreshold: Long
    $postOpMinThreshold: Long
    $postOpMaxThreshold: Long
    $samePhysicianORTurnover: Boolean
  ) {
    dashboardPrev(
      filter: $filter
      wrMinThreshold: $wrMinThreshold
      wrMaxThreshold: $wrMaxThreshold
      preOpMinThreshold: $preOpMinThreshold
      preOpMaxThreshold: $preOpMaxThreshold
      inOrMinThreshold: $inOrMinThreshold
      inOrMaxThreshold: $inOrMaxThreshold
      cleaningMinThreshold: $cleaningMinThreshold
      cleaningMaxThreshold: $cleaningMaxThreshold
      closingMinThreshold: $closingMinThreshold
      closingMaxThreshold: $closingMaxThreshold
      popiMinThreshold: $popiMinThreshold
      popiMaxThreshold: $popiMaxThreshold
      pacuMinThreshold: $pacuMinThreshold
      pacuMaxThreshold: $pacuMaxThreshold
      postOpMinThreshold: $postOpMinThreshold
      postOpMaxThreshold: $postOpMaxThreshold
      samePhysicianORTurnover: $samePhysicianORTurnover
    ) {
      ...StatisticsData
    }
  }
  ${fragments.all}
`;

export const analyticsConfigQuery = gql`
  query analyticsConfiguration {
    analyticsConfiguration {
      monthlyReportRecipients
      weeklyReportRecipients
      totalPatientsThreshold
      waitingRoomTimeThreshold
      preopTimeThreshold
      readyForAnesthesiaThreshold
      readyForORThreshold
      orUtilThreshold
      orTimeThreshold
      pacuTimeThreshold
      cleaningTimeThreshold
      closingTimeThreshold
      popiTimeThreshold
      postOpTimeThreshold
      pipoMinThreshold
      pipoMaxThreshold
      popiMinThreshold
      popiMaxThreshold
      wrMinThreshold
      wrMaxThreshold
      preOpMinThreshold
      preOpMaxThreshold
      cleaningMinThreshold
      cleaningMaxThreshold
      pacuMinThreshold
      pacuMaxThreshold
      postOpMinThreshold
      postOpMaxThreshold
      preOpToDischargedMinThreshold
      preOpToDischargedMaxThreshold
      inOrMinThreshold
      inOrMaxThreshold
      inSurgeryMinThreshold
      inSurgeryMaxThreshold
      closingMinThreshold
      closingMaxThreshold
      procedureCompleteMinThreshold
      procedureCompleteMaxThreshold
      orReadyMinThreshold
      orReadyMaxThreshold
      piToStartMinThreshold
      piToStartMaxThreshold
      poToCutMinThreshold
      poToCutMaxThreshold
      startToCloseMinThreshold
      startToCloseMaxThreshold
      physicianPopiMinThreshold
      physicianPopiMaxThreshold
      fcotsGracePeriod
      piToPhyiMinThreshold
      piToPhyiMaxThreshold
      closingCompleteToPatientOutMinThreshold
      closingCompleteToPatientOutMaxThreshold
      samePhysicianORTurnover
    }
  }
`;

export const analyticsReportQuery = gql`
  query detailedReport(
    $pdf: AnalyticsReport!
    $filter: Filter
    $pipoMinThreshold: Long
    $pipoMaxThreshold: Long
    $popiMinThreshold: Long
    $popiMaxThreshold: Long
    $wrMinThreshold: Long
    $wrMaxThreshold: Long
    $preOpMinThreshold: Long
    $preOpMaxThreshold: Long
    $cleaningMinThreshold: Long
    $cleaningMaxThreshold: Long
    $pacuMinThreshold: Long
    $pacuMaxThreshold: Long
    $postOpMinThreshold: Long
    $postOpMaxThreshold: Long
    $preOpToDischargedMinThreshold: Long
    $preOpToDischargedMaxThreshold: Long
    $inOrMinThreshold: Long
    $inOrMaxThreshold: Long
    $inSurgeryMinThreshold: Long
    $inSurgeryMaxThreshold: Long
    $closingMinThreshold: Long
    $closingMaxThreshold: Long
    $procedureCompleteMinThreshold: Long
    $procedureCompleteMaxThreshold: Long
    $orReadyMinThreshold: Long
    $orReadyMaxThreshold: Long
    $piToStartMinThreshold: Long
    $piToStartMaxThreshold: Long
    $poToCutMinThreshold: Long
    $poToCutMaxThreshold: Long
    $startToCloseMinThreshold: Long
    $startToCloseMaxThreshold: Long
    $physicianPopiMinThreshold: Long
    $physicianPopiMaxThreshold: Long
    $fcotsGracePeriod: Int
    $piToPhyiMinThreshold: Long
    $piToPhyiMaxThreshold: Long
    $closingCompleteToPatientOutMinThreshold: Long
    $closingCompleteToPatientOutMaxThreshold: Long
    $samePhysicianORTurnover: Boolean
  ) {
    detailedReport(
      pdf: $pdf
      filter: $filter
      pipoMinThreshold: $pipoMinThreshold
      pipoMaxThreshold: $pipoMaxThreshold
      popiMinThreshold: $popiMinThreshold
      popiMaxThreshold: $popiMaxThreshold
      wrMinThreshold: $wrMinThreshold
      wrMaxThreshold: $wrMaxThreshold
      preOpMinThreshold: $preOpMinThreshold
      preOpMaxThreshold: $preOpMaxThreshold
      cleaningMinThreshold: $cleaningMinThreshold
      cleaningMaxThreshold: $cleaningMaxThreshold
      pacuMinThreshold: $pacuMinThreshold
      pacuMaxThreshold: $pacuMaxThreshold
      postOpMinThreshold: $postOpMinThreshold
      postOpMaxThreshold: $postOpMaxThreshold
      preOpToDischargedMinThreshold: $preOpToDischargedMinThreshold
      preOpToDischargedMaxThreshold: $preOpToDischargedMaxThreshold
      inOrMinThreshold: $inOrMinThreshold
      inOrMaxThreshold: $inOrMaxThreshold
      inSurgeryMinThreshold: $inSurgeryMinThreshold
      inSurgeryMaxThreshold: $inSurgeryMaxThreshold
      closingMinThreshold: $closingMinThreshold
      closingMaxThreshold: $closingMaxThreshold
      procedureCompleteMinThreshold: $procedureCompleteMinThreshold
      procedureCompleteMaxThreshold: $procedureCompleteMaxThreshold
      orReadyMinThreshold: $orReadyMinThreshold
      orReadyMaxThreshold: $orReadyMaxThreshold
      piToStartMinThreshold: $piToStartMinThreshold
      piToStartMaxThreshold: $piToStartMaxThreshold
      poToCutMinThreshold: $poToCutMinThreshold
      poToCutMaxThreshold: $poToCutMaxThreshold
      startToCloseMinThreshold: $startToCloseMinThreshold
      startToCloseMaxThreshold: $startToCloseMaxThreshold
      physicianPopiMinThreshold: $physicianPopiMinThreshold
      physicianPopiMaxThreshold: $physicianPopiMaxThreshold
      fcotsGracePeriod: $fcotsGracePeriod
      piToPhyiMinThreshold: $piToPhyiMinThreshold
      piToPhyiMaxThreshold: $piToPhyiMaxThreshold
      closingCompleteToPatientOutMinThreshold: $closingCompleteToPatientOutMinThreshold
      closingCompleteToPatientOutMaxThreshold: $closingCompleteToPatientOutMaxThreshold
      samePhysicianORTurnover: $samePhysicianORTurnover
    )
  }
`;

export const staffingCostQuery = gql`
  query staffingCost($dateRange: DateRange!) {
    staffingCost(dateRange: $dateRange) {
      id
      staffCostSummary {
        id
        staffCostDateRange {
          id
          staffMemberName
          staffMemberTitle
          employmentType
          minutes
          hourlyRate
          cost
        }
        staffCostPerDate {
          id
          date
          staffCost {
            id
            staffMemberName
            staffMemberTitle
            employmentType
            in
            out
            minutes
            hourlyRate
            cost
          }
          totalMinutes
          totalCost
        }
        totalMinutes
        totalCost
      }
      procedureStaffCostSummary {
        id
        procedureStaffCostPerDate {
          id
          date
          totalMinutes
          actualTotalMinutes
          totalCost
          actualTotalCost
          staffCost {
            id
            physicianName
            procedure
            wheelsIn
            wheelsOut
            durationInMinutes
            actualWheelsIn
            actualWheelsOut
            actualDurationInMinutes
            staffingUtilization
            actualStaffingUtilization
            cost
            actualCost
          }
        }
      }
    }
  }
`;

export const ORUtilizationAnalyticsQuery = gql`
  query ORUtilizationAnalytics(
    $filter: Filter!
    $pipoMinThreshold: Long
    $pipoMaxThreshold: Long
    $popiMinThreshold: Long
    $popiMaxThreshold: Long
    $wrMinThreshold: Long
    $wrMaxThreshold: Long
    $preOpMinThreshold: Long
    $preOpMaxThreshold: Long
    $cleaningMinThreshold: Long
    $cleaningMaxThreshold: Long
    $pacuMinThreshold: Long
    $pacuMaxThreshold: Long
    $postOpMinThreshold: Long
    $postOpMaxThreshold: Long
    $preOpToDischargedMinThreshold: Long
    $preOpToDischargedMaxThreshold: Long
    $inOrMinThreshold: Long
    $inOrMaxThreshold: Long
    $inSurgeryMinThreshold: Long
    $inSurgeryMaxThreshold: Long
    $closingMinThreshold: Long
    $closingMaxThreshold: Long
    $procedureCompleteMinThreshold: Long
    $procedureCompleteMaxThreshold: Long
    $orReadyMinThreshold: Long
    $orReadyMaxThreshold: Long
    $samePhysicianORTurnover: Boolean
  ) {
    ORUtilizationAnalytics(
      filter: $filter
      pipoMinThreshold: $pipoMinThreshold
      pipoMaxThreshold: $pipoMaxThreshold
      popiMinThreshold: $popiMinThreshold
      popiMaxThreshold: $popiMaxThreshold
      wrMinThreshold: $wrMinThreshold
      wrMaxThreshold: $wrMaxThreshold
      preOpMinThreshold: $preOpMinThreshold
      preOpMaxThreshold: $preOpMaxThreshold
      cleaningMinThreshold: $cleaningMinThreshold
      cleaningMaxThreshold: $cleaningMaxThreshold
      pacuMinThreshold: $pacuMinThreshold
      pacuMaxThreshold: $pacuMaxThreshold
      postOpMinThreshold: $postOpMinThreshold
      postOpMaxThreshold: $postOpMaxThreshold
      preOpToDischargedMinThreshold: $preOpToDischargedMinThreshold
      preOpToDischargedMaxThreshold: $preOpToDischargedMaxThreshold
      inOrMinThreshold: $inOrMinThreshold
      inOrMaxThreshold: $inOrMaxThreshold
      inSurgeryMinThreshold: $inSurgeryMinThreshold
      inSurgeryMaxThreshold: $inSurgeryMaxThreshold
      closingMinThreshold: $closingMinThreshold
      closingMaxThreshold: $closingMaxThreshold
      procedureCompleteMinThreshold: $procedureCompleteMinThreshold
      procedureCompleteMaxThreshold: $procedureCompleteMaxThreshold
      orReadyMinThreshold: $orReadyMinThreshold
      orReadyMaxThreshold: $orReadyMaxThreshold
      samePhysicianORTurnover: $samePhysicianORTurnover
    ) {
      id
      room
      proceduresCnt
      surgeryDays
      procedurePerDay
      pipoAvg
      pipoMed
      pipoUtilization
      cleaningAvg
      cleaningMed
      popiAvg
      popiMed
      wrAvg
      wrMed
      preopAvg
      preopMed
      pacuAvg
      pacuMed
      postOpAvg
      postOpMed
      preOpToDischargedAvg
      preOpToDischargedMed
      inOrAvg
      inOrMed
      inSurgeryAvg
      inSurgeryMed
      closingAvg
      closingMed
      procedureCompleteAvg
      procedureCompleteMed
      orReadyAvg
      orReadyMed
      canceled
    }
  }
`;

export const ORUtilizationAnalyticsTotalsQuery = gql`
  query ORUtilizationAnalyticsTotals(
    $filter: Filter!
    $pipoMinThreshold: Long
    $pipoMaxThreshold: Long
    $popiMinThreshold: Long
    $popiMaxThreshold: Long
    $wrMinThreshold: Long
    $wrMaxThreshold: Long
    $preOpMinThreshold: Long
    $preOpMaxThreshold: Long
    $cleaningMinThreshold: Long
    $cleaningMaxThreshold: Long
    $pacuMinThreshold: Long
    $pacuMaxThreshold: Long
    $postOpMinThreshold: Long
    $postOpMaxThreshold: Long
    $preOpToDischargedMinThreshold: Long
    $preOpToDischargedMaxThreshold: Long
    $inOrMinThreshold: Long
    $inOrMaxThreshold: Long
    $inSurgeryMinThreshold: Long
    $inSurgeryMaxThreshold: Long
    $closingMinThreshold: Long
    $closingMaxThreshold: Long
    $procedureCompleteMinThreshold: Long
    $procedureCompleteMaxThreshold: Long
    $orReadyMinThreshold: Long
    $orReadyMaxThreshold: Long
    $samePhysicianORTurnover: Boolean
  ) {
    ORUtilizationAnalyticsTotals(
      filter: $filter
      pipoMinThreshold: $pipoMinThreshold
      pipoMaxThreshold: $pipoMaxThreshold
      popiMinThreshold: $popiMinThreshold
      popiMaxThreshold: $popiMaxThreshold
      wrMinThreshold: $wrMinThreshold
      wrMaxThreshold: $wrMaxThreshold
      preOpMinThreshold: $preOpMinThreshold
      preOpMaxThreshold: $preOpMaxThreshold
      cleaningMinThreshold: $cleaningMinThreshold
      cleaningMaxThreshold: $cleaningMaxThreshold
      pacuMinThreshold: $pacuMinThreshold
      pacuMaxThreshold: $pacuMaxThreshold
      postOpMinThreshold: $postOpMinThreshold
      postOpMaxThreshold: $postOpMaxThreshold
      preOpToDischargedMinThreshold: $preOpToDischargedMinThreshold
      preOpToDischargedMaxThreshold: $preOpToDischargedMaxThreshold
      inOrMinThreshold: $inOrMinThreshold
      inOrMaxThreshold: $inOrMaxThreshold
      inSurgeryMinThreshold: $inSurgeryMinThreshold
      inSurgeryMaxThreshold: $inSurgeryMaxThreshold
      closingMinThreshold: $closingMinThreshold
      closingMaxThreshold: $closingMaxThreshold
      procedureCompleteMinThreshold: $procedureCompleteMinThreshold
      procedureCompleteMaxThreshold: $procedureCompleteMaxThreshold
      orReadyMinThreshold: $orReadyMinThreshold
      orReadyMaxThreshold: $orReadyMaxThreshold
      samePhysicianORTurnover: $samePhysicianORTurnover
    ) {
      id
      room
      proceduresCnt
      surgeryDays
      procedurePerDay
      pipoAvg
      pipoMed
      pipoUtilization
      cleaningAvg
      cleaningMed
      popiAvg
      popiMed
      wrAvg
      wrMed
      preopAvg
      preopMed
      pacuAvg
      pacuMed
      postOpAvg
      postOpMed
      preOpToDischargedAvg
      preOpToDischargedMed
      inOrAvg
      inOrMed
      inSurgeryAvg
      inSurgeryMed
      closingAvg
      closingMed
      procedureCompleteAvg
      procedureCompleteMed
      orReadyAvg
      orReadyMed
    }
  }
`;

export const ORPatientsUtilizationAnalyticsQuery = gql`
  query ORPatientsUtilizationAnalytics($orId: Long!, $filter: Filter!) {
    ORPatientsUtilizationAnalytics(orId: $orId, filter: $filter) {
      id
      patient
      patientProviderId
      surgeryTime
      procedureType
      admittedAt
      preOpIn
      preOpOut
      orIn
      orOut
      recoveryIn
      recoveryOut
      postOpIn
      postOpOut
      dischargedAt
      preOpInMinutes
      pipoInMinutes
      recoveryInMinutes
      postOpInMinutes
      preOpToExitInMinutes
    }
  }
`;

export const physicianUtilizationAnalyticsQuery = gql`
  query physicianUtilizationAnalytics(
    $filter: Filter!
    $pipoMinThreshold: Long
    $pipoMaxThreshold: Long
    $wrMinThreshold: Long
    $wrMaxThreshold: Long
    $preOpMinThreshold: Long
    $preOpMaxThreshold: Long
    $pacuMinThreshold: Long
    $pacuMaxThreshold: Long
    $postOpMinThreshold: Long
    $postOpMaxThreshold: Long
    $preOpToDischargedMinThreshold: Long
    $preOpToDischargedMaxThreshold: Long
    $inOrMinThreshold: Long
    $inOrMaxThreshold: Long
    $closingMinThreshold: Long
    $closingMaxThreshold: Long
    $procedureCompleteMinThreshold: Long
    $procedureCompleteMaxThreshold: Long
    $orReadyMinThreshold: Long
    $orReadyMaxThreshold: Long
    $piToStartMinThreshold: Long
    $piToStartMaxThreshold: Long
    $poToCutMinThreshold: Long
    $poToCutMaxThreshold: Long
    $startToCloseMinThreshold: Long
    $startToCloseMaxThreshold: Long
    $physicianPopiMinThreshold: Long
    $physicianPopiMaxThreshold: Long
    $fcotsGracePeriod: Int
    $piToPhyiMinThreshold: Long
    $piToPhyiMaxThreshold: Long
    $closingCompleteToPatientOutMinThreshold: Long
    $closingCompleteToPatientOutMaxThreshold: Long
  ) {
    physicianUtilizationAnalytics(
      filter: $filter
      pipoMinThreshold: $pipoMinThreshold
      pipoMaxThreshold: $pipoMaxThreshold
      wrMinThreshold: $wrMinThreshold
      wrMaxThreshold: $wrMaxThreshold
      preOpMinThreshold: $preOpMinThreshold
      preOpMaxThreshold: $preOpMaxThreshold
      pacuMinThreshold: $pacuMinThreshold
      pacuMaxThreshold: $pacuMaxThreshold
      postOpMinThreshold: $postOpMinThreshold
      postOpMaxThreshold: $postOpMaxThreshold
      preOpToDischargedMinThreshold: $preOpToDischargedMinThreshold
      preOpToDischargedMaxThreshold: $preOpToDischargedMaxThreshold
      inOrMinThreshold: $inOrMinThreshold
      inOrMaxThreshold: $inOrMaxThreshold
      closingMinThreshold: $closingMinThreshold
      closingMaxThreshold: $closingMaxThreshold
      procedureCompleteMinThreshold: $procedureCompleteMinThreshold
      procedureCompleteMaxThreshold: $procedureCompleteMaxThreshold
      orReadyMinThreshold: $orReadyMinThreshold
      orReadyMaxThreshold: $orReadyMaxThreshold
      piToStartMinThreshold: $piToStartMinThreshold
      piToStartMaxThreshold: $piToStartMaxThreshold
      poToCutMinThreshold: $poToCutMinThreshold
      poToCutMaxThreshold: $poToCutMaxThreshold
      startToCloseMinThreshold: $startToCloseMinThreshold
      startToCloseMaxThreshold: $startToCloseMaxThreshold
      physicianPopiMinThreshold: $physicianPopiMinThreshold
      physicianPopiMaxThreshold: $physicianPopiMaxThreshold
      fcotsGracePeriod: $fcotsGracePeriod
      piToPhyiMinThreshold: $piToPhyiMinThreshold
      piToPhyiMaxThreshold: $piToPhyiMaxThreshold
      closingCompleteToPatientOutMinThreshold: $closingCompleteToPatientOutMinThreshold
      closingCompleteToPatientOutMaxThreshold: $closingCompleteToPatientOutMaxThreshold
    ) {
      id
      physician
      surgeryDays
      numberOfProcedures
      firstPIToLastPOTotal
      pipoTotal
      pipoOverE2EPercentage
      piToStartAvg
      piToStartMed
      startToCloseAvg
      startToCloseMed
      poToCutAvg
      poToCutMed
      popiPhysicianAvg
      popiPhysicianMed
      wrAvg
      wrMed
      preOpAvg
      preOpMed
      pipoAvg
      pipoMed
      recoveryAvg
      recoveryMed
      postOpAvg
      postOpMed
      preOpToExitAvg
      preOpToExitMed
      preOpToExitTotal
      inOrAvg
      inOrMed
      inSurgeryAvg
      inSurgeryMed
      closingAvg
      closingMed
      procedureCompleteAvg
      procedureCompleteMed
      orReadyAvg
      orReadyMed
      fcotsDelayAvg
      fcotsDelayMed
      fcotsDelayDist
      fcotsDelayReasons {
        reason
        count
      }
      proceduresOnTimePercentage
      scheduleEfficiency
      scheduledProcedureDurationAvg
      scheduledProcedureDurationMed
      piToPhyiAvg
      piToPhyiMed
      closingCompleteToPatientOutAvg
      closingCompleteToPatientOutMed
      readyForAnesthesiaToReadyForORAvg
      readyForAnesthesiaToReadyForORMed
      patientReadyToOrInAvg
      patientReadyToOrInMed
      inORToReadyForSurgeryAvg
      inORToReadyForSurgeryMed
      readyForSurgeryToStartAvg
      readyForSurgeryToStartMed
      readyForSurgeryToPhyiAvg
      readyForSurgeryToPhyiMed
    }
  }
`;

export const physicianUtilizationAnalyticsTotalsQuery = gql`
  query physicianUtilizationAnalyticsTotals(
    $filter: Filter!
    $pipoMinThreshold: Long
    $pipoMaxThreshold: Long
    $wrMinThreshold: Long
    $wrMaxThreshold: Long
    $preOpMinThreshold: Long
    $preOpMaxThreshold: Long
    $pacuMinThreshold: Long
    $pacuMaxThreshold: Long
    $postOpMinThreshold: Long
    $postOpMaxThreshold: Long
    $preOpToDischargedMinThreshold: Long
    $preOpToDischargedMaxThreshold: Long
    $inOrMinThreshold: Long
    $inOrMaxThreshold: Long
    $closingMinThreshold: Long
    $closingMaxThreshold: Long
    $procedureCompleteMinThreshold: Long
    $procedureCompleteMaxThreshold: Long
    $orReadyMinThreshold: Long
    $orReadyMaxThreshold: Long
    $piToStartMinThreshold: Long
    $piToStartMaxThreshold: Long
    $poToCutMinThreshold: Long
    $poToCutMaxThreshold: Long
    $startToCloseMinThreshold: Long
    $startToCloseMaxThreshold: Long
    $physicianPopiMinThreshold: Long
    $physicianPopiMaxThreshold: Long
    $fcotsGracePeriod: Int
    $piToPhyiMinThreshold: Long
    $piToPhyiMaxThreshold: Long
    $closingCompleteToPatientOutMinThreshold: Long
    $closingCompleteToPatientOutMaxThreshold: Long
  ) {
    physicianUtilizationAnalyticsTotals(
      filter: $filter
      pipoMinThreshold: $pipoMinThreshold
      pipoMaxThreshold: $pipoMaxThreshold
      wrMinThreshold: $wrMinThreshold
      wrMaxThreshold: $wrMaxThreshold
      preOpMinThreshold: $preOpMinThreshold
      preOpMaxThreshold: $preOpMaxThreshold
      pacuMinThreshold: $pacuMinThreshold
      pacuMaxThreshold: $pacuMaxThreshold
      postOpMinThreshold: $postOpMinThreshold
      postOpMaxThreshold: $postOpMaxThreshold
      preOpToDischargedMinThreshold: $preOpToDischargedMinThreshold
      preOpToDischargedMaxThreshold: $preOpToDischargedMaxThreshold
      inOrMinThreshold: $inOrMinThreshold
      inOrMaxThreshold: $inOrMaxThreshold
      closingMinThreshold: $closingMinThreshold
      closingMaxThreshold: $closingMaxThreshold
      procedureCompleteMinThreshold: $procedureCompleteMinThreshold
      procedureCompleteMaxThreshold: $procedureCompleteMaxThreshold
      orReadyMinThreshold: $orReadyMinThreshold
      orReadyMaxThreshold: $orReadyMaxThreshold
      piToStartMinThreshold: $piToStartMinThreshold
      piToStartMaxThreshold: $piToStartMaxThreshold
      poToCutMinThreshold: $poToCutMinThreshold
      poToCutMaxThreshold: $poToCutMaxThreshold
      startToCloseMinThreshold: $startToCloseMinThreshold
      startToCloseMaxThreshold: $startToCloseMaxThreshold
      physicianPopiMinThreshold: $physicianPopiMinThreshold
      physicianPopiMaxThreshold: $physicianPopiMaxThreshold
      fcotsGracePeriod: $fcotsGracePeriod
      piToPhyiMinThreshold: $piToPhyiMinThreshold
      piToPhyiMaxThreshold: $piToPhyiMaxThreshold
      closingCompleteToPatientOutMinThreshold: $closingCompleteToPatientOutMinThreshold
      closingCompleteToPatientOutMaxThreshold: $closingCompleteToPatientOutMaxThreshold
    ) {
      id
      physician
      surgeryDays
      numberOfProcedures
      firstPIToLastPOTotal
      pipoTotal
      pipoOverE2EPercentage
      piToStartAvg
      piToStartMed
      startToCloseAvg
      startToCloseMed
      poToCutAvg
      poToCutMed
      popiPhysicianAvg
      popiPhysicianMed
      wrAvg
      wrMed
      preOpAvg
      preOpMed
      pipoAvg
      pipoMed
      recoveryAvg
      recoveryMed
      postOpAvg
      postOpMed
      preOpToExitAvg
      preOpToExitMed
      preOpToExitTotal
      inOrAvg
      inOrMed
      inSurgeryAvg
      inSurgeryMed
      closingAvg
      closingMed
      procedureCompleteAvg
      procedureCompleteMed
      orReadyAvg
      orReadyMed
      fcotsDelayAvg
      fcotsDelayMed
      fcotsDelayDist
      fcotsDelayReasons {
        reason
        count
      }
      proceduresOnTimePercentage
      scheduleEfficiency
      scheduledProcedureDurationAvg
      scheduledProcedureDurationMed
      piToPhyiAvg
      piToPhyiMed
      closingCompleteToPatientOutAvg
      closingCompleteToPatientOutMed
      readyForAnesthesiaToReadyForORAvg
      readyForAnesthesiaToReadyForORMed
      patientReadyToOrInAvg
      patientReadyToOrInMed
      inORToReadyForSurgeryAvg
      inORToReadyForSurgeryMed
      readyForSurgeryToStartAvg
      readyForSurgeryToStartMed
      readyForSurgeryToPhyiAvg
      readyForSurgeryToPhyiMed
    }
  }
`;

export const physicianPatientsAnalyticsQuery = gql`
  query physicianPatientsAnalytics($physicianId: Long!, $filter: Filter!) {
    physicianPatientsAnalytics(physicianId: $physicianId, filter: $filter) {
      id
      patient
      patientProviderId
      surgeryTime
      operationRoom
      procedureType
      admittedAt
      preOpIn
      preOpOut
      orIn
      cut
      orOut
      recoveryIn
      recoveryOut
      postOpIn
      postOpOut
      dischargedAt
      preOpInMinutes
      pipoInMinutes
      recoveryInMinutes
      postOpInMinutes
      preOpToExitInMinutes
      wrInMinutes
      piToStartInMinutes
      startToCloseInMinutes
      procedureDurationInMinutes
      scheduledProcedureDurationInMinutes
      patientReadyToOrInInMinutes
      inORToReadyForSurgeryInMinutes
      readyForSurgeryToStartInMinutes
    }
  }
`;

export const sendAnalyticsReport = gql`
  mutation sendDetailedReport($monthYear: String!) {
    sendDetailedReport(monthYear: $monthYear)
  }
`;

export const analyticsConfigMutation = gql`
  mutation updateAnalyticsConfiguration(
    $monthlyReportRecipients: [Long!]!
    $weeklyReportRecipients: [Long!]!
    $totalPatientsThreshold: Long
    $waitingRoomTimeThreshold: Long
    $preopTimeThreshold: Long
    $readyForAnesthesiaThreshold: Long
    $readyForORThreshold: Long
    $orUtilThreshold: Long
    $orTimeThreshold: Long
    $pacuTimeThreshold: Long
    $cleaningTimeThreshold: Long
    $closingTimeThreshold: Long
    $popiTimeThreshold: Long
    $postOpTimeThreshold: Long
    $pipoMinThreshold: Long
    $pipoMaxThreshold: Long
    $popiMinThreshold: Long
    $popiMaxThreshold: Long
    $wrMinThreshold: Long
    $wrMaxThreshold: Long
    $preOpMinThreshold: Long
    $preOpMaxThreshold: Long
    $cleaningMinThreshold: Long
    $cleaningMaxThreshold: Long
    $pacuMinThreshold: Long
    $pacuMaxThreshold: Long
    $postOpMinThreshold: Long
    $postOpMaxThreshold: Long
    $preOpToDischargedMinThreshold: Long
    $preOpToDischargedMaxThreshold: Long
    $inOrMinThreshold: Long
    $inOrMaxThreshold: Long
    $inSurgeryMinThreshold: Long
    $inSurgeryMaxThreshold: Long
    $closingMinThreshold: Long
    $closingMaxThreshold: Long
    $procedureCompleteMinThreshold: Long
    $procedureCompleteMaxThreshold: Long
    $orReadyMinThreshold: Long
    $orReadyMaxThreshold: Long
    $piToStartMinThreshold: Long
    $piToStartMaxThreshold: Long
    $poToCutMinThreshold: Long
    $poToCutMaxThreshold: Long
    $startToCloseMinThreshold: Long
    $startToCloseMaxThreshold: Long
    $physicianPopiMinThreshold: Long
    $physicianPopiMaxThreshold: Long
    $fcotsGracePeriod: Int
    $piToPhyiMinThreshold: Long
    $piToPhyiMaxThreshold: Long
    $closingCompleteToPatientOutMinThreshold: Long
    $closingCompleteToPatientOutMaxThreshold: Long
    $samePhysicianORTurnover: Boolean
  ) {
    updateAnalyticsConfiguration(
      monthlyReportRecipients: $monthlyReportRecipients
      weeklyReportRecipients: $weeklyReportRecipients
      totalPatientsThreshold: $totalPatientsThreshold
      waitingRoomTimeThreshold: $waitingRoomTimeThreshold
      preopTimeThreshold: $preopTimeThreshold
      readyForAnesthesiaThreshold: $readyForAnesthesiaThreshold
      readyForORThreshold: $readyForORThreshold
      orUtilThreshold: $orUtilThreshold
      orTimeThreshold: $orTimeThreshold
      pacuTimeThreshold: $pacuTimeThreshold
      cleaningTimeThreshold: $cleaningTimeThreshold
      closingTimeThreshold: $closingTimeThreshold
      popiTimeThreshold: $popiTimeThreshold
      postOpTimeThreshold: $postOpTimeThreshold
      pipoMinThreshold: $pipoMinThreshold
      pipoMaxThreshold: $pipoMaxThreshold
      popiMinThreshold: $popiMinThreshold
      popiMaxThreshold: $popiMaxThreshold
      wrMinThreshold: $wrMinThreshold
      wrMaxThreshold: $wrMaxThreshold
      preOpMinThreshold: $preOpMinThreshold
      preOpMaxThreshold: $preOpMaxThreshold
      cleaningMinThreshold: $cleaningMinThreshold
      cleaningMaxThreshold: $cleaningMaxThreshold
      pacuMinThreshold: $pacuMinThreshold
      pacuMaxThreshold: $pacuMaxThreshold
      postOpMinThreshold: $postOpMinThreshold
      postOpMaxThreshold: $postOpMaxThreshold
      preOpToDischargedMinThreshold: $preOpToDischargedMinThreshold
      preOpToDischargedMaxThreshold: $preOpToDischargedMaxThreshold
      inOrMinThreshold: $inOrMinThreshold
      inOrMaxThreshold: $inOrMaxThreshold
      inSurgeryMinThreshold: $inSurgeryMinThreshold
      inSurgeryMaxThreshold: $inSurgeryMaxThreshold
      closingMinThreshold: $closingMinThreshold
      closingMaxThreshold: $closingMaxThreshold
      procedureCompleteMinThreshold: $procedureCompleteMinThreshold
      procedureCompleteMaxThreshold: $procedureCompleteMaxThreshold
      orReadyMinThreshold: $orReadyMinThreshold
      orReadyMaxThreshold: $orReadyMaxThreshold
      piToStartMinThreshold: $piToStartMinThreshold
      piToStartMaxThreshold: $piToStartMaxThreshold
      poToCutMinThreshold: $poToCutMinThreshold
      poToCutMaxThreshold: $poToCutMaxThreshold
      startToCloseMinThreshold: $startToCloseMinThreshold
      startToCloseMaxThreshold: $startToCloseMaxThreshold
      physicianPopiMinThreshold: $physicianPopiMinThreshold
      physicianPopiMaxThreshold: $physicianPopiMaxThreshold
      fcotsGracePeriod: $fcotsGracePeriod
      piToPhyiMinThreshold: $piToPhyiMinThreshold
      piToPhyiMaxThreshold: $piToPhyiMaxThreshold
      closingCompleteToPatientOutMinThreshold: $closingCompleteToPatientOutMinThreshold
      closingCompleteToPatientOutMaxThreshold: $closingCompleteToPatientOutMaxThreshold
      samePhysicianORTurnover: $samePhysicianORTurnover
    )
  }
`;
