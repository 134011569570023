import { ThemeProvider } from '@material-ui/core/styles';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import flow from 'lodash/fp/flow';
import set from 'lodash/fp/set';
import identity from 'lodash/fp/identity';
import { tabletThemeDark } from '../../../../themes/tabletTheme';
import PatientSlot from '../../../patient/PatientSlot/PatientSlot';
import Troubleshooting from '../tablet/Troubleshooting';
import {
  getFrameColor,
  getLogEntries,
  isHoldProcedureFn,
  isBlockNerveFn,
  isPatientReady,
  isReadyForAnesthesiaFn,
  isPatientReadyForSurgeonFn,
  isPreOpAsDPU,
  isReadyForFamilyPreOp,
} from '../tablet/utils';
import { CaretakerChatDialog } from '../../../chat/ChatDialog';
import { Idle, Illustration } from '../tablet/OperationRoomTabletV1';
import waiting from '../../../../assets/images/waiting.svg';
import ClientUpdater, { ClientUpdaterPresetTvInternal } from '../../../ClientUpdater';
import { AutomaticPatientTracking } from '../../../ModuleOptional';
import Button from '../../../../se/components/Button';
import { setRoom } from '../../../../graph/patients';
import { useMutation } from '@apollo/client';
import { Subtitle, Title } from '../tablet/Modal';
import get from 'lodash/get';
import PatientSelection from '../nursingApp/PatientSelection';
import { OR, PACU, POST_OP, PRE_OP, ROOM_TYPES, WAITING_ROOM } from '../../../entities/room/enums';
import filter from 'lodash/filter';
import { orderBy } from 'lodash';
import { getScreenType } from '../../../entities/screens/enums';
import { useModalState } from '../../../../util/useModalState';

export const PatientListContainer = styled.div`
  background: ${props => props.theme.backgroundColor.string()};
  color: ${props => props.theme.textColor.string()};
  padding: 1em 1.5em;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  h1 {
    font-size: 2em;
    margin-bottom: 1em;
    margin-left: 0.5em;
  }
`;

const SlotContainerWrapper = styled.div`
  flex: 1;
  ${props =>
    props.columns > 1 &&
    `
    flex: 1;
    columns: 2;
    column-fill: auto;
  `};
`;

const SlotContainer = styled.div`
  margin-bottom: 0.5rem;
  cursor: pointer;
  break-inside: avoid;
  display: inline-block;
  width: 100%;
  position: relative;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5em;
  margin-left: 0.5em;
  flex-shrink: 0;
  h1 {
    margin: 0;
  }

  .modal-body {
    margin-top: 0;
  }
`;

const EmptyState = () => (
  <Idle>
    <Illustration src={waiting} />
    <Title>Waiting for patients</Title>
    <Subtitle>Once a patient enters the room, this screen will automatically show the controls.</Subtitle>
  </Idle>
);

const MovePatient = ({ room }) => {
  const [modal, setModal] = useState(false);
  const [moveTo] = useMutation(setRoom);

  const filterPatients = patients => {
    const targetRoom =
      room?.type === PRE_OP
        ? [WAITING_ROOM]
        : room?.type === PACU
          ? [WAITING_ROOM, PRE_OP, OR]
          : [WAITING_ROOM, PRE_OP, OR, PACU, POST_OP];
    return orderBy(
      filter(
        patients,
        patient => targetRoom.includes(get(patient, 'room.type')) && get(patient, 'room.name') !== get(room, 'name')
      ),
      'room.type'
    );
  };

  const movePatient = id => async () => {
    try {
      await moveTo({ variables: { id, roomId: get(room, 'id') } });
      setModal(false);
    } catch (e) {
      console.log(e);
    }
  };

  if (modal) {
    return (
      <PatientSelection
        handleCancel={() => setModal(false)}
        handlePatientClick={movePatient}
        filterFn={filterPatients}
        TitleAndActions={() => null}
        withRoom={true}
      />
    );
  }

  return <Button onClick={() => setModal(true)}>{`Move Patient To ${get(room, 'name', 'Here')}`}</Button>;
};

const MovePatients = AutomaticPatientTracking(null, MovePatient);

const PatientList = props => {
  const { open, handleClose, handleOpen } = useModalState();

  const { hasNoteModule, hospitalOverviewMode, roomType, roomName, room } = props;

  const patients = props.patients.map(patient =>
    flow(
      set('isReady', isPatientReady(getLogEntries(patient))(roomType)),
      set('isFamilyReadyPreOp', isReadyForFamilyPreOp(getLogEntries(patient))(roomType)),
      props.showHoldProcedure ? set('isHoldProcedure', isHoldProcedureFn(getLogEntries(patient))) : identity,
      props.showBlockNerve || (props.showBlockNervePacu && get(patient, 'blockNerveFollow', true))
        ? set('isBlockNerve', isBlockNerveFn(getLogEntries(patient)))
        : identity,
      props.showReadyForSurgeon
        ? set('isReadyForSurgeon', isPatientReadyForSurgeonFn(getLogEntries(patient)))
        : identity,
      props.showReadyForAnesthesia
        ? set('isReadyForAnesthesia', isReadyForAnesthesiaFn(getLogEntries(patient)))
        : identity
    )(patient)
  );

  const isPreOp = roomType === PRE_OP;
  const isPacu = roomType === PACU;
  const isPostOp = roomType === POST_OP;

  const columns = patients.length < 11 ? 1 : 2;

  function handleListItemClick(e, patient) {
    if (!hospitalOverviewMode) {
      props.selectPatient(patient.id);
      return;
    }

    if (patient.caretakerConsent) {
      handleOpen();
    }
  }

  return (
    <PatientListContainer>
      <Header>
        {roomName ? <h1>{roomName}</h1> : <h1>Patients</h1>}
        {room && !(Array.isArray(room) && room.length === 0) && <MovePatients room={room} />}
      </Header>
      {patients.length === 0 && <EmptyState />}
      <SlotContainerWrapper columns={columns}>
        {patients.length > 0 ? (
          patients.map(patient => (
            <SlotContainer key={patient.id} onClick={e => handleListItemClick(e, patient)}>
              <PatientSlot
                patient={patient}
                {...patient}
                showPriority={props.hasPreOpPriorityModule}
                caretakerMessage={get(patient, 'caretakerMessages', null)}
                status="occupied"
                frameColor={getFrameColor(roomType, patient?.log)}
                ready={patient.isReady}
                isHoldProcedure={patient.isHoldProcedure}
                isBlockNerve={patient.isBlockNerve}
                readyForAnesthesia={patient.isReadyForAnesthesia}
                readyForSurgeon={patient.isReadyForSurgeon}
                readyForOr={patient.isReady && roomType === ROOM_TYPES.PRE_OP}
                familyReady={isPacu ? patient.familyReadyPACU : isPostOp ? patient.familyReadyPOSTOP : false}
                preventAnimation
                size={patients.length < 8 ? 'large' : null}
                screenType={getScreenType(roomType)}
                patientData={patient}
                showPreOpNote={isPreOp && hasNoteModule}
                showPacuNote={isPacu && hasNoteModule}
                preOpNote={patient?.preOpNote}
                pacuNote={patient?.pacuNote}
                hospitalOverviewMode={hospitalOverviewMode}
                showRoom={hospitalOverviewMode}
                showDuration={!hospitalOverviewMode}
                roomName={patient.room?.name}
                isDPU={isPreOpAsDPU(roomType, getLogEntries(patient))}
                blockNerveFollow={patient?.blockNerveFollow}
                called={patient?.called}
              />

              {patient.caretakerConsent ? (
                <CaretakerChatDialog patient={patient} open={open} handleClose={handleClose} withButton={false} />
              ) : null}
            </SlotContainer>
          ))
        ) : (
          <ClientUpdater {...ClientUpdaterPresetTvInternal} />
        )}
      </SlotContainerWrapper>
      <ThemeProvider theme={tabletThemeDark}>
        <Troubleshooting roomName={roomName} />
      </ThemeProvider>
    </PatientListContainer>
  );
};

PatientList.propTypes = {
  roomType: PropTypes.string.isRequired,
  roomName: PropTypes.string,
  selectPatient: PropTypes.func.isRequired,
  patients: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ).isRequired,
};

export default PatientList;
