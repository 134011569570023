import isArray from 'lodash/isArray';

export type Allergy = {
  name: string;
  reaction: string;
};

export function isAllergyFilled(type: 'q1' | 'q2' | 'q3', item: any) {
  switch (type) {
    case 'q1':
    case 'q3':
      return !!item?.[`${type}_name`]?.text && !!item?.[`${type}_reaction`]?.text;
    case 'q2':
      return !!item?.[`${type}_reaction`]?.text;
  }
}

export function hasAllergy(type: 'q1' | 'q2' | 'q3', item: any) {
  if (!item) {
    return undefined;
  }

  if (item.yes === false) {
    return false;
  }

  switch (type) {
    case 'q1': {
      if (
        isArray(item.yes) &&
        item.yes.length === 2 &&
        item.yes[0] === true &&
        isArray(item.yes[1].schema?.allergy_list?.list) &&
        (item.yes[1].schema.allergy_list?.list ?? []).reduce(
          (acc: boolean, value: any) => {
            return acc && isAllergyFilled(type, value?.schema);
          },
          (item.yes[1].schema.allergy_list?.list ?? []).length > 0
        )
      ) {
        return true;
      }
      break;
    }
    case 'q2':
    case 'q3': {
      if (
        isArray(item.yes) &&
        item.yes.length === 2 &&
        item.yes[0] === true &&
        isAllergyFilled(type, item.yes[1]?.schema)
      ) {
        return true;
      }
      break;
    }
  }

  return undefined;
}

export function extractAllergies(list: any[]): Allergy[] {
  return list
    .map((item: any) => item.schema)
    .map(item => extractAllergy('q1', item))
    .filter((allergy?: Allergy): allergy is Allergy => !!allergy);
}

export function extractAllergy(type: 'q1' | 'q2' | 'q3', item: any): Allergy | undefined {
  const allergyItem = getAllergyItem(type, item);

  if (!allergyItem) {
    return undefined;
  }

  const name = type === 'q2' ? 'latex' : allergyItem[`${type}_name`]?.text;
  const reaction = allergyItem[`${type}_reaction`]?.text;

  if (!name && !reaction) {
    return undefined;
  }

  return {
    name,
    reaction,
  };
}

function getAllergyItem(type: 'q1' | 'q2' | 'q3', item: any): any {
  switch (type) {
    case 'q1': {
      return item;
    }
    case 'q2':
    case 'q3': {
      if (item?.yes && item.yes.length > 1 && item.yes[1].schema) {
        return item.yes[1].schema;
      }
      return undefined;
    }
  }
}
