import isValid from 'date-fns/is_valid';
import isAfter from 'date-fns/is_after';
import addMinutes from 'date-fns/add_minutes';
import flatMap from 'lodash/fp/flatMap';
import { getNestedValue } from '../../../se/utilities/data/object';
import { sortBy, sortDatesAsc, sortUndefinedValuesFirst } from '../../../se/utilities/data/array';
import { isArray, isDefinedAndNotNull, isObject } from '../../../se/utilities/check';

import { MONITOR_STATUSES, STATUS_LABELS } from './enums';
import { OR, ROOM_TYPE_LABELS, STATUS_LABELS as ROOM_STATUS_LABELS, STATUSES as ROOM_STATUSES } from '../room/enums';
import { calculateDuration, formatTimeEntered, getInitials, sortByCreatedAtDesc, getRoom } from '../common/transducers';
import { roomTypeToProcedureColumn } from '../procedure-type/transducers';
import { patientEnteredTime } from '../../pages/kiosk/tablet/utils';
import set from 'lodash/set';
import merge from 'lodash/merge';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import map from 'lodash/fp/map';
import contains from 'lodash/fp/contains';
import isString from 'lodash/isString';

export const sortByChangedRoomAt = sortBy('changedRoomAt');
export const sortByChangedRoomAtAsc = sortByChangedRoomAt(sortDatesAsc);

export const getStatus = patient =>
  MONITOR_STATUSES.includes(patient.status)
    ? {
        status: patient.status,
        statusLabel: STATUS_LABELS[patient.status],
      }
    : {
        status: ROOM_STATUSES.VACANT,
        statusLabel: ROOM_STATUS_LABELS[ROOM_STATUSES.VACANT],
      };

export const getPatientProcedureDuration = (patient, room) => {
  if (!isObject(patient)) {
    return {};
  }

  const lastRecordedTimestamp = patientEnteredTime(patient, room) || new Date(patient.changedRoomAt);

  return {
    duration: calculateDuration(lastRecordedTimestamp, new Date()),
  };
};

export const prepareCreateData = ({ bracelet, name, ...rest }) => ({
  ...(isString(name)
    ? { name }
    : name.__isNew__
      ? { name: name.value }
      : { procedureId: get('procedure.id')(name), name: get('procedure.patientName')(name) }),
  ...bracelet,
  ...rest,
});

export const mapEditItemProps = ({ data }) => {
  if (data) {
    const ret = {
      ...data,
      patient: {
        ...data.patient,
        procedureType: getNestedValue('patient.procedureType.id', data),
        physician: getNestedValue('patient.physician.id', data),
        roomId: data && data.patient ? getRoom(data.patient.room).id : undefined,
      },
    };

    return { data: ret };
  }
};

export const sortActivePatientsAtTheTop = sortBy('dischargedAt')(sortUndefinedValuesFirst);

export const mapListProps = props => {
  const { data, entityNamePlural } = props;

  return {
    ...props,
    data: {
      ...data,
      [entityNamePlural]: isArray(data[entityNamePlural])
        ? [...data[entityNamePlural]]
            .sort(sortByCreatedAtDesc)
            .sort(sortActivePatientsAtTheTop)
            // Place a separator when we get to the first discharged patient
            .reduce(
              (acc, item) =>
                item.dischargedAt && !acc.isSeparatorPlaced
                  ? {
                      ...acc,
                      isSeparatorPlaced: true,
                      items: [...acc.items, { separator: true, label: 'Completed Visits' }, item],
                    }
                  : {
                      ...acc,
                      items: [...acc.items, item],
                    },
              { isSeparatorPlaced: false, items: [] }
            ).items
        : [],
    },
  };
};

export const groupByRoom = rooms => props => {
  const { data, entityNamePlural } = props;

  const patients = data?.patients || [];
  const patientsByRooms = patients.reduce((acc, patient) => {
    const roomType = patient?.room?.type;
    return rooms.includes(roomType)
      ? acc[roomType]
        ? { ...acc, [roomType]: [...acc[roomType], patient] }
        : { ...acc, [roomType]: [patient] }
      : acc;
  }, {});

  const patientsWithSeparator = rooms.reduce((acc, roomType) => {
    const patients = patientsByRooms[roomType];
    return patients ? [...acc, { separator: true, label: `${ROOM_TYPE_LABELS[roomType]} Patients` }, ...patients] : acc;
  }, []);

  return { ...props, data: { ...data, [entityNamePlural]: patientsWithSeparator } };
};

export const pickPatientByOldestChangedRoomAt = patients => {
  if (!isArray(patients)) {
    return undefined;
  }

  const sortedPatientsByChangedRoomAtAsc = patients.sort(sortByChangedRoomAtAsc);

  if (!isObject(sortedPatientsByChangedRoomAtAsc[0])) {
    return undefined;
  }

  return sortedPatientsByChangedRoomAtAsc[0];
};

export const getORPatient = room => {
  const transformedRoom = transformORPatients(room);

  return transformedRoom.patient
    ? {
        patient: transformedRoom.patient,
      }
    : {};
};

export const transformORPatients = room => {
  if (!isObject(room) || room.type !== OR) {
    return {};
  }

  const patients = {};

  (room.patients || []).forEach(patient => (patients[patient.id] = patient));

  if (isDefinedAndNotNull(room.patient)) {
    patients[room.patient.id] = room.patient;
  }

  // Here we take a patient from a server response
  // or we choose one from the patient list
  //    condition is to be the first one that got into OR
  const patient = (() => {
    const { id } =
      (isDefinedAndNotNull(room.patient) ? room.patient : pickPatientByOldestChangedRoomAt(room.patients)) || {};
    return id ? patients[id] : undefined;
  })();

  return set(
    set(room, 'patient', patient && merge(patient, getTimeEntered()(patient))),
    'patients',
    Object.values(patients)
  );
};

export const getPatientNameAsInitials = (
  patientName,
  lastNameChars = 1,
  fullFirstName = false,
  fullLastName = false
) =>
  isString(patientName)
    ? {
        initials: getInitials(patientName, lastNameChars, fullFirstName, fullLastName),
      }
    : {};

export const assignInitials =
  (patients = [], fullFirstName, fullLastName) =>
  patient => {
    const collisions = patients.filter(p => p.initials === patient.initials && p.id !== patient.id);
    const isNewer = collisions.find(c => new Date(c.changedRoomAt) < new Date(patient.changedRoomAt));

    if (isNewer) {
      const { initials } = getPatientNameAsInitials(patient.name, 3, fullFirstName, fullLastName);
      return { ...patient, initials };
    } else {
      return { ...patient, initials: patient.initials };
    }
  };

// You can also send room type if you need it
export const getTimeEntered = () => patient => {
  const date = isObject(patient) ? patient.changedRoomAt : undefined;
  if (isObject(patient) && isValid(new Date(date))) {
    return {
      timeEntered: date,
      formattedTimeEntered: formatTimeEntered(new Date(date)),
    };
  }

  return {};
};

export const getMonitorStatus = roomType => patient => {
  const expectedDuration = patient.procedureType
    ? patient.procedureType[roomTypeToProcedureColumn[roomType]]
    : undefined;
  const lastRecordedTimestamp = patient.changedRoomAt || patient.createdAt;

  if (!expectedDuration) {
    return {
      monitorStatus: 'occupied',
    };
  }

  return {
    monitorStatus: isAfter(new Date(), addMinutes(new Date(lastRecordedTimestamp), expectedDuration))
      ? 'alert'
      : 'occupied',
    expectedExitTime: addMinutes(new Date(lastRecordedTimestamp), expectedDuration),
  };
};

export const mapMonitorRoomPatient = room => patient =>
  isObject(patient)
    ? {
        ...patient,
        ...getTimeEntered(room.type)(patient),
        ...getMonitorStatus(room.type)(patient),
      }
    : {};

export const mapMonitorRoomPatients = room => patients => {
  if (isObject(room) && isArray(patients)) {
    return { patients: patients.map(mapMonitorRoomPatient(room)).sort(sortByChangedRoomAtAsc) };
  } else {
    return [];
  }
};

/**
 *
 * @param {string=} dischargedAt
 * @param {string=} voidedAt
 * @return {boolean}
 */
export const isPatientActive = (dischargedAt, voidedAt) => !(dischargedAt || voidedAt);

export const addFamilyReadyPACU = patient =>
  set(
    patient,
    'familyReadyPACU',
    flow(get('log'), flatMap(get('entries')), map(get('type')), contains('FamilyReady'))(patient)
  );

export const addFamilyReadyPOSTOP = patient =>
  set(
    patient,
    'familyReadyPOSTOP',
    flow(get('log'), flatMap(get('entries')), map(get('type')), contains('SetFamilyReadyPOSTOP'))(patient)
  );
