import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from '../../styles';
import MedicalPassportField from '../../components/MedicalPassportField';
import {
  ageFrom60to69YearsOld,
  ageFrom70to79YearsOld,
  ageFrom80YearsOld,
  toBooleanNegative,
  toBooleanPositive,
  toFemale,
  toMale,
} from '../../../../../../questionnaire/fallRiskReducers';
import toSex from '../../../../../../questionnaire/toSex';
import toAge from '../../../../../../questionnaire/toAge';
import FallRiskCheckbox from '../../components/FallRiskCheckbox';
import toSelectedCheckBox from '../../../../../../questionnaire/toSelectedCheckbox';
import Barcode from '../../../Barcode';

function initializeValues(rootRef, setChecked) {
  const root = rootRef.current;

  if (!root) {
    return;
  }

  const checkboxes = root.querySelectorAll('input[type="checkbox"]');

  setChecked(
    [...checkboxes].reduce((acc, checkbox) => {
      const value = checkbox.checked ? parseInt(checkbox.value) : 0;
      return { ...acc, [checkbox.name]: isFinite(value) ? value : 1 };
    }, {})
  );
}

const Page7 = ({ formName, pageNumber, showQRCode, defaultValue }) => {
  const providerId = defaultValue?.procedure?.patient?.providerId;

  const [values1, setValues1] = useState({});
  const [values2, setValues2] = useState({});

  const total1 = useMemo(() => Object.values(values1).reduce((acc, value) => acc + value, 0), [values1]);
  const total2 = useMemo(() => Object.values(values2).reduce((acc, value) => acc + value, 0), [values2]);
  const total = total1 + total2;

  const root1Ref = useRef(undefined);
  const root2Ref = useRef(undefined);

  useEffect(() => {
    initializeValues(root1Ref, setValues1);
    initializeValues(root2Ref, setValues2);
  }, []);

  const handleCheckboxChange = setValues => (value, name) => {
    setValues(prev => ({ ...prev, [name]: value }));
  };

  return (
    <div id="page7" className="page" style={{ fontSize: '11px', fontFamily: 'Arial' }}>
      <div style={styles.center}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div />
          <h2 style={styles.underlineHeading}>Fall Risk Assessment</h2>
          <div>
            {showQRCode && (
              <Barcode
                formName={formName}
                pageNumber={pageNumber}
                providerId={defaultValue?.procedure?.patient?.providerId}
                serviceTime={defaultValue?.procedure?.serviceTime}
              />
            )}
          </div>
        </div>
        <div
          style={{
            marginBottom: '2px',
            width: '100%',
            display: 'grid',
            gridTemplateRows: '1fr 1fr',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <MedicalPassportField name="procedure.patient.name" label="Patient" fontWeight={'bold'} fontSize={14} />
            <MedicalPassportField name="dob" label="DOB" path={['demographicInformation', 'dob']} fontSize={12} />
            <MedicalPassportField name="procedure.patient.providerId" label="MRN" fontSize={12} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <MedicalPassportField name="procedure.physician.name" label="Surgeon" fontSize={12} />
            <MedicalPassportField
              name="sex"
              label="Sex"
              path={['demographicInformation', 'sex']}
              reducer={toSex}
              fontSize={12}
            />
            <MedicalPassportField
              name="age"
              label="Age"
              path={['demographicInformation', 'dob']}
              reducer={toAge}
              fontSize={12}
            />
            <MedicalPassportField name="procedure.serviceTime" label="DOS" fontSize={12} />
          </div>
        </div>
      </div>
      <div ref={root1Ref}>
        <div style={{ ...styles.oneColumn, fontSize: '14px', padding: '3px 0' }}>Fall Risk Assessment</div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '2fr 3fr 1fr',
            border: '1px solid black',
            borderBottom: 0,
          }}
        >
          <div style={{ textAlign: 'center', borderRight: '1px solid black', padding: '2px', fontSize: '13px' }}>
            Risk Factor(s)
          </div>
          <div style={{ textAlign: 'center', borderRight: '1px solid black', padding: '2px', fontSize: '13px' }}>
            Scale
          </div>
          <div style={{ textAlign: 'center', padding: '2px', fontSize: '13px' }}>Score</div>
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '2fr 3fr 1fr',
            border: '1px solid black',
            borderBottom: 0,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRight: '1px solid black',
              gridRow: '1 / 4',
            }}
          >
            <div style={{ fontSize: '11px' }}>Age</div>
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '11px',
            }}
          >
            <FallRiskCheckbox
              name="fallRiskAssessment.ageFrom60to69YearsOld"
              value={1}
              label="60-69"
              path={['demographicInformation', 'dob']}
              reducer={ageFrom60to69YearsOld}
              onCheckboxChange={handleCheckboxChange(setValues1)}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '11px',
            }}
          >
            1
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '11px',
            }}
          >
            <FallRiskCheckbox
              name="fallRiskAssessment.ageFrom70to79YearsOld"
              value={2}
              label="70-79"
              path={['demographicInformation', 'dob']}
              reducer={ageFrom70to79YearsOld}
              onCheckboxChange={handleCheckboxChange(setValues1)}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '11px',
            }}
          >
            2
          </div>
          <div style={{ borderRight: '1px solid black', fontSize: '11px' }}>
            <FallRiskCheckbox
              name="fallRiskAssessment.ageFrom80YearsOld"
              value={3}
              label="greater than or equal to 80 years"
              path={['demographicInformation', 'dob']}
              reducer={ageFrom80YearsOld}
              onCheckboxChange={handleCheckboxChange(setValues1)}
            />
          </div>
          <div style={{ textAlign: 'center', fontSize: '11px' }}>3</div>
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '2fr 3fr 1fr',
            border: '1px solid black',
            borderBottom: 0,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRight: '1px solid black',
              gridRow: '1 / 3',
              fontSize: '11px',
            }}
          >
            Gender
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '11px',
            }}
          >
            <FallRiskCheckbox
              name="fallRiskAssessment.genderMale"
              value={2}
              label="Male"
              path={['demographicInformation', 'sex']}
              reducer={toMale}
              onCheckboxChange={handleCheckboxChange(setValues1)}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '11px',
            }}
          >
            2
          </div>
          <div style={{ borderRight: '1px solid black', fontSize: '11px' }}>
            <FallRiskCheckbox
              name="fallRiskAssessment.genderFemale"
              value={1}
              label="Female"
              path={['demographicInformation', 'sex']}
              reducer={toFemale}
              onCheckboxChange={handleCheckboxChange(setValues1)}
            />
          </div>
          <div style={{ textAlign: 'center', fontSize: '11px' }}>1</div>
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '2fr 3fr 1fr',
            border: '1px solid black',
            borderBottom: 0,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRight: '1px solid black',
              gridRow: '1 / 2',
              fontSize: '11px',
            }}
          >
            Fall History
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              fontSize: '11px',
            }}
          >
            <FallRiskCheckbox
              name="fallRiskAssessment.fallHistory"
              value={20}
              label="One fall within past 6 months - accidental trips/falls/balances
              issues"
              path={['basicscreening', 'q1']}
              reducer={toBooleanPositive}
              onCheckboxChange={handleCheckboxChange(setValues1)}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              fontSize: '11px',
            }}
          >
            20
          </div>
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '2fr 3fr 1fr',
            border: '1px solid black',
            borderBottom: 0,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRight: '1px solid black',
              gridRow: '1 / 4',
              fontSize: '11px',
            }}
          >
            Medication in last 24 hours- sedatives, hypnotics, barbiturates, phenothiazines, antidepressants,
            laxatives/diuretics, narcotics
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '11px',
            }}
          >
            <FallRiskCheckbox
              name="fallRiskAssessment.medicationOne"
              value={1}
              label="On 1 high fall risk medication"
              onCheckboxChange={handleCheckboxChange(setValues1)}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '11px',
            }}
          >
            1
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '11px',
            }}
          >
            <FallRiskCheckbox
              name="fallRiskAssessment.medicationTwo"
              value={3}
              label="On 2 high fall risk medication"
              onCheckboxChange={handleCheckboxChange(setValues1)}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              padding: '2px',
              fontSize: '11px',
            }}
          >
            3
          </div>
          <div style={{ borderRight: '1px solid black', fontSize: '11px' }}>
            <FallRiskCheckbox
              name="fallRiskAssessment.medicationThree"
              value={5}
              label="On 3 or more high fall risk medication"
              onCheckboxChange={handleCheckboxChange(setValues1)}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              fontSize: '11px',
            }}
          >
            5
          </div>
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '2fr 3fr 1fr',
            border: '1px solid black',
            borderBottom: 0,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRight: '1px solid black',
              gridRow: '1 / 4',
              fontSize: '11px',
            }}
          >
            Current Co-morbid Conditions-HTN, Cardiac, seizure disorder, diabetes, cancer, Parkinson’s, MS, stroke,
            dementia, vertigo, hx of vaso-vagal episodes, pacemaker/defibrillator, clotting/bleeding disorders,
            arthritis, aphasia, PTSD
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '11px',
            }}
          >
            <FallRiskCheckbox
              name="fallRiskAssessment.conditionOne"
              value={5}
              label="1 Co-morbid condition"
              onCheckboxChange={handleCheckboxChange(setValues1)}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '11px',
            }}
          >
            5
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '11px',
            }}
          >
            <FallRiskCheckbox
              name="fallRiskAssessment.conditionTwo"
              value={10}
              label="2 Co-morbid condition"
              onCheckboxChange={handleCheckboxChange(setValues1)}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '11px',
            }}
          >
            10
          </div>
          <div style={{ borderRight: '1px solid black', fontSize: '11px' }}>
            <FallRiskCheckbox
              name="fallRiskAssessment.conditionThree"
              value={15}
              label="3 or more Co-morbid condition"
              onCheckboxChange={handleCheckboxChange(setValues1)}
            />
          </div>
          <div style={{ textAlign: 'center', fontSize: '11px' }}>15</div>
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '2fr 3fr 1fr',
            border: '1px solid black',
            borderBottom: 0,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRight: '1px solid black',
              gridRow: '1 / 5',
              fontSize: '11px',
            }}
          >
            Mobility (multi-select)
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '11px',
            }}
          >
            <FallRiskCheckbox
              name="fallRiskAssessment.mobiliyAssist"
              value={10}
              label="Requires assistance or supervision for mobility, transfer, or ambulation"
              path={['basicscreening', 'mobility']}
              reducer={toSelectedCheckBox('Requires assistance or supervision for mobility, transfer, or ambulation')}
              onCheckboxChange={handleCheckboxChange(setValues1)}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '11px',
            }}
          >
            10
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '11px',
            }}
          >
            <FallRiskCheckbox
              name="fallRiskAssessment.unsteadyGait"
              value={10}
              label="Unsteady gait"
              path={['basicscreening', 'mobility']}
              reducer={toSelectedCheckBox('Unsteady gait')}
              onCheckboxChange={handleCheckboxChange(setValues1)}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '11px',
            }}
          >
            10
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '11px',
            }}
          >
            <FallRiskCheckbox
              name="fallRiskAssessment.visualImpairment"
              value={5}
              label="Visual impairment affecting mobility"
              path={['basicscreening', 'mobility']}
              reducer={toSelectedCheckBox('Visual impairment affecting mobility')}
              onCheckboxChange={handleCheckboxChange(setValues1)}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '11px',
            }}
          >
            5
          </div>
          <div style={{ borderRight: '1px solid black', fontSize: '11px' }}>
            <FallRiskCheckbox
              name="fallRiskAssessment.auditoryImpaired"
              value={2}
              label="Auditory impairment affecty mobility"
              path={['basicscreening', 'mobility']}
              reducer={toSelectedCheckBox('Auditory impairment affecty mobility')}
              onCheckboxChange={handleCheckboxChange(setValues1)}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              fontSize: '11px',
            }}
          >
            2
          </div>
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '2fr 3fr 1fr',
            border: '1px solid black',
            borderBottom: 0,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRight: '1px solid black',
              gridRow: '1 / 3',
              fontSize: '11px',
            }}
          >
            Home Oxygen
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '11px',
            }}
          >
            <FallRiskCheckbox
              name="fallRiskAssessment.oxygenYes"
              value={1}
              label="Yes"
              path={['basicscreening', 'homeoxygen']}
              reducer={toBooleanPositive}
              onCheckboxChange={handleCheckboxChange(setValues1)}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '11px',
            }}
          >
            1
          </div>
          <div style={{ borderRight: '1px solid black', fontSize: '11px' }}>
            <FallRiskCheckbox
              name="fallRiskAssessment.oxygenNo"
              value={0}
              label="No"
              path={['basicscreening', 'homeoxygen']}
              reducer={toBooleanNegative}
              onCheckboxChange={handleCheckboxChange(setValues1)}
            />
          </div>
          <div style={{ textAlign: 'center', fontSize: '11px' }}>0</div>
        </div>
      </div>
      <div ref={root2Ref}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '5fr 1fr',
            border: '1px solid black',
          }}
        >
          <div style={{ textAlign: 'right', padding: '3px', borderRight: '1px solid black', fontSize: '11px' }}>
            Total
          </div>
          <div style={{ textAlign: 'center', padding: '2px', fontSize: '11px' }}>
            <MedicalPassportField name="fallRiskAssessment.total1" value={total1} />
          </div>
        </div>
        <div style={{ ...styles.oneColumn, fontSize: '14px' }}>
          Pre-Procedure Assessment--completed by RN during preop phone call or upon arrival the day of service
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '2fr 3fr 1fr',
            border: '1px solid black',
            borderBottom: 0,
          }}
        >
          <div style={{ textAlign: 'center', borderRight: '1px solid black', padding: '2px', fontSize: '13px' }}>
            Risk Factor(s)
          </div>
          <div style={{ textAlign: 'center', borderRight: '1px solid black', padding: '2px', fontSize: '13px' }}>
            Scale
          </div>
          <div style={{ textAlign: 'center', padding: '2px', fontSize: '13px' }}>Score</div>
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '2fr 3fr 1fr',
            border: '1px solid black',
            borderBottom: 0,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRight: '1px solid black',
              gridRow: '1 / 4',
              fontSize: '11px',
            }}
          >
            Tubes & Cords (multi-select)
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '11px',
            }}
          >
            <FallRiskCheckbox
              name="fallRiskAssessment.IV"
              value={1}
              label="IVs"
              onCheckboxChange={handleCheckboxChange(setValues2)}
              path={['demographicInformation', 'dob']}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '11px',
            }}
          >
            1
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '11px',
            }}
          >
            <FallRiskCheckbox
              name="fallRiskAssessment.O2"
              value={1}
              label="O2"
              onCheckboxChange={handleCheckboxChange(setValues2)}
              path={['demographicInformation', 'dob']}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '11px',
            }}
          >
            1
          </div>
          <div style={{ borderRight: '1px solid black', fontSize: '11px' }}>
            <FallRiskCheckbox
              name="fallRiskAssessment.SCD"
              value={1}
              label="SCDs"
              onCheckboxChange={handleCheckboxChange(setValues2)}
              path={['demographicInformation', 'dob']}
            />
          </div>
          <div style={{ textAlign: 'center', fontSize: '11px' }}>1</div>
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '2fr 3fr 1fr',
            border: '1px solid black',
            borderBottom: 0,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRight: '1px solid black',
              gridRow: '1 / 3',
              fontSize: '11px',
            }}
          >
            Blocks / Sedation
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '11px',
            }}
          >
            <FallRiskCheckbox
              name="fallRiskAssessment.regionalBlock"
              value={5}
              label="Regional Block"
              path={['demographicInformation', 'dob']}
              onCheckboxChange={handleCheckboxChange(setValues2)}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '11px',
            }}
          >
            5
          </div>
          <div style={{ borderRight: '1px solid black', fontSize: '11px' }}>
            <FallRiskCheckbox
              name="fallRiskAssessment.sedation"
              value={5}
              label="Pre-Procedure Sedation"
              path={['demographicInformation', 'dob']}
              onCheckboxChange={handleCheckboxChange(setValues2)}
            />
          </div>
          <div style={{ textAlign: 'center', fontSize: '11px' }}>5</div>
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '2fr 3fr 1fr',
            border: '1px solid black',
            borderBottom: 0,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRight: '1px solid black',
              gridRow: '1 / 8',
              fontSize: '11px',
            }}
          >
            Type of Procedure
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '11px',
            }}
          >
            <FallRiskCheckbox
              name="fallRiskAssessment.pain"
              value={15}
              label="Pain"
              path={['demographicInformation', 'dob']}
              onCheckboxChange={handleCheckboxChange(setValues2)}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '11px',
            }}
          >
            15
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '11px',
            }}
          >
            <FallRiskCheckbox
              name="fallRiskAssessment.kneeHipReplace"
              value={10}
              label="Total Knee or Hip Joint Replacement"
              path={['demographicInformation', 'dob']}
              onCheckboxChange={handleCheckboxChange(setValues2)}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '11px',
            }}
          >
            10
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              fontSize: '11px',
            }}
          >
            <FallRiskCheckbox
              name="fallRiskAssessment.colonoscopy"
              value={5}
              label="Colonoscopy"
              path={['demographicInformation', 'dob']}
              onCheckboxChange={handleCheckboxChange(setValues2)}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              fontSize: '11px',
            }}
          >
            5
          </div>
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '2fr 3fr 1fr',
            border: '1px solid black',
            borderBottom: 0,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRight: '1px solid black',
              gridRow: '1 / 5',
              fontSize: '11px',
            }}
          >
            Cognitive or behavioral issues (multi-select)
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '11px',
            }}
          >
            <FallRiskCheckbox
              name="fallRiskAssessment.agitated"
              value={2}
              label="Agitated"
              path={['demographicInformation', 'dob']}
              onCheckboxChange={handleCheckboxChange(setValues2)}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '11px',
            }}
          >
            2
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '11px',
            }}
          >
            <FallRiskCheckbox
              name="fallRiskAssessment.impulseControl"
              value={5}
              label="Impulse control"
              path={['demographicInformation', 'dob']}
              onCheckboxChange={handleCheckboxChange(setValues2)}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '11px',
            }}
          >
            5
          </div>
          <div
            style={{
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '11px',
            }}
          >
            <FallRiskCheckbox
              name="fallRiskAssessment.noncompliance"
              value={5}
              label="Noncompliance"
              path={['demographicInformation', 'dob']}
              onCheckboxChange={handleCheckboxChange(setValues2)}
            />
          </div>
          <div
            style={{
              textAlign: 'center',
              borderBottom: '1px solid black',
              fontSize: '11px',
            }}
          >
            5
          </div>
          <div style={{ borderRight: '1px solid black', fontSize: '11px' }}>
            <FallRiskCheckbox
              name="fallRiskAssessment.lackUnderstandingLimit"
              value={5}
              label="Lack of Understanding limitations"
              path={['demographicInformation', 'dob']}
              onCheckboxChange={handleCheckboxChange(setValues2)}
            />
          </div>
          <div style={{ textAlign: 'center', fontSize: '11px' }}>5</div>
        </div>
      </div>
      <div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '5fr 1fr',
            border: '1px solid black',
          }}
        >
          <div
            style={{
              textAlign: 'right',
              padding: '3px',
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
              fontSize: '11px',
            }}
          >
            Pre-Op Assessment point Total
          </div>
          <div style={{ textAlign: 'center', padding: '3px', fontSize: '11px', borderBottom: '1px solid black' }}>
            <MedicalPassportField name="fallRiskAssessment.preProcedureTotal" value={total2} />
          </div>
          <div style={{ textAlign: 'right', padding: '3px', borderRight: '1px solid black', fontSize: '11px' }}>
            Combined Total PAT Pre-procedure Assessment Point Total
          </div>
          <div style={{ textAlign: 'center', padding: '3px', fontSize: '11px' }}>
            <MedicalPassportField name="fallRiskAssessment.combinedTotal" value={total} />
          </div>
        </div>
        <div style={styles.oneColumn}>
          <div style={{ fontSize: '11px' }}>
            Implement High Risk Fall Precautions for any patient with a fall history in the last 6 months or an
            assessment score of &gt; 20 points.
          </div>
        </div>
        <div style={styles.oneColumn}>
          <div style={{ fontSize: '11px' }}>
            WHEELCHAIR REQUIRED FOR ANY PATIENT USING ASSISTIVE DEVICES OR VISIBLE DIFFICULTY WITH AMBULATION.
          </div>
        </div>
        <div
          style={{
            borderBottom: '1px solid black',
            fontSize: '11px',
          }}
        >
          <div style={styles.oneColumn}>
            <div style={{ fontSize: '11px' }}>
              REMINDER: All PACU patients are considered High Risk for falls. Additional protocols for any patients
              experiencing any of the following risks.
            </div>
          </div>
        </div>
      </div>
      <div style={{ paddingTop: '3px', fontSize: '11px' }}>
        <div style={styles.twoEqualColumnsNoBorder}>
          <MedicalPassportField
            name="rnName"
            label="PreOp Nurse Signature"
            path={[
              'basicscreening',
              'mobility',
              'Requires assistance or supervision for mobility, transfer, or ambulation',
            ]}
            fontSize={11}
          />
          <MedicalPassportField name="rnDT" label="Date/Time" fontSize={11} />
        </div>
      </div>
    </div>
  );
};

export default Page7;
