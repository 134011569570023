import React from 'react';
import { deserialize } from 'react-serialize';
import ObjectField from './ObjectField';
import DateOfBirthField from './DateOfBirthField';
import BodyMassIndexField from './BodyMassIndexField';
import YesNoField from './YesNoField';
import LongTextField from './LongTextField';
import ErrorBoundary from '../../../../se/components/ErrorBoundary';
import Sections from './Sections';
import ListField from './ListField';
import CheckboxesField from './CheckboxesField';
import OneOfField from './OneOfField';
import SingleTextField from './SingleTextField';
import CityStateZip from './CityStateZip';

const withErrorBoundary = Component => props => (
  <ErrorBoundary>
    <Component {...props} />
  </ErrorBoundary>
);

export default (dom, props) =>
  React.cloneElement(
    deserialize(dom, {
      components: {
        Sections: withErrorBoundary(Sections),
        Object: withErrorBoundary(ObjectField),
        List: withErrorBoundary(ListField),
        Checkboxes: withErrorBoundary(CheckboxesField),
        Text: withErrorBoundary(SingleTextField),
        DateOfBirth: withErrorBoundary(DateOfBirthField),
        BodyMassIndex: withErrorBoundary(BodyMassIndexField),
        YesNo: withErrorBoundary(YesNoField),
        LongText: withErrorBoundary(LongTextField),
        OneOf: withErrorBoundary(OneOfField),
        CityStateZip: withErrorBoundary(CityStateZip),
      },
    }),
    props
  );
