import React from 'react';
import pacuEmpty from '../../../../assets/images/postoperation.svg';
import preopEmpty from '../../../../assets/images/preop.svg';
import waitingRoomEmpty from '../../../../assets/images/waitingroom.svg';
import { PACU, POST_OP, PRE_OP } from '../../../entities/room/enums';
import { INTERNAL_MONITOR } from '../../../entities/screens/enums';
import PanelSlots from '../../../Panel/PanelSlots';
import { PanelsStatus } from './Monitor';
import RoomPanelSlots from './RoomPanelSlots';

const BottomPanelStatus = ({
  isMini,
  waitingRoom,
  preOp,
  pacu,
  postOps,
  hasPostop,
  hospitalId,
  preOpFocused,
  pacuFocused,
  hasPreOpPriorityModule,
  hasNoteModule,
  messagingView,
  carouselSize,
}) => {
  // This can change depending on what number of panels are needed, for now it is static as we do not have a need for dynamic changes
  const CAROUSEL_SIZE_PREOP_FOCUSED = 7;
  const PREOP_PANEL_NUMBER = 3;
  const PACU_PANEL_NUMBER = 3;
  const WAITING_ROOM_PANEL_NUMBER = 2;
  const POSTOP_ROOM_PANEL_NUMBER = 2;

  if (preOpFocused) {
    return (
      <PanelsStatus miniView={isMini} moreSpace={messagingView}>
        {waitingRoom && waitingRoom.id && (
          <RoomPanelSlots
            room={waitingRoom}
            hospitalId={hospitalId}
            focused={true}
            screenType={INTERNAL_MONITOR}
            panelNumber={waitingRoom.patients.length === 0 ? 1 : WAITING_ROOM_PANEL_NUMBER}
            carouselSize={CAROUSEL_SIZE_PREOP_FOCUSED}
            imageEmpty={waitingRoomEmpty}
            messagingView={messagingView}
          />
        )}
        {preOp && preOp.id && (
          <RoomPanelSlots
            room={preOp}
            hospitalId={hospitalId}
            focused={true}
            roomType={PRE_OP}
            screenType={INTERNAL_MONITOR}
            panelNumber={PREOP_PANEL_NUMBER}
            carouselSize={CAROUSEL_SIZE_PREOP_FOCUSED}
            imageEmpty={preopEmpty}
            hasPreOpPriorityModule={hasPreOpPriorityModule}
            hasNoteModule={hasNoteModule}
            messagingView={messagingView}
          />
        )}
      </PanelsStatus>
    );
  }

  if (pacuFocused) {
    return (
      <PanelsStatus miniView={isMini} moreSpace={messagingView}>
        <>
          {pacu && pacu.id && (
            <RoomPanelSlots
              room={pacu}
              name={pacu?.name}
              roomType={PACU}
              hospitalId={hospitalId}
              focused={true}
              screenType={INTERNAL_MONITOR}
              panelNumber={PACU_PANEL_NUMBER}
              carouselSize={CAROUSEL_SIZE_PREOP_FOCUSED}
              imageEmpty={pacuEmpty}
              highlightReady
              patientsCount={pacu?.patients?.length || 0}
              showEmptyContent
              showPacuNote={hasNoteModule}
              showBed={true}
              messagingView={messagingView}
            />
          )}
          {hasPostop &&
            postOps &&
            Array.isArray(postOps) &&
            postOps
              .filter(postOp => postOp.id)
              .map(postOp => (
                <RoomPanelSlots
                  ket={postOp.id}
                  room={postOp}
                  hospitalId={hospitalId}
                  focused={true}
                  roomType={POST_OP}
                  panelNumber={POSTOP_ROOM_PANEL_NUMBER}
                  carouselSize={CAROUSEL_SIZE_PREOP_FOCUSED}
                  screenType={INTERNAL_MONITOR}
                  name={postOp?.name}
                  patientsCount={postOp?.patients?.length || 0}
                  imageEmpty={pacuEmpty}
                  showBed={true}
                  highlightReady
                  showEmptyContent
                  messagingView={messagingView}
                />
              ))}
        </>
      </PanelsStatus>
    );
  }

  return (
    <PanelsStatus miniView={isMini} moreSpace={messagingView}>
      <>
        {waitingRoom && waitingRoom.id && (
          <RoomPanelSlots
            room={waitingRoom}
            hospitalId={hospitalId}
            focused={false}
            screenType={INTERNAL_MONITOR}
            panelNumber={1}
            carouselSize={messagingView ? 10 : carouselSize || 5}
            imageEmpty={waitingRoomEmpty}
            messagingView={messagingView}
          />
        )}
        {preOp && preOp.id && (
          <RoomPanelSlots
            room={preOp}
            hospitalId={hospitalId}
            focused={false}
            roomType={PRE_OP}
            screenType={INTERNAL_MONITOR}
            panelNumber={1}
            carouselSize={messagingView ? 10 : carouselSize || 5}
            imageEmpty={preopEmpty}
            hasPreOpPriorityModule={hasPreOpPriorityModule}
            hasNoteModule={hasNoteModule}
            messagingView={messagingView}
          />
        )}
      </>
      <>
        {pacu && pacu.id && (
          <PanelSlots
            {...pacu}
            name={pacu?.name}
            roomType={PACU}
            patientsCount={pacu?.patients?.length || 0}
            screenType={INTERNAL_MONITOR}
            image={pacuEmpty}
            showBed={true}
            highlightReady
            showEmptyContent
            showPacuNote={hasNoteModule}
            messagingView={messagingView}
            carouselSize={messagingView ? 10 : 5}
          />
        )}

        {hasPostop &&
          postOps &&
          Array.isArray(postOps) &&
          postOps
            .filter(postOp => postOp.id)
            .map(postOp => (
              <PanelSlots
                key={postOp.id}
                {...postOp}
                name={postOp?.name}
                patientsCount={postOp?.patients?.length || 0}
                roomType={POST_OP}
                screenType={INTERNAL_MONITOR}
                image={pacuEmpty}
                showBed={true}
                highlightReady
                showEmptyContent
                messagingView={messagingView}
                carouselSize={messagingView ? 10 : 5}
              />
            ))}
      </>
    </PanelsStatus>
  );
};

export default BottomPanelStatus;
