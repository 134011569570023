import React, { useEffect, useMemo } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  Checkbox,
  createStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  useTheme,
} from '@material-ui/core';
import { AccessoriesTableAnswer, OperativeExtremityTableAnswer } from '../../../../types/Answer';
import { alpha, withStyles } from '@material-ui/core/styles';
import { RadioButtonUncheckedTwoTone } from '@material-ui/icons';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useSave } from './SaveProvider';
import { useSlideIn } from '../../../../form/Form';
import Box from '@material-ui/core/Box';

interface OperativeExtremityTableProps {
  value?: OperativeExtremityTableAnswer;
  onChange: (value: OperativeExtremityTableAnswer) => void;
}

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
    },
    root: {
      '&:nth-of-type(odd)': {
        // backgroundColor: theme.palette.action.hover,
      },
      '&:last-of-type': {
        // backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      borderRight: `2px solid ${theme.palette.divider}`,
      '&:last-of-type': {
        borderRight: 'none',
      },
    },
    body: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      borderRight: `2px solid ${theme.palette.divider}`,
      borderBottom: 'none',
      '&:first-of-type': {
        // borderRight: 0,
      },
      '&:last-of-type': {
        borderRight: 'none',
      },
    },
  })
)(TableCell);

type Key =
  | 'skinBreakdown'
  | 'colorWNL'
  | 'motorFunctionWNL'
  | 'sensationWNL'
  | 'edema'
  | 'capillaryRefill'
  | 'ptPulseStrength'
  | 'dpPulseStrength';

const rows: { name: Key; label: string }[] = [
  {
    name: 'skinBreakdown',
    label: 'Skin Breakdown',
  },
  {
    name: 'colorWNL',
    label: 'Color WNL',
  },
  {
    name: 'motorFunctionWNL',
    label: 'Motor Function WNL',
  },
  {
    name: 'sensationWNL',
    label: 'Sensation WNL',
  },
  {
    name: 'edema',
    label: 'Edema',
  },
];

const additionalRows: { name: Key; label: string }[] = [
  {
    name: 'capillaryRefill',
    label: 'Capillary Refill',
  },
  {
    name: 'ptPulseStrength',
    label: 'PT Pulse Strength',
  },
  {
    name: 'dpPulseStrength',
    label: 'DP Pulse Strength',
  },
];

const options = ['+1', '+2', '+3', 'absent'];

const isCompleted = (value: OperativeExtremityTableAnswer) =>
  [...rows, ...additionalRows].every(row => value[row?.name]?.value !== null && value[row?.name]?.value !== undefined);

const isCompletedRow = (key: Key, value: OperativeExtremityTableAnswer) =>
  value[key]?.value !== null && value[key]?.value !== undefined;

const OperativeExtremityTable = ({ value: answer, onChange }: OperativeExtremityTableProps) => {
  const { validationRequest } = useSave();
  const classes = useStyles();
  const slideIn = useSlideIn();
  const theme = useTheme();

  const value = useMemo(
    () =>
      answer || {
        skinBreakdown: {},
        colorWNL: {},
        motorFunctionWNL: {},
        sensationWNL: {},
        edema: {},
        capillaryRefill: {},
        ptPulseStrength: {},
        dpPulseStrength: {},
      },
    [answer]
  );

  useEffect(() => {
    if (validationRequest) {
      if (!value || !isCompleted(value)) {
        slideIn();
      }
    }
  }, [validationRequest, value, slideIn]);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <StyledTableRow className={classes.mainRow}>
            <StyledTableCell>Operative Extremity</StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell>Comments</StyledTableCell>
          </StyledTableRow>
        </TableHead>

        <TableBody>
          {rows.map((row, i) => (
            <StyledTableRow className={classes.mainRow}>
              <StyledTableCell
                padding="none"
                rowSpan={1}
                style={{
                  color: validationRequest
                    ? isCompletedRow(row?.name, value)
                      ? 'inherit'
                      : theme.palette.error.light
                    : 'inherit',
                }}
              >
                {row?.label}
              </StyledTableCell>

              <StyledTableCell style={{ textAlign: 'center' }}>
                <Box display="flex" flexDirection="row" style={{ gap: '0.5em' }}>
                  <BlueCheckbox
                    label="Y"
                    checked={value?.[row.name]?.value === true}
                    onChange={() =>
                      onChange({
                        ...value,
                        [row.name]: {
                          ...value[row.name],
                          value: true,
                        },
                      })
                    }
                  />
                  <BlueCheckbox
                    label="N"
                    checked={value?.[row.name]?.value === false}
                    onChange={() =>
                      onChange({
                        ...value,
                        [row.name]: {
                          ...value[row.name],
                          value: false,
                        },
                      })
                    }
                  />
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <TextField
                  label="Comments"
                  value={value?.[row.name]?.comment}
                  onChange={e =>
                    onChange({
                      ...value,
                      [row.name]: {
                        ...value[row.name],
                        comment: e.target.value,
                      },
                    })
                  }
                />
              </StyledTableCell>
            </StyledTableRow>
          ))}
          <StyledTableRow className={classes.mainRow}>
            <StyledTableCell
              padding="none"
              rowSpan={1}
              style={{
                color: validationRequest
                  ? isCompletedRow(additionalRows[0]?.name, value)
                    ? 'inherit'
                    : theme.palette.error.light
                  : 'inherit',
              }}
            >
              {additionalRows[0]?.label}
            </StyledTableCell>

            <StyledTableCell style={{ textAlign: 'center' }}>
              <Box display="flex" flexDirection="row" style={{ gap: '0.5em' }}>
                <BlueCheckbox
                  label="<3 sec"
                  checked={value?.[additionalRows[0]?.name]?.value === 'below3sec'}
                  onChange={() =>
                    onChange({
                      ...value,
                      [additionalRows[0]?.name]: {
                        ...value[additionalRows[0]?.name],
                        value: 'below3sec',
                      },
                    })
                  }
                />
                <BlueCheckbox
                  label=">3 sec"
                  checked={value?.[additionalRows[0]?.name]?.value === 'above3sec'}
                  onChange={() =>
                    onChange({
                      ...value,
                      [additionalRows[0]?.name]: {
                        ...value[additionalRows[0]?.name],
                        value: 'above3sec',
                      },
                    })
                  }
                />
              </Box>
            </StyledTableCell>
            <StyledTableCell>
              <TextField
                label="Comments"
                value={value?.[additionalRows[0]?.name]?.comment}
                onChange={e =>
                  onChange({
                    ...value,
                    [additionalRows[0]?.name]: {
                      ...value[additionalRows[0]?.name],
                      comment: e.target.value,
                    },
                  })
                }
              />
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow className={classes.mainRow}>
            <StyledTableCell
              padding="none"
              rowSpan={1}
              style={{
                color: validationRequest
                  ? isCompletedRow(additionalRows[1]?.name, value)
                    ? 'inherit'
                    : theme.palette.error.light
                  : 'inherit',
              }}
            >
              {additionalRows[1]?.label}
            </StyledTableCell>

            <StyledTableCell style={{ textAlign: 'center' }}>
              <Box display="flex" flexDirection="row" style={{ gap: '0.5em' }}>
                {options.map(optionValue => (
                  <BlueCheckbox
                    label={optionValue}
                    checked={value?.[additionalRows[1].name]?.value === optionValue}
                    onChange={() =>
                      onChange({
                        ...value,
                        [additionalRows[1].name]: {
                          ...value[additionalRows[1].name],
                          value: optionValue,
                        },
                      })
                    }
                  />
                ))}
              </Box>
            </StyledTableCell>
            <StyledTableCell>
              <TextField
                label="Comments"
                value={value?.[additionalRows[1].name]?.comment}
                onChange={e =>
                  onChange({
                    ...value,
                    [additionalRows[1].name]: {
                      ...value[additionalRows[1].name],
                      comment: e.target.value,
                    },
                  })
                }
              />
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow className={classes.mainRow}>
            <StyledTableCell
              padding="none"
              rowSpan={1}
              style={{
                color: validationRequest
                  ? isCompletedRow(additionalRows[2]?.name, value)
                    ? 'inherit'
                    : theme.palette.error.light
                  : 'inherit',
              }}
            >
              {additionalRows[2]?.label}
            </StyledTableCell>

            <StyledTableCell style={{ textAlign: 'center' }}>
              <Box display="flex" flexDirection="row" style={{ gap: '0.5em' }}>
                {options.map(optionValue => (
                  <BlueCheckbox
                    label={optionValue}
                    checked={value?.[additionalRows[2].name]?.value === optionValue}
                    onChange={() =>
                      onChange({
                        ...value,
                        [additionalRows[2].name]: {
                          ...value[additionalRows[2].name],
                          value: optionValue,
                        },
                      })
                    }
                  />
                ))}
              </Box>
            </StyledTableCell>
            <StyledTableCell>
              <TextField
                label="Comments"
                value={value?.[additionalRows[2].name]?.comment}
                onChange={e =>
                  onChange({
                    ...value,
                    [additionalRows[2].name]: {
                      ...value[additionalRows[2].name],
                      comment: e.target.value,
                    },
                  })
                }
              />
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const useStyles = makeStyles(theme => ({
  mainRow: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
}));

export default OperativeExtremityTable;

const BlueCheckbox = withStyles({
  root: {
    color: 'rgba(0, 0, 0, 0.54)',
    '&$checked': {
      color: 'rgb(25, 118, 210)',
    },
  },
  checked: {},
})((props: any) => (
  <>
    <Checkbox
      icon={<RadioButtonUncheckedTwoTone color="primary" />}
      checkedIcon={<CheckCircleIcon color="primary" />}
      {...props}
      style={{ padding: 0 }}
    />
    {props?.label}
  </>
));
