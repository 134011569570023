import React from 'react';
import styled from 'styled-components';
import { Text } from '../../../se/components/typography';
import { resendFamilyPageSms } from '../../../graph/patients';
import { ResendSms } from '../patient/columns';
import { useQuery } from '@apollo/client';
import { getNotificationTemplate } from '../../../graph/notificationTemplates';
import { CaretakerChatDialog } from '../../chat/ChatDialog';
import ChatButton from '../../chat/ChatButton';
import { Box } from '@material-ui/core';
import { SendingSetting } from '../notificationTemplate/NotificationTemplates';

const TextWithAction = styled.div`
  display: flex;
  align-items: baseline;
  flex-direction: column;

  @media (min-width: 1400px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const NotificationActionButton = ({
  data: { number, patientId, active, status, error, lastEventAt, consent, patient },
}) => {
  const { data: notifications } = useQuery(getNotificationTemplate, { variables: { trigger: 'PatientAdmitted' } });
  const notificationData = notifications?.getNotificationTemplate[0] || null;

  return (
    <TextWithAction>
      <Text wrap="true">{number || '-'}</Text>
      {number && (active || consent) && (
        <Box display="flex">
          {active && (
            <ResendSms
              mutation={resendFamilyPageSms}
              variables={{ patientId }}
              tooltip={
                !notificationData || !(notificationData?.sendingSetting !== SendingSetting.Off)
                  ? 'Caretaker messaging has been turned off. Go to message configuration to enable it.'
                  : consent
                    ? 'This will resend the SMS containing a link to the patient status tracking screen.'
                    : `Caretaker doesn‘t want to receive SMS`
              }
              status={status}
              error={error}
              lastEventAt={lastEventAt}
              disabled={!consent || !(notificationData?.sendingSetting !== SendingSetting.Off)}
            />
          )}
          {consent && (
            <Box ml={1}>
              <ChatButton
                hasUnreadMessages={patient?.caretakerThread?.hasUnreadMessages}
                chatComponent={CaretakerChatDialog}
                chatProps={{
                  patient,
                }}
              />
            </Box>
          )}
        </Box>
      )}
    </TextWithAction>
  );
};

export default NotificationActionButton;
