import React from 'react';
import styled from 'styled-components';
import { H3, TruncatedText } from '../../se/components/typography';
import responsive from '../../se/utilities/responsive';
import {
  Box,
  Breadcrumbs,
  CircularProgress,
  fade,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';
import { formatRelative } from 'date-fns-3/formatRelative';
import { Link as RouterLink } from 'react-router-dom';
import {
  ANALYTICS,
  CHARTING_TABLET,
  EXIT_DISPLAY,
  INTERNAL_MONITOR,
  INTERNAL_MONITOR_LABELS,
  OPERATION_ROOM_MONITOR,
  PACU_TABLET,
  POST_OP_TABLET,
  PREP_TABLET,
  REVIEW_DISPLAY,
  SCHEDULE_MONITOR,
  WAITING_ROOM_MONITOR,
  WAITING_ROOM_TABLET,
} from '../entities/screens/enums';
import { ROOM_TYPE_LABELS } from '../entities/room/enums';
import { getNestedValue } from '../../se/utilities/data/object';
import { useTheme } from '@material-ui/core/styles';
import useSystemWideRecentlyOfflineScreens from './useSystemWideRecentlyOfflineScreens';

export const Title = styled(H3)`
  margin: 0;
  ${responsive.md.andSmaller`
    font-size: 1rem;
  `}
`;

interface ScreensProps extends ReturnType<typeof useSystemWideRecentlyOfflineScreens> {}

const Screens = ({ loading, data, error }: ScreensProps) => {
  const theme = useTheme();

  return (
    <Box>
      <Box mb={2}>
        <Breadcrumbs>
          <Link component={RouterLink} to="/dashboard" color="inherit">
            Dashboard
          </Link>
          <Typography color="textPrimary">Screens</Typography>
        </Breadcrumbs>
      </Box>
      <Paper>
        <Box p={2}>
          <Title>Screens</Title>
          {loading ? (
            <CircularProgress />
          ) : error ? (
            <pre>{JSON.stringify(error)}</pre>
          ) : (
            <TableContainer>
              <Table>
                <TableBody>
                  {data!.systemWideRecentlyOfflineScreens.map(s => (
                    <TableRow key={s.id}>
                      <TableCell component="th" scope="row">
                        <Link component={RouterLink} to={`/su/${s.organization.id}/screens`}>
                          {s.organization.name}
                        </Link>
                      </TableCell>
                      <TableCell>{s.code}</TableCell>
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          <Box
                            style={{
                              width: '.875rem',
                              height: '.875rem',
                              borderRadius: '50%',
                              marginRight: theme.spacing(1),
                              backgroundColor: fade(theme.palette.text.disabled, 0.25),
                            }}
                          />
                          <TruncatedText>
                            {[
                              INTERNAL_MONITOR,
                              WAITING_ROOM_MONITOR,
                              SCHEDULE_MONITOR,
                              PACU_TABLET,
                              PREP_TABLET,
                              POST_OP_TABLET,
                              CHARTING_TABLET,
                              REVIEW_DISPLAY,
                              EXIT_DISPLAY,
                              WAITING_ROOM_TABLET,
                              ANALYTICS,
                            ].includes(s.type.type)
                              ? INTERNAL_MONITOR_LABELS[s.type.type]
                              : s.type.type === OPERATION_ROOM_MONITOR
                                ? getNestedValue('room.name', s.type) || ROOM_TYPE_LABELS.OR
                                : 'Unknown monitoring screen'}
                          </TruncatedText>
                        </Box>
                      </TableCell>
                      <TableCell>{s.description}</TableCell>
                      <TableCell>{s.clientVersion ?? 'Unknown'}</TableCell>
                      <TableCell align="right">since {formatRelative(new Date(s.lastSeen), new Date())}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default Screens;
