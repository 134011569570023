import { Procedure } from '../../../../../types/Procedure';
import React, { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { useScheduleUserContext } from './context/ScheduleUserContext';
import Button from '@material-ui/core/Button';
import { ScheduleUserType } from '../../../../../types/ScheduleUser';

const ScheduleUserButtons: FC<{ procedure: Procedure }> = ({ procedure }) => {
  const classes = useStyles();
  const {
    scheduleUserId,
    scheduleUserType,
    isAssignedProcedure,
    isAnsweredProcedure,
    isEditing,
    acceptProcedure,
    rejectProcedure,
  } = useScheduleUserContext();

  const isAssigned = isAssignedProcedure(procedure);
  const isAnswered = isAnsweredProcedure(procedure);

  const shiftId =
    scheduleUserType === ScheduleUserType.Vendor
      ? procedure?.assignedVendors?.find(e => e.vendor?.id === scheduleUserId)?.id || null
      : scheduleUserType === ScheduleUserType.StaffMember
        ? procedure?.staffShifts?.find(e => e.staff?.staffId === scheduleUserId)?.id || null
        : null;

  return (
    <>
      {((isAssigned && !isAnswered) || isEditing(shiftId ?? null, 'ProcedureShift')) && (
        <div className={classes.root}>
          <Button onClick={() => rejectProcedure(procedure?.id)} variant="outlined" color="primary" fullWidth>
            Reject
          </Button>
          <Button onClick={() => acceptProcedure(procedure?.id)} variant="contained" color="primary" fullWidth>
            Accept
          </Button>
        </div>
      )}
    </>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      gap: '1em',
      margin: '0.5em',
    },
  })
);

export default ScheduleUserButtons;
