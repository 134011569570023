import { Mutation } from '@apollo/client/react/components';
import React from 'react';
import { setReadyForAnesthesia } from '../../../../graph/patients';

const withSetReadyForAnesthesia = Component => props => (
  <Mutation mutation={setReadyForAnesthesia}>
    {setReadyForAnesthesia => <Component {...props} setReadyForAnesthesia={setReadyForAnesthesia} />}
  </Mutation>
);

export default withSetReadyForAnesthesia;
