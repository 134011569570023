import { Mutation } from '@apollo/client/react/components';
import React, { Fragment, useState } from 'react';
import { TruncatedText } from '../../../se/components/typography';
import { sortDate } from '../../../util/sort';
import { format, isBefore } from 'date-fns';
import PhoneInput, { formatPhoneNumber } from '../../../se/components/inputs/PhoneInput';
import get from 'lodash/get';
import { ResendSms } from '../patient/columns';
import { getLogEvents, smsInvitationCount } from '../procedures/events';
import { resendRegisterReadySMS } from '../../../graph/procedures';
import { eventTypes } from '../procedures/ProcedureEventLog';
import { Actions, idColumns } from '../procedures/columns';
import { first } from 'lodash';
import orderBy from 'lodash/orderBy';
import LinkButton from '../../../se/components/LinkButton';
import scheme from '../../../graph/procedures';
import { compose, withProps } from 'recompose';
import { withLabel } from '../../../se/components/Label';
import { withDescription } from '../../../se/components/Description';
import Form from '../../../se/components/Form';
import ObjectInput from '../../../se/components/inputs/ObjectInput';
import { RouterlessModal } from '../../../se/components/Modal';
import { ZonedDateTime } from '@js-joda/core';

export const PatientNameColumn = ({ Text = TruncatedText, data }) => <Text>{data.name}</Text>;
export const PatientDOBColumn = ({ Text = TruncatedText, data }) => <Text>{data.name}</Text>;

export const baseColumns = [
  {
    title: 'Date of Service',
    lens: data => data.startTime,
    Component: props => (
      <TruncatedText>
        {format(ZonedDateTime.parse(props.data).toLocalDateTime().toString(), 'MM/DD/YYYY HH:mm')}
      </TruncatedText>
    ),
    sortFunction: (l, r) => sortDate(l.startTime, r.startTime),
  },
  {
    title: 'Date of Birth',
    lens: data => data?.patientDateOfBirth,
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
    sortFunction: (l, r) => sortDate(l.patientDateOfBirth, r.patientDateOfBirth),
  },
  {
    title: 'Physician',
    lens: data => data.physician.name,
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
];

export const MobilePhoneNumberInput = compose(
  withLabel('Patient Mobile Phone Number'),
  withDescription('To add multiple phone numbers, separate them with a comma.'),
  withProps({ placeholder: 'Enter patient’s mobile phone number' })
)(PhoneInput);

const PhoneEditInput = withProps({
  schema: {
    patientCellPhone: MobilePhoneNumberInput,
  },
})(ObjectInput);

const PhoneEditForm = ({ id, patientCellPhone, onClose }) => (
  <RouterlessModal title={'Update Patient Cell Phone Number'} onClose={onClose}>
    <Mutation
      mutation={scheme.updateMobileNumber}
      refetchQueries={[{ query: scheme.item, variables: { id } }]}
      onCompleted={onClose}
    >
      {(mutate, { loading }) => (
        <Form
          autoFocus
          label={'Update'}
          input={PhoneEditInput}
          onSubmit={value => mutate({ variables: { ...value, id } })}
          busy={loading}
          initialValue={{ patientCellPhone }}
        />
      )}
    </Mutation>
  </RouterlessModal>
);

const PhoneEditModal = ({ id, patientCellPhone }) => {
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      {open && <PhoneEditForm id={id} patientCellPhone={patientCellPhone} onClose={() => setOpen(false)} />}
      <LinkButton onClick={() => setOpen(true)}>
        {patientCellPhone ? formatPhoneNumber(patientCellPhone) : 'Enter Phone Number'}
      </LinkButton>
    </Fragment>
  );
};

const patientMobileColumn = {
  title: 'Mobile',
  lens: data => data,
  Component: ({ data: { id, patientCellPhone } }) => <PhoneEditModal id={id} patientCellPhone={patientCellPhone} />,
};

const ResendActions =
  ({
    resendInvitationSMS,
    smsTooltip,
    templateName,
    shouldWarnProvider,
    variablesProvider,
    invitationsProvider,
    smsLabel,
    patientNameProvider,
    patientMobilePhoneNumberProvider,
  }) =>
  ({ data, preSubmit }) => {
    const invitations = invitationsProvider(data);
    const shouldWarn = shouldWarnProvider(data);
    const patientName = patientNameProvider(data);
    const patientMobilePhoneNumber = patientMobilePhoneNumberProvider(data);
    const lastEvent = first(orderBy(invitations, 'timestamp', 'desc'));
    return data ? (
      <Actions
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <ResendSms
          disabled={
            !data.patientCellPhone ||
            isBefore(get(data, 'startTime'), new Date()) ||
            !data?.readyToRegisterNotificationEnabled
          }
          disabledTooltip={
            !data.patientCellPhone
              ? 'No Patient Cell Phone'
              : !data?.readyToRegisterNotificationEnabled
                ? 'Patient messaging has been turned off. Go to message configuration to enable it.'
                : 'Procedure is completed'
          }
          mutation={resendInvitationSMS}
          variables={variablesProvider(data)}
          // options={{ refetchQueries: [{ query: procedures.item, variables: { id: data.id } }] }}
          tooltip={smsTooltip}
          timesSent={smsInvitationCount(invitations)}
          shouldWarn={shouldWarn}
          label={smsLabel}
          preSubmit={preSubmit}
          status={get(lastEvent, 'status')}
          error={[get(lastEvent, 'content'), get(lastEvent, 'text')].filter(_ => !!_).join(' - ')}
          templateName={templateName}
          patientName={patientName}
          patientMobilePhoneNumber={patientMobilePhoneNumber}
        />
      </Actions>
    ) : null;
  };

const ResendActionsQuestionnaire = ResendActions({
  resendInvitationSMS: resendRegisterReadySMS,
  smsTooltip: 'This will resend the SMS inviting patient to Front Desk.',
  templateName: '“Ready To Register” SMS',
  shouldWarnProvider: () => false,
  variablesProvider: data => ({ procedureId: data.id }),
  invitationsProvider: getLogEvents(eventTypes.MessageDeliveryReadyToRegister),
  patientNameProvider: data => data?.patientName || '',
  patientMobilePhoneNumberProvider: data => data?.patientCellPhone || '',
});

const arrivedColumn = {
  title: 'Arrived',
  lens: data => ({ arrivedAt: data.arrivedAt }),
  Component: ({ data }) =>
    data.arrivedAt ? (
      <TruncatedText>
        {format(ZonedDateTime.parse(data.arrivedAt).toLocalDateTime().toString(), 'MM/DD/YYYY HH:mm')}
      </TruncatedText>
    ) : null,
  sortFunction: (l, r) => sortDate(l.arrivedAt, r.arrivedAt),
};

const actionsColumn = {
  title: 'Actions',
  lens: data => data,
  Component: ResendActionsQuestionnaire,
};

export const listColumns = [...idColumns, ...baseColumns, patientMobileColumn, arrivedColumn, actionsColumn];
