import React from 'react';
import { PrimarySpecialty, PrimarySpecialtyColors, PrimarySpecialtyLabel } from '../../../../../types/StaffMember';
import { Box, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

interface PrimarySpecialtyPickerProps {
  value: PrimarySpecialty | null;
  onChange: (value: PrimarySpecialty | null) => void;
}

const PrimarySpecialtyPicker = ({ value, onChange }: PrimarySpecialtyPickerProps) => (
  <Box mx={2} my={1} style={{ zoom: 0.75 }}>
    <RadioGroup
      row
      aria-label="position"
      name="position"
      value={value === null ? '__null__' : value}
      onChange={(_, value) => onChange(value === '__null__' ? null : (value as PrimarySpecialty))}
    >
      {Object.values(PrimarySpecialty).map(specialty => (
        <FormControlLabel
          key={specialty}
          value={specialty}
          control={<Radio style={{ color: PrimarySpecialtyColors[specialty] }} />}
          label={PrimarySpecialtyLabel[specialty]}
        />
      ))}
      <FormControlLabel key="__null__" value="__null__" control={<Radio />} label="Other" />
    </RadioGroup>
  </Box>
);

export default PrimarySpecialtyPicker;
