import { TruncatedText } from '../../../se/components/typography';
import React from 'react';
import { sortNumber } from '../../../util/sort';
import { format } from 'date-fns';
import get from 'lodash/get';
import { formatPhoneNumber } from '../../../se/components/inputs/PhoneInput';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ZonedDateTime } from '@js-joda/core';
import { NotesStatus } from '../surgeonOffice/columns';
import secretValue from '../../../util/secretValue';

const idColumn = {
  title: '#',
  lens: data => data.id,
  sortFunction: (l, r) => sortNumber(l.id, r.id),
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

const requestFrom = {
  title: 'Request From',
  lens: data => get(data, 'fromOrg.name'),
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

const physicianColumn = {
  title: 'Physician',
  lens: data => get(data, 'payload.physician.name'),
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

const sexColumn = {
  title: 'Sex',
  lens: data => get(data, 'payload.patient.sex'),
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

const dateOfBirthColumn = {
  title: 'Date of Birth',
  lens: data => get(data, 'payload.patient.dateOfBirth'),
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

const nameColumn = {
  title: 'Patient Name',
  lens: data => ({
    patientName: get(data, 'payload.patient.name'),
  }),
  Component: props => <TruncatedText>{props.data.patientName}</TruncatedText>,
};

const dateColumn = {
  title: 'Date of Service',
  lens: data => data.forDate,
  Component: props => (
    <TruncatedText>{`${format(
      ZonedDateTime.parse(props.data).toLocalDateTime().toString(),
      'MM/DD/YYYY @ HH:mm'
    )}`}</TruncatedText>
  ),
};

const phoneColumn = {
  title: 'Phone Number',
  lens: data => get(data, 'payload.patient.cellPhone'),
  Component: props => (
    <TruncatedText>{props.data !== secretValue ? formatPhoneNumber(props.data) : secretValue}</TruncatedText>
  ),
};

const useStyles = makeStyles(theme => ({
  warning: {
    backgroundColor: theme.palette.warning.dark,
    color: theme.palette.getContrastText(theme.palette.warning.dark),
  },
  info: {
    backgroundColor: theme.palette.info.dark,
    color: theme.palette.getContrastText(theme.palette.info.dark),
  },
}));

const stateColumn = {
  title: 'Status',
  lens: data => ({
    status: data.state ? JSON.parse(data.state) : null,
  }),
  Component: ({ data: { status } }) => (
    <Chip
      label={
        status?.__typename === 'Requested'
          ? 'To be Scheduled'
          : status?.__typename === 'Confirmed'
            ? 'Case Scheduled'
            : status?.__typename === 'Rescheduled'
              ? 'Surgery Rescheduled'
              : status?.__typename === 'Cancelled'
                ? 'Surgery Canceled'
                : status?.__typename || 'Unknown'
      }
      size="small"
    />
  ),
};

const alertColumn = {
  title: 'Alert',
  lens: data => ({
    state: JSON.parse(data.state),
    issues: data?.issues || [],
    hasUnseenChangeListItem: data?.hasUnseenChange || false,
    notesStatus: data?.notesStatus,
  }),
  Component: ({ data: { state, hasUnseenChangeListItem, issues, notesStatus } }) => {
    const classes = useStyles();
    const isNotCanceled = state?.__typename !== 'Cancelled';
    const isNewNote = isNotCanceled && notesStatus === NotesStatus.NewNoteFromSO;
    const isNew = state?.__typename === 'Requested' && !state?.seen;
    const needDocs = issues.some(issue => JSON.parse(issue.state).__typename === 'Requested');
    const docsUpdated = issues.some(issue => JSON.parse(issue.state).__typename === 'Resolved');
    const documentsStatus = isNotCanceled && (needDocs || docsUpdated);
    const documentsStatusLabel = docsUpdated ? 'Docs Updated' : needDocs ? 'Missing Docs' : '';

    if (isNew) return <Chip label="New" size="small" />;

    if (documentsStatus) {
      return (
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <Chip
            label={documentsStatusLabel}
            className={clsx({
              [classes.warning]: needDocs && !docsUpdated,
              [classes.info]: docsUpdated,
            })}
            size="small"
          />
          {isNewNote && (
            <Box ml={1}>
              <Chip label="Note" size="small" />
            </Box>
          )}
        </Box>
      );
    }

    if (isNotCanceled) {
      return (
        <Box display="flex" justifyContent="center">
          {hasUnseenChangeListItem && <Chip label="Updated" size="small" />}
          {isNewNote && (
            <Box ml={1}>
              <Chip label="Note" size="small" />
            </Box>
          )}
        </Box>
      );
    }

    return null;
  },
};

export const listColumns = [
  idColumn,
  requestFrom,
  physicianColumn,
  nameColumn,
  dateColumn,
  dateOfBirthColumn,
  sexColumn,
  phoneColumn,
  stateColumn,
  alertColumn,
];

export const viewColumns = [
  idColumn,
  requestFrom,
  physicianColumn,
  nameColumn,
  dateColumn,
  dateOfBirthColumn,
  sexColumn,
  phoneColumn,
];
