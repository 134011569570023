import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTheme } from '@material-ui/core';
import { PostAnesthesiaRecoveryScoreAnswer } from '../../../../types/Answer';
import produce from 'immer';
import { useSave } from './SaveProvider';
import { useSlideIn } from '../../../../form/Form';

interface PostAnesthesiaRecoveryScoreProps {
  value?: PostAnesthesiaRecoveryScoreAnswer;
  onChange: (value: PostAnesthesiaRecoveryScoreAnswer) => void;
}

type ColumnKey = 'in5_1' | 'in5_2' | 'in5_3' | 'in15_1' | 'in15_2' | 'in30_1' | 'in30_2' | 'in60_1' | 'in60_2';
const columns = ['5', '5', '5', '15', '15', '30', '30', '60', '60'];
const columnsKeys: ColumnKey[] = [
  'in5_1',
  'in5_2',
  'in5_3',
  'in15_1',
  'in15_2',
  'in30_1',
  'in30_2',
  'in60_1',
  'in60_2',
];

const calcTotal = (
  value: PostAnesthesiaRecoveryScoreAnswer,
  field: ColumnKey,
  prevFieldValue?: 0 | 1 | 2,
  nextFieldValue?: 0 | 1 | 2
) => {
  const oldTotal =
    (value?.[field]?.resp || 0) +
    (value?.[field]?.circ || 0) +
    (value?.[field]?.activity || 0) +
    (value?.[field]?.alertness || 0) +
    (value?.[field]?.color || 0);
  return (oldTotal || 0) - (prevFieldValue || 0) + (nextFieldValue || 0);
};

const isCompletedColumn = (key: ColumnKey, value: PostAnesthesiaRecoveryScoreAnswer) =>
  ['resp', 'circ', 'activity', 'alertness', 'color'].every(
    j => value?.[key]?.[j] !== undefined && value?.[key]?.[j] !== null
  );

const PostAnesthesiaRecoveryScoreV1 = ({ value: answer, onChange }: PostAnesthesiaRecoveryScoreProps) => {
  const classes = useStyles();

  const theme = useTheme();

  const { validationRequest } = useSave();
  const slideIn = useSlideIn();

  const value = useMemo(
    () =>
      answer || {
        in5_1: {},
        in5_2: {},
        in5_3: {},
        in15_1: {},
        in15_2: {},
        in30_1: {},
        in30_2: {},
        in60_1: {},
        in60_2: {},
      },
    [answer]
  );

  useEffect(() => {
    if (validationRequest) {
      if (!value) {
        slideIn();
      }
    }
  }, [validationRequest, value, slideIn]);

  return (
    <div className={classes.centered}>
      <div className={classes.root}>
        <>
          {columnsKeys.map((e, i) => (
            <div
              style={{
                gridRow: '1 / span 23',
                gridColumn: `${i + 3} / span 1`,
                borderRadius: '0.5rem',
                backgroundColor: validationRequest
                  ? isCompletedColumn(columnsKeys[i], value)
                    ? theme.palette.secondary.light
                    : theme.palette.error.light
                  : theme.palette.secondary.light,
              }}
            />
          ))}
        </>

        <>
          <div
            style={{
              gridRow: '1 / span 1',
              gridColumn: '1 / span 2',
              textAlign: 'center',
              marginTop: '0.4rem',
              fontSize: '23px',
              fontWeight: 500,
            }}
          >
            Post Anesthesia Score
          </div>
          {columns.map((e, i) => (
            <div
              style={{
                gridRow: '1 / span 1',
                gridColumn: `${i + 3} / span 1`,
                textAlign: 'center',
                marginTop: '0.4rem',
                fontSize: '23px',
                fontWeight: 500,
              }}
            >
              {e}
            </div>
          ))}
        </>
        <div style={{ gridRow: `2 / span 1`, gridColumn: '1 / span 11', height: '0.5rem', marginTop: '8px' }}>
          <div style={{ backgroundColor: '#1976D2', width: '100%', height: '1px' }} />
        </div>
        <Aspect
          row={3}
          title="Resp:"
          two="Spont. Resp., no support"
          one="Spont. Resp., artificial airway"
          zero="Assisted Ventilation"
          value={{
            in5_1: value.in5_1.resp,
            in5_2: value.in5_2.resp,
            in5_3: value.in5_3.resp,
            in15_1: value.in15_1.resp,
            in15_2: value.in15_2.resp,
            in30_1: value.in30_1.resp,
            in30_2: value.in30_2.resp,
            in60_1: value.in60_1.resp,
            in60_2: value.in60_2.resp,
          }}
          onChange={v =>
            onChange(
              produce(value, draft => {
                draft.in5_1.resp = v.in5_1;
                draft.in5_2.resp = v.in5_2;
                draft.in5_3.resp = v.in5_3;
                draft.in15_1.resp = v.in15_1;
                draft.in15_2.resp = v.in15_2;
                draft.in30_1.resp = v.in30_1;
                draft.in30_2.resp = v.in30_2;
                draft.in60_1.resp = v.in60_1;
                draft.in60_2.resp = v.in60_2;
                draft.in5_1.total = calcTotal(value, 'in5_1', value.in5_1.resp, v.in5_1);
                draft.in5_2.total = calcTotal(value, 'in5_2', value.in5_2.resp, v.in5_2);
                draft.in5_3.total = calcTotal(value, 'in5_3', value.in5_3.resp, v.in5_3);
                draft.in15_1.total = calcTotal(value, 'in15_1', value.in15_1.resp, v.in15_1);
                draft.in15_2.total = calcTotal(value, 'in15_2', value.in15_2.resp, v.in15_2);
                draft.in30_1.total = calcTotal(value, 'in30_1', value.in30_1.resp, v.in30_1);
                draft.in30_2.total = calcTotal(value, 'in30_2', value.in30_2.resp, v.in30_2);
                draft.in60_1.total = calcTotal(value, 'in60_1', value.in60_1.resp, v.in60_1);
                draft.in60_2.total = calcTotal(value, 'in60_2', value.in60_2.resp, v.in60_2);
              })
            )
          }
        />
        <Aspect
          row={7}
          title="Circ:"
          two="BP +/- 30mm Hg of pre-op"
          one="BP+/- 31-50mm Hg of pre-op"
          zero="BP > 51 mm Hg of pre-op"
          value={{
            in5_1: value.in5_1.circ,
            in5_2: value.in5_2.circ,
            in5_3: value.in5_3.circ,
            in15_1: value.in15_1.circ,
            in15_2: value.in15_2.circ,
            in30_1: value.in30_1.circ,
            in30_2: value.in30_2.circ,
            in60_1: value.in60_1.circ,
            in60_2: value.in60_2.circ,
          }}
          onChange={v =>
            onChange(
              produce(value, draft => {
                draft.in5_1.circ = v.in5_1;
                draft.in5_2.circ = v.in5_2;
                draft.in5_3.circ = v.in5_3;
                draft.in15_1.circ = v.in15_1;
                draft.in15_2.circ = v.in15_2;
                draft.in30_1.circ = v.in30_1;
                draft.in30_2.circ = v.in30_2;
                draft.in60_1.circ = v.in60_1;
                draft.in60_2.circ = v.in60_2;
                draft.in5_1.total = calcTotal(value, 'in5_1', value.in5_1.circ, v.in5_1);
                draft.in5_2.total = calcTotal(value, 'in5_2', value.in5_2.circ, v.in5_2);
                draft.in5_3.total = calcTotal(value, 'in5_3', value.in5_3.circ, v.in5_3);
                draft.in15_1.total = calcTotal(value, 'in15_1', value.in15_1.circ, v.in15_1);
                draft.in15_2.total = calcTotal(value, 'in15_2', value.in15_2.circ, v.in15_2);
                draft.in30_1.total = calcTotal(value, 'in30_1', value.in30_1.circ, v.in30_1);
                draft.in30_2.total = calcTotal(value, 'in30_2', value.in30_2.circ, v.in30_2);
                draft.in60_1.total = calcTotal(value, 'in60_1', value.in60_1.circ, v.in60_1);
                draft.in60_2.total = calcTotal(value, 'in60_2', value.in60_2.circ, v.in60_2);
              })
            )
          }
        />
        <Aspect
          row={11}
          title="Activity:"
          two="Able to move 4 extremities"
          one="Able to move 2 extremities"
          zero="Able to move 0 extremities"
          value={{
            in5_1: value.in5_1.activity,
            in5_2: value.in5_2.activity,
            in5_3: value.in5_3.activity,
            in15_1: value.in15_1.activity,
            in15_2: value.in15_2.activity,
            in30_1: value.in30_1.activity,
            in30_2: value.in30_2.activity,
            in60_1: value.in60_1.activity,
            in60_2: value.in60_2.activity,
          }}
          onChange={v =>
            onChange(
              produce(value, draft => {
                draft.in5_1.activity = v.in5_1;
                draft.in5_2.activity = v.in5_2;
                draft.in5_3.activity = v.in5_3;
                draft.in15_1.activity = v.in15_1;
                draft.in15_2.activity = v.in15_2;
                draft.in30_1.activity = v.in30_1;
                draft.in30_2.activity = v.in30_2;
                draft.in60_1.activity = v.in60_1;
                draft.in60_2.activity = v.in60_2;
                draft.in5_1.total = calcTotal(value, 'in5_1', value.in5_1.activity, v.in5_1);
                draft.in5_2.total = calcTotal(value, 'in5_2', value.in5_2.activity, v.in5_2);
                draft.in5_3.total = calcTotal(value, 'in5_3', value.in5_3.activity, v.in5_3);
                draft.in15_1.total = calcTotal(value, 'in15_1', value.in15_1.activity, v.in15_1);
                draft.in15_2.total = calcTotal(value, 'in15_2', value.in15_2.activity, v.in15_2);
                draft.in30_1.total = calcTotal(value, 'in30_1', value.in30_1.activity, v.in30_1);
                draft.in30_2.total = calcTotal(value, 'in30_2', value.in30_2.activity, v.in30_2);
                draft.in60_1.total = calcTotal(value, 'in60_1', value.in60_1.activity, v.in60_1);
                draft.in60_2.total = calcTotal(value, 'in60_2', value.in60_2.activity, v.in60_2);
              })
            )
          }
        />
        <Aspect
          row={15}
          title="Alertness:"
          two="Awake, rarely dozes"
          one="Arouses to stimulation"
          zero="No response to stimulation"
          value={{
            in5_1: value.in5_1.alertness,
            in5_2: value.in5_2.alertness,
            in5_3: value.in5_3.alertness,
            in15_1: value.in15_1.alertness,
            in15_2: value.in15_2.alertness,
            in30_1: value.in30_1.alertness,
            in30_2: value.in30_2.alertness,
            in60_1: value.in60_1.alertness,
            in60_2: value.in60_2.alertness,
          }}
          onChange={v =>
            onChange(
              produce(value, draft => {
                draft.in5_1.alertness = v.in5_1;
                draft.in5_2.alertness = v.in5_2;
                draft.in5_3.alertness = v.in5_3;
                draft.in15_1.alertness = v.in15_1;
                draft.in15_2.alertness = v.in15_2;
                draft.in30_1.alertness = v.in30_1;
                draft.in30_2.alertness = v.in30_2;
                draft.in60_1.alertness = v.in60_1;
                draft.in60_2.alertness = v.in60_2;
                draft.in5_1.total = calcTotal(value, 'in5_1', value.in5_1.alertness, v.in5_1);
                draft.in5_2.total = calcTotal(value, 'in5_2', value.in5_2.alertness, v.in5_2);
                draft.in5_3.total = calcTotal(value, 'in5_3', value.in5_3.alertness, v.in5_3);
                draft.in15_1.total = calcTotal(value, 'in15_1', value.in15_1.alertness, v.in15_1);
                draft.in15_2.total = calcTotal(value, 'in15_2', value.in15_2.alertness, v.in15_2);
                draft.in30_1.total = calcTotal(value, 'in30_1', value.in30_1.alertness, v.in30_1);
                draft.in30_2.total = calcTotal(value, 'in30_2', value.in30_2.alertness, v.in30_2);
                draft.in60_1.total = calcTotal(value, 'in60_1', value.in60_1.alertness, v.in60_1);
                draft.in60_2.total = calcTotal(value, 'in60_2', value.in60_2.alertness, v.in60_2);
              })
            )
          }
        />
        <Aspect
          row={19}
          title="Color:"
          two="Normal Skin / Extremity Color"
          one="Pale, Dusky, Flushed"
          zero="Cyanotic"
          value={{
            in5_1: value.in5_1.color,
            in5_2: value.in5_2.color,
            in5_3: value.in5_3.color,
            in15_1: value.in15_1.color,
            in15_2: value.in15_2.color,
            in30_1: value.in30_1.color,
            in30_2: value.in30_2.color,
            in60_1: value.in60_1.color,
            in60_2: value.in60_2.color,
          }}
          onChange={v =>
            onChange(
              produce(value, draft => {
                draft.in5_1.color = v.in5_1;
                draft.in5_2.color = v.in5_2;
                draft.in5_3.color = v.in5_3;
                draft.in15_1.color = v.in15_1;
                draft.in15_2.color = v.in15_2;
                draft.in30_1.color = v.in30_1;
                draft.in30_2.color = v.in30_2;
                draft.in60_1.color = v.in60_1;
                draft.in60_2.color = v.in60_2;
                draft.in5_1.total = calcTotal(value, 'in5_1', value.in5_1.color, v.in5_1);
                draft.in5_2.total = calcTotal(value, 'in5_2', value.in5_2.color, v.in5_2);
                draft.in5_3.total = calcTotal(value, 'in5_3', value.in5_3.color, v.in5_3);
                draft.in15_1.total = calcTotal(value, 'in15_1', value.in15_1.color, v.in15_1);
                draft.in15_2.total = calcTotal(value, 'in15_2', value.in15_2.color, v.in15_2);
                draft.in30_1.total = calcTotal(value, 'in30_1', value.in30_1.color, v.in30_1);
                draft.in30_2.total = calcTotal(value, 'in30_2', value.in30_2.color, v.in30_2);
                draft.in60_1.total = calcTotal(value, 'in60_1', value.in60_1.color, v.in60_1);
                draft.in60_2.total = calcTotal(value, 'in60_2', value.in60_2.color, v.in60_2);
              })
            )
          }
        />
        <>
          <div
            style={{
              gridRow: '23 / span 1',
              gridColumn: '1 / span 2',
              textAlign: 'right',
              marginBottom: '0.25rem',
              fontWeight: 500,
            }}
          >
            Total
          </div>

          {columnsKeys.map((e, i) => (
            <div
              style={{
                gridRow: '23 / span 1',
                gridColumn: `${3 + i} / span 1`,
                textAlign: 'center',
                marginBottom: '0.25rem',
                fontWeight: 500,
              }}
            >
              {calcTotal(value, e)}
            </div>
          ))}
        </>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  centered: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  root: {
    margin: 'auto',
    whiteSpace: 'nowrap',
    display: 'grid',
    gridTemplateColumns: 'min-content min-content repeat(5, 4rem)',
    fontSize: '14px',
    columnGap: '1rem',
    '& > div': {
      paddingTop: '0.25rem',
      paddingBottom: '0.25rem',
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
    },
  },
});

export default PostAnesthesiaRecoveryScoreV1;

interface AspectValue {
  in5_1?: 0 | 1 | 2;
  in5_2?: 0 | 1 | 2;
  in5_3?: 0 | 1 | 2;
  in15_1?: 0 | 1 | 2;
  in15_2?: 0 | 1 | 2;
  in30_1?: 0 | 1 | 2;
  in30_2?: 0 | 1 | 2;
  in60_1?: 0 | 1 | 2;
  in60_2?: 0 | 1 | 2;
}

interface AspectProps {
  row: number;
  title: string;
  two: string;
  one: string;
  zero: string;
  value: AspectValue;
  onChange: (value: AspectValue) => void;
  optionValue?: 0 | 1 | 2;
}

const AspectRow = ({ row, title, two, one, zero, value, optionValue, onChange }: AspectProps) => (
  <>
    <div
      style={{
        gridRow: `${row} / span 1`,
        gridColumn: '3 / span 1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Selection
        selected={value.in5_1 === optionValue}
        onClick={() =>
          onChange(
            produce(value, draft => {
              draft.in5_1 = draft.in5_1 === optionValue ? undefined : optionValue;
            })
          )
        }
      >
        {optionValue}
      </Selection>
    </div>
    <div
      style={{
        gridRow: `${row} / span 1`,
        gridColumn: '4 / span 1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Selection
        selected={value.in5_2 === optionValue}
        onClick={() =>
          onChange(
            produce(value, draft => {
              draft.in5_2 = draft.in5_2 === optionValue ? undefined : optionValue;
            })
          )
        }
      >
        {optionValue}
      </Selection>
    </div>
    <div
      style={{
        gridRow: `${row} / span 1`,
        gridColumn: '5 / span 1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Selection
        selected={value.in5_3 === optionValue}
        onClick={() =>
          onChange(
            produce(value, draft => {
              draft.in5_3 = draft.in5_3 === optionValue ? undefined : optionValue;
            })
          )
        }
      >
        {optionValue}
      </Selection>
    </div>
    <div
      style={{
        gridRow: `${row} / span 1`,
        gridColumn: '6 / span 1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Selection
        selected={value.in15_1 === optionValue}
        onClick={() =>
          onChange(
            produce(value, draft => {
              draft.in15_1 = draft.in15_1 === optionValue ? undefined : optionValue;
            })
          )
        }
      >
        {optionValue}
      </Selection>
    </div>
    <div
      style={{
        gridRow: `${row} / span 1`,
        gridColumn: '7 / span 1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Selection
        selected={value.in15_2 === optionValue}
        onClick={() =>
          onChange(
            produce(value, draft => {
              draft.in15_2 = draft.in15_2 === optionValue ? undefined : optionValue;
            })
          )
        }
      >
        {optionValue}
      </Selection>
    </div>
    <div
      style={{
        gridRow: `${row} / span 1`,
        gridColumn: '8 / span 1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Selection
        selected={value.in30_1 === optionValue}
        onClick={() =>
          onChange(
            produce(value, draft => {
              draft.in30_1 = draft.in30_1 === optionValue ? undefined : optionValue;
            })
          )
        }
      >
        {optionValue}
      </Selection>
    </div>
    <div
      style={{
        gridRow: `${row} / span 1`,
        gridColumn: '9 / span 1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Selection
        selected={value.in30_2 === optionValue}
        onClick={() =>
          onChange(
            produce(value, draft => {
              draft.in30_2 = draft.in30_2 === optionValue ? undefined : optionValue;
            })
          )
        }
      >
        {optionValue}
      </Selection>
    </div>
    <div
      style={{
        gridRow: `${row} / span 1`,
        gridColumn: '10 / span 1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Selection
        selected={value.in60_1 === optionValue}
        onClick={() =>
          onChange(
            produce(value, draft => {
              draft.in60_1 = draft.in60_1 === optionValue ? undefined : optionValue;
            })
          )
        }
      >
        {optionValue}
      </Selection>
    </div>
    <div
      style={{
        gridRow: `${row} / span 1`,
        gridColumn: '11 / span 1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Selection
        selected={value.in60_2 === optionValue}
        onClick={() =>
          onChange(
            produce(value, draft => {
              draft.in60_2 = draft.in60_2 === optionValue ? undefined : optionValue;
            })
          )
        }
      >
        {optionValue}
      </Selection>
    </div>
  </>
);

const Aspect = ({ row, title, two, one, zero, value, onChange }: AspectProps) => (
  <>
    <div style={{ gridRow: `${row} / span 1`, gridColumn: '1 / span 1' }}>{title}</div>
    <div style={{ gridRow: `${row} / span 1`, gridColumn: '2 / span 1' }}>{two}</div>
    <AspectRow {...{ row, title, two, one, zero, value, onChange, optionValue: 2 }} />
    <div style={{ gridRow: `${row + 1} / span 1`, gridColumn: '2 / span 1' }}>{one}</div>
    <AspectRow {...{ row: row + 1, title, two, one, zero, value, onChange, optionValue: 1 }} />
    <div style={{ gridRow: `${row + 2} / span 1`, gridColumn: '2 / span 1' }}>{zero}</div>
    <AspectRow {...{ row: row + 2, title, two, one, zero, value, onChange, optionValue: 0 }} />
    <div style={{ gridRow: `${row + 3} / span 1`, gridColumn: '1 / span 11', height: '0.5rem' }}>
      <div style={{ backgroundColor: '#1976D2', width: '100%', height: '1px' }} />
    </div>
  </>
);

interface SelectionProps {
  selected: boolean;
  onClick: () => void;
  children: ReactNode;
}

const Selection = ({ selected, onClick, children }: SelectionProps) => (
  <div
    style={{
      width: '28px',
      height: '28px',
      margin: '-28px',
      borderRadius: '50%',
      backgroundColor: selected ? '#1976D2' : 'transparent',
      color: selected ? 'white' : undefined,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      userSelect: 'none',
    }}
    onClick={onClick}
  >
    {children}
  </div>
);
