import { Mutation } from '@apollo/client/react/components';
import React from 'react';
import { setPatientNotReadyForSurgeon } from '../../../../graph/patients';

const withSetPatientNotReadyForSurgeon = Component => props => (
  <Mutation mutation={setPatientNotReadyForSurgeon}>
    {setPatientNotReadyForSurgeon => (
      <Component {...props} setPatientNotReadyForSurgeon={setPatientNotReadyForSurgeon} />
    )}
  </Mutation>
);

export default withSetPatientNotReadyForSurgeon;
