import React from 'react';
import styled from 'styled-components';
import { Procedure } from '../../../../../types/Procedure';

import AnesthesiologistNameRoom from './AnesthesiologistNameRoom';

const Anes = styled.div<{ inline?: boolean; condensView?: boolean; secondaryRoomAnesthesiologist?: boolean }>`
  display: ${props => (props.secondaryRoomAnesthesiologist ? 'flex' : 'flex')};
  align-items: ${props => (props.secondaryRoomAnesthesiologist ? 'flex-end' : 'flex-start')};
  white-space: nowrap;
  flex: ${props => (props.inline ? '0 1 auto' : '1 0 auto')};
  justify-content: ${props => (props.condensView ? 'space-between' : 'flex-end')};
  background-color: ${props => (props.condensView ? 'rgba(255,255,255,0.1)' : undefined)};
  padding: ${props => (props.condensView ? '0px 5px 0px 3px;' : undefined)};
  margin-bottom: ${props => (props.condensView ? '0' : undefined)};
  font-size: ${props => (props.condensView ? '1.33em' : 'inherit')};
  line-height: inherit;
  flex-direction: ${props => (props.secondaryRoomAnesthesiologist ? 'column' : 'row')};
`;

export const Anesthesiologist = ({
  date,
  roomId,
  editableAnesthesiologist,
  anesthesiologistName,
  secondaryAnesthesiologistName,
  inline,
  style,
  condensView,
  anesthesiologistShiftRoomId,
  secondaryAnesthesiologistShiftRoomId,
  procedureQuery,
  roomType,
  secondaryRoomAnesthesiologist,
}: {
  date?: Date;
  roomId: number;
  editableAnesthesiologist?: boolean;
  anesthesiologistName?: string;
  secondaryAnesthesiologistName?: string;
  inline?: boolean;
  condensView?: boolean;
  style?: React.CSSProperties;
  anesthesiologistShiftRoomId?: number;
  secondaryAnesthesiologistShiftRoomId?: number;
  procedureQuery?: Array<Procedure>;
  roomType?: string;
  secondaryRoomAnesthesiologist?: boolean;
}) => (
  <Anes
    condensView={condensView}
    inline={inline}
    style={style}
    onClick={e => e.stopPropagation()}
    secondaryRoomAnesthesiologist={secondaryRoomAnesthesiologist}
  >
    <AnesthesiologistNameRoom
      date={date}
      roomId={roomId}
      editableAnesthesiologist={editableAnesthesiologist}
      anesthesiologistName={anesthesiologistName}
      secondaryAnesthesiologistName={secondaryAnesthesiologistName}
      condensView={condensView}
      anesthesiologistShiftRoomId={anesthesiologistShiftRoomId}
      secondaryAnesthesiologistShiftRoomId={secondaryAnesthesiologistShiftRoomId}
      procedureQuery={procedureQuery}
      roomType={roomType}
      secondaryRoomAnesthesiologist={secondaryRoomAnesthesiologist}
    />
  </Anes>
);
