import { Mutation } from '@apollo/client/react/components';
import React from 'react';
import { assignBed } from '../../../../graph/patients';

const withAssignBedMutation = Component => props => (
  <Mutation mutation={assignBed}>
    {assignBedMutation => <Component {...props} assignBedMutation={assignBedMutation} />}
  </Mutation>
);

export default withAssignBedMutation;
