import { RouteComponentProps, withRouter } from 'react-router';
import React, { Ref, useEffect, useRef, useState } from 'react';
import { DocumentNode, useMutation } from '@apollo/client';
import { Box, CircularProgress, ClickAwayListener, Grow, IconButton, MenuList, Paper, Popper } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MenuItem from '@material-ui/core/MenuItem';
import { useToaster } from '../../core/Toaster';
import { PinInput } from '../../pages/kiosk/charting/components';
import { TruncatedText } from '../../../se/components/typography';
import Link from '../../../se/components/html/Link';
import Modal from '../../../se/components/Modal';
import OspitekButton from '../../../se/components/Button';

const Text = TruncatedText as any;

interface PinColumnParams<TData> {
  getId: (data: TData) => number;
  getName: (data: TData) => string;
  getPin: (data: TData) => string | undefined;
  createPinMutation: DocumentNode;
  issuePinMutation: DocumentNode;
  removePinMutation: DocumentNode;
}

export const PinColumn = <TData,>({
  getId,
  getName,
  getPin,
  createPinMutation,
  issuePinMutation,
  removePinMutation,
}: PinColumnParams<TData>) => ({
  title: 'PIN',
  lens: (data: TData) => data,
  Component: withRouter((props: RouteComponentProps & { data: TData }) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement>();
    const [visible, setVisible] = useState(false);
    const [editing, setEditing] = useState(false);

    useEffect(() => {
      if (visible) {
        const timeout = setTimeout(() => setVisible(false), 5000);

        return () => clearTimeout(timeout);
      }
    }, [visible]);

    const togglePin = () => {
      setVisible(prev => !prev);
    };

    const handleToggle = () => {
      setOpen(prevOpen => !prevOpen);
    };

    const handleClose = (event: any) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }

      setOpen(false);
    };

    const [setPin] = useMutation(createPinMutation, {
      variables: {
        id: getId(props.data),
        pin: '',
      },
    });

    const [issuePin, pinIssuing] = useMutation(issuePinMutation, {
      variables: {
        id: getId(props.data),
      },
    });

    const [removePin, pinRemoval] = useMutation(removePinMutation, {
      variables: {
        id: getId(props.data),
      },
    });

    const handleCreate = async (pin: string) => {
      await setPin({
        variables: {
          id: getId(props.data),
          pin,
        },
      });

      setEditing(false);
      setVisible(true);
    };

    const handleEdit = async (pin: string) => {
      await setPin({
        variables: {
          id: getId(props.data),
          pin,
        },
      });

      setEditing(false);
      setVisible(true);
    };

    const toaster = useToaster();

    return (
      <Box onClick={e => e.stopPropagation()}>
        {getPin(props.data) ? (
          <Box>
            {editing && (
              <Box gridRow={1} gridColumn={1}>
                <PinEdit
                  name={getName(props.data)}
                  initialValue={getPin(props.data)}
                  onSave={handleEdit}
                  onCancel={() => setEditing(false)}
                />
              </Box>
            )}
            <div ref={anchorRef as Ref<HTMLDivElement>} style={{ display: 'flex', alignItems: 'center' }}>
              <Box display="grid">
                {visible && (
                  <Box gridRow={1} gridColumn={1} visibility={visible ? undefined : 'hidden'} display="flex">
                    <span style={{ color: 'white', marginRight: '1em' }}>
                      <Text>{getPin(props.data)}</Text>
                    </span>
                    <Link onClick={togglePin}>
                      <Text>Hide</Text>
                    </Link>
                  </Box>
                )}
                <Box gridRow={1} gridColumn={1} visibility={visible ? 'hidden' : undefined}>
                  <Link onClick={togglePin}>
                    <Text>Show PIN</Text>
                  </Link>
                </Box>
              </Box>
              <IconButton
                color="primary"
                aria-controls={open ? 'split-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
                style={{ marginTop: '-1.125em', marginBottom: '-1em' }}
              >
                <ArrowDropDownIcon />
              </IconButton>
            </div>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="split-button-menu">
                        <MenuItem
                          onClick={() => {
                            setOpen(false);
                            setEditing(true);
                          }}
                        >
                          Edit PIN {pinIssuing.loading && <CircularProgress size={16} style={{ marginLeft: '8px' }} />}
                        </MenuItem>
                        <MenuItem
                          onClick={async () => {
                            await issuePin();
                            const name = getName(props.data);
                            toaster.success([...(name ? [name + '’s'] : []), 'PIN', 'issued'].join(' ') + '.');
                            setOpen(false);
                            setVisible(true);
                          }}
                        >
                          Randomize PIN{' '}
                          {pinIssuing.loading && <CircularProgress size={16} style={{ marginLeft: '8px' }} />}
                        </MenuItem>
                        <MenuItem
                          onClick={async () => {
                            await removePin();
                            const name = getName(props.data);
                            toaster.success([...(name ? [name + '’s'] : []), 'PIN', 'removed'].join(' ') + '.');
                            setOpen(false);
                            setVisible(false);
                          }}
                        >
                          Remove PIN{' '}
                          {pinRemoval.loading && <CircularProgress size={16} style={{ marginLeft: '8px' }} />}
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Box>
        ) : (
          <Box>
            {editing && (
              <Box gridRow={1} gridColumn={1}>
                <PinEdit create name={getName(props.data)} onSave={handleCreate} onCancel={() => setEditing(false)} />
              </Box>
            )}
            <div ref={anchorRef as Ref<HTMLDivElement>} style={{ display: 'flex', alignItems: 'center' }}>
              <Box display="grid">
                <Box gridRow={1} gridColumn={1} visibility={editing ? 'hidden' : undefined}>
                  <Link
                    onClick={() => {
                      setOpen(false);
                      setEditing(true);
                    }}
                  >
                    <Text>
                      Create PIN {pinIssuing.loading && <CircularProgress size={16} style={{ marginLeft: '8px' }} />}
                    </Text>
                  </Link>
                </Box>
              </Box>
              <IconButton
                color="primary"
                aria-controls={open ? 'split-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
                style={{ marginTop: '-1.125em', marginBottom: '-1em' }}
              >
                <ArrowDropDownIcon />
              </IconButton>
            </div>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="split-button-menu">
                        <MenuItem
                          onClick={async () => {
                            await issuePin();
                            const name = getName(props.data);
                            toaster.success([...(name ? [name + '’s'] : []), 'PIN', 'issued'].join(' ') + '.');
                            setOpen(false);
                            setVisible(true);
                          }}
                        >
                          Randomize PIN{' '}
                          {pinIssuing.loading && <CircularProgress size={16} style={{ marginLeft: '8px' }} />}
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Box>
        )}
      </Box>
    );
  }),
});

interface PinEditProps {
  create?: boolean;
  name?: string;
  initialValue?: string;
  onSave: (pin: string) => Promise<unknown>;
  onCancel: () => void;
}

const PinEdit = ({ create, name, initialValue, onSave, onCancel }: PinEditProps) => {
  const saveRef = useRef<HTMLSpanElement>();

  const [value, setValue] = useState(initialValue);
  const [busy, setBusy] = useState(false);

  const toaster = useToaster();

  const cancel = () => onCancel();
  const save = async () => {
    try {
      setBusy(true);
      await onSave(value!);
      toaster.success([...(name ? [name + '’s'] : []), 'PIN', create ? 'created' : 'saved'].join(' ') + '.');
    } catch (e) {
      toaster.error('Unable to ' + title + '. Please try again.');
    } finally {
      setBusy(false);
    }
  };

  const title = [create ? 'Create' : 'Edit', ...(name ? [name + '’s'] : []), 'PIN'].join(' ');

  return (
    <Modal title={title} withRouter={false} onClose={cancel}>
      <PinInput
        value={value ?? ''}
        onChange={setValue}
        onComplete={() => {
          const save = saveRef.current;

          if (!save) {
            return;
          }

          const button = save.parentElement;

          if (!button) {
            return;
          }

          button.focus();
        }}
        disabled={false}
      />
      <div style={{ marginTop: '2rem' }}>
        <OspitekButton
          primary
          busy={busy}
          disabled={!value || value.length < 6}
          style={{ marginRight: '1rem' }}
          onClick={save}
        >
          <span ref={saveRef as Ref<HTMLSpanElement>}>{create ? 'Create' : 'Save'}</span>
        </OspitekButton>
        <OspitekButton busy={false} disabled={false} style={{ marginRight: '1rem' }} onClick={cancel}>
          Cancel
        </OspitekButton>
      </div>
    </Modal>
  );
};
