import { Mutation } from '@apollo/client/react/components';
import React, { Fragment, useState } from 'react';
import format from 'date-fns/format';
import identity from 'lodash/identity';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { getNestedValue } from '../../../se/utilities/data/object';
import { formatPhoneNumber, processPhoneNumbersString } from '../../../se/components/inputs/PhoneInput';
import {
  formatPhoneNumberCaretaker,
  processPhoneNumbersStringCaretaker,
} from '../../../se/components/inputs/CaretakerPhoneInput';
import { Text, TruncatedText } from '../../../se/components/typography';
import LinkButton from '../../../se/components/LinkButton';
import Tooltip from '../../Tooltip';
import { calculateDurationInMinutes, getRoom } from '../common/transducers';
import DefaultPatientIcon from '../../patient/PatientIcon';
import { item, setFamilyReadyPACU, setFamilyReadyPOSTOP, setReady as setPatientReady } from '../../../graph/patients';
import { CustomModal, WithWorking } from '../../pages/kiosk/tablet/OperationRoomTabletV1';
import { RouterlessModal } from '../../../se/components/Modal';
import BedInlineInput from './BedInlineInput';
import { optionalFormatWithTimezone } from '../../pages/kiosk/tablet/utils';
import Icon from '../../core/Icon';
import { withTheme } from '../../../se/theme';
import { sortDate, sortString } from '../../../util/sort';
import { isDefinedAndNotNull } from '../../../se/utilities/check';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import first from 'lodash/first';
import { smsStatusLabel, smsStatusType } from '../procedures/ProcedureEventLog';
import { errorDescription, errorMessage } from '../../../vendor/twillio';
import { differenceInMinutes } from 'date-fns';
import ExpandableText from '../../core/ExpandableText';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import NotificationActionButton from '../surgeonOffice/NotificationActionButton';
import useNow from '../../../hooks/useNow';
import { Box } from '@material-ui/core';
import { CaretakerChatDialog, PatientChatDialog } from '../../chat/ChatDialog';
import ChatButton from '../../chat/ChatButton';
import secretValue from '../../../util/secretValue';
import { PACU, POST_OP, WAITING_ROOM } from '../room/enums';
import { PatientTypeIconSwitch } from '../../inputs/PatientTypeButtonGroup';
import { formatProviderId } from '../../../util/providerId';
import { withScope } from '../../../contexts/ScopeContext';
import { useScope } from '../../../hooks/useScope';
import moment from 'moment-timezone';
import {
  getLogEntries,
  isReadyForPickupPACU as isReadyForPickupPACUFn,
  isReadyForPickupPOSTOP as isReadyForPickupPOSTOPFn,
} from '../../pages/kiosk/tablet/utils';

const PatientIconAndName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PatientIconContainer = styled.div`
  margin-right: 0.75rem;
  color: rgba(0, 0, 0, 0.5);
`;

const ReadyButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PatientNameColumn = ({ Text = TruncatedText, data, showIcon = true, showPatientType = true }) => (
  <PatientIconAndName>
    {showIcon && (
      <PatientIconContainer>
        <DefaultPatientIcon backgroundColor={data.color} icon={data.icon} inline />
      </PatientIconContainer>
    )}
    <Text>{data.name}</Text>
    {showPatientType && <PatientTypeIconSwitch patientType={data.type} />}
    {/*{showPatientChartCompleted && (<Box ml={1}><PatientChartIndicator patient={data?.patient} format={'STRING'}/></Box>)}*/}
  </PatientIconAndName>
);

export const nameColumn = sortField => ({
  title: 'Name',
  key: 'name',
  lens: data => ({ id: data.id, icon: data.icon, color: data.color, name: data.name, type: data.type }),
  sortFunction: (l, r) => sortString(l[sortField], r[sortField]),
  Component: PatientNameColumn,
});

export const idColumn = {
  title: 'ID',
  key: 'patientId',
  lens: (data, _, scope) => {
    if (!scope) {
      return data.id;
    }

    const hospitalId = get(scope, 'hospital.id');
    const groupId = get(scope, 'hospital.groupId');

    return hospitalId ? `G${groupId}H${hospitalId}-${data.id}` : String(data.id);
  },
  Component: props =>
    withScope(({ scope, data }) => {
      const hospitalId = get(scope, 'hospital.id');
      const groupId = get(scope, 'hospital.groupId');
      return <TruncatedText>{hospitalId ? `G${groupId}H${hospitalId}-${data}` : String(data)}</TruncatedText>;
    })(props),
};

export const physicianColumn = {
  title: 'Physician',
  key: 'physician',
  lens: data => getNestedValue('physician.name', data),
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

export const rideHomeNumberColumn = {
  title: 'Ride Home Phone Number',
  key: 'rideHomeCellPhone',
  lens: data =>
    getNestedValue('procedure.rideHomeCellPhone', data)
      ? formatPhoneNumber(
          processPhoneNumbersString(getNestedValue('procedure.rideHomeCellPhone', data)).nationalNumbers
        )
      : getNestedValue('procedure.rideHomeCellPhone', data),
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

export const providerIdColumn = {
  title: 'Provider ID',
  key: 'hstId',
  lens: data => data.hstId,
  Component: props => <TruncatedText>{formatProviderId(props.data)}</TruncatedText>,
};

export const procedureStartTimeColumn = {
  title: 'Scheduled Date',
  key: 'procedureStartTime',
  lens: data => optionalFormatWithTimezone(data.procedure?.startTime, 'MM/DD/YYYY HH:mm'),
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
  hidden: true,
};

export const patientAgeColumn = {
  title: 'Age',
  lens: data => data.procedure?.patientAge || '-',
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

export const patientSexColumn = {
  title: 'Gender',
  lens: data => data.procedure?.patientSex || '-',
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

export const procedureDurationColumn = {
  title: 'Duration',
  key: 'procedureDuration',
  lens: data => data.procedure?.duration || 0,
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
  hidden: true,
};

export const procedureTypeColumn = {
  title: 'Procedure Type',
  key: 'procedureType',
  lens: data => getNestedValue('procedureType.name', data),
  style: { overflow: 'hidden', maxWidth: '30em' },
  Component: props => <ExpandableText style={{ lineHeight: 1.25 }} text={props.data} />,
};

export const procedureTypeColumnList = {
  title: 'Procedure Type',
  key: 'procedureType',
  lens: data => getNestedValue('procedureType.name', data),
  style: { overflow: 'hidden', maxWidth: '30em' },
  Component: props => <ExpandableText style={{ lineHeight: 1.25 }} text={props.data} />,
};

export const braceletIdColumn = {
  title: 'Bracelet ID',
  key: 'braceletId',
  lens: identity,
  sortFunction: (l, r) => sortString(l.braceletId, r.braceletId),
  Component: ({ data }) => (
    <TruncatedText>
      {data.dischargedByDisappearance === true && (
        <Tooltip content="This bracelet signal was lost. Most likely the patient left the center with the bracelet still on.">
          <Icon
            className="material-icons"
            style={{
              lineHeight: 0,
              margin: '0 0.25rem 0 0',
              transform: 'translate(0, 0.25em)',
            }}
          >
            warning
          </Icon>
        </Tooltip>
      )}
      {data.braceletId}
    </TruncatedText>
  ),
};

export const InlineIcon = ({ children }) => (
  <Icon
    className="material-icons"
    style={{
      lineHeight: '1em',
      opacity: 0.35,
      color: withTheme(theme => theme.border.color.default.string()),
    }}
  >
    {children}
  </Icon>
);

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
`;

const IndicatorFlavor = styled.span`
  display: inline-block;
  font-weight: 500;
  > * {
    opacity: 0.7 !important;
    margin: 0 !important;
  }
`;

const Success = styled(IndicatorFlavor)`
  color: ${withTheme(theme => theme.successColor.string())};
`;

const Error = styled(IndicatorFlavor)`
  color: ${withTheme(theme => theme.dangerColor.string())};
  > * {
    border-radius: 50%;
    background: white;
  }
`;

const Warning = styled(IndicatorFlavor)`
  color: ${withTheme(theme => theme.warningColor.string())};
`;

export const Count = withStyles(theme => ({
  root: {
    color: 'inherit',
    fontSize: theme.typography.caption.fontSize,
    paddingLeft: theme.spacing(1),
  },
}))(Typography);

const handleResend =
  (
    mutate,
    variables,
    options,
    shouldWarn,
    preSubmit,
    patientName,
    patientMobilePhoneNumber,
    patientEmailAddress,
    templateName
  ) =>
  async e => {
    e.stopPropagation();
    if (preSubmit) {
      const result = await preSubmit();
      if (!result) {
        return;
      }
    }
    if (shouldWarn) {
      if (window.confirm('Form is already filled, are you sure you want to send another invitation?')) {
        await mutate({ variables, ...options });
      }
    } else if (patientName && patientMobilePhoneNumber) {
      if (
        window.confirm(
          `${
            templateName || 'The PreOp Registration Questionnaire'
          } is about to be sent to ${patientName} with mobile phone number(s) ${patientMobilePhoneNumber}. Please ensure the patient’s mobile number is correct before proceeding.`
        )
      ) {
        await mutate({ variables, ...options });
      }
    } else if (patientName && patientEmailAddress) {
      if (
        window.confirm(
          `${
            templateName || 'The PreOp Registration Questionnaire'
          } is about to be sent to ${patientName} with email address(s) ${patientEmailAddress}. Please ensure the patient’s email address is correct before proceeding.`
        )
      ) {
        await mutate({ variables, ...options });
      }
    } else {
      await mutate({ variables, ...options });
    }
  };

const handleResendIntake =
  (mutate, variables, options, shouldWarn, preSubmit, patientName, patientMobilePhoneNumber, patientEmailAddress) =>
  async e => {
    e.stopPropagation();
    if (preSubmit) {
      const result = await preSubmit();
      if (!result) {
        return;
      }
    }
    if (shouldWarn) {
      if (window.confirm('Form is already filled, are you sure you want to send another invitation?')) {
        await mutate({ variables, ...options });
      }
    } else if (patientName && patientMobilePhoneNumber) {
      if (
        window.confirm(
          `The Registration Package is about to be sent to ${patientName} with mobile phone number(s) ${patientMobilePhoneNumber}. Please ensure the patient’s mobile number is correct before proceeding.`
        )
      ) {
        await mutate({ variables, ...options });
      }
    } else if (patientName && patientEmailAddress) {
      if (
        window.confirm(
          `The Registration Package is about to be sent to ${patientName} with email address(s) ${patientEmailAddress}. Please ensure the patient’s email address is correct before proceeding.`
        )
      ) {
        await mutate({ variables, ...options });
      }
    } else {
      await mutate({ variables, ...options });
    }
  };

const handleResendInstructions =
  (mutate, variables, options, shouldWarn, preSubmit, patientName, patientMobilePhoneNumber, patientEmailAddress) =>
  async e => {
    e.stopPropagation();
    if (preSubmit) {
      const result = await preSubmit();
      if (!result) {
        return;
      }
    }
    if (shouldWarn) {
      if (window.confirm('Form is already filled, are you sure you want to send another invitation?')) {
        await mutate({ variables, ...options });
      }
    } else if (patientName && patientMobilePhoneNumber) {
      if (
        window.confirm(
          `The Instructions are about to be sent to ${patientName} with mobile phone number(s) ${patientMobilePhoneNumber}. Please ensure the patient’s mobile number is correct before proceeding.`
        )
      ) {
        await mutate({ variables, ...options });
      }
    } else if (patientName && patientEmailAddress) {
      if (
        window.confirm(
          `The Instructions are about to be sent to ${patientName} with email address(s) ${patientEmailAddress}. Please ensure the patient’s email address is correct before proceeding.`
        )
      ) {
        await mutate({ variables, ...options });
      }
    } else {
      await mutate({ variables, ...options });
    }
  };

const UnifonicErrorMessages = {
  'ER-01': 'Invalid AppSid',
  'ER-02': 'Missing parameter',
  'ER-03': 'Sender ID already exists',
  'ER-04':
    'Wrong sender ID format, sender ID should not exceed 11 characters or 16 numbers, only English letters allowed with no special characters or spaces',
  'ER-05': 'Sender ID is blocked and can’t be used',
  'ER-06': 'Sender ID doesn‘t exist',
  'ER-07': 'Default sender ID can‘t be deleted',
  'ER-08': 'Sender ID is not approved',
  'ER-09': 'No sufficient balance',
  'ER-10': 'Wrong number format , mobile numbers must be in international format without 00 or + Example: (4452023498)',
  'ER-11': 'Unsupported destination',
  'ER-12': 'Message body exceeded limit',
  'ER-13': 'Service not found',
  'ER-14': 'Sender ID is blocked on the required destination',
  'ER-15': 'User is Not active',
  'ER-16': 'Exceed throughput limit',
  'ER-17': 'Inappropriate content in message body',
  'ER-18': 'Invalid Message ID',
  'ER-19': 'Wrong date format, date format should be yyyy-mm-dd',
  'ER-20': 'Page limit Exceeds (10000)',
  'ER-21': 'Method not found.',
  'ER-22': 'Language not supported',
  'ER-23': 'You have exceeded your Sender ID’s requests limit, delete one sender ID to request a new one',
  'ER-24':
    'Wrong Status data , message status should be one of the following statuses “Queued” , “Sent” , “Failed” or “Rejected”',
  'ER-25': 'This request is not included in your account plan',
  'ER-26': 'Invalid Call ID',
  'ER-27': 'Wrong Status Data',
  'ER-28': 'Wrong Email Format',
  'ER-29': 'Invalid Email ID',
  'ER-30': 'Invalid Security Type',
  'ER-31': 'Wrong Passcode',
  'ER-32': 'Passcode expired',
  'ER-33': 'Wrong channel type, Channel value should be TextMessage, Call or Both',
  'ER-34': 'Wrong time to live value TTL, TTL should be between 1 – 60 minutes, and should not exceed the Expiry time',
  'ER-35': 'MessageID already sent',
  'ER-36': 'Wrong voice type, voice value should be Male or Female',
  'ER-37': 'Wrong Delay type, Delay value should be between 0 and 5 seconds',
  'ER-38': 'Invalid number; number is not available or had expired for the submitted AppSid',
  'ER-39': 'Invalid Rule ; rule should be: Is, StartsWith, Contains, Any. Only “Is” is available for a shared number',
  'ER-40': 'Keyword not defined',
  'ER-41': 'Invalid Country code',
};

export const getErrorMessage = errorCode =>
  UnifonicErrorMessages[errorCode]
    ? `${errorCode}: ${UnifonicErrorMessages[errorCode]}`
    : errorCode === 'Attempt to send to unsubscribed recipient'
      ? 'Patient is unsubscribed. Please instruct patient to text in “START” to (938) 333-3995'
      : errorCode;

export const DeliveryStatus = ({ status, working }) => {
  if (status === smsStatusType.delivered) {
    return (
      <Tooltip content="Delivered">
        <Success>
          <InlineIcon>check_circle</InlineIcon>
        </Success>
      </Tooltip>
    );
  } else if ([smsStatusType.failed, smsStatusType.undelivered].includes(status)) {
    return (
      <Error>
        <InlineIcon>error</InlineIcon>
      </Error>
    );
  } else if ([smsStatusType.sent, smsStatusType.dispatched].includes(status)) {
    return (
      <Tooltip content="Sent">
        <Success>
          <InlineIcon>check_circle_outline</InlineIcon>
        </Success>
      </Tooltip>
    );
  } else if (!!status) {
    return (
      <Warning>
        <InlineIcon>hourglass_top</InlineIcon>
      </Warning>
    );
  } else {
    return (
      <InlineIcon>
        <WithWorking working={working} size={'1em'}>
          info
        </WithWorking>
      </InlineIcon>
    );
  }
};

export const ResendSms = ({
  mutation,
  variables,
  options,
  tooltip,
  disabled,
  disabledTooltip,
  timesSent,
  shouldWarn,
  label,
  preSubmit,
  status,
  error,
  lastEventAt,
  patientName,
  patientMobilePhoneNumber,
  templateName,
}) => {
  const now = useNow();
  const timeout = differenceInMinutes(now, lastEventAt) >= 3;
  const canResend =
    [smsStatusType.delivered, smsStatusType.failed, smsStatusType.undelivered].includes(status) || !status || timeout;

  return (
    <ActionWrapper>
      <Mutation mutation={mutation}>
        {(mutate, { loading }) => (
          <>
            <DeliveryStatus status={status} working={loading} />
            <Tooltip content={error ? getErrorMessage(error) : disabled && disabledTooltip ? disabledTooltip : tooltip}>
              <LinkButton
                onClick={handleResend(
                  mutate,
                  variables,
                  options,
                  shouldWarn,
                  preSubmit,
                  patientName,
                  patientMobilePhoneNumber,
                  null,
                  templateName
                )}
                disabled={loading || disabled || !canResend}
              >
                {label || (status ? 'Resend SMS Invite' : 'Send SMS Invite')}
                {isDefinedAndNotNull(timesSent) && <Count>{`[ ${timesSent} ]`}</Count>}
              </LinkButton>
            </Tooltip>
          </>
        )}
      </Mutation>
    </ActionWrapper>
  );
};

export const ResendSmsIntake = ({
  mutation,
  variables,
  options,
  tooltip,
  disabled,
  disabledTooltip,
  timesSent,
  shouldWarn,
  label,
  preSubmit,
  status,
  error,
  lastEventAt,
  patientName,
  patientMobilePhoneNumber,
}) => {
  const now = useNow();
  const timeout = differenceInMinutes(now, lastEventAt) >= 3;
  const canResend =
    [smsStatusType.delivered, smsStatusType.failed, smsStatusType.undelivered].includes(status) || !status || timeout;

  return (
    <ActionWrapper>
      <Mutation mutation={mutation}>
        {(mutate, { loading }) => (
          <>
            <DeliveryStatus status={status} working={loading} />
            <Tooltip content={error ? getErrorMessage(error) : disabled && disabledTooltip ? disabledTooltip : tooltip}>
              <LinkButton
                onClick={handleResendIntake(
                  mutate,
                  variables,
                  options,
                  shouldWarn,
                  preSubmit,
                  patientName,
                  patientMobilePhoneNumber,
                  null
                )}
                disabled={loading || disabled || !canResend}
              >
                {label || (status ? 'Resend SMS Invite' : 'Send SMS Invite')}
                {isDefinedAndNotNull(timesSent) && <Count>{`[ ${timesSent} ]`}</Count>}
              </LinkButton>
            </Tooltip>
          </>
        )}
      </Mutation>
    </ActionWrapper>
  );
};

export const ResendSmsInstructions = ({
  mutation,
  variables,
  options,
  tooltip,
  disabled,
  disabledTooltip,
  timesSent,
  shouldWarn,
  label,
  preSubmit,
  status,
  error,
  lastEventAt,
  patientName,
  patientMobilePhoneNumber,
}) => {
  const now = useNow();
  const timeout = differenceInMinutes(now, lastEventAt) >= 3;
  const canResend =
    [smsStatusType.delivered, smsStatusType.failed, smsStatusType.undelivered].includes(status) || !status || timeout;

  return (
    <ActionWrapper>
      <Mutation mutation={mutation}>
        {(mutate, { loading }) => (
          <>
            <DeliveryStatus status={status} working={loading} />
            <Tooltip content={error ? getErrorMessage(error) : disabled && disabledTooltip ? disabledTooltip : tooltip}>
              <LinkButton
                onClick={handleResendInstructions(
                  mutate,
                  variables,
                  options,
                  shouldWarn,
                  preSubmit,
                  patientName,
                  patientMobilePhoneNumber,
                  null
                )}
                disabled={loading || disabled || !canResend}
              >
                {label || (status ? 'Resend SMS Invite' : 'Send SMS Invite')}
                {isDefinedAndNotNull(timesSent) && <Count>{`[ ${timesSent} ]`}</Count>}
              </LinkButton>
            </Tooltip>
          </>
        )}
      </Mutation>
    </ActionWrapper>
  );
};

export const ResendEmail = ({
  mutation,
  variables,
  options,
  tooltip,
  disabled,
  disabledTooltip,
  timesSent,
  shouldWarn,
  label,
  preSubmit,
  patientName,
  patientEmailAddress,
}) => {
  const [sent, setSent] = useState(false);

  const onDone = () => {
    setSent(true);
  };

  const Sent = (
    <Success>
      <InlineIcon>check_circle</InlineIcon>
      <Text>Email Sent</Text>
      {isDefinedAndNotNull(timesSent) && <Count>{`[ ${timesSent} ]`}</Count>}
    </Success>
  );

  const Send = (
    <Tooltip content={disabled && disabledTooltip ? disabledTooltip : tooltip}>
      <Mutation mutation={mutation} onCompleted={onDone}>
        {(mutate, { loading }) => (
          <Box display="flex" flexDirection="row" alignItems="center" style={{ gap: '0.5em' }}>
            <InlineIcon>
              <WithWorking working={loading} size={'1em'}>
                info
              </WithWorking>
            </InlineIcon>
            <LinkButton
              onClick={handleResend(
                mutate,
                variables,
                options,
                shouldWarn,
                preSubmit,
                patientName,
                null,
                patientEmailAddress
              )}
              disabled={loading || disabled}
            >
              {label || 'Send Email Invite'}
              {isDefinedAndNotNull(timesSent) && <Count>{`[ ${timesSent} ]`}</Count>}
            </LinkButton>
          </Box>
        )}
      </Mutation>
    </Tooltip>
  );

  return sent ? Sent : Send;
};

export const ResendEmailIntake = ({
  mutation,
  variables,
  options,
  tooltip,
  disabled,
  disabledTooltip,
  timesSent,
  shouldWarn,
  label,
  preSubmit,
  patientName,
  patientEmailAddress,
}) => {
  const [sent, setSent] = useState(false);

  const onDone = () => {
    setSent(true);
  };

  const Sent = (
    <Success>
      <InlineIcon>check_circle</InlineIcon>
      <Text>Email Sent</Text>
      {isDefinedAndNotNull(timesSent) && <Count>{`[ ${timesSent} ]`}</Count>}
    </Success>
  );

  const Send = (
    <Tooltip content={disabled && disabledTooltip ? disabledTooltip : tooltip}>
      <Mutation mutation={mutation} onCompleted={onDone}>
        {(mutate, { loading }) => (
          <Box display="flex" flexDirection="row" alignItems="center" style={{ gap: '0.5em' }}>
            <InlineIcon>
              <WithWorking working={loading} size={'1em'}>
                info
              </WithWorking>
            </InlineIcon>
            <LinkButton
              onClick={handleResendIntake(
                mutate,
                variables,
                options,
                shouldWarn,
                preSubmit,
                patientName,
                null,
                patientEmailAddress
              )}
              disabled={loading || disabled}
            >
              {label || 'Send Email Invite'}
              {isDefinedAndNotNull(timesSent) && <Count>{`[ ${timesSent} ]`}</Count>}
            </LinkButton>
          </Box>
        )}
      </Mutation>
    </Tooltip>
  );

  return sent ? Sent : Send;
};

export const ResendEmailInstructions = ({
  mutation,
  variables,
  options,
  tooltip,
  disabled,
  disabledTooltip,
  timesSent,
  shouldWarn,
  label,
  preSubmit,
  patientName,
  patientEmailAddress,
}) => {
  const [sent, setSent] = useState(false);

  const onDone = () => {
    setSent(true);
  };

  const Sent = (
    <Success>
      <InlineIcon>check_circle</InlineIcon>
      <Text>Email Sent</Text>
      {isDefinedAndNotNull(timesSent) && <Count>{`[ ${timesSent} ]`}</Count>}
    </Success>
  );

  const Send = (
    <Tooltip content={disabled && disabledTooltip ? disabledTooltip : tooltip}>
      <Mutation mutation={mutation} onCompleted={onDone}>
        {(mutate, { loading }) => (
          <Box display="flex" flexDirection="row" alignItems="center" style={{ gap: '0.5em' }}>
            <InlineIcon>
              <WithWorking working={loading} size={'1em'}>
                info
              </WithWorking>
            </InlineIcon>
            <LinkButton
              onClick={handleResendInstructions(
                mutate,
                variables,
                options,
                shouldWarn,
                preSubmit,
                patientName,
                null,
                patientEmailAddress
              )}
              disabled={loading || disabled}
            >
              {label || 'Send Email Invite'}
              {isDefinedAndNotNull(timesSent) && <Count>{`[ ${timesSent} ]`}</Count>}
            </LinkButton>
          </Box>
        )}
      </Mutation>
    </Tooltip>
  );

  return sent ? Sent : Send;
};

export const patientLogMessageStatus = (log = []) => {
  const event = first(
    orderBy(
      log.filter(log => ['MessageDelivery@FamilyTrackingInvite'].includes(get(log, 'entries[0].type'))),
      'timestamp',
      'desc'
    )
  );
  const messageStatusEvent = get(event, 'entries[0]', {});
  const error = get(JSON.parse(get(messageStatusEvent, 'delivery', '{}')), 'errorCode');
  const sendingError = get(JSON.parse(get(messageStatusEvent, 'delivery', '{}')), 'sendingError');
  const status = get(messageStatusEvent, 'status');

  const lastEventAt = get(event, 'timestamp');

  return {
    error:
      sendingError === 'Attempt to send to unsubscribed recipient'
        ? 'Patient is unsubscribed. Please instruct patient to text in “START” to (938) 333-3995'
        : error || sendingError
          ? [errorMessage(error), errorDescription(error), sendingError].filter(_ => !!_).join(' - ')
          : undefined,
    status,
    lastEventAt,
  };
};

export const familySmsStatusColumn = {
  title: 'Message Status',
  lens: data => patientLogMessageStatus(get(data, 'log', [])),
  sortFunction: (l, r) =>
    sortString(
      get(patientLogMessageStatus(get(l, 'log', [])), 'status'),
      get(patientLogMessageStatus(get(r, 'log', [])), 'status')
    ),
  Component: ({ data: { status, error } }) => (
    <ActionWrapper>
      <Tooltip content={error ? getErrorMessage(error) : 'You can resend the SMS from the patient profile screen'}>
        <DeliveryStatus status={status} />
        <Text>{smsStatusLabel[status] || 'Not Sent'}</Text>
      </Tooltip>
    </ActionWrapper>
  ),
};

export const caretakerChatColumn = {
  title: 'Message Caretaker',
  lens: data => data,
  Component: ({ data }) => (
    <>
      {data?.notificationNumbers ? (
        <ChatButton
          hasUnreadMessages={data?.caretakerThread?.hasUnreadMessages}
          chatComponent={CaretakerChatDialog}
          chatProps={{
            patient: data,
          }}
        />
      ) : (
        ''
      )}
    </>
  ),
};

export const smsNotificationNumbersColumn = {
  title: 'Caretaker Phone Numbers',
  lens: data => ({
    number:
      data.notificationNumbers !== null &&
      data.notificationNumbers !== undefined &&
      data.notificationNumbers !== secretValue
        ? formatPhoneNumberCaretaker(processPhoneNumbersStringCaretaker(data.notificationNumbers, data.caretakerName))
        : data.notificationNumbers === secretValue
          ? secretValue
          : undefined,
    patientId: data.id,
    active: !(data.dischargedAt || data.voidedAt),
    consent: data.caretakerConsent,
    ...patientLogMessageStatus(get(data, 'log', [])),
    patient: {
      ...data,
    },
  }),
  Component: NotificationActionButton,
};

export const chartingSmsNotificationNumbersColumn = {
  title: 'Caretaker Phone Numbers',
  lens: data => ({
    number:
      data.notificationNumbers !== null &&
      data.notificationNumbers !== undefined &&
      data.notificationNumbers !== secretValue
        ? formatPhoneNumber(processPhoneNumbersString(data.notificationNumbers).nationalNumbers)
        : data.notificationNumbers === secretValue
          ? secretValue
          : undefined,
    patientId: data.id,
    active: false,
    consent: data.caretakerConsent,
    ...patientLogMessageStatus(get(data, 'log', [])),
    patient: {
      ...data,
    },
  }),
  Component: NotificationActionButton,
};

const checkDuplicate = phoneNumbers => {
  if (!phoneNumbers) return null;
  const numberArr = phoneNumbers.split(',').map(e => e.trim());
  const uniqueNumbers = [...new Set(numberArr)];
  return uniqueNumbers.join(', ');
};

const PatientPhoneNumbers = props => {
  const isPhoneNumber = !!props?.data?.patientPhoneNumbers;

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
      <TruncatedText>{props?.data?.patientPhoneNumbers || '-'}</TruncatedText>
      {isPhoneNumber && (
        <ChatButton
          hasUnreadMessages={props?.data?.patient?.patientThread?.hasUnreadMessages}
          chatComponent={PatientChatDialog}
          chatProps={{
            patient: props?.data?.patient,
          }}
        />
      )}
    </Box>
  );
};

export const phoneNumberColumn = {
  title: 'Patient Phone Number(s)',
  lens: data => ({
    patientPhoneNumbers:
      (checkDuplicate(data.phoneNumber) || (data.phoneNumber !== null && data.phoneNumber !== undefined)) &&
      data.phoneNumber !== secretValue
        ? formatPhoneNumber(processPhoneNumbersString(data.phoneNumber).nationalNumbers)
        : data.phoneNumber === secretValue
          ? secretValue
          : undefined,
    patient: {
      ...data,
    },
  }),
  Component: PatientPhoneNumbers,
};

const defaultDate = new Date();

export const visitDurationColumn = {
  title: 'Visit Duration (m)',
  lens: data => calculateDurationInMinutes(data.createdAt, data.dischargedAt || data.voidedAt),
  sortFunction: (l, r) =>
    new Date(r.createdAt) -
    (r.dischargedAt ? new Date(r.dischargedAt) : defaultDate) -
    (new Date(l.createdAt) - (l.dischargedAt ? new Date(l.dischargedAt) : defaultDate)),
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

const getPatientReady = log => !!log.find(item => item.entries.find(entry => entry.type === 'BecameReady'));
const getFamilyReadyPACU = log => !!log.find(item => item.entries.find(entry => entry.type === 'FamilyReady'));
const getFamilyReadyPOSTOP = log =>
  !!log.find(item => item.entries.find(entry => entry.type === 'SetFamilyReadyPOSTOP'));

const ReadyModal = ({ handleConfirm, handleCancel, patientName, procedureType }) => {
  const ReadyPrompt = CustomModal(`Patient ${patientName} is ready for ${procedureType} PreOp?`, true);

  return (
    <RouterlessModal onClose={handleCancel}>
      <ReadyPrompt handleConfirm={handleConfirm} handleCancel={handleCancel} />
    </RouterlessModal>
  );
};

const PatientReadyModal = ({ handleConfirm, handleCancel, patientName }) => {
  const ReadyPrompt = CustomModal(`The family of ${patientName} has arrived?`, true);

  return (
    <RouterlessModal onClose={handleCancel}>
      <ReadyPrompt handleConfirm={handleConfirm} handleCancel={handleCancel} />
    </RouterlessModal>
  );
};

export const roomColumn = {
  title: 'Room',
  sortFunction: (l, r) => sortString(getRoom(l.room).name, getRoom(r.room).name),
  lens: data => ({
    id: data.id,
    room: getRoom(data.room).name,
    roomType: data?.room?.type,
    patientName: getNestedValue('name', data) || '',
    procedureType: getNestedValue('procedureType.name', data) || '',
    isReady: data?.room?.type === WAITING_ROOM ? getPatientReady(data.log) : undefined,
    isFamilyReadyPACU: data?.room?.type === PACU ? getFamilyReadyPACU(data.log) : undefined,
    isFamilyReadyPOSTOP: data?.room?.type === POST_OP ? getFamilyReadyPOSTOP(data.log) : undefined,
  }),
  Component: ({
    data: { id, room, patientName, procedureType, isReady, roomType, isFamilyReadyPACU, isFamilyReadyPOSTOP },
  }) => {
    const mutationOptions = {
      options: ({ data: { id } }) => ({
        refetchQueries: [
          {
            query: item,
            variables: {
              id: parseInt(id, 10),
            },
          },
        ],
      }),
    };

    const [doSetPatientReady] = useMutation(setPatientReady, mutationOptions);
    const [doSetFamilyReadyPACU] = useMutation(setFamilyReadyPACU, mutationOptions);
    const [doSetFamilyReadyPOSTOP] = useMutation(setFamilyReadyPOSTOP, mutationOptions);
    const [busy, setBusy] = useState(false);
    const [modal, setModal] = useState(null);

    const setReady = async e => {
      e.preventDefault();
      e.stopPropagation();
      setModal(null);
      setBusy(true);

      try {
        await doSetPatientReady({ variables: { id } });
      } finally {
        setBusy(false);
      }
    };

    const setHere = async e => {
      e.preventDefault();
      e.stopPropagation();
      setModal(false);
      setBusy(true);

      try {
        if (roomType === PACU) {
          await doSetFamilyReadyPACU({ variables: { id } });
        } else if (roomType === POST_OP) {
          await doSetFamilyReadyPOSTOP({ variables: { id } });
        }
      } finally {
        setBusy(false);
      }
    };

    const openPatientReadyModal = e => {
      e.preventDefault();
      e.stopPropagation();

      setModal(
        <ReadyModal
          handleConfirm={setReady}
          handleCancel={closeModal}
          patientName={patientName}
          procedureType={procedureType}
        />
      );
    };

    const openFamilyReadyModal = e => {
      e.preventDefault();
      e.stopPropagation();

      setModal(
        <PatientReadyModal
          handleConfirm={setHere}
          handleCancel={closeModal}
          patientName={patientName}
          procedureType={procedureType}
        />
      );
    };

    const closeModal = e => {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      setModal(null);
    };

    return (
      modal || (
        <TruncatedText>
          <ReadyButtonWrapper>
            <div>{room}</div>
            <div style={{ padding: '0 0.5em' }}>
              {isReady !== undefined ? (
                isReady ? (
                  ' (Ready)'
                ) : (
                  <Fragment>
                    <LinkButton onClick={openPatientReadyModal} disabled={busy}>
                      Set Ready
                    </LinkButton>
                  </Fragment>
                )
              ) : isFamilyReadyPACU !== undefined ? (
                isFamilyReadyPACU ? (
                  ' (Family Here)'
                ) : (
                  <Fragment>
                    <LinkButton onClick={openFamilyReadyModal} disabled={busy}>
                      Set Family Here
                    </LinkButton>
                  </Fragment>
                )
              ) : isFamilyReadyPOSTOP !== undefined ? (
                isFamilyReadyPOSTOP ? (
                  ' (Family Here)'
                ) : (
                  <Fragment>
                    <LinkButton onClick={openFamilyReadyModal} disabled={busy}>
                      Set Family Here
                    </LinkButton>
                  </Fragment>
                )
              ) : (
                ''
              )}
            </div>
          </ReadyButtonWrapper>
        </TruncatedText>
      )
    );
  },
};

export const bedColumn = {
  title: 'Bed',
  lens: data => data,
  Component: props => <BedInlineInput patient={props?.data} />,
};

export const preOpBedNumber = {
  title: 'PreOp Bed Number',
  lens: data => data?.preOpBed,
  Component: props => <TruncatedText>{props.data || '-'}</TruncatedText>,
};

export const recoveryBedNumber = {
  title: 'Recovery Bed Number',
  lens: data => data?.pacuBed,
  Component: props => <TruncatedText>{props.data || '-'}</TruncatedText>,
};
export const operationRoom = {
  title: 'Operation Room',
  lens: data => data?.operationRoom?.name,
  Component: props => <TruncatedText>{props.data || '-'}</TruncatedText>,
};

export const roomOrDateOfVisit = {
  title: 'Room / Date of Visit',
  key: 'roomOrDateOfVisit',
  lens: data =>
    data.dischargedAt ? optionalFormatWithTimezone(data.createdAt, 'MM/DD/YYYY') : getRoom(data.room).name,
  sortFunction: (l, r) =>
    l.dischargedAt ? sortDate(l.createdAt, r.createdAt) : sortString(getRoom(l.room).name, getRoom(r.room).name),
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

export const dateOfVisit = {
  title: 'Date of Visit',
  lens: data => format(data.createdAt, 'MM/DD/YYYY'),
  sortFunction: (l, r) => sortDate(l.createdAt, r.createdAt),
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

export const note = {
  title: 'Note',
  lens: data => data.note,
  Component: props => <Text wrap="true">{props.data}</Text>,
};

export const entryTimeColumn = {
  title: 'Entry Time',
  lens: data => format(data.createdAt, 'MM/DD/YYYY hh:mm A'),
  sortFunction: (l, r) => sortDate(l.createdAt, r.createdAt),
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

export const readyForPickupColumn = {
  title: 'Ready For Pickup',
  lens: data => data,
  Component: props => {
    const { data } = props;
    const patientLogs = getLogEntries(data);
    const isReadyForPickupPACU = isReadyForPickupPACUFn(patientLogs);
    const isReadyForPickupPOSTOP = isReadyForPickupPOSTOPFn(patientLogs);
    const displayText = isReadyForPickupPACU || isReadyForPickupPOSTOP ? 'Ready for Pickup' : '';

    return <TruncatedText>{displayText}</TruncatedText>;
  },
};
