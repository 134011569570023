import React, {
  ComponentClass,
  createElement,
  CSSProperties,
  DependencyList,
  EffectCallback,
  forwardRef,
  FunctionComponent,
  LegacyRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import styled, { css, ThemeProvider } from 'styled-components';
import { light as lightTheme } from '../../../theme';
import { InstructionBody, TemplateEditor } from '../procedures/instructions/SurgeryInstructions';
import get from 'lodash/get';
import form, { procedureForms } from '../../../graph/surgeon/forms';
import { useMutation } from '@apollo/client';
import compact from 'lodash/compact';
import pick from 'lodash/pick';
import merge from 'lodash/merge';
import isFunction from 'lodash/isFunction';
import { sanitize } from '../../../util/sanitize';
import getObjectDeepKeys from '../procedures/utils/getObjectDeepKeys';
import { difference, omit } from 'lodash';
import useHasAccessRight from '../../../hooks/useHasAccessRight';
import Tooltip from '../../Tooltip';
import Box from '@material-ui/core/Box';
import predefinedForms from './predefinedForms';
import legacyPredefinedForms from './predefinedForms/index.legacy.jsx';
import isEqual from 'lodash/fp/isEqual';
import DatabaseHtmlForm from './DatabaseHtmlForm';
import LegacyDatabaseHtmlForm from './DatabaseHtmlForm.legacy';

import { useConnectionProviderContext } from '../../pages/kiosk/connectionProviderContext';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { Button, useTheme } from '@material-ui/core';
import { Document, Page } from 'react-pdf';
import { tryParseJson } from '../../../util/parseJson';
import combinedForm from './combinedForms';
import FormIframe from './FormIframe';
import print from 'print-js';
import { useScope } from '../../../hooks/useScope';
import { DictationTemplateProvider } from '../../../contexts/DictationTemplateContext';
import { useDictationContext } from '../../../contexts/DictationsContext';
import { formsV2 } from '../../../formsv2/formsV2';
import { PredefinedForm } from './predefinedForms';
import { FormV2 } from '../../../formsv2/FormV2';

const removeNullAndUndefined = (obj: any) => {
  const isArray = Array.isArray(obj);
  for (const k of Object.keys(obj)) {
    if (obj[k] === null || obj[k] === undefined) {
      if (isArray) {
        obj.splice(k, 1);
      } else {
        delete obj[k];
      }
    } else if (typeof obj[k] === 'object') {
      removeNullAndUndefined(obj[k]);
    }
    if (isArray && obj.length === k) {
      removeNullAndUndefined(obj);
    }
  }
  return obj;
};

type CleanedUp<T> =
  T extends Array<any>
    ? T | undefined
    : T extends Function
      ? undefined
      : T extends Boolean
        ? T
        : T extends String
          ? T | undefined
          : T extends number
            ? T | undefined
            : T extends Object
              ? { [key in keyof T]: CleanedUp<T[key]> }
              : undefined;

const cleanedUp = <T,>(value: T): CleanedUp<T> => {
  if (!Boolean(value)) {
    return undefined as CleanedUp<T>;
  }

  if (Array.isArray(value)) {
    const array = value.map(cleanedUp).filter(Boolean);

    return (array.length === 0 ? undefined : array) as CleanedUp<T>;
  }

  if (typeof value === 'function') {
    return undefined as CleanedUp<T>;
  }

  if (typeof value === 'boolean') {
    return value as CleanedUp<T>;
  }

  if (typeof value === 'string') {
    return (Boolean(value) ? value : undefined) as CleanedUp<T>;
  }

  if (typeof value === 'number') {
    return (Boolean(value) ? value : undefined) as CleanedUp<T>;
  }

  if (typeof value === 'object') {
    const result = {};

    let hasEntry = false;

    Object.entries(value as any).forEach(([k, v]) => {
      const cv = cleanedUp(v);

      if (Boolean(cv)) {
        hasEntry = true;
        result[k] = cv;
      }
    });

    return (hasEntry ? result : undefined) as CleanedUp<T>;
  }

  return undefined as CleanedUp<T>;
};

const EditorPreview = styled.div<any>`
  width: 100%;
  overflow: hidden;
  ${props =>
    props.working &&
    css`
      opacity: 0.7;
      pointer-events: none;
      cursor: progress;
    `}
  transition: all 0.1s ease;
`;

export const isMedicalPassport = (content: string) =>
  content === 'medicalPassport' ||
  content === 'medicalPassport1' ||
  content === 'medicalPassport2' ||
  content === 'medicalPassport3' ||
  content === 'medicalPassport8' ||
  content === 'medicalPassport45' ||
  content?.startsWith('formsv2://');

const FormContent = ({
  id,
  name,
  content,
  defaultValue: defaultValueProp,
  value: valueProp,
  realValue: realValueProp,
  patientId,
  procedureId,
  signature,
  signedAt,
  entryQuestionnaire,
  lastPreOpCompletedEvent,
  withActions,
  isolated,
  headerBackground,
  headerOffset,
  MoreActionsComponent,
}: any) => {
  const scope = useScope();
  const hospitalName = scope?.hospital?.name;
  const hospitalId = scope?.hospital?.id;
  const showQRCode = scope?.hospital?.modules?.showQRCode || false;
  const hasAccessRight = useHasAccessRight();
  const isAllowedToView = hasAccessRight('patient.view');
  const isAllowedToEdit = hasAccessRight('patient.edit');
  const theme = useTheme();

  const questionnaire = get(entryQuestionnaire, 'questions');
  const questionnaireAnswers = get(entryQuestionnaire, 'answers');
  const defaultValue = useMemo(() => JSON.parse(defaultValueProp || '{}'), [defaultValueProp]);
  const value = useMemo(() => JSON.parse(valueProp || '{}'), [valueProp]);
  const realValue = useMemo(() => JSON.parse(realValueProp || '{}') || {}, [realValueProp]);
  const remoteValue = useMemo(() => merge({}, defaultValue, value), [defaultValue, value]);
  const actualValue = useMemo(() => (isMedicalPassport(content) ? realValue : value), [content, realValue, value]);
  const [localValue, setLocalValue] = useState(actualValue);
  const [isPdf, setIsPdf] = useState(false);
  const [numPages, setNumPages] = useState<number>();

  useEffect(() => {
    const isPdfFile = content && content.toLowerCase().endsWith('.pdf');
    const isS3Link = content && content.startsWith('https://assets-ospitek.s3.us-west-1.amazonaws.com/');
    setIsPdf(isPdfFile || isS3Link);
  }, [content]);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  useEffect(() => {
    setLocalValue((lv: any) => merge({}, lv, actualValue));
  }, [actualValue]);

  const combinedValue = useMemo(() => {
    const keys = difference(getObjectDeepKeys(remoteValue), getObjectDeepKeys(localValue));
    /* @ts-ignore */
    return omit(merge({}, remoteValue, localValue), keys);
  }, [remoteValue, localValue]);
  const isDirty = useMemo(() => {
    return isMedicalPassport(content)
      ? !isEqual(cleanedUp(omit(localValue, 'system')), cleanedUp(omit(actualValue, 'system')))
      : !isEqual(cleanedUp(omit(combinedValue, 'system')), cleanedUp(omit(remoteValue, 'system')));
  }, [content, localValue, actualValue, combinedValue, remoteValue]);

  const contentRef = useRef<HTMLElement | undefined>();
  const [updateFormMutation] = useMutation(form.update);
  const [working, setWorking] = useState(false);
  const [autosaving, setAutosaving] = useState(false);

  const updateForm = async () => {
    setWorking(true);

    try {
      if (isMedicalPassport(content)) {
        await updateFormMutation({
          variables: { id, procedureId, value: JSON.stringify(combinedValue), base: JSON.stringify(realValue) },
          refetchQueries: [{ query: procedureForms, variables: { procedureId } }],
        });
      } else {
        const curr = get(contentRef, 'current.node');
        const findInputsRecursively = (doc: Document) => {
          const inputs = doc.querySelectorAll('textarea, input');
          const iframes = doc.querySelectorAll('iframe');
          return [...inputs, ...[...iframes].flatMap(iframe => findInputsRecursively(iframe.contentDocument))];
        };
        const inputs = findInputsRecursively(curr.contentDocument);
        const keys = compact([...inputs].map(input => (!!input.name ? input.name : input.id)));
        await updateFormMutation({
          variables: {
            id,
            procedureId,
            value: JSON.stringify(pick(combinedValue, keys)),
            base: JSON.stringify(realValue),
          },
          refetchQueries: [{ query: procedureForms, variables: { procedureId } }],
        });
      }
    } catch (e) {
      console.warn(e);
    } finally {
      setWorking(false);
    }
  };

  const promptUpdateForm = () => {
    if (signature) {
      if (
        window.confirm(
          'Patient signed this form already, changing it will require them to sign again! Are you sure you want to save changes?'
        )
      ) {
        return updateForm();
      }
    } else {
      return updateForm();
    }
  };

  const cancelEditing = () => {
    setLocalValue(actualValue);
  };

  const printForm = () => {
    if (isPdf) {
      print(content);
    } else {
      const curr = get(contentRef, 'current.node');

      if (curr) {
        curr.contentWindow.focus();
        curr.contentWindow.print();
      }
    }
  };

  /* @ts-ignore */
  const LegacyForm = legacyPredefinedForms[content] || LegacyDatabaseHtmlForm;
  /* @ts-ignore */
  const Form = useMemo(() => resolveForm(content), [content]);

  const handleSetValue = useCallback(arg => {
    if (isFunction(arg)) {
      /* @ts-ignore */
      setLocalValue(prev => sanitize(arg(prev)) || {});
    } else {
      setLocalValue(sanitize(arg) || {});
    }
  }, []);

  const editorRef = useRef<HTMLElement>();

  const { accessToken } = useConnectionProviderContext();

  useEffectUntilDestructorIsDefined(() => {
    if (isPdf) {
      return;
    } else {
      const editor = editorRef.current;

      if (!editor) {
        console.warn('No editor defined when setting up vitals');
        return;
      }

      const iframe = editor.querySelector('iframe');

      if (!iframe) {
        console.warn('No iframe defined when setting up vitals');
        return;
      }

      if (!iframe.contentDocument) {
        console.warn('No iframe.contentDocument defined when setting up vitals');
        return;
      }

      const injectionContainers = iframe.contentDocument.body.querySelectorAll('div[id^="inject"]');

      if (injectionContainers.length === 0) {
        const numberOfDivs = iframe.contentDocument.body.querySelectorAll('div').length;

        return numberOfDivs > 0 ? () => {} : undefined;
      }

      injectionContainers.forEach(injectionContainer => {
        const id = injectionContainer.id.replace('inject-', '');

        const src = `${location.protocol}//${location.host}/inject/${hospitalId}/${id}/${patientId}?token=${accessToken}`;

        ReactDOM.render(
          <Portal
            src={src}
            height={
              id === 'vitals' || id === 'anesthesia-table' ? '600px' : id === 'vitals-signature' ? '100px' : '200px'
            }
          />,
          injectionContainer
        );
      });

      return () =>
        injectionContainers.forEach(injectionContainer => {
          ReactDOM.unmountComponentAtNode(injectionContainer);
        });
    }
  }, [isPdf, hospitalId, patientId]);

  const { newDictation, clear } = useDictationContext();

  useEffect(() => {
    if (newDictation) {
      setLocalValue(p => ({ ...p, dictations: p.dictations + '\n\n' + newDictation }));
      setAutosaving(true);
      clear();
    }
  }, [newDictation]);

  useEffect(() => {
    if (autosaving) {
      (async () => {
        await updateForm();
      })();
      setAutosaving(false);
    }
  }, [autosaving]);

  return (
    <DictationTemplateProvider
      defaultValue={localValue?.dictations || defaultValue?.dictations || ''}
      onChangeValue={v => setLocalValue(p => ({ ...p, dictations: v }))}
    >
      <InstructionBody>
        <TemplateEditor>
          <ThemeProvider theme={lightTheme}>
            {/* @ts-ignore */}
            {!isolated && (
              <Box
                py={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                style={{
                  position: 'sticky',
                  zIndex: 1,
                  top: headerOffset || 0,
                  backgroundColor: headerBackground || theme.palette.background.default,
                }}
                // flexEnd={!withActions}
              >
                {withActions && (
                  <Tooltip
                    content={isAllowedToEdit ? null : 'You don’t have sufficient permissions to edit this document.'}
                  >
                    {!isPdf && (
                      <Box display="flex" alignItems="center" style={{ gap: 8 }}>
                        {/* @ts-ignore */}
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={promptUpdateForm}
                          disabled={!isDirty || working || !isAllowedToEdit}
                        >
                          Save Changes
                        </Button>
                        {isDirty && (
                          <Button variant="outlined" onClick={cancelEditing} disabled={working}>
                            Cancel
                          </Button>
                        )}
                      </Box>
                    )}
                  </Tooltip>
                )}
                {MoreActionsComponent && <MoreActionsComponent />}
                <Tooltip
                  content={isAllowedToView ? null : 'You don’t have sufficient permissions to view this document.'}
                >
                  <Button startIcon={<PictureAsPdfIcon />} onClick={printForm} disabled={!isAllowedToView}>
                    Print Form
                  </Button>
                </Tooltip>
              </Box>
            )}

            <EditorPreview ref={editorRef} working={working}>
              {/* Conditionally render PDF or Form */}
              {isPdf ? (
                <Document file={content} onLoadSuccess={onDocumentLoadSuccess}>
                  {Array.from(new Array(numPages), (el, index) => (
                    <div key={`page_${index}`} style={{ marginBottom: '10px' }}>
                      <Page scale={96 / 72} pageNumber={index + 1} />
                    </div>
                  ))}
                </Document>
              ) : Form ? (
                <FormIframe ref={contentRef as LegacyRef<HTMLIFrameElement>} noStyles={Form['V2']}>
                  <Form
                    procedureId={procedureId}
                    hospitalId={hospitalId}
                    hospitalName={hospitalName}
                    content={content}
                    defaultValue={remoteValue}
                    localValue={localValue}
                    combinedValue={combinedValue}
                    value={isMedicalPassport(content) ? localValue : combinedValue}
                    setValue={handleSetValue}
                    signature={signature}
                    signedAt={signedAt}
                    questionnaire={questionnaire}
                    questionnaireAnswers={questionnaireAnswers}
                    lastPreOpCompletedEvent={lastPreOpCompletedEvent}
                    editable={withActions}
                    formName={name}
                    showQRCode={showQRCode}
                  />
                </FormIframe>
              ) : (
                <LegacyForm
                  procedureId={procedureId}
                  hospitalId={hospitalId}
                  hospitalName={hospitalName}
                  ref={contentRef}
                  content={content}
                  defaultValue={remoteValue}
                  value={isMedicalPassport(content) ? localValue : combinedValue}
                  setValue={handleSetValue}
                  signature={signature}
                  signedAt={signedAt}
                  questionnaire={questionnaire}
                  questionnaireAnswers={questionnaireAnswers}
                  lastPreOpCompletedEvent={lastPreOpCompletedEvent}
                  editable={withActions}
                  formName={name}
                  showQRCode={showQRCode}
                />
              )}
            </EditorPreview>
          </ThemeProvider>
        </TemplateEditor>
      </InstructionBody>
    </DictationTemplateProvider>
  );
};

export default FormContent;

const useEffectUntilDestructorIsDefined = (effect: EffectCallback, deps?: DependencyList) =>
  useEffect(() => {
    let idleCallback: ReturnType<typeof requestIdleCallback> | undefined;
    let destructor: ReturnType<EffectCallback>;

    const run = () => {
      destructor = effect();

      if (destructor) {
        return;
      }

      idleCallback = requestIdleCallback(run);
    };

    run();

    return () => {
      if (idleCallback !== undefined) {
        cancelIdleCallback(idleCallback);
      }
      if (destructor) {
        destructor();
      }
    };
  }, deps);

const Portal = ({ src, height }: { src: string; height: CSSProperties['height'] }) => (
  <div>
    <iframe src={src} style={{ border: 'none', width: '100%', height }} />
  </div>
);

function resolveForm<P extends {}>(input: unknown): FunctionComponent<P> | ComponentClass<P> | string | undefined {
  if (typeof input === 'string' && input.startsWith('formsv2://')) {
    const V2 = formsV2[input.substring(10)];

    if (V2) {
      return PredefinedForm(FormV2(V2));
    }
  }

  const json = tryParseJson(input as any);

  if (!Array.isArray(json)) {
    return;
  }

  return resolveFormRecursively(json);
}

export function resolveFormRecursively<P extends {}>(
  input: unknown
): FunctionComponent<P> | ComponentClass<P> | string {
  const Form = Array.isArray(input)
    ? combinedForm(input.map((item, i) => resolveFormRecursively(item)))
    : predefinedForms[input] || DatabaseHtmlForm;

  return resolveConcreteForm(input, Form);
}

function resolveFormFromArray<P extends {}>(input: unknown[]): FunctionComponent<P> | ComponentClass<P> | string {
  const Form = combinedForm<P>(input.map((item, i) => resolveFormRecursively(item)[0]));

  return resolveConcreteForm(input, Form);
}

function resolveConcreteForm<P extends {}>(
  input: unknown,
  Form: FunctionComponent<P> | ComponentClass<P> | string
): FunctionComponent<P> | ComponentClass<P> | string {
  const Component = forwardRef<HTMLElement, P>((props, ref) => {
    const value =
      (isMedicalPassport(input as string) ? get(props, 'localValue') : get(props, 'combinedValue')) ??
      get(props, 'value');

    return <Form ref={ref} {...props} content={input} value={value} />;
  }) as unknown as FunctionComponent<P>;

  Component.displayName = 'ResolvedForm';

  return Component;
}
