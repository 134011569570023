import React, { forwardRef, LegacyRef, RefObject, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import IMask from 'imask';
import { format as formatDate } from 'date-fns';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import useMouseDownRef from './useMouseDownRef';
import { alpha } from '@material-ui/core';

export interface MeasurementInputProps {
  format?:
    | 'BloodPressure'
    | 'HeartRate'
    | 'MilitaryTime'
    | 'RespirationRate'
    | 'Saturation'
    | 'O2'
    | 'TEMP'
    | 'PAIN'
    | 'String';
  disabled?: boolean;
  autoFocus?: boolean;
  preFill?: boolean;
  selectOnFocus?: boolean;
  highlightOnFocus?: boolean;
  value: string;
  onChange: (value: string) => void;
  onComplete: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

export interface MeasurementInputControl {
  inputRef: RefObject<HTMLInputElement>;
  focus(): void;
  blur(): void;
}

const MeasurementInput = forwardRef<MeasurementInputControl, MeasurementInputProps>(
  (
    {
      format,
      disabled,
      autoFocus,
      preFill,
      selectOnFocus,
      highlightOnFocus,
      value,
      onChange,
      onComplete,
      onFocus,
      onBlur,
    },
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement | undefined>();

    const control = useMemo<MeasurementInputControl>(
      () => ({
        inputRef: inputRef as RefObject<HTMLInputElement>,
        focus: () => inputRef.current?.focus(),
        blur: () => inputRef.current?.blur(),
      }),
      [inputRef]
    );

    useImperativeHandle(ref, () => control, [control]);

    const classes = useStyles();

    const isNumeric =
      format === 'BloodPressure' ||
      format === 'HeartRate' ||
      format === 'MilitaryTime' ||
      format === 'RespirationRate' ||
      format === 'Saturation' ||
      format === 'O2' ||
      format === 'TEMP' ||
      format === 'PAIN';

    useEffect(() => {
      if (preFill && format === 'MilitaryTime' && !value) {
        onChange?.(formatDate(new Date(), 'HH:mm'));
      }
    }, [preFill, format, value]);

    useEffect(() => {
      if (!inputRef.current) {
        return;
      }

      if (format === 'BloodPressure') {
        const bloodPressureMask = IMask(inputRef.current, {
          mask: [
            {
              mask: '#0/@0',
              definitions: {
                '#': /[3-9]/,
                '@': /[2-9]/,
              },
            },
            {
              mask: '#0/100',
              definitions: {
                '1': /1/,

                '#': /[3-9]/,
                '@': /[2-9]/,
              },
            },
            {
              mask: '100/@0',
              definitions: {
                '1': /[12]/,
                '@': /[2-9]/,
              },
            },
            {
              mask: '100/100',
              definitions: {
                '1': /[12]/,
              },
            },
          ],
        });

        bloodPressureMask.on('complete', () => {
          requestAnimationFrame(() => {
            onComplete();
          });
        });

        return () => bloodPressureMask.destroy();
      }

      if (format === 'HeartRate') {
        const digitsMask = IMask(inputRef.current, {
          mask: [
            {
              mask: '!00',
              definitions: {
                '!': /[12]/,
              },
            },
            {
              mask: '!0',
              definitions: {
                '!': /[3-9]/,
              },
            },
          ],
        });

        digitsMask.on('complete', () => {
          requestAnimationFrame(() => {
            onComplete();
          });
        });

        return () => digitsMask.destroy();
      }

      if (format === 'Saturation') {
        const digitsMask = IMask(inputRef.current, {
          mask: [
            {
              mask: '!zz',
              definitions: {
                '!': /1/,
                z: /0/,
              },
            },
            {
              mask: '@0',
              definitions: {
                '@': /[2-9]/,
              },
            },
          ],
        });

        digitsMask.on('complete', () => {
          requestAnimationFrame(() => {
            onComplete();
          });
        });

        return () => digitsMask.destroy();
      }

      if (format === 'RespirationRate') {
        const digitsMask = IMask(inputRef.current, {
          mask: '00',
        });

        digitsMask.on('complete', () => {
          requestAnimationFrame(() => {
            onComplete();
          });
        });

        return () => digitsMask.destroy();
      }

      if (format === 'O2') {
        const digitsMask = IMask(inputRef.current, {
          mask: [
            {
              mask: '!zz',
              definitions: {
                '!': /1/,
                z: /0/,
              },
            },
            {
              mask: '@0',
              definitions: {
                '@': /[1-9]/,
              },
            },
          ],
        });

        digitsMask.on('complete', () => {
          requestAnimationFrame(() => {
            onComplete();
          });
        });

        return () => digitsMask.destroy();
      }

      if (format === 'TEMP') {
        const tempMask = IMask(inputRef.current, {
          mask: [
            {
              mask: '000{.}0',
            },
            {
              mask: '00{.}0',
            },
          ],
        });

        tempMask.on('complete', () => {
          requestAnimationFrame(() => {
            onComplete();
          });
        });

        return () => tempMask.destroy();
      }

      if (format === 'PAIN') {
        const painMask = IMask(inputRef.current, {
          mask: [
            {
              mask: '00',
            },
            {
              mask: '0',
            },
          ],
        });

        painMask.on('complete', () => {
          requestAnimationFrame(() => {
            onComplete();
          });
        });

        return () => painMask.destroy();
      }

      if (format === 'MilitaryTime') {
        const militaryTimeMask = IMask(inputRef.current, {
          mask: 'HH{:}MM',
          blocks: {
            HH: {
              mask: IMask.MaskedRange,
              from: 0,
              to: 23,
            },
            MM: {
              mask: IMask.MaskedRange,
              from: 0,
              to: 59,
            },
          },
        });

        militaryTimeMask.on('complete', () => {
          requestAnimationFrame(() => {
            onComplete();
          });
        });

        return () => militaryTimeMask.destroy();
      }
    }, [format]);

    const mouseDownRef = useMouseDownRef();

    const handleFocus = () => {
      if (selectOnFocus && !mouseDownRef.current) {
        inputRef.current?.select();
      }

      onFocus?.();
    };

    const handleBlur = () => {
      onBlur?.();
    };

    const handleMouseDown = () => {
      const handleMouseUp = () => {
        const input = inputRef.current;

        if (!input) {
          return;
        }

        if (input.selectionStart === input.selectionEnd) {
          input.select();
        }
      };

      document.addEventListener('mouseup', handleMouseUp);

      return () => document.removeEventListener('mouseup', handleMouseUp);
    };

    return (
      <Box display="grid" className={classes.container}>
        <input
          ref={inputRef as LegacyRef<HTMLInputElement>}
          type="text"
          autoFocus={autoFocus}
          autoComplete="off"
          placeholder={format === 'BloodPressure' ? 'SYS/DIA' : undefined}
          inputMode={isNumeric ? 'numeric' : undefined}
          disabled={disabled}
          value={value}
          onChange={e => {
            onChange?.(e.target.value);
          }}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onMouseDown={selectOnFocus ? handleMouseDown : undefined}
          className={clsx(classes.input, { [classes.highlightOnFocus]: highlightOnFocus })}
        />
        {highlightOnFocus && <div className={classes.highlight} />}
        <span className={classes.span}>{value}</span>
      </Box>
    );
  }
);

const useStyles = makeStyles(theme => ({
  input: {
    fontSize: 'inherit',
    fontFamily: 'inherit',
    fontWeight: 'inherit',
    lineHeight: '1',
    color: 'inherit',
    background: 'inherit',
    padding: 0,
    margin: 0,
    border: 'none',
    outline: 'none',
    gridRow: '1 / span 1',
    gridColumn: '1 / span 1',
    width: '100%',
    '&::placeholder': {
      color: alpha(theme.palette.primary.main, 0.3),
    },
  },
  span: {
    gridRow: '1 / span 1',
    gridColumn: '1 / span 1',
    pointerEvents: 'none',
    visibility: 'hidden',
  },
  container: {
    position: 'relative',
  },
  highlight: {
    display: 'none',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: 'white',
    zIndex: -1,
    margin: '-8px',
  },
  highlightOnFocus: {
    '&:focus + div': {
      display: 'block',
    },
  },
}));

export default MeasurementInput;
