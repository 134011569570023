import { Query } from '@apollo/client/react/components';
import React, { Fragment } from 'react';
import { branch, compose, renderComponent, withState } from 'recompose';
import EntityView from '../../../../se/components/entity/EntityView';
import ChangeLocation from './ChangeLocationForm';
import ChangeBracelet from './ChangeBraceletForm';
import { DurationBreakdown, TrackingPanel } from './dataFields';
import PatientEvents from '../patientJourney/PatientEvents';
import { calculateDuration, getRoom } from '../../common/transducers';
import { getNestedValue } from '../../../../se/utilities/data/object';
import { unpackSessionObject } from '../../../pages/unpackSessionObject';
import { withRouter } from 'react-router';
import withDebugKey from '../../../../hocs/withDebugKey';
import SignalAnalysis from '../../../pages/signal/SignalAnalysis';
import LinkButton from '../../../../se/components/LinkButton';
import styled from 'styled-components';
import { withSession } from '../../../../state/Session';
import { STATUSES } from '../enums';
import { signalProcessing } from '../../../../graph/signal';
import { CenteredSpinner } from '../../../../se/components/Spinner';
import get from 'lodash/get';
import { NamedRange } from '../../../core/DatePicker';
import { convertHoursAndMinutesForSlider, getRoomEntry, procedureAccuracy } from '../../../../util/procedureAccuracy';
import Box from '@material-ui/core/Box';
import FileUploader from '../../../FileUploader';
import PatientFileGenerator from './PatientFileGenerator';
import ScanChartFileGenerator from './ScanChartFileGenerator';
import { useConnectionProviderContext } from '../../../pages/kiosk/connectionProviderContext';
import useCareAppAccess from '../../procedures/utils/useCareAppAccess';
import ChartingQuestionnairesContext from '../../../../contexts/ChartingQuestionnairesContext';
import ChartingForms from '../../../pages/kiosk/charting/ChartingForms';
import { FormType } from '../../../../types/Form';
import { withScope } from '../../../../contexts/ScopeContext';

const RightAlignedLinkButton = styled(LinkButton)`
  justify-content: flex-end;
  margin-bottom: -4em;
  padding-top: 1em;
  margin-left: auto;
`;

export const withOptionalDebug = Component =>
  compose(
    withSession(unpackSessionObject),
    branch(props => props.isSuperAdmin || props.isMonitoringEngineer, renderComponent(withDebugKey(Component)))
  )(Component);

const PatientViewContent = props => {
  const { scope } = props;
  const timeZone = get(scope, 'hospital.timezone.id');
  const { accessToken } = useConnectionProviderContext();
  useCareAppAccess(get(scope, 'hospital.id'), accessToken);
  const {
    id,
    waitingRoomTime,
    prepTime,
    orTime,
    postopTime,
    pacuTime,
    createdAt,
    dischargedAt,
    voidedAt,
    room,
    braceletId,
    status,
  } = props.data || {};

  const orRoomTimes = get(props.data, 'orRoomTimes', []);
  const startTime = get(props.data, 'procedure.startTime', false);
  const duration = get(props.data, 'procedure.duration', false);
  const [enteredAt, exitedAt] = orRoomTimes.length > 0 ? getRoomEntry(orRoomTimes) : [0, 0];
  const calculate = startTime && duration && enteredAt && exitedAt;
  const procedureSchedulingAccuracy = calculate
    ? procedureAccuracy(startTime, duration, enteredAt, exitedAt, timeZone)
    : false;
  const [scheduledRange, actualRange, sliderRange] = calculate
    ? convertHoursAndMinutesForSlider(startTime, duration, enteredAt, exitedAt, timeZone)
    : [
        [0, 0],
        [0, 0],
        [6, 18],
      ];
  const { signalLogShown, setSignalLogShown, isSuperAdmin, isMonitoringEngineer, debug } = props;

  const totalTime = calculateDuration(createdAt, dischargedAt || voidedAt);
  const isActivePatient = !(dischargedAt || voidedAt);

  const debugEnabled = debug && (isSuperAdmin || isMonitoringEngineer);
  const procedureId = props?.data?.procedure?.id;
  const procedure = props?.data?.procedure;
  const hasPatientChartForm = scope?.hospital?.modules?.patientCharting;
  const hasQRCode = get(scope, 'hospital.modules.showQRCode');

  const events = props?.data?.log || [];

  return (
    <Fragment>
      <EntityView {...props} />

      <Box mt={2}>
        <TrackingPanel
          isActive={isActivePatient}
          isDischarged={!!dischargedAt}
          isCanceled={!!dischargedAt && status === STATUSES.CANCELED}
          patientId={id}
          room={getRoom(room)}
          braceletId={braceletId}
          setEditModalOpen={props.setEditModalOpen}
          procedureSchedulingAccuracy={procedureSchedulingAccuracy}
          scheduledRange={scheduledRange}
          actualRange={actualRange}
          sliderRange={sliderRange}
          showBracelet
          showProcedureSchedulingAccuracy
          withActionButton
          isChangeLocationDisabled={props?.isPhysician}
        />
      </Box>

      <DurationBreakdown
        totalTime={totalTime}
        waitingRoomTime={waitingRoomTime}
        prepTime={prepTime}
        orTime={orTime}
        pacuTime={pacuTime}
        postopTime={postopTime}
      />

      {!props?.isPhysician && (
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'right' }}>
          {procedureId && hasQRCode && <ScanChartFileGenerator procedureId={procedureId} />}
          {procedureId && (
            <PatientFileGenerator
              procedureId={procedureId}
              formTypes={[
                FormType.PreOpForm,
                FormType.InTakeForm,
                FormType.PreOpChartForm,
                FormType.OrChartForm,
                FormType.PacuChartForm,
                FormType.PhysicianChartForm,
                FormType.AnesthesiologistOrChartForm,
                FormType.AnesthesiologistPreOpChartForm,
                FormType.AnesthesiologistPacuChartForm,
              ]}
              chartingTablet={false}
            />
          )}
        </div>
      )}

      {procedureId && <FileUploader procedureId={procedureId} />}

      {hasPatientChartForm && (
        <ChartingQuestionnairesContext.Provider
          value={{
            preOpCharts: procedure?.preOpCharts || [],
            orCharts: procedure?.orCharts || [],
            pacuCharts: procedure?.pacuCharts || [],
          }}
        >
          <ChartingForms
            hospitalId={scope?.hospital?.id}
            patientId={id}
            procedureId={procedureId}
            questionnaire={procedure?.entryQuestionnaire || {}}
            procedure={procedure || {}}
            pacuCharts={procedure?.pacuCharts || {}}
          />
        </ChartingQuestionnairesContext.Provider>
      )}

      {debugEnabled && (
        <RightAlignedLinkButton style={{ zIndex: 1000 }} onClick={() => setSignalLogShown(!signalLogShown)}>{`${
          signalLogShown ? 'Hide' : 'Show'
        } Signal Log`}</RightAlignedLinkButton>
      )}
      {signalLogShown && (
        <Query query={signalProcessing.item}>
          {({ data: config, loading }) =>
            !loading ? (
              <SignalAnalysis patientId={props.data.id} config={get(config, 'signalProcessingConfig')} />
            ) : (
              <CenteredSpinner />
            )
          }
        </Query>
      )}

      {events.length !== 0 ? <PatientEvents events={events} debug={debugEnabled} patientId={id} /> : null}

      {props.editModalOpen.room && (
        <ChangeLocation
          patientId={props.data.id}
          initialValue={getNestedValue('id', room)}
          setEditModalOpen={props.setEditModalOpen}
        />
      )}

      {props.editModalOpen.braceletId && (
        <ChangeBracelet patientId={props.data.id} initialValue={braceletId} setEditModalOpen={props.setEditModalOpen} />
      )}
    </Fragment>
  );
};

export default compose(
  withScope,
  withRouter,
  withState('filter', 'setFilter', {
    dateRange: NamedRange.lastSevenDays(),
  }),
  withState('signalLogShown', 'setSignalLogShown', false),
  withState('editModalOpen', 'setEditModalOpen', {
    room: false,
    braceletId: false,
  }),
  withOptionalDebug
)(PatientViewContent);
