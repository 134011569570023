import { Mutation } from '@apollo/client/react/components';
import React from 'react';
import { setFamilyReadyForPickupPACU } from '../../../../graph/patients';

const withSetFamilyReadyForPickupPACU = Component => props => (
  <Mutation mutation={setFamilyReadyForPickupPACU}>
    {setFamilyReadyForPickup => <Component {...props} setFamilyReadyPACU={setFamilyReadyForPickup} />}
  </Mutation>
);

export default withSetFamilyReadyForPickupPACU;
