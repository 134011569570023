import React, { FC, Fragment } from 'react';
import StaffShifts from './staff/StaffShifts';
import StaffShiftMenu from './staff/StaffShiftMenu';
import { StaffShiftRoomContext } from './staff/StaffShiftContext';
import { StaffSlotRoomContext } from './staff/StaffSlotContext';
import { Room } from '../../../../types/Room';
import MultipleSelectionCheckbox from './staff/MultipleSelectionCheckbox';
import { Box, Theme, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { primarySpecialtyForRoomType } from '../../../../types/StaffMember';
import ScheduleMobileFooter from './ScheduleMobileFooter';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Divider from '@material-ui/core/Divider';
import { getSortedRooms, getSortedRoomsAnesthesiologist } from './functions';
import { Anesthesiologist } from '../schedule/anesthesiologist/PersonnelUtilityRoom';

interface ScheduleFooterProps {
  isPowerUser: boolean;
  isScheduleUser: boolean;
  isFrontDesk: boolean;
  hospitalId: number;
  date: string;
  rooms: Array<Room>;
  staffId?: number;
  myProceduresChecked: boolean;
  editableStaff: boolean;
  isKiosk: boolean;
  isFooter: boolean;
  anesthesiologistView: boolean;
  anesthesiologistDate: Date;
}

const FooterContent: FC<ScheduleFooterProps> = ({
  isFrontDesk,
  hospitalId,
  date,
  rooms,
  staffId,
  myProceduresChecked,
  editableStaff,
  isKiosk,
  isFooter,
  anesthesiologistView,
  anesthesiologistDate,
}) => {
  const canAddStaff = !isKiosk && !isFrontDesk && editableStaff;
  const sortedRooms = anesthesiologistView
    ? getSortedRoomsAnesthesiologist(rooms, myProceduresChecked, staffId)
    : getSortedRooms(rooms, myProceduresChecked, staffId);
  const showFooter = sortedRooms?.length > 0;
  const classes = useStyles({ isFooter });

  if (!showFooter) return null;

  return (
    <Box className={classes.rooms}>
      {sortedRooms.map((room, index) => (
        <Fragment key={room.id}>
          <Box className={classes.roomRow} key={room.id}>
            <StaffShiftRoomContext hospitalId={hospitalId} roomId={room.id} date={date} staffShifts={[]}>
              {canAddStaff ? (
                <MultipleSelectionCheckbox roomId={room.id} label={room.name} />
              ) : (
                <Typography>{room.name}</Typography>
              )}
              <StaffSlotRoomContext hospitalId={hospitalId} roomId={room.id} date={date}>
                {canAddStaff && (
                  <StaffShiftMenu
                    defaultPrimarySpecialty={primarySpecialtyForRoomType(room.type)}
                    lightMode={anesthesiologistView}
                  />
                )}
                <StaffShifts
                  isFooter={isFooter}
                  staffShifts={room.staffShifts || []}
                  isKiosk={isKiosk}
                  editableStaff={editableStaff}
                  type="RoomShift"
                />
              </StaffSlotRoomContext>
            </StaffShiftRoomContext>
            {room.type === 'ANESTHESIOLOGIST' && (
              <>
                <Box ml={1}>
                  <Anesthesiologist
                    date={anesthesiologistDate}
                    roomId={room.id}
                    roomType={room?.type}
                    room={room}
                    isKiosk={isKiosk}
                  />
                </Box>
              </>
            )}
          </Box>
          {index < sortedRooms.length - 1 && (
            <Box className={classes.roomListDivider} my={1}>
              <Divider />
            </Box>
          )}
        </Fragment>
      ))}
    </Box>
  );
};

const ScheduleFooter: FC<ScheduleFooterProps> = ({
  isFrontDesk,
  hospitalId,
  date,
  rooms,
  staffId,
  myProceduresChecked,
  editableStaff,
  isKiosk,
  isFooter,
  isPowerUser,
  isScheduleUser,
  anesthesiologistView,
  anesthesiologistDate,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return isMobile ? (
    isPowerUser || isScheduleUser ? (
      <ScheduleMobileFooter>
        <FooterContent
          isFooter={isFooter}
          isPowerUser={isPowerUser}
          isScheduleUser={isScheduleUser}
          isFrontDesk={isFrontDesk}
          hospitalId={hospitalId}
          date={date}
          rooms={rooms}
          staffId={staffId}
          myProceduresChecked={myProceduresChecked}
          editableStaff={editableStaff}
          isKiosk={isKiosk}
          anesthesiologistView={anesthesiologistView}
          anesthesiologistDate={anesthesiologistDate}
        />
      </ScheduleMobileFooter>
    ) : null
  ) : (
    <FooterContent
      isFooter={isFooter}
      isPowerUser={isPowerUser}
      isScheduleUser={isScheduleUser}
      isFrontDesk={isFrontDesk}
      hospitalId={hospitalId}
      date={date}
      rooms={rooms}
      staffId={staffId}
      myProceduresChecked={myProceduresChecked}
      editableStaff={editableStaff}
      isKiosk={isKiosk}
      anesthesiologistView={anesthesiologistView}
      anesthesiologistDate={anesthesiologistDate}
    />
  );
};

const useStyles = makeStyles<Theme, { isFooter: boolean }>(theme => ({
  rooms: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(0, 3),
    padding: theme.spacing(1, 2),
    flexDirection: props => (props.isFooter ? 'row' : 'column'),
    backgroundColor: theme.palette.background.paper,

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2, 0, 1),
      display: 'block',
      '& > div:first-of-type': {
        border: 'none',
      },
    },
  },

  roomRow: {
    display: 'flex',
    flexDirection: props => (props.isFooter ? 'row' : 'column'),
    alignItems: 'center',
    maxWidth: '100%',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      padding: theme.spacing(0, 2),
    },
  },

  roomListDivider: {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
}));

export default ScheduleFooter;
